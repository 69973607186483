export const cart = {
  namespaced: true,
  state: {
    data: {},
    items: [],
    subscriptions: []
  },
  mutations: {
    set(state, data) {
      state.data = data || {};
    },
    setItems(state, items) {
      state.items = items || [];
    },
    setSubscriptions(state, subscriptions) {
      state.subscriptions = subscriptions || [];
    },
  },
};
  