<template>
  <ion-page>
    <top-navigation />
    <ion-content
      :fullscreen="true"
      class="ion-padding"
    >
      <account-side-navigation class="notif-con">
        <slot>
          <div class="flex flex-wrap items-center justify-between">
            <h1 class="font--h4 font-bold">
              Notifications
            </h1>
            <div class="flex items-center">
              <input
                class="rounded-sm mr-2 cursor-pointer"
                type="checkbox"
                id="markread"
                v-model="markAllAsRead"
              >
              <label
                class="form-check-label inline-block text--gray--300"
                for="markread"
              >
                Mark all as read
              </label>
            </div>
          </div>
          <template v-if="items.length">
            <NotificationList 
              v-for="(item,index) in items"
              :key="`notification-item-${index}`"
              :item="item"
              @refresh="fetch"
            />
          </template>
          <template v-else>
            <span class="text--gray--300">No notifications found.</span>
          </template>
        </slot>
      </account-side-navigation>
      <bottom-navigation />
    </ion-content>
  </ion-page>
</template>

<script type="text/javascript">

import { computed, defineComponent, ref, watch } from 'vue';

import { IonPage, IonContent, modalController } from '@ionic/vue';
import BottomNavigation from '@/partials/BottomNavigation.vue';
import TopNavigation from '@/partials/TopNavigation.vue';
import AccountSideNavigation from '@/partials/AccountSideNavigation.vue';
import NotificationList from './components/NotificationList.vue';
import DropletsModal from '@/components/DropletsModal.vue';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'NotificationPage',
  components: {
    IonPage,
    IonContent,
    BottomNavigation,
    TopNavigation,
    AccountSideNavigation,
    NotificationList
  },
  setup() {
    
    const store = useStore();
    
    const items = ref([]);
    const markAllAsRead = ref(false);

    const fetchUrl = computed(() => store.state.api.routes.fetchNotifications);

    const fetch = (url) => {
      window.axios.post(url || fetchUrl.value)
        .then(({ data }) => {
          items.value = data.items;
        });
    };

    const readAll = () => {
      window.axios.post(store.state.api.routes.readAllNotifications)
        .then(() => {
          fetch();
        });
    };

    watch(
      () => fetchUrl.value,
      (url) => {
        if(typeof url === 'string') fetch();
      },
      { immediate: true }
    );

    watch(
      () => markAllAsRead.value,
      (bool) => {
        if(bool) readAll();
      }
    );

    const openDropletModal = async () => {
        const modal = await modalController.create({
                component: DropletsModal,
                cssClass: 'gen-modal',
                componentProps: {
                droplets: '50',
                itemHref: '#',
            }
        });
        return modal.present();
    };

    return {
      items,
      markAllAsRead,
      fetch,
      openDropletModal
    }
  }
})
</script>
