export const checkout = {
  namespaced: true,
  state: {
    cartId: null,
    referenceNumber: null,
    notes: null,
    paymentMethod: 'CASH',
    paymentStatus: 1,
    referralCode: null,
    items: [],
    dataItems: [],
    address: {},
    oneTimePayload: {},
    subscriptionPayload: [],
    replacements: [],
    utmTags: {},
    firstPurchaseDiscount: 0,
    voucherDiscount: 0,
    vouchers: []
  },
  mutations: {
    set(state, payload) {
      Object.keys(payload).forEach(key => {
        state[key] = payload[key];
      });
    },
    addItem(state, item) {
      const index = state.items.findIndex(x => x.id == item.id);
      if(index === -1) state.items.push(item);
      else state.items[index] = item;
    },
    updateItem(state, item) {
      const index = state.items.findIndex(x => x.id == item.id);
      if(index !== -1) state.items[index] = item;
    },
    removeItem(state, item) {
      const index = state.items.findIndex(x => x.id == item.id);
      if(index !== -1) state.items.splice(index, 1);
    },
    setAsUnavailable(state, item) {
      const index = state.items.findIndex(x => x.id == item.id);
      if(index !== -1) state.items[index].unavailable = true;
    },
    setPaymentMethod(state, value) {
      state.paymentMethod = value;
    },
    setAddress(state, payload) {
      state.address = payload;
    },
    setOneTimePurchase(state, payload) {
      Object.keys(payload).forEach(key => {
        state.oneTimePayload[key] = payload[key];
      });
    },
    setSubscriptions(state, items) {
      items.forEach(payload => {
        const index = state.subscriptionPayload.findIndex(x => x.id == payload.id);
        if(index !== -1) {
          Object.keys(payload).forEach(key => {
            state.subscriptionPayload[index][key] = payload[key];
          });
        } else {
          state.subscriptionPayload.push(payload);
        }
      });
    },
    initReplacement(state, productId) {
      const index = state.replacements.findIndex(x => x.productId == productId);
      if(index == -1) state.replacements.push({ productId });
      else state.replacements[index].replacementItemId = null;
    },
    setReplacement(state, id) {
      const index = state.replacements.findIndex(x => !x.replacementItemId);
      if(index !== -1) state.replacements[index].replacementItemId = id;
    },
    setFirstPurchaseDiscount(state, value) {
      state.firstPurchaseDiscount = value || 0;
    },
    setVoucherDiscount(state, value) {
      state.voucherDiscount = value || 0;
    },
    setVouchers(state, value) {
      state.vouchers = value || [];
    }
  },
};
