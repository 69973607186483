<template>
  <ion-page>
    <top-navigation />
    <ion-content
      :fullscreen="true"
      class="ion-padding"
    >
      <account-side-navigation>
        <slot>
          <h1 class="font--h4 font-bold mb-5">
            Subscriptions
          </h1>
          <div class="flex flex-wrap align-center justify-between">
            <div class="flex flex-wrap align-center lg:mb-3 mb-8">
              <ion-button
                v-for="(frequency,index) in frequencyOptions"
                :key="`frequency-filter-${index}`"
                shape="round"
                class="button tight mr-3 btn-primary"
                :class="{ 'text--white': isSelected('frequency', frequency.id) }"
                :fill="!isSelected('frequency', frequency.id) ? 'outline' : ''"
                @click="selectFrequency(frequency.id)"
              >
                {{ frequency.name }}
              </ion-button>
            </div>
            <div class="flex lg:flex-nowrap flex-wrap mb-3">
              <ion-select
                v-model="filters.duration"
                interface="popover"
                class="frm-select lg:mr-4 mr-0 lg:mb-0 mb-6 lg:w-250 w-full"
                placeholder="Duration: --"
              >
                <ion-select-option
                  v-for="(duration,index) in durationOptions"
                  :key="`duration-filter-${index}`"
                  :value="duration.id"
                >
                  {{ duration.name }}
                </ion-select-option>
              </ion-select>
              <ion-select
                v-model="sortBy"
                interface="popover"
                class="frm-select lg:mb-0 mb-6 lg:w-250 w-full"
                placeholder="Sort by: --"
              >
                <ion-select-option 
                  v-for="(sort,index) in sortOptions"
                  :key="`sort-option-${index}`"
                  :value="sort.id"
                >
                  {{ sort.name }}
                </ion-select-option>
              </ion-select>
            </div>
          </div>
          <subscription-list 
            v-for="(subscription,index) in filteredItems"
            :key="`subscription-item-${index}`"
            :item="subscription"
            @click="showSubscription(subscription.id)"
          />
        </slot>
      </account-side-navigation>
      <bottom-navigation />
    </ion-content>
  </ion-page>
</template>
<script type="text/javascript">

import { ref, defineComponent, computed, watch } from 'vue';
import { useStore } from 'vuex';

import {
    IonPage,
    IonContent,
    IonSelect,
    IonSelectOption,
    IonButton,
} from '@ionic/vue';
import BottomNavigation from '@/partials/BottomNavigation.vue';
import TopNavigation from '@/partials/TopNavigation.vue';
import AccountSideNavigation from '@/partials/AccountSideNavigation.vue';
import SubscriptionList from './components/SubscriptionList.vue';

export default defineComponent({
  name: 'SubscriptionIndexPage',
  components: {
    IonPage,
    IonContent,
    IonSelect,
    IonSelectOption,
    IonButton,
    BottomNavigation,
    TopNavigation,
    AccountSideNavigation,
    SubscriptionList
  },
  setup() {

    const store = useStore();

    /*
    |--------------------------------------------------------------------------
    | @Data
    |--------------------------------------------------------------------------
    */

    const subscriptions = ref([]);
    const sortBy = ref(null);
    const filters = ref({
      frequency: null,
      duration: null,
    });

    const frequencyOptions = ref([
      { id: null, name: 'All' },
      { id: 1, name: 'Weekly' },
      { id: 2, name: 'Bi-Weekly' },
      { id: 3, name: 'Monthly' },
    ]);

    const durationOptions = ref([
      { id: null, name: 'All' },
      { id: 3, name: '3 Months' },
      { id: 6, name: '6 Months' },
      { id: 12, name: '1 year' },
    ]);

    const sortOptions = ref([
      { id: 'next-delivery', name: 'Next Delivery' },
      { id: 'subscribed-date', name: 'Subscribed Date' },
    ]);

    /*
    |--------------------------------------------------------------------------
    | @Computed
    |--------------------------------------------------------------------------
    */

    const fetchSubscriptionsUrl = computed(() => store.state.api.routes.subscriptionFetch);
    const filteredItems = computed(() => {
      const items = ref(subscriptions.value);
      if(filters.value.frequency) items.value = items.value.filter(x => x.frequency == filters.value.frequency);
      if(filters.value.duration) items.value = items.value.filter(x => x.duration == filters.value.duration);
      switch(sortBy.value) {
        case 'next-delivery': 
          items.value.sort((a,b) => {
            const aSchedule = a.schedules[0];
            const bSchedule = b.schedules[0];
            return aSchedule.billing_date > bSchedule.billing_date ? 1 : -1;
          }); 
          break;

        case 'subscribed-date': items.value.sort((a,b) => a.created_at > b.created_at ? 1 : -1); break;
        default: items.value.sort((a,b) => a.id < b.id ? 1 : -1);
      }
      return items.value;
    });

    /*
    |--------------------------------------------------------------------------
    | @Methods
    |--------------------------------------------------------------------------
    */

    const selectFrequency = (id) => {
      filters.value.frequency = id;
    };

    const selectDuration = (id) => {
      filters.value.duration = id;
    };

    const isSelected = (type, id) => {
      return filters.value[type] == id;
    };

    const showSubscription = (id) => {
      window.location.href = `/subscription/view?i=${id}`;
    }

    const fetch = (url) => {
      window.axios.post(url || fetchSubscriptionsUrl.value)
        .then(({ data }) => {
          subscriptions.value = data.subscriptions;
        });
    };

    /*
    |--------------------------------------------------------------------------
    | @Watch
    |--------------------------------------------------------------------------
    */

    watch(
      () => fetchSubscriptionsUrl.value,
      (url) => {
        if(typeof url === 'string') fetch(url);
      },
      { immediate: true }
    );

    return {
      filteredItems,
      sortBy,
      filters,
      frequencyOptions,
      durationOptions,
      sortOptions,
      selectFrequency,
      selectDuration,
      isSelected,
      showSubscription,
    }
  }
})
</script>
