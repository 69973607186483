<template>
  <div class="order-details__hldr mb-8 md:mb-16" :key="pageKey">
    <h3 class="font-bold mb-4">
      {{ title }}
    </h3>
    <OneTimePurchase 
      v-if="oneTimeItems.length"
      :branch-id="branchId"
      :items="oneTimeItems" 
      :is-mobile="isMobile" 
      @choose-replacement="openReplacementModal"
      @refresh="fetchCart"
    />
    <hr class="my-12">
    <Subscriptions 
      v-if="subscriptionItems.length"
      :branch-id="branchId"
      :items="subscriptionItems" 
      :subscriptions="subscriptions" 
      :is-mobile="isMobile" 
      @choose-replacement="openReplacementModal"
      @refresh="fetchCart"
    />

    <ReplacementModal
      :is-open="showReplacementModal"
      @close="closeReplacementModal"
    />
  </div>
</template>
<script>
import { computed, defineComponent, onMounted, ref, watch } from "@vue/runtime-core";
import { useStore } from "vuex";
import useCart from '@/composables/useCart';
import useCheckout from '@/composables/useCheckout';
import useLocalStorage from "@/composables/useLocalStorage";

import OneTimePurchase from './OneTimePurchase.vue';
import Subscriptions from './Subscriptions.vue';
import ReplacementModal from './ReplacementModal.vue';

export default defineComponent({
  name: 'OrderDetails',
  components:{
    OneTimePurchase,
    Subscriptions,
    ReplacementModal
  },
  props:{
    branchId:{
      type:Number,
      default:null
    },
    title:{
      type:String,
      defaul:null,
    }
  },
  setup() {

    /*
    |--------------------------------------------------------------------------
    | @Data
    |--------------------------------------------------------------------------
    */

    const store = useStore();
    const { fetchCart } = useCart();
    const { fetchCheckoutItemsUrl, fetchCheckoutItems } = useCheckout();
    const { localStorage } = useLocalStorage();
    
    const pageKey = ref(null);
    const isMobile = ref(false);
    const showReplacementModal = ref(false);

    const cart = computed(() => store.state.cart.data);
    // const cartItems = computed(() => store.state.cart.items);
    const cartItems = computed(() => store.state.checkout.dataItems);
    const subscriptions = computed(() => store.state.cart.subscriptions.filter(a => cartItems.value.map(b => b.subscription_id).includes(a.id)));

    const oneTimeItems = computed(() => cartItems.value.filter(x => x.type == 1));
    const subscriptionItems = computed(() => cartItems.value.filter(x => x.type == 2));

    /*
    |--------------------------------------------------------------------------
    | @Methods
    |--------------------------------------------------------------------------
    */

    const openReplacementModal = () => { showReplacementModal.value = true; };
    const closeReplacementModal = (replaced) => {
      showReplacementModal.value = false;
      if(!replaced) generatePageKey();
    };

    const generatePageKey = () => (pageKey.value = `CheckoutPage${window.luxon.now().toISO()}`);

    /*
    |--------------------------------------------------------------------------
    | @Watch
    |--------------------------------------------------------------------------
    */

    watch(
      () => cartItems.value,
      (items) => localStorage.value.set('checkoutItems', items),
      { deep: true }
    );

    watch(
      () => typeof fetchCheckoutItemsUrl.value === 'string' && typeof cart.value.id !== 'undefined',
      (bool) => {
        if(bool) {
          fetchCheckoutItems(fetchCheckoutItemsUrl.value);
        }
      },
      { immediate: true }
    );

    onMounted(() => {
      var width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
      isMobile.value = width < 768 ? true : false;
      generatePageKey();
      if(typeof fetchCheckoutItemsUrl.value === 'string' && typeof cart.value.id !== 'undefined') {
        fetchCheckoutItems(fetchCheckoutItemsUrl.value);
      }
    });

    return {
      pageKey,
      isMobile,
      oneTimeItems,
      subscriptionItems,
      subscriptions,
      showReplacementModal,
      openReplacementModal,
      closeReplacementModal,
      fetchCart
    }
  }
});
</script>