<template>
    <ion-page>
        <div>
            <img class="mx-auto" src="/assets/image/PayMayaLogo.png" alt="PayMaya" width="500">
            <div class="grid gap-4 md:grid-cols-2 md:grid-rows-1 pt-5 md:px-20">
                <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                    <p class="font-bold text-lg text-gray-400">Pay using your Credit, Debit, or Prepaid Card</p>
                    <p class="text-gray-700">Provide your card information below:</p>
                    <div class="mt-4">
                        <div class="mb-4">
                            <label class="block text-gray-700 text-sm font-bold mb-2" for="card_number">
                                Card Number
                            </label>
                            <input 
                                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                :class="showError ? 'border-red-500' : ''"
                                id="card_number" 
                                type="tel"
                                :value="formattedCardNumber"
                                maxlength="23"
                                @input="onCardNumberInput"
                                @blur="validateCardNumber"
                                @keydown="numberOnlyInput"
                                placeholder="Card Number"
                            >

                            <div 
                                v-if="showError" 
                                class="text-red-500 text-sm"
                            >
                                {{ errorMessage }}
                            </div>
                            <!-- <input 
                                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="card_number" 
                                type="text"
                                placeholder="Enter Card Number"
                                maxlength="16"
                                v-model="form.cardNumber"
                                @keydown="numberOnlyInput"
                            > -->
                            <label class="block text-gray-700 text-sm mt-3">
                                Card Type:
                            </label>
                        </div>
                        <div class="mb-4 grid gap-5 grid-cols-2">
                            <div>
                                <label class="block text-gray-700 text-sm font-bold mb-2">
                                    Expiration Date
                                </label>
                                <div class="grid gap-1 grid-cols-2">
                                    <select 
                                        class="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        id="expiration_month" 
                                        name="expiration_month" 
                                        v-model="form.expMonth"
                                    >
                                        <option selected disabled :value="null">Month</option>
                                        <option 
                                            v-for="month in monthOptions" 
                                            :key="`month-option-${month}`"
                                            :value="month"
                                        >
                                            {{ month }}
                                        </option>
                                    </select>
                                    <select 
                                        class="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        id="expiration_year" 
                                        name="expiration_year" 
                                        v-model="form.expYear"
                                    >
                                        <option selected disabled :value="null">Year</option>
                                        <option 
                                            v-for="year in yearOptions"
                                            :key="`year-option-${year}`"
                                            :value="year"
                                        >
                                            {{ year }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div>
                                <label class="block text-gray-700 text-sm font-bold mb-2" for="security_code">
                                    Security Code
                                </label>
                                <input 
                                    class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="security_code" 
                                    type="text"
                                    placeholder="CVC"
                                    maxlength="3"
                                    v-model="form.cvc"
                                    @keydown="numberOnlyInput"
                                >
                            </div>
                        </div>
                    </div>

                    <div class="grid gap-4 grid-cols-2">
                        <ion-button 
                            class="w-full" size="large" color="light"
                            @click="router.push('/checkout')"
                        >
                            Cancel
                        </ion-button>
                        <ion-button 
                            class="w-full" size="large" 
                            @click="submit"
                            :disabled="cannotContinue || isLoading || showError"
                        >
                            Pay
                        </ion-button>
                    </div>
                </div>
                <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                    <p class="font-bold text-lg text-gray-400">Payment Summary</p>
                    <!-- <table class="w-full table-fixed mt-1 mb-2">
                        <thead>
                            <tr class="text-left text-gray-500">
                                <th class="pb-2">Description</th>
                                <th class="pb-2">Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="border px-2 py-2">
                                    <p class="font-bold">Always Chink</p>
                                    <p class="text-sm">Item Number: 001</p>
                                    <p class="text-sm">Quantity: 1</p>
                                </td>
                                <td class="border px-2 py-2">
                                    PHP 300.00
                                </td>
                            </tr>
                        </tbody>
                    </table> -->
                    <table class="w-full table-fixed mb-4">
                        <!-- <tr class="text-gray-500">
                            <td class="text-left">VAT</td>
                            <td class="text-right">0.00</td>
                        </tr> -->
                        <tr class="text-lg text-gray-500">
                            <td class="pt-4 font-bold text-left">Total Amount</td>
                            <td class="pt-4 text-right">{{ moneyFormat(totalAmount) }}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
        <Loader v-show="isLoading" />
    </ion-page>
</template>
<script>
/* eslint-disable */
import useFormat from "@/composables/useFormat";
import useLocalStorage from "@/composables/useLocalStorage";
import useResponseHandler from "@/composables/useResponseHandler"
import Loader from '@/components/PageLoader.vue';

import { computed, defineComponent, onMounted, ref } from "@vue/runtime-core";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { initializePayment } from '@/vendor/PaymentService';
import { createPaymentToken, createCustomer, createPaymentForSubscription, createCustomerCard, config } from '@/vendor/PaymentService/services/Paymaya.js';
import { useRouter } from 'vue-router';
import { IonButton, IonPage } from '@ionic/vue';

export default defineComponent({
    name:'PaymayaCreditCard',
    components:{
        IonButton,
        IonPage,
        Loader
    },
    setup() {
        
        const store = useStore();
        const route = useRoute();
        const router = useRouter();
        const { localStorage } = useLocalStorage();
        const { openToast } = useResponseHandler()
        const { numberOnlyInput, moneyFormat } = useFormat();
        /*
        |--------------------------------------------------------------------------
        | @Data
        |--------------------------------------------------------------------------
        */
        const showError = ref(false)
        const errorMessage = ref(null)
        const totalAmount = ref(0);
        const form = ref({ 
            cardNumber: null, 
            expMonth: null, 
            expYear: null, 
            cvc: null 
        });


        const formattedCardNumber = computed(() => {
            return form.value.cardNumber?.replace(/\s/g, "") // Remove spaces from the input value
            .replace(/(\d{4})(?=\d)/g, "$1 "); // Add a space after every 4 digits
        })

        const onCardNumberInput = (event) => {
            form.value.cardNumber = event.target.value.replace(/\D/g, "");
        }

        const validateCardNumber = () => {
            // Perform credit card number validation
            const isValid = isValidCardNumber(form.value.cardNumber);
            showError.value = !isValid;
            errorMessage.value = isValid ? "" : "Invalid credit card format";
        }

        const isValidCardNumber = (cardNumber) => {
            // Remove non-digit characters from the input value
            const cleanedCardNumber = cardNumber.replace(/\D/g, "");
            // Check if the cleaned card number has only digits and is not empty
            if (!/^\d+$/.test(cleanedCardNumber)) {
                return false;
            }

            let sum = 0;
            let isAlternate = false;
            // Start from the rightmost digit and process each digit
            for (let i = cleanedCardNumber.length - 1; i >= 0; i--) {
                let digit = parseInt(cleanedCardNumber.charAt(i), 10);

                if (isAlternate) {
                    digit *= 2;
                    if (digit > 9) {
                    digit -= 9;
                    }
                }

                sum += digit;
                isAlternate = !isAlternate;
            }
            // The card number is valid if the sum is divisible by 10
            return sum % 10 === 0;
        }



        /*
        |--------------------------------------------------------------------------
        | @Computed
        |--------------------------------------------------------------------------
        */

        const monthOptions = computed(() => {
            const currentDate = new Date();
            const result = [];
            let offset = currentDate.getFullYear() == form.value.expYear ? currentDate.getMonth() : 0;
            for(offset; offset < 12; offset++) {
                result.push(offset + 1);
            }
            return result;
        });

        const yearOptions = computed(() => {
            const result = [];
            let currentYear = new Date().getFullYear();
            for(let i = 0; i < 10; i++) {
                result.push(currentYear++);
            }
            return result;
        });

        const cannotContinue = computed(
            () => form.value.cardNumber == null
                || form.value.expMonth == null
                // || !monthOptions.value.includes(form.value.expMonth)
                || form.value.expYear == null
                || form.value.cvc == null
        );

        const payload = computed(() => {
            const user = store.state.user.data;
            return Object.assign(form.value, {
                firstName: user.first_name, 
                lastName: user.last_name, 
                mobileNumber: `+63${user.mobile_number}`, 
                email: user.email, 
                amount: totalAmount.value
            });
        });

        /*
        |--------------------------------------------------------------------------
        | @Methods
        |--------------------------------------------------------------------------
        */

        const onetimePayment = async() => {
            const vuexCheckout = store.state.checkout;
            isLoading.value = true;

            initializePayment(
                Object.assign(
                    payload.value, 
                    { 
                        paymentMethod: vuexCheckout.paymentMethod,
                        requestReferenceNumber: route.params.ref
                    }
                )
            )
            .then(
                (response) => {
                    isLoading.value = false;

                    if(response.status == "FOR_AUTHENTICATION") {
                        store.commit('checkout/set', { referenceNumber: response.id });
                        localStorage.value.set('checkout', store.state.checkout);
                        window.location.href = response.verificationUrl;
                    }
                },
                () => console.log('ERROR')
            )
        };

        const customerId = ref()
        const cardTokenId = ref()
        const isLoading = ref(false)

        const submit = async () => {
            try {
                isLoading.value = true;

                // const subscriptionPayload = store.state.checkout.items.filter(e => e.subscription_id !== null);
                const checkoutItems = await localStorage.value.get('checkout');
                const subscriptionPayload = checkoutItems?.items?.filter(e => e.subscription_id !== null);

                if (subscriptionPayload.length > 0) {
                    const id = await createCustomer(payload.value);
                    customerId.value = id

                    const paymentTokenId = await createPaymentToken(payload.value);

                    const responseUrl = `${config('VUE_APP_URL')}/customer/payment/callback?author=paymaya&token=${paymentTokenId}`;
                    
                    const response = await createCustomerCard(customerId.value, paymentTokenId, Object.assign(payload.value, { responseUrl }));
                    cardTokenId.value = response.cardTokenId

                    const paymentResponse = await createPaymentForSubscription(
                        Object.assign(payload.value, { paymentMethod: 'PAYMAYA_CREDIT_CARD' }),
                        customerId.value,
                        cardTokenId.value
                    );

                    verify(paymentResponse.id);
                    store.commit('checkout/set', { referenceNumber: paymentResponse.id });
                    localStorage.value.set('checkout', store.state.checkout);
                    window.location.href = paymentResponse.verificationUrl;
                } else {
                    await onetimePayment();
                }
            } catch (error) {
                console.log(error)
                openToast({ message: 'An Error Occurred. ' + error?.message, color: 'danger-toast' });
            } finally {
                isLoading.value = false;
            }
        };

          const submitss = () => {
            isLoading.value = true;
            const subscriptionPayload = store.state.checkout.items.filter((e) => e.subscription_id != null)

            if(subscriptionPayload.length > 0) {
                createCustomer(payload.value).then(id => {
                    customerId.value = id

                    createPaymentToken(payload.value)
                    .then(paymentTokenId => {

                        const responseUrl = `${config('VUE_APP_URL')}/customer/payment/callback?author=paymaya&token=${paymentTokenId}`;
                        
                        createCustomerCard(customerId.value, paymentTokenId, Object.assign(payload.value, { responseUrl }))
                        .then(response => {
                            cardTokenId.value = response.cardTokenId

                            createPaymentForSubscription(Object.assign(payload.value, { paymentMethod: 'PAYMAYA_CREDIT_CARD' }), customerId.value, response.cardTokenId)
                                .then((response) => {
                                    verify(response.id)
                                    store.commit('checkout/set', { referenceNumber: response.id });
                                    localStorage.value.set('checkout', store.state.checkout);
                                    window.location.href = response.verificationUrl;
                                })
                            })
                            .catch(() => {
                                alert('Something went wrong. please try again later.')
                                isLoading.value = false;
                            });
                    }) 
                    .catch(() => {
                        isLoading.value = false;
                    });
                });
                    
            } else {
                onetimePayment()
            }
        };


        const verify = (token) => {
            const url = store.state.user.token ? store.state.api.routes.verifyPaymentToken : store.state.api.routes.guestVerifyPayment;
            window.axios
            .post(url, { token, author: 'paymaya' })
            .then(({ data }) => {
                console.log(data)
                store.commit('invoices/setPayment', {
                    customerId: customerId.value,
                    cardTokenId: cardTokenId.value,
                    amount: totalAmount.value,
                    referenceNumber: referenceNumber.value
                });
                // router.replace({ name:'checkout', query:{ status: data.status } });
            });
        };

        onMounted(async() => {
            const user = await localStorage.value.get('user') || {};
            const payload = await localStorage.value.get('checkout') || {};
            payload.items = await localStorage.value.get('checkoutItems') || [];
            totalAmount.value = await localStorage.value.get('total') || 0;
            store.commit('user/setData', user);
            store.commit('checkout/set', payload);
        });

        return {
            form,
            totalAmount,
            monthOptions,
            yearOptions,
            cannotContinue,
            numberOnlyInput,
            moneyFormat,
            submit,
            isLoading,
            router,
            formattedCardNumber,
            onCardNumberInput,
            validateCardNumber,
            isValidCardNumber,
            showError,
            errorMessage
        }
    },
})
</script>