export const replacement = {
    namespaced: true,
    state: {
      amount: 0,
    },
    mutations: {
      setAmount(state, value) {
        state.amount = value || 0;
      },
    },
  };
  