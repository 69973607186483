<template>
   <div class="frm-spinner">
      <div class="flex items-center bg-white p-3 rounded-full shadow-md">
         <ion-spinner name="circles"></ion-spinner>
      </div>
   </div>
</template>

<script type="text/javascript">
import { IonSpinner } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'PageLoader',
    components: {
       IonSpinner
    }
})
</script>
