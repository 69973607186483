<template>
  <ion-page>
    <top-navigation />
    <ion-content
      :fullscreen="true"
      class="ion-padding"
    >
      <generic-frame-one
        class="animate-up"
        :title="getCmsContent('header_pageTitle') || 'Wishlist'"
      />
      <section class="wish-fr1 lg:mb-20 mb-[90px]">
        <div class="w-11/12 mx-auto">
          <div class="flex flex-wrap">
            <template
              v-for="(item, index) in items"
              :key="`wishlist-item-${index}`"
            >
              <ProductCard
                :item="item.product"
                :source="'wishlist'"
                class="cursor-pointer mb-10 xl:w-1/4 lg:w-1/3 w-1/2 wishlist-product animate-up"
              />
            </template>
          </div>
        </div>
      </section>
      
      <bottom-navigation />
    </ion-content>
  </ion-page>
</template>

<script type="text/javascript">
import { defineComponent, onMounted, computed } from 'vue';

import { IonPage, IonContent } from '@ionic/vue';
import GenericFrameOne from '@/components/GenericFrame1.vue';
import BottomNavigation from '@/partials/BottomNavigation.vue';
import TopNavigation from '@/partials/TopNavigation.vue';
import ProductCard from '@/views/products/components/ProductCard.vue';
import useCms from "@/composables/useCms";
import { useStore } from 'vuex';

export default defineComponent({
  name: 'WishlistPage',
  components: { IonPage, IonContent, BottomNavigation, TopNavigation, GenericFrameOne, ProductCard },
  setup() {
    const { contentSlug, getCmsContent } = useCms();

    const store = useStore();
    const items = computed(() => store.state.wishlist.items);

    onMounted(() => {
        contentSlug.value = 'wishlist';
    })

    return {
      getCmsContent,
      items
    }
  }
})
</script>
<style lang="scss">
  .wishlist-product {
    .product-tag__hldr {display: none;}

    &.product-card__hldr:hover {
      .product-img__hldr {
        opacity: 1;
        &::before {display: none;}
      }
      .overlay__hldr {display: none;}
    }
  }
</style>
