<template>
  <!-- <div class="delivery-method__hldr hidden md:flex justify-between"> -->
  <div class="delivery-method__hldr hidden md:flex">
    <template
      v-for="(option, index) in options"
      :key="`delivery-option-${index}`"
    >
      <div
        v-if="methods.length && methods[index]"
        class="mt-4 mr-2 mb-4 bg--primary--50 border-radius-10 p-4 w-[32.5%]"
        :class="{ 'border-active': selectedOption == option.id }"
      >
        <input 
          class="mr-3" 
          type="radio" 
          :name="`deliveryOption-${id}-${type}`" 
          :id="`option-${id}-${type}-${option.id}`" 
          :value="option.id"
          v-model="selectedOption"
        >
        <label
          class="form-check-label inline-block font-bold"
          :for="`option-${id}-${type}-${option.id}`"
        >
          {{ option.name }}
        </label>
        <div class="mt-1 ml-7">
          <template v-if="option.is_delivery">
            <h5>{{ moneyFormat(option.delivery_fee, 2, '₱') }}</h5>
            <h6 class="text--gray--200">
              {{ option.min_delivery_days }} - {{ option.max_delivery_days }} business days,
            </h6>
            <h6 class="text--gray--200">
              Max qty: 10 boxes
            </h6>
          </template>
          <template v-else>
            <h6 class="mb-2 text--gray--200">3 business days after order is confirmed.</h6>
            <!-- <h6 class="text--gray--200">
              Pick your items from our physical branch
            </h6> -->
          </template>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { computed, defineComponent, ref, watch } from "@vue/runtime-core";
import { useStore } from "vuex";
import useFormat from '@/composables/useFormat.js';

export default defineComponent({
  name: 'DeliveryOptions',
  emits:[ 'update:modelValue' ],
  props:{
    modelValue:{
      type:Number,
      default:null
    },
    id:{
      type:Number,
      default:null
    },
    type:{
      type:Number,
      default:null
    }
  },
  setup(props, { emit }) {

    const store = useStore();
    const { moneyFormat } = useFormat();

    /*
		|--------------------------------------------------------------------------
		| @Data
		|--------------------------------------------------------------------------
		*/

    const selectedOption = ref(props.modelValue);
    const options = computed(() => store.state.settings.fees);

    const methods = computed(() => store.state.settings.branchDeliveryMethods || []);

    /*
		|--------------------------------------------------------------------------
		| @Watch
		|--------------------------------------------------------------------------
		*/
    
    watch(
      () => selectedOption.value,
      (id) => {
        if(id) emit('update:modelValue', id);
      },
      { immediate: true }
    );

    watch(
      () => options.value,
      (opts) => {
        if(opts.length) {
          selectedOption.value = selectedOption.value || opts[0].id
        }
      },
      { immediate: true }
    );

    return {
      options,
      selectedOption,
      moneyFormat,
      methods
    }
  }
});
</script>