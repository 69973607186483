import { createRouter, createWebHistory } from '@ionic/vue-router';

// Pages
import Home from '../views/home/Home.vue'
import Faqs from '../views/faqs/Faqs.vue'
import Contact from '../views/Contact.vue'
import Wishlist from '../views/Wishlist.vue'

import PrivacyPolicy from '../views/generic/PrivacyPolicy.vue'
import TermsCondition from '../views/generic/TermsCondition.vue'

// Auth Pages
import Login from '../views/auth/Login.vue'
import Register from '../views/auth/Register.vue'
import ForgotPassword from '../views/auth/ForgotPassword.vue'
import ResetPassword from '../views/auth/ResetPassword.vue'
import EmailVerification from '../views/auth/EmailVerification.vue'

// Product Pages
import ProductPage from '../views/products/ProductPage.vue'
import SelectedProductPage from '../views/products/SelectedProductPage.vue'

// My Account
import OrderHistoryIndex from '../views/order-history/OrderHistoryIndex.vue';
import OrderHistoryView from '../views/order-history/OrderHistoryView.vue';
import SubscriptionIndex from '../views/subscription/SubscriptionIndexPage.vue'
import SubscriptionView from '../views/subscription/SubscriptionViewPage.vue'
import SubscriptionEdit from '../views/subscription/SubscriptionEditPage.vue'
import Notification from '../views/notifications/Notification.vue'
import ProfileIndex from '../views/account-details/profile/ProfileIndex.vue';
import AddressBookIndex from '../views/account-details/address-book/AddressBookIndex.vue';
import AddressBookEdit from '../views/account-details/address-book/AddressBookEdit.vue';
import ChangePasswordIndex from '../views/account-details/change-password/ChangePasswordIndex.vue';
import ReferAFriend from '../views/ReferAFriend.vue';
import AnnouncementsIndex from '../views/account-details/announcements/AnnouncementsIndex.vue';
import DeleteAccount from '../views/account-details/delete-account/DeleteAccount.vue';
import DeleteAccountOTP from '../views/account-details/delete-account/DeleteAccountOTP.vue';

// Cart Pages
import MyCartPage from '../views/cart/MyCartPage.vue'

// Checkout Pages
import CheckoutPage from '../views/checkout/CheckoutPage.vue'
import OrderSuccessPage from '../views/checkout/OrderSuccessPage.vue'

import PaymayaCreditCard from '../views/payments/PaymayaCreditCard'
import PayWithMaya from '../views/payments/PayWithMaya'
import Dragonpay from '../views/payments/Dragonpay'

// Chat
import ChatPage from "@/views/chat-page/ChatPage.vue";

// Others
import Stylesheet from "../views/Stylesheet.vue";

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta:{
      needsAuth: false
    }
  },
  {
    path: '/faqs',
    name: 'faqs',
    component: Faqs,
    meta:{
      needsAuth: false
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta:{
      needsAuth: false
    }
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
    meta:{
      needsAuth: false
    }
  },
  {
    path: '/verification/:email',
    name: 'email-verification',
    component: EmailVerification,
    meta:{
      needsAuth: false
    }
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPassword,
    meta:{
      needsAuth: false
    }
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: ResetPassword,
    meta:{
      needsAuth: false
    }
  },
  {
    path: '/contact-us',
    name: 'contact',
    component: Contact,
    meta:{
      needsAuth: false
    }
  },
  {
    path: '/wishlist',
    name: 'wishlist',
    component: Wishlist,
    meta:{
      needsAuth: true
    }
  },
  {
    path: '/shop',
    name: 'shop',
    component: ProductPage,
    meta:{
      needsAuth: false
    }
  },
  {
    path: '/shop/:id',
    name: 'selected-product',
    component: SelectedProductPage,
    meta:{
      needsAuth: false
    }
  },
  {
    path: '/order-history',
    name: 'order-history-index',
    component: OrderHistoryIndex,
    meta:{
      needsAuth: true
    }
  },
  {
    path: '/order-history/view',
    name: 'order-history-view',
    component: OrderHistoryView,
    meta:{
      needsAuth: true
    }
  },
  {
    path: '/my-cart',
    name: 'my-cart',
    component: MyCartPage,
    meta:{
      needsAuth: false
    }
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: CheckoutPage,
    meta:{
      needsAuth: false
    }
  },
  {
    path: '/payments/paymaya/credit-card/:ref',
    name: 'paymaya-credit-card',
    component: PaymayaCreditCard,
    meta:{
      needsAuth: false
    }
  },
  {
    path: '/payments/paymaya/pay-with-maya/:ref',
    name: 'pay-with-maya',
    component: PayWithMaya,
    meta:{
      needsAuth: false
    }
  },
  {
    path: '/payments/dragonpay/',
    name: 'dragonpay',
    component: Dragonpay,
    meta:{
      needsAuth: false
    }
  },
  {
    path: '/order-success',
    name: 'order-success',
    component: OrderSuccessPage,
    meta:{
      needsAuth: false
    }
  },
  {
    path: '/subscription',
    name: 'subscriptionIndex',
    component: SubscriptionIndex,
    meta:{
      needsAuth: true
    }
  },
  {
    path: '/subscription/view',
    name: 'subscriptionView',
    component: SubscriptionView,
    meta:{
      needsAuth: true
    }
  },
  {
    path: '/subscription/edit',
    name: 'subscriptionEdit',
    component: SubscriptionEdit,
    meta:{
      needsAuth: true
    }
  },
  {
    path: '/notifications',
    name: 'Notification',
    component: Notification,
    meta:{
      needsAuth: true
    }
  },
  {
    path: '/refer-a-friend',
    name: 'ReferAFriend',
    component: ReferAFriend,
    meta:{
      needsAuth: true
    }
  },
  {
    path: '/announcements',
    name: 'Announcements',
    component: AnnouncementsIndex,
    meta:{
      needsAuth: true
    }
  },
  {
    path: '/delete-account',
    name: 'delete-account',
    component: DeleteAccount
  },
  {
    path: '/delete-account/otp',
    name: 'delete-account-otp',
    component: DeleteAccountOTP
  },
  {
    path: '/profile',
    name: 'profile-index',
    component: ProfileIndex,
    meta:{
      needsAuth: true
    }
  },
  {
    path: '/address-book',
    name: 'address-book-index',
    component: AddressBookIndex,
    meta:{
      needsAuth: true
    }
  },
  {
    path: '/address-book/edit',
    name: 'address-book-edit',
    component: AddressBookEdit,
    meta:{
      needsAuth: true
    }
  },
  {
    path: '/change-password',
    name: 'change-password-index',
    component: ChangePasswordIndex,
    meta:{
      needsAuth: true
    }
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
    meta:{
      needsAuth: false
    }
  },
  {
    path: "/terms-and-conditions",
    name: "TermsCondition",
    component: TermsCondition,
    meta:{
      needsAuth: false
    }
  },
  {
    path: '/chat-page/:id',
    name: 'ChatPage',
    component: ChatPage
  },
  {
    path: "/stylesheet",
    name: "Stylesheet",
    component: Stylesheet,
    meta:{
      needsAuth: false
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
