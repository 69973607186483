<template>
  <div class="w-full mb-4">
    <input
      type="text"
      name="input"
      id="input"
      class="focus:ring-[#02adf0] focus:border-[#02adf0] block w-full border-gray-300 placeholder-gray-400 rounded-[10px] h-12"
      placeholder="Leave a message"
      v-model="form.notes"
    >
    <template v-if="type == 1">
      <div class="flex flex-col-reverse md:flex-row md:justify-between">
        <div class="w-full md:w-[49%] relative mt-4">
          <input
            type="text"
            name="input"
            id="input"
            class="focus:ring-[#02adf0] focus:border-[#02adf0] block w-full border-gray-300 placeholder-gray-400 rounded-[10px] h-12"
            placeholder="Gift Card Code"
            v-model="code"
          >
          <ion-button
            slot="end"
            shape="round"
            class="font--main btn-link font--13 font-weight-regular text--gray--400 my-0 capitalized font-size--x-small input-btn--remove"
            v-if="code"
            @click="clearGiftCard"
          >
            X
          </ion-button>
          <ion-button
            shape="round"
            fill="outline"
            class="flex items-center font--main m-0 capitalized input-btn px-0 w-[110px]"
            :disabled="form.giftCardId"
            @click="applyGiftCard"
          >
            Verify
          </ion-button>
        </div>
        <!-- v-if="isAuthenticated" -->
        <div 
          class="w-full md:w-[49%] relative mt-4" 
        >
          <input
            type="text"
            name="input"
            id="input"
            class="focus:ring-[#02adf0] focus:border-[#02adf0] block w-full border-gray-300 placeholder-gray-400 rounded-[10px] h-12"
            placeholder="Voucher Code"
            v-model="voucher_code"
          >
          <ion-button
            slot="end"
            shape="round"
            class="font--main btn-link font--13 font-weight-regular text--gray--400 my-0 capitalized font-size--x-small input-btn--remove"
            v-if="voucher_code"
            @click="clearVoucher"
          >
            X
          </ion-button>
          <ion-button
            shape="round"
            fill="outline"
            class="flex items-center font--main m-0 capitalized input-btn px-0 w-[110px]"
            :disabled="form.voucherId"
            @click="applyVoucher"
          >
            Verify
          </ion-button>
        </div>

      </div>
      <div 
        class="w-full relative mt-4"
        v-if="isAuthenticated"
      >
        <input
          type="text"
          name="input"
          id="input"
          class="focus:ring-[#02adf0] focus:border-[#02adf0] block w-full border-gray-300 placeholder-gray-400 rounded-[10px] h-12"
          placeholder="Input Referral Code"
          v-model="referralCode"
        >
        <ion-button
          slot="end"
          shape="round"
          class="font--main btn-link font--13 font-weight-regular text--gray--400 my-0 capitalized font-size--x-small input-btn--remove"
          v-if="referralCode"
          @click="clearReferralCode"
        >
          X
        </ion-button>
        <ion-button
          shape="round"
          fill="outline"
          class="flex items-center font--main m-0 capitalized input-btn px-0 w-[110px]"
          :disabled="form.referral_code"
          @click="applyReferralCode"
        >
          Confirm
        </ion-button>
      </div>

      <div 
        class="w-full relative mt-4"
        v-if="isAuthenticated"
      >
        <input
          type="number"
          name="input"
          id="input"
          class="focus:ring-[#02adf0] focus:border-[#02adf0] block w-full border-gray-300 placeholder-gray-400 rounded-[10px] h-12"
          placeholder="Use points"
          v-model="points"
        >
        <ion-button
          slot="end"
          shape="round"
          class="font--main btn-link font--13 font-weight-regular text--gray--400 my-0 capitalized font-size--x-small input-btn--remove"
          v-if="points"
          @click="clearPoints"
        >
          X
        </ion-button>
        <ion-button
          shape="round"
          fill="outline"
          class="flex items-center font--main m-0 capitalized input-btn px-0 w-[110px]"
          :disabled="form.points"
          @click="applyPoints"
        >
          Confirm
        </ion-button>
      </div>
      <div 
        class="w-full mt-1" 
        v-if="isAuthenticated"
      >
        <h6 class="text--gray--200">
          You have: <span class="font-bold">{{ earnedPoints }} Droplet/s</span>
        </h6>
        <span>Points can only be redeemed up to 10% of your max. order</span>
      </div>
    </template>
  </div>
  <Loader v-show="loader" />
</template>
<script>

import { computed, defineComponent, ref, watch } from "@vue/runtime-core";
import { useStore } from "vuex";
import { IonButton, modalController } from "@ionic/vue";
import Modal from '@/components/Modal';
import Loader from '@/components/PageLoader.vue';
import useAuth from "@/composables/useAuth";
import useResponseHandler from '@/composables/useResponseHandler';
import useLocalStorage from '@/composables/useLocalStorage';

export default defineComponent({
  name: 'DiscountOptions',
  components: {
      Loader,
      IonButton
  },
  emits:[ 'update:modelValue' ],
  props:{
    modelValue:{
      type:Object,
      default:() => {
        return {};
      }
    },
    id:{
      type:Number,
      default:null
    },
    branchId:{
      type:Number,
      default:null
    },
    type:{
      type:Number,
      default:null
    },
    items: {
      type: Object,
      default: () => {}
    }
  },
  setup(props, { emit }) {

    const store = useStore();
    const { isAuthenticated } = useAuth();
    const { localStorage } = useLocalStorage();

    /*
		|--------------------------------------------------------------------------
		| @Data
		|--------------------------------------------------------------------------
		*/
    
    const form = ref(props.modelValue);
    const code = ref(props.modelValue.code);
    const points = ref(props.modelValue.points);
    const voucher_code = ref(props.modelValue.voucher_code)
    const referralCode = ref()

    const title = ref(null);
    const message = ref(null);
    const image = ref(null);

    const loader = ref(false);
    const conversionValue = ref(0);
    const branch_id = computed(() => store.state.cart.data.branch_id)
    const { errorHandler } = useResponseHandler();

    /*
		|--------------------------------------------------------------------------
		| @Computed
		|--------------------------------------------------------------------------
		*/

    const earnedPoints = computed(() => {
      const points = store.state.user.data.points || 0;
      const otpPoints = store.state.checkout.oneTimePayload.points || 0;
      const subPoints = store.state.checkout.subscriptionPayload.reduce((acc,item) => acc + (item.points || 0), 0);
      return points - (conversionValue.value * (otpPoints + subPoints));
    });

    const applyPointUrl = computed(() => store.state.api.routes.usePoints);
    const applyGiftCardUrl = computed(() => store.state.api.routes.useGiftCard);

    /*
		|--------------------------------------------------------------------------
		| @Modal
		|--------------------------------------------------------------------------
		*/

    const openModal = async () => {
      const modal = await modalController.create({
        component: Modal,
        cssClass: 'gen-modal',
        componentProps: {
          title: title.value,
          description: message.value,
          imageLink: image.value,
          closeModalButton: true,
          imageWidth: '64',
          imageHeight: '64',
        }
      });
      return modal.present();
    };

    /*
		|--------------------------------------------------------------------------
		| @Methods
		|--------------------------------------------------------------------------
		*/

    const hasEnoughPoints = () => {
      const result = points.value > earnedPoints.value;
      if(result) {
        points.value = null;
        title.value = 'Oops!';
        message.value = 'Insufficient points';
        image.value = '/assets/icon/ic-status-warning.svg';
        openModal();
      }
      return result;
    };

    const voucherIsUsed = () => {
      const subscriptions = store.state.checkout.subscriptionPayload;
      let result = false;
      switch(props.type) {
        case 1: result = subscriptions.findIndex(x => x.code == code.value) !== -1; break;
        case 2: 
          result = store.state.checkout.oneTimePayload.code == code.value 
            || subscriptions.findIndex(x => x.code == code.value && x.id != props.id) !== -1; 
          break;
      }
      if(result) {
        code.value = null;
        title.value = 'Oops!';
        message.value = 'Gift Card is already applied!';
        image.value = '/assets/icon/ic-status-warning.svg';
        openModal();
      }
      return result;
    };

    const applyPoints = () => {
      if(hasEnoughPoints()) return;
      loader.value = true;
      window.axios.post(applyPointUrl.value, {
          branchId: props.branchId,
          type: props.type,
          subscriptionId: props.id,
          points: points.value,
          cartItemIds: store.state.checkout.items.map(x => x.id),
          subtotal: store.state.checkout.items.reduce((sum,item) => sum + parseFloat(item.subtotal), 0)
        })
        .then(({data}) => {
          // form.value.points = points.value;
          form.value.points = data.convertedAmt;
          conversionValue.value = data.conversionVal;
          title.value = 'Success!';
          message.value = `${points.value} points were applied to this transaction`;
          image.value = '/assets/icon/ic-status-check.svg';
          openModal();
          loader.value = false;
        })
        .catch(({ response }) => {
          loader.value = false;
          points.value = null;
          title.value = 'Oops!';
          message.value = response.data.message || response.data.error;
          image.value = '/assets/icon/ic-status-warning.svg';
          openModal();
        });
    };

    const applyGiftCard = () => {
      if(voucherIsUsed()) return;
      loader.value = true;
      window.axios.post(applyGiftCardUrl.value, {
        type: props.type,
          code: code.value,
          subtotal: store.state.checkout.items.reduce((sum,item) => sum + parseFloat(item.subtotal), 0)
        })
        .then(({ data }) => {
          
          form.value.code = code.value;
          form.value.giftCardId = data.id;
          form.value.giftCardAmount = data.amount;

          title.value = 'Success!';
          message.value = `Gift Card ${code.value} was applied to this transaction`;
          image.value = '/assets/icon/ic-status-check.svg';
          
          openModal();
          loader.value = false;
        })
        .catch(({ response }) => {
          loader.value = false;
          code.value = null;
          
          title.value = 'Oops!';
          message.value = response.data.message || response.data.error;
          image.value = '/assets/icon/ic-status-warning.svg';
          
          openModal();
        });
    };

    const applyVoucher = () => {
      if(voucherIsUsed()) return;
      loader.value = true;
      window.axios.post(process.env.VUE_APP_API_URL + '/checkout/use-voucher-code', {
          type: props.type,
          voucherCode: voucher_code.value,
          items: props.items,
          branchId: branch_id.value,
          platform: 'web',
          subtotal: store.state.checkout.items.reduce((sum,item) => sum + parseFloat(item.subtotal), 0)
        })
        .then(({ data }) => {

          form.value.voucher_code = voucher_code.value;
          form.value.voucherId = data.voucher[0].id;

          form.value.voucherAmount = data.voucher.reduce((totalDiscount, voucher) => {
            return totalDiscount + parseFloat(voucher.discount_amount);
          }, 0);
          
          // form.value.voucherAmount = data.voucher.map((x) => {
          //   let discount = 0;
          //   discount += parseFloat(x.discount_amount)
            
          //   return discount;
          // });
          
          store.commit('checkout/setVoucherDiscount', form.value.voucherAmount)
          store.commit('checkout/setVouchers', data.voucher)

          title.value = 'Success!';
          message.value = `Voucher ${voucher_code.value} was applied to this transaction`;
          image.value = '/assets/icon/ic-status-check.svg';
          
          openModal();
          loader.value = false;
        })
        .catch(({ response }) => {
          loader.value = false;
          code.value = null;
          
          title.value = 'Oops!';
          message.value = response.data.message || response.data.error;
          image.value = '/assets/icon/ic-status-warning.svg';
          
          openModal();
        });
    };

    const applyReferralCode = () => {
      loader.value = true;

      window.axios
          .post(store.state.api.routes.useReferralCode, { code: referralCode.value })
          .then(() => {
              store.commit('checkout/set', { referralCode: referralCode.value });
              localStorage.value.set('referral', store.state.checkout.referralCode);

              form.value.referral_code = referralCode.value;

              title.value = 'Success!';
              message.value = `Referral Code: ${referralCode.value} was applied to this transaction`;
              image.value = '/assets/icon/ic-status-check.svg';
              openModal();
              loader.value = false;

          })
          .catch(( error) => {
              loader.value = false;
              store.commit('checkout/set', { referralCode: null });
              localStorage.value.set('referral', null);

              if (error.response) {
                  errorHandler(error.response);
              } else {
                  console.error('No response in the error object:', error);
              }
          });
    };

    const clearReferralCode = () => {
      referralCode.value = null;
      form.value.referral_code = null;
      store.commit('checkout/set', { referralCode: null });
      localStorage.value.set('referral', null);
    }

    const clearPoints = () => {
      points.value = null;
      form.value.points = null;
    };

    const clearVoucher = () => {
      voucher_code.value = null;
      form.value.voucherCode = null;
      form.value.voucherId = null;
      form.value.voucherAmount = null;
    };

    const clearGiftCard = () => {
      code.value = null;
      form.value.code = null;
      form.value.giftCardId = null;
      form.value.giftCardAmount = null;
    };

    /*
		|--------------------------------------------------------------------------
		| @Watch
		|--------------------------------------------------------------------------
		*/
    
    watch(
      () => form.value,
      (data) => emit('update:modelValue', data),
      { deep: true }
    );

    return {
      form,
      code,
      points,
      earnedPoints,
      referralCode,
      isAuthenticated,
      applyPoints,
      applyGiftCard,
      applyVoucher,
      applyReferralCode,
      clearReferralCode,
      clearPoints,
      clearVoucher,
      clearGiftCard,
      loader,
      voucher_code,
      branch_id
    }
  }
});
</script>