<template>
  <ion-page>
    <top-navigation />

    <ion-content
      :fullscreen="true"
      class="ion-padding"
    >
      <div class="w-11/12 mx-auto">
        <!-- <section class="spacer">
          <h1 class="frm-title text--secondary font-bold mb-0">
            {{ getCmsContent('header_pageTitle') || 'Checkout' }}
          </h1>
        </section> -->
        <section class="checkout__hldr flex flex-wrap mt-8 mb-12">
          <div class="w-full md:w-2/3">

            <h1 class="frm-title text--secondary font-bold">
              {{ getCmsContent('header_pageTitle') || 'Checkout' }}
            </h1>

            <div class="mb-8">
              <span 
                class="font--h6 text--gray--400 cursor-pointer" 
                @click="() => router.push('/')">
                Home
              </span>
              <span class="font--h6 text--secondary mx-1">></span>
              <!-- <span class="font--h6 text--gray--400 cursor-pointer" @click="() => router.push('/my-cart')">My Cart</span> -->
              <span 
                class="font--h6 text--gray--400 cursor-pointer" 
                @click="backToCartPage">
                My Cart
              </span>
              <span class="font--h6 text--secondary mx-1">></span>
              <span class="font--h6 text--secondary font-bold">Checkout</span>
            </div>

            <!-- Guest Delivery Address -->
            <template v-if="isAuthenticated">
              <DeliveryAddress 
                :title="getCmsContent('section1_title') || 'Delivery Address'"
                :branch-id="branchId"
                @refresh="setBranch" 
              />
            </template>
            <template v-else>
              <GuestDeliveryAddress 
                ref="guestDeliveryAddressRef"
                :title="getCmsContent('section1_title') || 'Delivery Address'"
              />
            </template>

            <!-- User Delivery Address -->
            

            <OrderDetails 
              :branch-id="branchId"
              :title="getCmsContent('section2_title') || 'Order Details'"
            />

            <PaymentMethod 
              :title="getCmsContent('section3_title') || 'Payment Method'"
            />

            <!-- Web: Return Shopping Link -->
            <div class="w-full mt-12 hidden md:block">
              <a
                href="/shop"
                class="font-bold font--h6 text--gray--200"
              >Return to Shopping</a>
            </div>
          </div>
          
          <!-- Cart Summary -->
          <div class="w-full md:w-1/3 pl-0 md:pl-8 mt-11 md:mt-0 h-full sticky top-0">
            <CartSummary
              ref="cartSummaryRef"
              is-checkout
              @checkout="openConfirmationModal"
            />
          </div>
          
          <!-- Mobile: Return Shopping Link -->
          <div class="w-full mt-8 block md:hidden text-center">
            <a
              href="/shop"
              class="font-bold font--h6 text--gray--200"
            >Return to Shopping</a>
          </div>
        </section>
      </div>
      <bottom-navigation />
    </ion-content>
    <Loader v-show="isLoading" />
  </ion-page>

  <referral-modal
    :is-visible="showReferralModal" 
    @close="showReferralModal = false"
  ></referral-modal>

</template>

<script type="text/javascript">

import { ref, defineComponent, onMounted, watch, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import useAuth from '@/composables/useAuth';
import useCart from '@/composables/useCart';
import useCheckout from '@/composables/useCheckout';
import useCms from "@/composables/useCms";
import useLocalStorage from '@/composables/useLocalStorage';
import ReferralModal from "@/components/ReferralModal.vue";

import {
  IonPage,
  IonContent,
  modalController,
} from '@ionic/vue';
import Modal from '@/components/Modal';
import Loader from '@/components/PageLoader.vue';
import TopNavigation from '@/partials/TopNavigation.vue';
import BottomNavigation from '@/partials/BottomNavigation.vue';
import CartSummary from '@/views/cart/components/CartSummary.vue';
import DeliveryAddress from './components/DeliveryAddress.vue';
import GuestDeliveryAddress from './components/GuestDeliveryAddress.vue';
import OrderDetails from './components/OrderDetails/Index.vue';
import PaymentMethod from './components/PaymentMethod.vue';
import { createCheckoutPayment } from '@/vendor/PaymentService/services/Paymaya.js';

export default defineComponent({
  name: 'CheckoutPage',
  components: {
    Loader,
    IonPage,
    IonContent,
    TopNavigation,
    BottomNavigation,
    CartSummary,
    DeliveryAddress,
    GuestDeliveryAddress,
    OrderDetails,
    PaymentMethod,
    ReferralModal
  },
  setup() {
    
    const showReferralModal = ref(true);
    const store = useStore();
    const router = useRouter();

    const { isAuthenticated } = useAuth();
    const { checkCartItems } = useCart();
    const { checkout, clearCheckout, checkoutLoader } = useCheckout();
    const { contentSlug, getCmsContent } = useCms();
    const { localStorage } = useLocalStorage();

    const branchId = ref(null);
    const cartSummaryRef = ref(null);
    const guestDeliveryAddressRef = ref(null);
    const pageLoaded = ref(false);
    
    const isLoading = computed(() => checkoutLoader.value);
    // const referenceNumber = ref()

    const checkoutPayload = computed(() => {
      let form = store.state.checkout;
      form.cartId = store.state.cart.data.id;

      if(form.address.id) form.deliveryAddressId = form.address.id;
      else form.deliveryAddressId = null;
      
      return form;
    });

    const totalAmount = computed(() => cartSummaryRef.value ? cartSummaryRef.value.total : 0);
    /*
    |--------------------------------------------------------------------------
    | @Modals
    |--------------------------------------------------------------------------
    */

    const openConfirmationModal = async () => {
      const modal = await modalController.create({
        component: Modal,
        cssClass: 'gen-modal order-confirmation',
        componentProps: {
          title: 'Is your order final?',
          description: 'Clicking “Okay” confirms that the information you provided in this page is final, and will redirect you to the next step. Confirm order?',
          buttonText: 'Okay',
          buttonClass: 'btn-primary',
          emitButton: true,
          cancelButton: true,
          shouldAutoClose: false,
          cancelButtonClass: 'btn-primary',
          cancelButtonFill: 'outline',
          cancelButtonText: 'Go Back',
          imageLink: '/assets/icon/ic-status-warning.svg',
          imageWidth: '36',
          imageHeight: '36'
        },
      });
      modal.onDidDismiss().then(({ data }) => {
        if(data == 'emit') checkoutHandler();
      });
      return modal.present();
    };

    /*
    |--------------------------------------------------------------------------
    | @Methods
    |--------------------------------------------------------------------------
    */

    const setBranch = (id) => {
      
      if(branchId.value) {
        checkCartItems(id);
      }
      
      branchId.value = id;
      store.commit('address/setBranch', id);
      localStorage.value.set('branch', id);
    };


    const address = computed(() => store.state.address.form);
    const payload = computed(() => {
        const user = store.state.user.data;
        return {
            firstName: user.first_name, 
            lastName: user.last_name, 
            mobileNumber: `+63${user.mobile_number}`, 
            email: user.email, 
            amount: totalAmount.value,
            street: address.value.street,
            unitNo: address.value.unitNo ?? address.value.unit_no,
            barangay: address.value.barangayLabel ?? address.value.barangay,
            city: address.value.cityLabel ?? address.value.city,
            province: address.value.provinceLabel ?? address.value.province,
            zipCode: address.value.zipCode ?? '',
        };
    });

    const checkoutHandler = async () => {
      
      if(guestDeliveryAddressRef.value) await guestDeliveryAddressRef.value.initForm();
      
      await localStorage.value.set('checkout', store.state.checkout);
      await localStorage.value.set('user', store.state.user.data);
      await localStorage.value.set('location', store.state.address.form);

      localStorage.value.set('total', totalAmount.value.toFixed(2));
      checkoutPayload.value.order = 'pending'

      checkout(checkoutPayload.value)
        .then(data => {
          if(data !== false) {
            
            localStorage.value.set('invoices', data.invoices);
            localStorage.value.set('orders', data.orders);
            localStorage.value.set('total', totalAmount.value.toFixed(2));
            store.commit('invoices/setOrder', data.orders); 

            switch(checkoutPayload.value.paymentMethod) {
              
              case 'PAYMAYA_CREDIT_CARD':   
                window.location.href = `/payments/paymaya/credit-card/${data.referenceNumber}`;
                break;
              case 'PAY_WITH_MAYA': 
                checkoutLoader.value = true
                // window.location.href = `/payments/paymaya/pay-with-maya/${data.referenceNumber}`;
                createCheckoutPayment(payload.value, totalAmount.value, data.referenceNumber)
                .then(data => {
                    store.commit('checkout/set', { referenceNumber: data.referenceNumber });
                    window.location.href = data.redirectUrl;
                    checkoutLoader.value = false
                })
                break;
              case 'DRAGONPAY':             
                router.replace({ name:'dragonpay', query:{ ref: data.referenceNumber } }); 
                break;
              case 'CASH':
                clearCheckout();
                router.push({ name: 'order-success', query: { i: data.invoices } });
                break;
            }
          }
        });
    };

    const backToCartPage = () => window.location.href = '/my-cart';

    /*
    |--------------------------------------------------------------------------
    | @Watch
    |--------------------------------------------------------------------------
    */

    watch(
      () => store.state.checkout,
      (payload) => {
        if(payload.referenceNumber) localStorage.value.set('checkout', payload);
      },
      { deep: true }
    );

    watch(
      () => checkoutPayload.value.cartId 
        && typeof store.state.api.routes.checkout === 'string'
        && typeof router.currentRoute.value.query.paymentStatus === 'string'
        && pageLoaded.value,
      async (pass) => {
        const status = router.currentRoute.value.query.paymentStatus;
        const invoices = await localStorage.value.get('invoices');
        
        if(pass && ['success','pending'].includes(status)) {
          clearCheckout();
          router.push({ name: 'order-success', query: { i: invoices } });
        }
      }
    );

    onMounted(async () => {
      const payload = await localStorage.value.get('checkout') || {};
      payload.items = await localStorage.value.get('checkoutItems') || [];
      store.commit('checkout/set', payload);
      contentSlug.value = 'checkout';
      pageLoaded.value = true;
    });

    return {
      isLoading,
      isAuthenticated,
      branchId,
      cartSummaryRef,
      guestDeliveryAddressRef,
      openConfirmationModal,
      setBranch,
      getCmsContent,
      router,
      showReferralModal,
      checkoutPayload,
      backToCartPage
    }
  }
})
</script>