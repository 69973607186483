<template>
  <div
    class="product-purchase__subscription"
    v-if="subscriptions.length"
  >
    <template
      v-for="(sub, index) in subscriptions"
      :key="`checkout-subscriptions-${index}`"
    >
      <div class="flex justify-between items-center mb-2">
        <h5 class="font-bold">
          Frequency: {{ sub.frequency_label }} / Duration: {{ sub.duration_label }} ({{ filterItems(sub.id).length }})
        </h5>
        <h5 class="font--21 font-bold text--gray--200">
          <span class="lg:inline-block hidden">Total</span> <span class="font-bold text--secondary">{{ moneyFormat(getSubtotal(sub.id), 2, '₱') }}</span>
        </h5>
      </div>
      <p class="text-sm italic mb-4 text--secondary">Prices, product packaging and configuration are subject to change without prior notice</p>
      <FreeShippingReminder 
        :outstanding-amount="getOutstanding(sub.id)"
        :minimum-required-amount="getMinimum(sub.id)"
      />
      <FreeShippingCongratulations v-if="getForDelivery(sub.id) && getOutstanding(sub.id) <= 0" />
      <div class="checkout-items__hldr">
        <template
          v-for="item in filterItems(sub.id)"
          :key="`subscription-item-${item.id}`"
        >
          <CheckoutItem
            :item="item"
            :is-mobile="isMobile"
            is-checkout-item
            @choose-replacement="openReplacementModal"
            @refresh="refresh"
          />
        </template>
      </div>
      <DeliveryOptions 
        :id="sub.id" 
        :type="TYPE_SUBSCRIPTION" 
        v-model="optionForms[getIndex(sub.id)].deliveryMethod" 
      />
      <DiscountOptions 
        :id="sub.id" 
        :branch-id="branchId"
        :type="TYPE_SUBSCRIPTION" 
        v-model="optionForms[getIndex(sub.id)]" 
      />
      <hr class="my-6 md:my-6">
    </template>
  </div>
</template>
<script>
import { defineComponent, computed, ref, watch } from '@vue/runtime-core';
import { useStore } from 'vuex';
import useFormat from '@/composables/useFormat.js';

import CheckoutItem from './CheckoutItem.vue';
import FreeShippingReminder from './FreeShippingReminder.vue';
import FreeShippingCongratulations from './FreeShippingCongratulations.vue';
import DeliveryOptions from './DeliveryOptions.vue';
import DiscountOptions from './DiscountOptions.vue';

export default defineComponent({
	components:{
    CheckoutItem,
    FreeShippingReminder,
    FreeShippingCongratulations,
    DeliveryOptions,
    DiscountOptions
  },
  emits:[ 'choose-replacement', 'refresh' ],
	props:{
    branchId:{
      type:Number,
      default:null
    },
		items:{
			type:Array,
			default:null
		},
    subscriptions:{
			type:Array,
			default:null
		},
    isMobile:{
			type:Boolean,
			default:false
		}
	},
	setup(props, { emit }) {

    const TYPE_SUBSCRIPTION = 2;

		const store = useStore();
		const { moneyFormat } = useFormat();

    /*
		|--------------------------------------------------------------------------
		| @Data
		|--------------------------------------------------------------------------
		*/

    const optionForms = ref(props.subscriptions.map(x => ({ id: x.id })));

    /*
		|--------------------------------------------------------------------------
		| @Computed
		|--------------------------------------------------------------------------
		*/

    const fees = computed(() => store.state.settings.fees);

		/*
		|--------------------------------------------------------------------------
		| @Methods
		|--------------------------------------------------------------------------
		*/

    const filterItems = (subscriptionId) => {
      return props.items.filter(x => x.subscription_id == subscriptionId && !x.unavailable) || [];
    };

    const getIndex = (subscriptionId) => {
      return optionForms.value.findIndex(x => x.id == subscriptionId);
    };

    const getDeliveryOption = (subscriptionId) => {
      const form = optionForms.value[getIndex(subscriptionId)] || {};
      return form.deliveryMethod || 1;
    };

    const getForDelivery = (subscriptionId) => {
      const fee = fees.value.find(x => x.id == getDeliveryOption(subscriptionId)) || {};
      return fee.is_delivery;
    };

    const getSubtotal = (subscriptionId) => {
      const { giftCardAmount, points } = optionForms.value[getIndex(subscriptionId)] || {};
      return filterItems(subscriptionId).reduce((value,item) => value + parseFloat(item.subtotal), 0) - (giftCardAmount || 0 + points || 0);
    };

    const getMinimum = (subscriptionId) => {
      const fee = fees.value.find(x => x.id == getDeliveryOption(subscriptionId)) || {};
      return fee.free_delivery_fee || 0;
    };

    const getOutstanding = (subscriptionId) => {
      return getMinimum(subscriptionId) - getSubtotal(subscriptionId);
    };

    const openReplacementModal = () => { emit('choose-replacement'); };
    const refresh = () => { emit('refresh'); };

    /*
		|--------------------------------------------------------------------------
		| @Watch
		|--------------------------------------------------------------------------
		*/

    watch(
    () => optionForms.value,
    (arr) => store.commit('checkout/setSubscriptions', arr),
    { deep: true });

    watch(
    () => props.subscriptions,
    (arr) => optionForms.value = arr.map(x => ({ id: x.id })),
    { immediate: true });

		return {
      /* Data */
      TYPE_SUBSCRIPTION,
      optionForms,
      /* Methods */
      moneyFormat,
      filterItems,
      getIndex,
      getDeliveryOption,
      getForDelivery,
      getSubtotal,
      getOutstanding,
      getMinimum,
      openReplacementModal,
      refresh
		}
	},
});
</script>