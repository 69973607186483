<template>
  <ion-app>
    <ion-router-outlet />
  </ion-app>
</template>

<script type="text/javascript">
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { onMounted } from '@vue/runtime-core';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from "vuex";
import useAuth from "./composables/useAuth";
import useLocalStorage from "./composables/useLocalStorage";
import { Device } from "@capacitor/device";

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet
  },
setup() {
    const router = useRouter();
    const store = useStore();
    const { assignToken, setToken, onAuthRoute, logout } = useAuth();
    const { localStorage } = useLocalStorage();

    const checkAuthSession = () => {
      assignToken()
        .then(response => {
          if(response) {
            Promise.all([
              localStorage.value.get("authToken"),
              localStorage.value.get("user")
            ]).then(([ token, user ]) => {
              setToken(token, user);
              if(router.currentRoute.value.name == 'login') {
                router.push({ name: 'home' });
              }
            });

          } else if(onAuthRoute()) {
            logout();
          }
        });
    };

    const fetch = () => {
      let url = process.env.VUE_APP_API_URL + "/config/fetch?platform=web";
      if(store.state.address.branchId) url += `&branch=${store.state.address.branchId}`;
      return window.axios
        .get(url)
        .then(({ data }) => {
          
          window.axios.defaults.headers.common["ApiKey"] = `${process.env.VUE_APP_API_KEY}${data.key}`;
          
          store.commit("api/setRoutes", data.routes);
          store.commit("gender/setOptions", data.others.genderOptions);
          store.commit("settings/set", data.others);
          store.commit("content/set", data.cms);

          localStorage.value.set("routes", data.routes);
          localStorage.value.set("cms", data.cms);
          setBranchMethods();

          checkAuthSession();
        });
    };

    const setUtmTags = () => {
      const form = {};
      const query = router.currentRoute.value.query;
      const keys = Object.keys(query).filter(x => x.indexOf('utm') !== -1);
      keys.forEach(key => form[key] = query[key]);

      if(keys.length) {
        store.commit('checkout/set', { utmTags: form });
        localStorage.value.set('utm', form);
      }
    };

    const storeSessionRelatedData = async () => {
      const referrerSrc = document.referrer;
      const referrerName = referrerSrc ?  new URL(referrerSrc).hostname.replace('www.','') : null;
      const address = store.state.address.form
      const device = await Device.getInfo();

      const form = {
        user_id: store.state.user.token ? store.state.user.data.id : store.state.user.guestId,
        referrer_source: referrerSrc,
        referrer_name: referrerName,
        user_agent: navigator.userAgent,
        device: device.platform,
        province_code: address.province,
        city_code: address.city,
        barangay_code: address.barangay,
      }

      window.axios.post(process.env.VUE_APP_API_URL + '/session/store', form);
    }

    const setBranchMethods = async () => {
      // store.commit('settings/setDeliveryMethods', await localStorage.value.get('branchMethods'));
    }

    onMounted(async() => {
      
      const branchId = await localStorage.value.get('branch');
      const utmTags = await localStorage.value.get('utm');
      
      store.commit('address/setBranch', branchId);
      store.commit('checkout/set', { utmTags });

      fetch().then(() => {
        storeSessionRelatedData()
        setUtmTags()
      })
    });
  }
});
</script>