import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import pinia from "./store/pinia"

import { DateTime } from "luxon";
window.luxon = DateTime;

window.axios = require("axios").default;

window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
window.axios.defaults.headers.common["App-Key"] = process.env.VUE_APP_API_URL;

import Echo from 'laravel-echo';
window.Pusher = require('pusher-js');

window.Echo = new Echo({
  broadcaster: 'pusher',
  key: process.env.VUE_APP_MIX_PUSHER_APP_KEY,
  wsHost: process.env.VUE_APP_MIX_PUSHER_HOST,
  wssHost: process.env.VUE_APP_MIX_PUSHER_HOST,
  wsPort: process.env.VUE_APP_MIX_PUSHER_PORT,
  wssPort: process.env.VUE_APP_MIX_PUSHER_PORT,
  forceTLS: true,
  encrypted: true,
  disableStats: true,
  enabledTransports: ['ws', 'wss'],
});

window.Echo.connector.pusher.connection.bind('state_change', function(states) {
  if(states.current === 'disconnected') {
      window.Echo.connector.pusher.connect();
  }
});

import { IonicVue } from "@ionic/vue";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./theme/tailwind.css";

/* Custom Font */
import "./assets/fonts/nunito/stylesheet.scss";

/* Custom CSS */
import "./assets/scss/app.scss";
import useAuth from "./composables/useAuth";

// import * as Sentry from "@sentry/vue";
// import { BrowserTracing } from "@sentry/tracing";

const app = createApp(App)
  .use(IonicVue)
  .use(router)
  .use(store)
  .use(pinia);
const { assignToken } = useAuth();


// TODO: uncomment
// Sentry.init({
//   app,
//   dsn: "https://9052311b5e834584bc96b3e87bc0752b@sentry.praxxys.ph/6",
//   integrations: [
//     new BrowserTracing({
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//       tracingOrigins: ["localhost", "my-site-url.com", /^\//],
//     }),
//   ],
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });

Promise.allSettled([assignToken(), router.isReady()]).then(() => {
  app.mount("#app");
});
