<template>
    <div 
        class="cart-item__hldr--subscription"
    >
        <input
            class="rounded-sm mr-3 cursor-pointer"
            type="checkbox"
            id="subscription"
            v-model="checkAll"
        >
        <label
            class="form-check-label inline-block font-bold text--secondary"
            for="subscription"
        >
            Subscription ({{ items.length }})
        </label>
        <div class="cart-item__list mt-8 ml-8">
            <template
                v-for="(item, index) in items"
                :key="`cart-subscription-item-${index}-${item.updated_at}`"
            >
                <CartItem
                    class="mb-6"
                    :item="item"
                    :guest-id="guestId"
                    :is-mobile="isMobile"
                    :checked-all="checkAll"
                    is-subscription
                    @choose-replacement="openReplacementModal"
                    @refresh="refresh"
                />
            </template>
        </div>
    </div>
</template>
<script>
import { defineComponent, ref, watch } from "@vue/runtime-core";
import useCheckout from '@/composables/useCheckout';
import CartItem from './CartItem.vue';

export default defineComponent({
    name: 'Subscriptions',
    components:{
        CartItem
    },
    emits:[ 'choose-replacement', 'refresh' ],
    props:{
        items:{
            type:Array,
            default:() => ([])
        }
    },
    setup(props, { emit }) {

        const { addToCheckout } = useCheckout();

        const checkAll = ref(true);

        const openReplacementModal = () => { emit('choose-replacement'); };
        const refresh = () => { emit('refresh'); };

        watch(
            () => props.items,
            (items) => items.forEach(item => addToCheckout(item))
        );

        return {
            checkAll,
            openReplacementModal,
            refresh
        }
    },
});
</script>