<template>
  <ion-page>
    <top-navigation />
    <ion-content
      :fullscreen="true"
      class="ion-padding"
    >
      <div class="flex items-center justify-center pt-[40px] pb-24">
        <div class="w-[90%] md:w-[35%] mx-auto">
          <div class="ion-no-padding mb-10 md:mb-8">
            <h1 class="leading-12 font-size--xx-large font-bold text--secondary text-center mb-6">
              Delete Account
            </h1>
            <p class="text-gray-700 font-semibold text-center">You have requested for account deletion, please check the code we’ve sent through your email address to proceed.</p>
            <div class="mb-5">
                <ion-grid>
                    <ion-row>
                        <ion-col size="12">
                            <div class="flex items-center justify-center gap-2 md:gap-4">
                                <!-- add active class if input has value -->
                                <input
                                v-model="otpNUm.num1"
                                class="otp-input"
                                type="text"
                                id="input-1"
                                @input='digitValidate(1)'
                                @keyup='tabChange(1)'
                                maxlength="1"
                                autofocus
                                >
                                <input
                                v-model="otpNUm.num2"
                                class="otp-input"
                                type="text"
                                id="input-2"
                                @input='digitValidate(2)'
                                @keyup='tabChange(2)'
                                maxlength="1"
                                >
                                <input
                                v-model="otpNUm.num3"
                                class="otp-input"
                                type="text"
                                id="input-3"
                                @input='digitValidate(3)'
                                @keyup='tabChange(3)'
                                maxlength="1"
                                >
                                <input
                                v-model="otpNUm.num4"
                                class="otp-input"
                                type="text"
                                id="input-4"
                                @input='digitValidate(4)'
                                @keyup='tabChange(4)'
                                maxlength="1"
                                >
                                <input
                                v-model="otpNUm.num5"
                                class="otp-input"
                                type="text"
                                id="input-5"
                                @input='digitValidate(5)'
                                @keyup='tabChange(5)'
                                maxlength="1"
                                >
                                <input
                                v-model="otpNUm.num6"
                                class="otp-input"
                                type="text"
                                id="input-6"
                                @input='digitValidate(6)'
                                @keyup='tabChange(6)'
                                maxlength="1"
                                >
                            </div>
                            <div class="text-center" v-if="errors">
                              <label class="text-red-600 text-xs" v-if="errors.code">{{ errors.code[0] }}</label>
                          </div>
                        </ion-col>
                    </ion-row>
                </ion-grid>
            </div>
            <div class="text-center mt-7">
                <p class="text-xs text-gray-500 mb-4">Code expires in : <span class="text-primary-600 font-semibold">{{ displayCountDown }}</span> </p>
                <p class="text-xs text-gray-500 mb-4">Didn’t receive code? <span class="text-primary-600 font-semibold" @click="resend">Resend Code</span> </p>
            </div>
          </div>
          <div class="w-full flex justify-center items-center">
            <ion-button
              class="font--main btn-primary m-0 capitalized"
              shape="round"
              size="large"
              @click="openDeleteAccountModal"
            >
              Continue
            </ion-button>
          </div>
        </div>
      </div>
      <bottom-navigation />
    </ion-content>
    <Loader v-show="loader" />
  </ion-page>
</template>

<script type="text/javascript">
import {
    IonPage,
    IonContent,
    IonButton,
    IonGrid,
    IonRow,
    IonCol,
    modalController,
} from '@ionic/vue';
import { defineComponent, onMounted, ref, } from 'vue';
import { useStore } from "vuex";
import TopNavigation from '@/partials/TopNavigation.vue';
import BottomNavigation from '@/partials/BottomNavigation.vue';
import Loader from '@/components/PageLoader.vue';
import Modal from '@/components/Modal.vue';
import useAuth from "@/composables/useAuth";

export default defineComponent({
    name: 'DeleteAccountOTP',
    components: {
        IonContent,
        IonPage,
        IonButton,
        TopNavigation,
        BottomNavigation,
        IonGrid,
        IonRow,
        IonCol,
        Loader
    },
    setup() {

      const store = useStore();
      const loader = ref(false);
      const { logout } = useAuth();

      const otpNUm = ref({})
      const countDownTimeout = ref(null);
      const countDown = ref(120);
      const displayCountDown = ref('00:00');

      const countDownTimer = () => {
        if(countDown.value > 0) {
          countDownTimeout.value = setTimeout(() => {
            countDown.value -= 1;

            let minutes = parseInt(countDown.value / 60, 10);
            let seconds = parseInt(countDown.value % 60, 10);

            minutes = minutes < 10 ? "0" + minutes : minutes;
            seconds = seconds < 10 ? "0" + seconds : seconds;

            displayCountDown.value = minutes + ":" + seconds;
            countDownTimer()
          }, 1000)
        } else {
            clearTimeout(countDownTimeout.value);
        }
      }

      const digitValidate = (val) => {
        const element = document.querySelector('#input-'+val);
        element.value = element.value.replace(/[^0-9]/g,'');
      }

      const tabChange = (val) => {
        const ele = document.querySelectorAll('.otp-input');
        if(ele[val-1].value != ''){
            ele[val].focus()
        }else if(ele[val-1].value == ''){
            ele[val-2].focus()
        }   
      }   

      const errors = ref(null);

      /* Modal */
      const title = ref('Oops!');
      const message = ref('Something went wrong.');
      const buttonText = ref('Close');
      const imageLink = ref('/assets/icon/ic-warning.svg');

      const openErrorModal = async () => {
        const modal = await modalController.create({
              component: Modal,
              cssClass: 'gen-modal',
              componentProps: {
              title: title.value,
              description: message.value,
              buttonText: buttonText.value,
              imageLink: imageLink.value,
              closeModalButton: true,
              imageWidth: '80',
              imageHeight: '75',
              full: 'full'
            }
        });
        return modal.present();
      };

      const openSuccessModal = async () => {
          const modal = await modalController.create({
                component: Modal,
                cssClass: 'gen-modal',
                componentProps: {
                title: successTitle.value,
                description: successDescription.value,
                imageLink: '/assets/icon/ic-check.svg',
                closeModalButton: true,
                imageWidth: '80',
                imageHeight: '75',
                full: 'full'
              }
          });
          return modal.present();
        };

        const clearFields = () => {
          otpNUm.value.num1 = null;
          otpNUm.value.num2 = null;
          otpNUm.value.num3 = null;
          otpNUm.value.num4 = null;
          otpNUm.value.num5 = null;
          otpNUm.value.num6 = null;
        };

        const successTitle = ref();
        const successDescription = ref();

        const deleteAccount = () => {
            if(loader.value) return false;
            loader.value = true;

            const formData = {
                code: otpNUm.value.num1 + otpNUm.value.num2 + otpNUm.value.num3 + otpNUm.value.num4 + otpNUm.value.num5 + otpNUm.value.num6,
                deleteReason: store.state.user.deleteReasons.deleteReason,
                otherReasons:store.state.user.deleteReasons.otherReasons,
            }

            window.axios
                .post(store.state.api.routes.deleteUserAccount, formData)
                .then(() => {
                    loader.value = false;
                    successTitle.value = 'Account Deleted';
                    successDescription.value = 'Your account deletion will be processed shortly. You will be redirected into the home page.';
                    clearFields()
                    openSuccessModal()
                    setTimeout(() => {
                        modalController.dismiss();
                        logout()
                        window.location.href = "/"
                    }, 3000);
                }).catch((error) => {
                    loader.value = false;
                    message.value = null;
                    errors.value = null;
                    if(error) {
                        message.value = error.response.data.error;
                    }
                    if(error.response.data.message) {
                        message.value = error.response.data.message;
                    }
                    if(error.response.data.errors) {
                        errors.value = error.response.data.errors;
                    }
                    openErrorModal();
                });
        }

        const resend = async () => {
          loader.value = true;
          window.axios
            .post(store.state.api.routes.resendOtpDeletion )
            .then(() => {
                loader.value = false;
                successTitle.value = 'success'
                successDescription.value = 'Verification code sent to your email.'

                clearFields();
                openSuccessModal();
                countDown.value = 120;
                countDownTimer();

            }).catch((error) => {
                if(error.response.data.message) {
                    message.value = error.response.data.message;
                }
                loader.value = false;
                openErrorModal()
            });
        };

        const openDeleteAccountModal = async () => {
            const modal = await modalController.create({
                component: Modal,
                cssClass: 'gen-modal order-confirmation',
                componentProps: {
                title: 'Are you sure?',
                description: ``,
                imageLink: '/assets/icon/ic-status-warning.svg',
                cancelButtonText: 'Go Back',
                cancelButtonClass: 'btn-primary',
                cancelButton: true,
                cancelButtonFill: 'outline',
                emitButton: true,
                buttonClass: 'btn-danger',
                buttonText: 'Delete Account',
                imageWidth: '36',
                imageHeight: '36',
                }
            });

            modal.onDidDismiss().then(({ data }) => {
              if(data === 'emit') deleteAccount();
            });

            return modal.present();
        };

        onMounted(() => {
          countDownTimer()
        })
      
        return {
            openDeleteAccountModal,
            displayCountDown,
            otpNUm,
            digitValidate,
            tabChange,
            deleteAccount,
            resend,
            loader,
            errors
        };
      },
  })
</script>