<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <ion-grid class="ion-no-padding pa-5">
        <!-- Typography -->
        <ion-row>
          <ion-col size="12">
            <ion-item-divider class="px-0">
              <ion-label>
                <h1 class="font-weight-extrabold text--primary">
                  Typography | Font Face
                </h1>
              </ion-label>
            </ion-item-divider>
          </ion-col>
          <ion-col
            size="12"
            class="mb-8"
          >
            <h2 class="font-weight-bold">
              Nunito Font
            </h2>
            <div class="inlineBlock-parent">
              <h1 class="align-b mr-2">
                H1
              </h1>
              <h2 class="align-b mr-2">
                H2
              </h2>
              <h3 class="align-b mr-2">
                H3
              </h3>
              <h4 class="align-b mr-2">
                H4
              </h4>
              <h5 class="align-b mr-2">
                H5
              </h5>
              <h6 class="align-b mr-2">
                H6
              </h6>
            </div>
            <p class="font--21">
              Paragraph: Lorem ipsum dolor sit amet, consectetur adipisicing
            </p>
            <p>
              Paragraph: Lorem ipsum dolor sit amet, consectetur adipisicing
            </p>
            <p class="font--13">
              Paragraph: Lorem ipsum dolor sit amet, consectetur adipisicing
            </p>
            <p class="font--10">
              Paragraph: Lorem ipsum dolor sit amet, consectetur adipisicing
            </p>
            <small>
              Small: Lorem ipsum dolor sit amet, consectetur adipisicing elit
            </small>
          </ion-col>
          <ion-col size="12">
            <ion-item-divider class="px-0">
              <ion-label>
                <h1 class="font-weight-extrabold text--primary">
                  Typography | Font Size
                </h1>
              </ion-label>
            </ion-item-divider>
          </ion-col>
          <ion-col
            size="12"
            class="mb-8"
          >
            <h1 class="align-b mr-2 font-size--xx-large">
              XX-Large | 31px
            </h1>
            <h1 class="align-b mr-2">
              H1 | 25px
            </h1>
            <h2 class="align-b mr-2">
              H2 | 20px
            </h2>
            <h3 class="align-b mr-2">
              H3 | 16px
            </h3>
            <h4 class="align-b mr-2">
              H4 | 14px
            </h4>
            <h5 class="align-b mr-2">
              H5 | 12px
            </h5>
            <h6 class="align-b mr-2">
              H6 | 10px
            </h6>
            <p class="mb-0 font--21">
              Paragraph | 21px
            </p>
            <p class="mb-0">
              Paragraph | 16px
            </p>
            <p class="mb-0 font--13">
              Paragraph 13px
            </p>
            <p class="mb-0 font--10">
              Paragraph 10px
            </p>
            <small>Small | 8px</small>
          </ion-col>
          <ion-col size="12">
            <ion-item-divider class="px-0">
              <ion-label>
                <h1 class="font-weight-extrabold text--primary">
                  Typography | Font Style
                </h1>
              </ion-label>
            </ion-item-divider>
          </ion-col>
          <ion-col
            size="12"
            class="mb-8"
          >
            <p class="font-weight-extrabold mb-2">
              Extra Bold
            </p>
            <p class="font-weight-extrabold font-italic mb-2">
              Extra Bold Italic
            </p>
            <p class="font-weight-bold mb-2">
              Bold
            </p>
            <p class="font-weight-bold font-italic mb-2">
              Bold Italic
            </p>
            <p class="font-weight-regular mb-2">
              Regular
            </p>
            <p class="font-weight-regular font-italic mb-2">
              Regular Italic
            </p>
          </ion-col>
        </ion-row>

        <!-- Buttons -->
        <ion-row>
          <ion-col size="12">
            <ion-item-divider class="px-0">
              <ion-label>
                <h1 class="font-weight-extrabold text--primary">
                  Buttons
                </h1>
              </ion-label>
            </ion-item-divider>
          </ion-col>
          <ion-col size="12" />
        </ion-row>

        <ion-row>
          <ion-col size="12">
            <!-- Colors -->
            <ion-button
              size="large"
              class="btn-primary"
            >
              Primary
            </ion-button>
            <ion-button
              size="large"
              class="btn-secondary"
            >
              Secondary
            </ion-button>
            <ion-button
              size="large"
              class="btn-success"
            >
              Success
            </ion-button>
            <ion-button
              size="large"
              class="btn-warning"
            >
              Warning
            </ion-button>
            <ion-button
              size="large"
              class="btn-danger"
            >
              Danger
            </ion-button>
            <ion-button
              size="large"
              class="btn-light"
            >
              Light
            </ion-button>
            <ion-button
              size="large"
              class="btn-medium"
            >
              Medium
            </ion-button>
            <ion-button
              size="large"
              class="btn-dark"
            >
              Dark
            </ion-button>

            <ion-button
              class="btn-primary"
              shape="round"
              size="large"
              fill="outline"
            >
              Outline + Primary
            </ion-button>
            <ion-button
              class="btn-secondary"
              shape="round"
              size="large"
              fill="outline"
            >
              Outline + Secondary
            </ion-button>
            <ion-button
              class="btn-success"
              shape="round"
              size="large"
              fill="outline"
            >
              Outline + Success
            </ion-button>
            <ion-button
              class="btn-warning"
              shape="round"
              size="large"
              fill="outline"
            >
              Outline + Warning
            </ion-button>
            <ion-button
              class="btn-danger"
              shape="round"
              size="large"
              fill="outline"
            >
              Outline + Danger
            </ion-button>
            <ion-button
              class="btn-light"
              shape="round"
              size="large"
              fill="outline"
            >
              Outline + Light
            </ion-button>
            <ion-button
              class="btn-medium"
              shape="round"
              size="large"
              fill="outline"
            >
              Outline + Medium
            </ion-button>
            <ion-button
              class="btn-dark"
              shape="round"
              size="large"
              fill="outline"
            >
              Outline + Dark
            </ion-button>
          </ion-col>
          <ion-col
            size="12"
            class="mb-8"
          >
            <ion-button
              class="btn-primary"
              size="large"
              shape="round"
              disabled
            >
              Disabled
            </ion-button>

            <!-- Anchor -->
            <ion-button
              shape="round"
              class="btn-link text--primary"
              href="#"
            >
              Anchor Primary
            </ion-button>
            <ion-button
              shape="round"
              class="btn-link text--secondary"
              href="#"
            >
              Anchor Secondary
            </ion-button>

            <!-- Expand -->
            <ion-button
              class="btn-primary"
              shape="round"
              size="large"
              expand="full"
            >
              Full Button
            </ion-button>
            <ion-button
              class="btn-primary"
              shape="round"
              size="large"
              expand="block"
            >
              Block Button
            </ion-button>

            <!-- Fill -->
            <ion-button
              class="btn-primary"
              shape="round"
              size="large"
              expand="full"
              fill="outline"
            >
              Outline + Full
            </ion-button>
            <ion-button
              class="btn-secondary"
              shape="round"
              size="large"
              expand="block"
              fill="outline"
            >
              Outline + Block
            </ion-button>

            <!-- Icons -->
            <ion-button
              shape="round"
              size="large"
            >
              <ion-icon
                slot="start"
                src="/assets/icons/ic-cart.svg"
                class="mr-3"
                name="cart"
              />
              Left Icon
            </ion-button>

            <ion-button
              shape="round"
              size="large"
            >
              Right Icon
              <ion-icon
                slot="end"
                src="/assets/icons/ic-cart.svg"
                class="ml-3"
                name="cart"
              />
            </ion-button>

            <ion-button
              shape="round"
              size="large"
              class="btn-tile"
            >
              <ion-icon
                slot="icon-only"
                src="/assets/icons/ic-cart.svg"
                name="cart"
              />
            </ion-button>

            <!-- Sizes -->
            <ion-button
              shape="round"
              size="large"
            >
              Large
            </ion-button>
            <ion-button shape="round">
              Default
            </ion-button>
            <ion-button
              shape="round"
              size="small"
            >
              Small
            </ion-button>
          </ion-col>
        </ion-row>

        <!-- Forms -->
        <ion-row>
          <ion-col size="12">
            <ion-item-divider class="px-0">
              <ion-label>
                <h1 class="font-weight-extrabold text--primary">
                  Forms
                </h1>
              </ion-label>
            </ion-item-divider>
          </ion-col>
          <ion-col
            size="12"
            class="mt-4 mb-8"
          >
            <div class="mb-4">
              <input
                type="email"
                name="email"
                id="email"
                class="focus:ring-primary focus:border-primary block w-full py-2.5 px-3 border-gray-100 rounded-lg placeholder:text-gray-400"
                placeholder="you@example.com"
              >
            </div>
            <div class="mb-4 relative">
              <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <img
                  src="/assets/icon/favicon.png"
                  width="20"
                  alt=""
                >
              </div>
              <input
                type="email"
                name="email"
                id="email"
                class="focus:ring-primary focus:border-primary block w-full py-2.5 pl-10 border-gray-100 rounded-lg placeholder:text-gray-400"
                placeholder="you@example.com"
              >
            </div>
            <ion-item>
              <ion-label>Toggle</ion-label>
              <ion-toggle
                slot="end"
                checked
              />
            </ion-item>

            <ion-item>
              <ion-checkbox
                slot="start"
                checked
                mode="md"
              />
              <ion-label>Checkbox</ion-label>
            </ion-item>
          </ion-col>
        </ion-row>

        <!-- Cards -->
        <ion-row>
          <ion-col size="12">
            <ion-card class="w-100" />
          </ion-col>
          <ion-col size="12" />
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script type="text/javascript">
// import { defineComponent } from '@vue/composition-api'
// import { TextInput } from '@/components/TextInput.vue'

export default ({
  name: 'StylesheetPage',
  components: {
      // TextInput,
  },
})
</script>
