'use strict'

var axios = require("axios");
var axiosInstance = axios.create();

async function initPaymaya(options, result = null) {
    switch(options.paymentMethod) {
        case 'PAYMAYA': break;
        case 'PAYMAYA_CREDIT_CARD': result = await processCreditCard(options); break;
    }
    if(result) return Promise.resolve(result);
    else return Promise.reject();
}

async function processCreditCard(options) {
    const paymentToken = await createPaymentToken(options);
    return paymentToken ? await createPayment(options, paymentToken) : Promise.reject();
}

function createCustomer({ firstName, lastName, mobileNumber, email }) {
    return axiosInstance.post(
            `${config('PAYMAYA_URL')}/payments/v1/customers`, 
            {
                firstName, lastName,
                contact: {
                    phone: mobileNumber,
                    email,
                }
            }, 
            { 
                headers: {
                    'Authorization': generateBasicToken(config('PAYMAYA_SECRET_KEY')) 
                }
            }
        )
        .then(({ data }) => data.id)
        .catch(error => console.log(error));
}

function createPaymentToken({ cardNumber, expMonth, expYear, cvc }) {
    return axiosInstance.post(
        `${config('PAYMAYA_URL')}/payments/v1/payment-tokens`, 
        {
            card: {
                number: cardNumber,
                expMonth: String(expMonth).padStart(2, '0'),
                expYear,
                cvc
            }
        }, 
        { 
            headers: {
                'Authorization': generateBasicToken(config('PAYMAYA_PUBLIC_KEY')) 
            }
        }
    )
    .then(({ data }) => data.paymentTokenId)
    .catch(error => console.log(error));
}

function createPayment({ firstName, lastName, mobileNumber, email, amount, paymentMethod, requestReferenceNumber }, paymentTokenId) {
    const baseUrl = `${config('VUE_APP_URL')}/checkout?paymentMethod=${paymentMethod}`;
    return axiosInstance.post(
        `${config('PAYMAYA_URL')}/payments/v1/payments`, 
        {
            paymentTokenId,
            totalAmount: {
                currency: 'PHP',
                amount, 
            },
            buyer:{
                firstName, lastName,
                contact: {
                    phone: mobileNumber,
                    email,
                }
            },
            redirectUrl: {
                success: `${baseUrl}&paymentStatus=success`,
                failure: `${baseUrl}&paymentStatus=failure`,
                cancel: `${baseUrl}&paymentStatus=cancel`
            },
            requestReferenceNumber
        }, 
        { 
            headers: {
                'Authorization': generateBasicToken(config('PAYMAYA_SECRET_KEY')) 
            }
        }
    )
    .then(({ data }) => data)
    .catch(error => console.log(error));
}

function createCustomerCard(customerId, paymentTokenId, { paymentMethod, responseUrl }) {
    const baseUrl = responseUrl || `${config('VUE_APP_URL')}/checkout?paymentMethod=${paymentMethod}`;
    
    return axiosInstance.post(
        `${config('PAYMAYA_URL')}/payments/v1/customers/${customerId}/cards`, 
        {
            paymentTokenId: paymentTokenId,
            isDefault: false,
            redirectUrl: {
                success: `${baseUrl}&paymentStatus=success`,
                failure: `${baseUrl}&paymentStatus=failure`,
                cancel: `${baseUrl}&paymentStatus=cancel`
            },
        }, 
        { 
            headers: {
                'Authorization': generateBasicToken(config('PAYMAYA_SECRET_KEY')),
                'content-type': 'application/json',
                'accept': 'application/json',
            }
        },
        
    )
    .then(({ data }) => data)
    .catch(error => console.log(error));
}

function createPaymentForSubscription({ amount, paymentMethod }, customerId, cardTokenId) {
    const baseUrl = `${config('VUE_APP_URL')}/checkout?paymentMethod=${paymentMethod}`;
    return axiosInstance.post(
        `${config('PAYMAYA_URL')}/payments/v1/customers/${customerId}/cards/${cardTokenId}/payments`, 
        {
            totalAmount: {
                currency: 'PHP',
                amount, 
            },
            redirectUrl: {
                success: `${baseUrl}&paymentStatus=success`,
                failure: `${baseUrl}&paymentStatus=failure`,
                cancel: `${baseUrl}&paymentStatus=cancel`
            },
        }, 
        { 
            headers: {
                'Authorization': generateBasicToken(config('PAYMAYA_SECRET_KEY')) 
            }
        }
    )
    .then(({ data }) => data)
    .catch(error => console.log(error));
}

/** Checkout Paymement */
function createCheckoutPayment({ firstName, lastName, mobileNumber, email, street, unitNo, barangay, city, province, zipCode }, amount, refNumber){
    const baseUrl = `${config('VUE_APP_URL')}/checkout?paymentMethod=PAY_WITH_MAYA`;
    return axiosInstance.post(
        `${config('PAYMAYA_URL')}/checkout/v1/checkouts`, 
        {
            totalAmount: {
                currency: 'PHP',
                value: amount, 
            },
            requestReferenceNumber: refNumber,
            buyer:{
                firstName, lastName,
                contact: {
                    phone: mobileNumber,
                    email,
                },
                billingAddress: {
                    line1: `${unitNo} ${street} ${barangay}`,
                    city: city,
                    state: province,
                    zipCode: zipCode,
                    countryCode: 'PH',
                },
                shippingAddress: {
                    line1: `${unitNo} ${street} ${barangay}`,
                    city: city,
                    state: province,
                    zipCode: zipCode,
                    countryCode: 'PH',
                },
            },
           
            redirectUrl: {
                success: `${baseUrl}&paymentStatus=success`,
                failure: `${baseUrl}&paymentStatus=failure`,
                cancel: `${baseUrl}&paymentStatus=cancel`
            },
            metadata: {
                subMerchantRequestReferenceNumber: refNumber,
            }
        }, 
        { 
            headers: {
                'Authorization': generateBasicToken(config('PAYMAYA_CHECKOUT_PUBLIC_KEY')) 
            }
        }
    )
    .then(({ data }) => data)
    .catch(error => console.log(error));
}



function config(key) {
    return process.env[key] || process.env[`VUE_APP_${key}`];
}

function generateBasicToken(key) {
    return 'Basic '+btoa(`${key}:`);
}

module.exports = {
    config,
    initPaymaya,
    createCustomer,
    createPaymentToken,
    createPayment,
    createCustomerCard,
    createPaymentForSubscription,
    createCheckoutPayment
};