<template>
  <div class="mt-2">
    <div class="flex">
      <div
        class="w-28"
        v-if="!isMobile"
      >
        <img
          width="74"
          height="74"
          class="border-radius-5"
          :src="item.product.initial_image || '/assets/image/prd-img1.png'"
          alt="cart-item-img"
        >
      </div>
      <div class="w-full">
        <div class="flex justify-between">
          <h5 :class="item.unavailable ? 'text--gray--400' : 'text--gray--800'">
            {{ item.product.name }}
          </h5>
          <img
            v-if="isMobile"
            class="w-[42px] h-[42px] border-radius-5 ml-16"
            :src="item.product.initial_image || '/assets/image/prd-img1.png'"
            alt="cart-item-img"
          >
        </div>
        <h5 :class="item.unavailable ? 'text--gray--400' : 'font-bold'">
          <span 
            class="mr-1" 
            v-if="item.original_price != item.discounted_price"
          >
            <strike>{{ moneyFormat(item.original_price, 2, '₱') }}</strike>
          </span>
          <span>{{ moneyFormat(item.discounted_price, 2, '₱') }}</span> x {{ item.quantity }}
        </h5>
        <h5 class="text--gray--200 font-bold" v-if="isAuthenticated">
          {{ item.product.points }} Droplet/s
        </h5>
        <div class="w-full mt-2" v-if="!item.unavailable">
          <div class="w-full mb-2">
            <h6 class="text--gray--300">
              In case the item is unavailable:
            </h6>
          </div>
          <p>{{ unavailableActionlabel }}</p>
          <!-- <ion-select
            :key="selectOptionKey"
            interface="popover"
            class="frm-select bg--white w-full mr-4 mb-2"
            v-model="unavailableAction"
          >
            <ion-select-option 
              v-for="option in unavailableOptions" 
              :key="`unavailable-option-${option.id}`"
              :value="option.id"
            >
              {{ option.value }}
            </ion-select-option>
          </ion-select> -->
          <div
            class="flex justify-between mb-4"
            v-if="replacementItem.id"
          >
            <h6 class="text--gray--400">
              <span class="font-bold">Replacement Item:</span> <span>{{ replacementItem.name }}</span>
            </h6>
            <h6
              class="text--gray--400 cursor-pointer"
              @click="openReplacementModal"
            >
              Change
            </h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">
import { ref, defineComponent, watch, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import useAuth from '@/composables/useAuth';
import useCheckout from '@/composables/useCheckout.js';
import useFormat from '@/composables/useFormat.js';

import {  modalController } from '@ionic/vue';
import Modal from '@/components/Modal';

export default defineComponent({
  name: 'CheckoutItem',
  components: {
    // IonSelect,
    // IonSelectOption
  },
  emits:[ 'choose-replacement', 'refresh' ],
  props:{
    isSubscription: {
      default: false,
      type: Boolean
    },
    item: {
      type: Object,
      default:() => ({})
    },
    isMobile : {
      default: false,
      type: Boolean
    },
  },
  setup(props, { emit }) {

		const store = useStore();

    const { isAuthenticated } = useAuth();
		const { moneyFormat } = useFormat();
    const { updateCheckoutItem } = useCheckout();

    /*
		|--------------------------------------------------------------------------
		| @Data
		|--------------------------------------------------------------------------
		*/

    const title = ref(null);
    const message = ref(null);
    const image = ref(null);
    const isLoading = ref(false);
    const unavailableAction = ref(props.item.not_available_option || 1);
    const selectOptionKey = ref(`unavailable-action-${props.item.id}`);

    /*
		|--------------------------------------------------------------------------
		| @Computed
		|--------------------------------------------------------------------------
		*/
    const vouchers = computed(() => store.state.checkout.vouchers);
    const unavailableOptions = computed(() => store.state.settings.unavailableOptions);
    const unavailableActionlabel = computed(() => unavailableOptions.value?.find(x => x.id == unavailableAction.value)?.value || 'Remove Item')

    const replacementOption = computed(() => {
      return store.state.checkout.replacements.find(x => x.productId == props.item.product_id) || {};
    });

    const replacementItem = computed(() => {
      return store.state.products.items.find(x => x.id == props.item.replacement_item_id) || {};
    });

    const cartItem = computed(() => {
      const option = unavailableAction.value;
      const rid = option == 4 ? replacementOption.value.replacementItemId : null;
      return {
        branchId: store.state.address.branchId,
        id: props.item.id,
        productId: props.item.product_id,
        subscriptionId: props.item.subscription_id,
        type: props.item.type,
        quantity: props.item.quantity,
        originalPrice: props.item.original_price,
        discountedPrice: props.item.discounted_price,
        notAvailableOption: option,
        replacementItemId: rid
      };
    });

    /*
		|--------------------------------------------------------------------------
		| @Methods
		|--------------------------------------------------------------------------
		*/

    const openModal = async () => {
      const modal = await modalController.create({
        component: Modal,
        cssClass: 'gen-modal',
        componentProps: {
          title: title.value,
          description: message.value,
          imageLink: image.value,
          closeModalButton: true,
          imageWidth: '64',
          imageHeight: '64',
        }
      });
      return modal.present();
    };

    const openReplacementModal = () => { 
      store.commit('checkout/initReplacement', props.item.product_id);
      emit('choose-replacement');
    };

    const submit = () => {
      if(isLoading.value) return;
      isLoading.value = true;
      window.axios.post(store.state.api.routes.cartItemUpdate, cartItem.value)
        .then(({ data }) => {
          
          title.value = 'Success!';
          message.value = data.message;
          image.value = '/assets/icon/ic-status-check.svg';
          openModal();
          updateCheckoutItem(data.cartItems.find(x => x.id == props.item.id));
          
          emit('refresh');
          isLoading.value = false;
        })
        .catch(({ response }) => {
          
          title.value = 'Oops!';
          message.value = response.data.message;
          image.value = '/assets/icon/ic-status-warning.svg';
          openModal();

          unavailableAction.value = null;
          isLoading.value = false;
        });
    };

    /*
		|--------------------------------------------------------------------------
		| @Watch
		|--------------------------------------------------------------------------
		*/

    watch(
			() => unavailableAction.value,
			(option, oldValue) => {
        if(!oldValue) return;
        if(option == 4 && !props.item.replacement_item_id) {
          openReplacementModal();
        } else if(option) {
          submit();
        }
			}
		);

    watch(
      () => replacementOption.value.replacementItemId,
      (id) => {
        if(id) submit();
      });

    onMounted(() => {
      
      setTimeout(() => {
        /**
         * refreshes ion-select key to force it re-render coz on initial load 
         * it doesnt show the pre-selected option
         */
        selectOptionKey.value += Math.random();
      }, 1000);
    });

    return {
      isLoading,
      isAuthenticated,
      selectOptionKey,
			unavailableOptions,
			unavailableAction,
			replacementItem,
			moneyFormat,
      openReplacementModal,
      unavailableActionlabel,
      vouchers
    }
  }
})
</script>