<template>
  <ion-page>
    <top-navigation />
    <ion-content
      :fullscreen="true"
      class="ion-padding"
    >
      <account-side-navigation>
        <slot>
          <div class="mb-4 space-y-4">
            <h4 class="text--gray--900 font-bold">
              Account Details
            </h4>
            <p class="text--secondary text-xl font-bold">
              Change Password
            </p>
            <p class="text-base font-normal">
              Leave blank to keep it unchanged.
            </p>
          </div>
          <div class="w-full">
            <form action="">
              <div class="flex flex-col gap-4 lg:w-4/12 w-full">
                <div>
                  <div class="mt-1 relative rounded-md">
                    <input
                      v-model="form.oldPassword"
                      :type="show ? 'text' : 'password'"
                      name="oldPassword"
                      id="oldPassword"
                      class="focus:ring-[#02adf0] focus:border-[#02adf0] block w-full border-gray-300 placeholder-gray-400 rounded-[10px] h-12"
                      placeholder="Enter your old password"
                    >
                    <ion-icon
                      class="password-ic"
                      :src="show ? '/assets/icon/ic-eye.svg' : '/assets/icon/ic-eye-off.svg'"
                      @click="show = !show"
                    />
                  </div>
                </div>
                <div>
                  <div class="mt-1 relative rounded-md">
                    <input
                      v-model="form.password"
                      :type="show ? 'text' : 'password'"
                      name="password"
                      id="password"
                      class="focus:ring-[#02adf0] focus:border-[#02adf0] block w-full border-gray-300 placeholder-gray-400 rounded-[10px] h-12"
                      placeholder="Enter your new password"
                    >
                    <ion-icon
                      class="password-ic"
                      :src="show ? '/assets/icon/ic-eye.svg' : '/assets/icon/ic-eye-off.svg'"
                      @click="show = !show"
                    />
                  </div>
                </div>
                <div>
                  <div class="mt-1 relative rounded-md">
                    <input
                      v-model="form.password_confirmation"
                      :type="show ? 'text' : 'password'"
                      name="password_confirmation"
                      id="password_confirmation"
                      class="focus:ring-[#02adf0] focus:border-[#02adf0] block w-full border-gray-300 placeholder-gray-400 rounded-[10px] h-12"
                      placeholder="Confirm your new password"
                    >
                    <ion-icon
                      class="password-ic"
                      :src="show ? '/assets/icon/ic-eye.svg' : '/assets/icon/ic-eye-off.svg'"
                      @click="show = !show"
                    />
                  </div>
                </div>
                <div>
                  <ion-button
                    class="btn-primary"
                    shape="round"
                    size="large"
                    fill="outline"
                    @click="submit"
                    :disabled="loader"
                  >
                    Save
                  </ion-button>
                </div>
              </div>
            </form>
          </div>
        </slot>
      </account-side-navigation>
      <bottom-navigation />
    </ion-content>
    <Loader v-show="loader" />
  </ion-page>
</template>
<script type="text/javascript">

import { ref, defineComponent } from 'vue';
import { useStore } from 'vuex';

import {
    IonPage,
    IonContent,
    IonButton,
    IonIcon,
    modalController,
} from '@ionic/vue';
import Modal from '@/components/Modal.vue';
import TopNavigation from '@/partials/TopNavigation.vue';
import BottomNavigation from '@/partials/BottomNavigation.vue';
import AccountSideNavigation from '@/partials/AccountSideNavigation.vue';
import Loader from '@/components/PageLoader.vue';

export default defineComponent({
  name: 'Login',
  components: {
    IonContent,
    IonPage,
    IonButton,
    IonIcon,
    TopNavigation,
    BottomNavigation,
    AccountSideNavigation,
    Loader
  },
  setup() {
    const loader = ref(false);

    const store = useStore();
    
    const errorMessage = ref(null);
    const show = ref(false);
    const form = ref({
      oldPassword: null,
      password: null,
      password_confirmation: null,
    });

    const openModal = async () => {
      const modal = await modalController.create({
        component: Modal,
        cssClass: 'gen-modal email-verification',
        componentProps: {
          title: "Success!",
          description: "Your password has been successfully updated.",
          buttonText: "Okay",
          imageLink: "/assets/icon/ic-status-check.svg",
          closeModalButton: true,
          imageWidth: '64',
          imageHeight: '64'
        }
      });
      return modal.present();
    };

    const openErrorModal = async () => {
      const modal = await modalController.create({
        component: Modal,
        cssClass: 'gen-modal',
        componentProps: {
        title: 'Oops!',
        description: errorMessage.value,
        buttonText: 'Close',
        imageLink: '/assets/icon/ic-status-warning.svg',
        closeModalButton: true,
        buttonClass: 'btn-primary',
        imageWidth: '28',
        imageHeight: '28',
        full: 'full'
        }
      });
      return modal.present();
    };

    const clearFields = () => {
      form.value.oldPassword = null;
      form.value.password = null;
      form.value.password_confirmation = null;
    };

    const submit = () => {
      loader.value = true;
      window.axios.post(store.state.api.routes.changePassword, form.value)
        .then(() => {
          openModal();
          clearFields();
          loader.value = false;
        })
        .catch(({ response }) => {
          loader.value = false;
          if (response.data.error) {
            errorMessage.value = response.data.error;
          }
          if (response.data.message) {
            errorMessage.value = response.data.message;
          }
          openErrorModal();
        });
    };

    return {
      show,
      form,
      submit,
      loader
    };
  },
})
</script>