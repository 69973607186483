<template>
  <div class="lg:col-span-2 col-span-full lg:p-16 p-6 py-10">
    <div class="h-[400px]">
      <div class="mb-3">
        <template v-if="showMap == false">
          <p class="text-base">
            Please input additional details to confirm your location
          </p>
          <!-- <ion-button
            shape="round"
            class="btn-link map text--primary ion-no-margin h-6"
            @click="showMap = !showMap"
          >
            <span class="underline font-normal">Find your address using our map</span>
          </ion-button> -->
        </template>
        <template v-else>
          <p class="text-base">
            Please find your exact location on the map below, or
          </p>
          <ion-button
            shape="round"
            class="btn-link map text--primary ion-no-margin h-6"
            @click="showMap = !showMap"
          >
            <span class="underline font-normal">Input your address manually</span>
          </ion-button>
        </template>
      </div>
      
      <template v-if="showMap == false">
        <div class="flex flex-col items-center justify-center gap-4 mb-6 h-[230px]">
          <input
            autofocus
            type="text"
            class="focus:ring-[#02adf0] focus:border-[#02adf0] block w-full border-gray-300 placeholder-gray-400 rounded-[10px] h-12"
            placeholder="Street"
            v-model="form.street"
          >
          <input
            type="text"
            class="focus:ring-[#02adf0] focus:border-[#02adf0] block w-full border-gray-300 placeholder-gray-400 rounded-[10px] h-12"
            placeholder="House/Unit No. and house/building name"
            v-model="form.unitNo"
          >
          <input
            type="text"
            class="focus:ring-[#02adf0] focus:border-[#02adf0] block w-full border-gray-300 placeholder-gray-400 rounded-[10px] h-12"
            placeholder="Zip Code"
            v-model="form.zipCode"
          >
        </div>
      </template>
      
      <div class="flex items-center justify-between">
        <div>
          <ion-button
            size="large"
            class="btn-primary mr-4"
            :disabled="cannotContinue"
            @click="nextStep"
          >
            Continue
          </ion-button>
          <ion-button
            shape="round"
            class="btn-link text--primary"
            @click="previousStep"
          >
            <span class="underline font-normal">Go Back</span>
          </ion-button>
        </div>
        <div>
          <p class="text--secondary text-sm">
            <span class="font-bold">02</span>/03
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { computed, onMounted, ref, watch } from 'vue';
import { useStore } from "vuex";

import { IonButton } from '@ionic/vue';

export default {
  name: 'StepTwo',
  components: {
    IonButton
  },
  props:{
    currentStep:{
      type:Number,
      default:1
    }
  },
  emits:[ 'previous', 'next' ],
  setup(props, { emit }) {

    /*
    |--------------------------------------------------------------------------
    | @Data
    |--------------------------------------------------------------------------
    */

    const store = useStore();

    const showMap = ref(false);
    const form = ref({
      street: '',
      unitNo: '',
      zipCode: ''
    });
    const errorMessage = ref(null);
    const isLoading = ref(false);

    const cannotContinue = computed(() => !form.value.street || !form.value.unitNo || !form.value.zipCode);

    /*
    |--------------------------------------------------------------------------
    | @Methods
    |--------------------------------------------------------------------------
    */

    const getLocationDetails = async () => {
      const streetAddress = store.state.address.form;
      form.value.street = streetAddress.street ? streetAddress.street : null;
      form.value.unitNo = streetAddress.unitNo ? streetAddress.unitNo : null;
      form.value.zipCode = streetAddress.street ? streetAddress.zipCode : null;
    }
    
    const previousStep = () => { emit('previous'); };

    const nextStep = () => {
      store.commit('address/setForm', form.value);
      emit('next');
    };

    /*
    |--------------------------------------------------------------------------
    | @Watch
    |--------------------------------------------------------------------------
    */

    watch(
      () => form.value,
      (value) => {
        if(value) {
          form.value = store.state.address.form;
        }
      },
      { deep: true }
    );

    onMounted(() => {
      getLocationDetails();
    });

    return {
      form,
      errorMessage,
      isLoading,
      cannotContinue,
      showMap,
      previousStep,
      nextStep,
    }
  }
}
</script>