<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-10" @close="open = false">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        />
      </TransitionChild>

      <div class="fixed z-10 inset-0 overflow-y-auto">
        <div
          class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              class="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-xl sm:w-full sm:p-6"
            >
              <XIcon
                @click="open = false"
                class="w-6 x-6 absolute top-5 right-4 cursor-pointer mobile-close"
              />
              <div>
                <div class="mt-3 sm:mt-5 space-y-4">
                  <div class="flex items-start justify-start gap-4">
                    <div class="flex-none w-28 h-28">
                      <img class="rounded-lg"
                        :src="product.initial_image || '/assets/image/prd-img5.png'"
                      />
                    </div>
                    <div class="flex-1 flex flex-col justify-between h-28">
                      <p
                        class="text-base text-[#343A40] font-bold whitespace-normal w-80 mobile-product-name"
                      >
                        {{ product.name }}
                      </p>
                      <div class="flex items-center justify-between mt-auto">
                        <div class="product-quantity__option">
                          <button class="minus-qty__btn" @click="decrement">
                            -
                          </button>
                          <input
                            v-model="addToCartVars.quantity"
                            class="input-qty text-center font-bold text-xl"
                            type="number"
                          />
                          <button class="add-qty__btn" @click="increment">
                            +
                          </button>
                        </div>

                        <div v-if="stock <= product.danger_stock_level" 
                          class="py-2 px-3 inline-flex bg--red--400">
                            <p class="text-xs font-bold text--white">
                              Stock count is low
                            </p>
                        </div>
                        <!-- <div v-if="stock == 0 || addToCartVars.quantity == (stock - 1)" 
                          class="py-2 px-3 inline-flex bg--red--400">
                            <p class="text-xs font-bold text--white">
                              Stock count is low
                            </p>
                        </div> -->

                      </div>
                    </div>
                  </div>

                  <!-- If checked, add 'border-active' class  -->
                  <div
                    v-if="product.for_subscription"
                    class="form-check product-purchase__subscription bg--primary--50 border-radius-10 p-5 pb-2"
                    :class="isSubscriptionPurchase ? 'border-active' : ''"
                  >
                    <input
                      v-model="addToCartVars.type"
                      class="mr-3"
                      type="radio"
                      name="flexRadioDefault"
                      id="subscription"
                      value="2"
                    />
                    <label
                      class="form-check-label inline-block"
                      for="subscription"
                    >
                      Subscribe and Save
                      <span class="font-bold text--secondary"
                        >{{ selectedSubscription.amount }}%</span
                      >
                    </label>
                    <div class="flex md:float-right">
                      <div>
                        <p class="font-bold block md:float-right">
                          {{ moneyFormat(parseFloat(selectedSubscription.price) * addToCartVars.quantity, 2, "₱")}}
                        </p>
                        <p class="font--h6 text--gray--400 md:float-right mr-2 line-through">
                          {{ moneyFormat( product.price_per_case * addToCartVars.quantity, 2, "₱")}}
                        </p>
                      </div>
                    </div>
                    <div class="mt-4 flex">
                      <div class="w-full flex flex-nowrap md:justify-between gap-4">
                        <select
                          v-model="frequency"
                          class="mt-1 block w-full pl-3 pr-10 py-2 text-base focus:outline-none focus:ring-[#02ADF0] focus:border-[#02ADF0] sm:text-sm rounded-md border-gray-300"
                          placeholder="Delivery Frequency: -- "
                        >
                          <option selected disabled :value="null">
                            Delivery Frequency: --
                          </option>
                          <option
                            v-for="freq in subscriptions.frequencies"
                            :key="`frequency-option-${freq.id}`"
                            :value="freq.id"
                          >
                            {{ freq.name }}
                          </option>
                        </select>
                        <!-- <ion-select
                                                    v-model="frequency"
                                                    interface="popover"
                                                    class="frm-select bg--white w-full md:w-[49%] mb-3"
                                                    placeholder="Frequency: --"
                                                >
                                                    <ion-select-option 
                                                        v-for="freq in subscriptions.frequencies" 
                                                        :key="`frequency-option-${freq.id}`" 
                                                        :value="freq.id"
                                                    >
                                                    {{ freq.name }}
                                                    </ion-select-option>
                                                </ion-select> -->
                        <select
                          v-model="duration"
                          class="mt-1 block w-full pl-3 pr-10 py-2 text-base focus:outline-none focus:ring-[#02ADF0] focus:border-[#02ADF0] sm:text-sm rounded-md border-gray-300"
                          placeholder="Duration: -- "
                        >
                          <option selected disabled :value="null">
                            Duration: --
                          </option>
                          <option
                            v-for="dur in subscriptions.durations"
                            :key="`duration-option-${dur.id}`"
                            :value="dur.id"
                          >
                            {{ dur.name }}
                          </option>
                        </select>
                        <!-- <ion-select
                                                    v-model="duration"
                                                    interface="popover"
                                                    class="frm-select bg--white w-full md:w-[49%] mb-3"
                                                    placeholder="Duration: --"
                                                >
                                                    <ion-select-option 
                                                    v-for="dur in subscriptions.durations" 
                                                    :key="`duration-option-${dur.id}`" 
                                                    :value="dur.id"
                                                    >
                                                    {{ dur.name }}
                                                    </ion-select-option>
                                                </ion-select> -->
                      </div>
                    </div>
                  </div>

                  <!-- If checked, add 'border-active' class  -->
                  <div
                    class="form-check product-purchase__one-time bg--primary--50 border-radius-10 p-5 mb-5"
                    :class="isOnetimePurchase ? 'border-active' : ''"
                  >
                    <input
                      v-model="addToCartVars.type"
                      class="mr-3"
                      type="radio"
                      name="flexRadioDefault"
                      id="oneTime"
                      checked
                      value="1"
                    />
                    <label class="form-check-label inline-block" for="oneTime">
                      One-Time Purchase
                    </label>
                    <label class="form-check-label font-bold float-right" for="oneTime">
                      {{ moneyFormat( product.price_per_case * addToCartVars.quantity, 2, "₱") }}
                    </label>
                  </div>
                </div>
              </div>
              <div class="mt-5 sm:mt-6">
                <!-- <slot name="button"></slot> -->
                <ion-button
                  v-if="stock > 0"
                  @click="addToCartHandler"
                  :disabled="cannotContinue"
                  class="btn-primary"
                  shape="round"
                  size="large"
                  expand="full"
                >
                  Add To Cart
                </ion-button>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script type="text/javascript">
import { ref, onMounted, defineComponent, computed, watch, toRefs } from "vue";
import { useStore } from "vuex";
// import { useRouter } from 'vue-router';
import useAuth from "@/composables/useAuth";
import useCart from "@/composables/useCart";
import useFormat from "@/composables/useFormat";
import useLocalStorage from "@/composables/useLocalStorage";
import { Dialog, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { XIcon } from "@heroicons/vue/outline";

import {
  //IonSelect,
  //IonSelectOption,
  IonButton,
  modalController,
} from "@ionic/vue";
import Modal from "@/components/Modal";

export default defineComponent({
  name: "AddToCartModal",
  components: {
    //IonSelect,
    //IonSelectOption,
    IonButton,
    Dialog,
    TransitionChild,
    TransitionRoot,
    XIcon,
  },
  emits: ["close"],
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    itemId: {
      type: String,
      default: null,
    },
    stock:{
      type: Number,
      default:0
    },
  },
  setup(props, { emit }) {
    const open = ref(false);
    const itemId = computed(() => props.itemId);
    // let { itemId } = toRefs(props);
    let { isVisible } = toRefs(props);

    watch(isVisible, (newValue) => {
      // console.log(newValue)
      // console.log(oldValue)
      // console.log(props.itemId)
      open.value = newValue;
      fetch();
    });

    // watch(
    //     () => props.isVisible,
    //     (bool) => open.value = bool,
    //     { immediate: true }
    // );

    watch(
      () => open.value,
      (bool) => {
        if (!bool) emit("close");
      }
    );

    const store = useStore();
    // const router = useRouter();

    const { isAuthenticated } = useAuth();
    const { addToCart, cartItemType } = useCart();
    const { moneyFormat } = useFormat();
    const { localStorage } = useLocalStorage();

    const fetchProductUrl = computed(() => store.state.api.routes.product);

    const user = ref(null);
    const guestId = ref(null);

    const setUpGuestId = async () => {
      guestId.value = await localStorage.value.get("guestId");
    };

    const setUpUser = async () => {
      user.value = await localStorage.value.get("user");
    };

    // const productId = itemId.value;

    const product = ref({});
    const category = ref({});
    const ratings = ref([]);
    const relatedItems = ref([]);

    /* Product Subscription */
    const subscriptions = ref([]);
    const frequency = ref(null);
    const duration = ref(null);
    const selectedSubscription = ref([]);
    const subscriptionPrices = ref({
      three: [],
      six: [],
      twelve: [],
    });

    const addToCartVars = ref({
      productId: itemId.value,
      quantity: 1,
      type: 1,
      originalPrice: 0,
      discountedPrice: 0,
      notAvailableOption: null,
      replacementItemId: null,
      frequency: null,
      duration: null,
      sessionId: null,
      branchId: null,
      platform: 'web'
    });

    const isOnetimePurchase = computed(
      () => addToCartVars.value.type == cartItemType.value.ONE_TIME_PURCHASE
    );
    const isSubscriptionPurchase = computed(
      () => addToCartVars.value.type == cartItemType.value.SUBSCRIPTION
    );
    const cannotContinue = computed(() => {
      return (
        addToCartVars.value.type == 2 &&
        (!addToCartVars.value.frequency || !addToCartVars.value.duration)
      );
    });

    watch(
      () => frequency.value,
      (value) => {
        if (value) {
          addToCartVars.value.frequency = value;
        }
      }
    );

    watch(
      () => duration.value,
      (value) => {
        if (frequency.value) {
          switch (value) {
            case 3:
              selectedSubscription.value = subscriptionPrices.value.three;
              break;
            case 6:
              selectedSubscription.value = subscriptionPrices.value.six;
              break;
            case 12:
              selectedSubscription.value = subscriptionPrices.value.twelve;
              break;
          }
          addToCartVars.value.duration = value;
          addToCartVars.value.discountedPrice =
            selectedSubscription.value.price;
        }
      }
    );

    const productImages = ref([
      // '/assets/image/prd-img1.png',
      // '/assets/image/prd-img2.png',
      // '/assets/image/prd-img3.png',
    ]);

    const errorMessage = ref(null);

    /*
    |--------------------------------------------------------------------------
    | @Modals
    |--------------------------------------------------------------------------
    */

    const openErrorModal = async () => {
      const modal = await modalController.create({
        component: Modal,
        cssClass: "gen-modal",
        componentProps: {
          title: "Oops!",
          description: errorMessage.value,
          buttonText: "Close",
          imageLink: "/assets/icon/ic-status-warning.svg",
          closeModalButton: true,
          buttonClass: "btn-primary",
          imageWidth: "28",
          imageHeight: "28",
          full: "full",
        },
      });
      return modal.present();
    };

    function increment() {
      if (addToCartVars.value.quantity < product.value.stock - 1) {
        addToCartVars.value.quantity += 1;
      }
    }

    function decrement() {
      if (addToCartVars.value.quantity > 1) {
        addToCartVars.value.quantity -= 1;
      }
    }

    const fetch = (url = null) => {
      window.axios
        .post(url || fetchProductUrl.value, { productId: itemId.value, platform: 'web' })
        .then((result) => {
          product.value = result.data.product;
          addToCartVars.value.originalPrice = result.data.product.price_per_case;
          addToCartVars.value.discountedPrice = result.data.product.price_per_case;
          addToCartVars.value.productId = itemId.value;
          category.value = result.data.product.category;
          subscriptions.value = result.data.subscriptions;
          ratings.value = result.data.product.ratings;
          relatedItems.value = result.data.relatedProducts;
          productImages.value = result.data.product.images
            ? result.data.product.images.map((x) => x.file_path)
            : productImages.value;

          subscriptionPrices.value.three = result.data.subscriptions.prices[0];
          subscriptionPrices.value.six = result.data.subscriptions.prices[1];
          subscriptionPrices.value.twelve = result.data.subscriptions.prices[2];
          selectedSubscription.value = result.data.subscriptions.prices[0];
        })
        .catch(({ response }) => {
          if (response) {
            errorMessage.value = response.data.message;
            openErrorModal();
          }
        });
    };

    const addToCartHandler = () => {
      open.value = false;
      return addToCart(
        Object.assign(addToCartVars.value, {
          sessionId: guestId.value,
          branchId: store.state.address.branchId,
          originalPrice: product.value.price_per_case,
        })
      );
    };

    onMounted(() => {
      setUpGuestId();
      setUpUser();
    });

    return {
      productImages,
      increment,
      decrement,
      fetch,
      product,
      category,
      subscriptions,
      subscriptionPrices,
      frequency,
      duration,
      selectedSubscription,
      user,
      ratings,
      relatedItems,
      addToCartHandler,
      addToCartVars,
      isOnetimePurchase,
      isSubscriptionPurchase,
      setUpGuestId,
      guestId,
      cannotContinue,
      moneyFormat,
      isAuthenticated,
      open,
    };
  },
});
</script>
<style lang="scss">
  @media screen and (max-width: 768px) {
        .mobile-product-name {
            width: 100% !important;
        }

        .mobile-close {
          top: .5rem !important;
        }
    }
</style>
