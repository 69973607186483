import { ref } from "vue";
import { useStore } from "vuex";

import { modalController } from "@ionic/vue";
import Modal from "@/components/Modal";

export default function useWishlist() {
    
    const store = useStore();

    const title = ref(null);
    const message = ref(null);
    const image = ref(null);

    const loader = ref(false);

    const openModal = async () => {
        const modal = await modalController.create({
            component: Modal,
            cssClass: 'gen-modal',
            componentProps: {
                title: title.value,
                description: message.value,
                imageLink: image.value,
                closeModalButton: true,
                imageWidth: '64',
                imageHeight: '64',
            }
        });
        return modal.present();
    };
    
    const fetchWishlist = () => {
        if(!store.state.user.token) return;
        window.axios.post(store.state.api.routes.wishlistFetch)
            .then(({ data }) => {
                store.commit('wishlist/set', data.wishlists);
            });
    };

    const addToWishlist = (productId) => {
        if(!store.state.user.token) return Promise.reject();
        loader.value = true;
        return window.axios.post(store.state.api.routes.wishlistStore, { productId })
        .then(({ data }) => {
            store.commit('wishlist/set', data.wishlists);
            title.value = 'Success!';
            message.value = data.message;
            image.value = '/assets/icon/ic-status-check.svg';
            openModal();
            fetchWishlist();
            loader.value = false;
        });
    };
    
    const removeToWishlist = (productId) => {
        if(!store.state.user.token) return;
        loader.value = true;
        window.axios.post(store.state.api.routes.wishlistRemove, { productId })
        .then(({ data }) => {
            store.commit('wishlist/set', data.wishlists);
            title.value = 'Success!';
            message.value = data.message;
            image.value = '/assets/icon/ic-status-check.svg';
            openModal();
            fetchWishlist();
            loader.value = false;
        });
    };

    return {
        fetchWishlist,
        addToWishlist,
        removeToWishlist,
        loader
    };
}