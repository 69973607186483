export const wishlist = {
	namespaced: true,
	state: {
		items: [],  
	},
	mutations: {
		set(state, items) {
			state.items = items || [];
		},
	},
};
