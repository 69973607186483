<template>
  <ion-page>
    <top-navigation />

    <ion-content
      :fullscreen="true"
      class="ion-padding"
    >
      <div class="w-full md:w-11/12 md:mx-auto px-2 md:px-0">
        <section class="text-center md:text-left">
          <h1
            class="frm-title text--secondary font-bold mb-0 animate-up"
          >
            {{ getCmsContent('header_pageTitle') || 'Shop' }}
          </h1>
          <span class="font--h6 text--gray--400">Home</span>
          <span class="font--h6 text--secondary mx-1">></span>
          <span class="font--h6 text--secondary font-bold">Shop</span>
        </section>
        <section class="product-catalog__hldr mb-12">
          <div class="filter-type__hldr mb-8 ml-auto">
            <div class="flex lg:flex-nowrap flex-wrap justify-end lg:pr-3 pr-0 lg:gap-0 gap-4 lg:pt-6 pt-0">
              <ion-select
                interface="popover"
                class="lg:w-auto w-full frm-select lg:mr-4 mr-0"
                placeholder="Filter by: --"
                v-model="filterBy"
              >
                <ion-select-option 
                  v-for="(filter,index) in filterOptions"
                  :key="`product-filter-option-${index}`"
                  :value="filter.id"
                >
                  {{ filter.name }}
                </ion-select-option>
              </ion-select>
              <ion-select
                interface="popover"
                class="lg:w-auto w-full frm-select"
                placeholder="Sort by: --"
                v-model="sortBy"
              >
                <ion-select-option 
                  v-for="(sort,index) in sortOptions"
                  :key="`product-sort-option-${index}`"
                  :value="sort.id"
                >
                  {{ sort.name }}
                </ion-select-option>
              </ion-select>
            </div>
          </div>
          <div class="filter-type__hldr--mobile block md:hidden px-2 mb-4 mt-8">
            <div class="flex items-center">
              <img
                src="/assets/icon/ic-menu-cat.svg"
                alt="ic-menu"
                class="h-[12px] w-[21px] object-contain mr-2"
                @click.stop="toggleCatMenu"
              >
              <p>Choose Product Type</p>  
            </div> 
          </div>
          <div
            class="flex animate-up"
          >
            <div class="filter-cat__hldr bg--primary--50 border-radius-10 md:w-1/4 mr-3 px-4 md:px-9 py-4 md:py-8">
              <img
                src="/assets/icon/ic-close-gray.svg"
                alt="ic-close"
                class="h-[13px] w-[13px] object-contain ml-auto mb-5 block md:hidden"
                @click.stop="toggleCatMenu"
              > 
              <p class="font--21 text--secondary font-bold mb-3">
                Categories
              </p>
              <ul class="product-cat__list">
                <li class="product-cat__item mb-2">
                  <input
                    class="rounded-sm mr-3 cursor-pointer"
                    type="checkbox"
                    id="all"
                    value="all"
                    @change="checkAll()"
                  >
                  <label
                    class="form-check-label inline-block text-gray-800"
                    for="all"
                  >
                    All Types
                  </label>
                </li> 
                <template
                  v-for="(cat, index) in categories"
                  :key="`product-page-category-input-${index}`"
                >
                  <li class="product-cat__item mb-2">
                    <input
                      class="rounded-sm mr-3 cursor-pointer"
                      type="checkbox"
                      :id="`cat-${cat.id}`"
                      @change="addCheck(cat.id)"
                    >
                    <label
                      class="form-check-label inline-block text-gray-800"
                      :for="`cat-${cat.name}`"
                    >
                      {{ cat.name }}
                    </label>
                  </li> 
                </template>
              </ul>
              <ion-button
                shape="round"
                size="small"
                fill="outline"
                class="font--main m-0 capitalized mt-6 inline-block md:hidden"
              >
                Apply
              </ion-button>
            </div>
            <div class="product-list__hldr grid 2xl:grid-cols-4 xl:grid-cols-3 grid-cols-2 w-full md:w-3/4 gap-6">
              <template
                v-for="(item, index) in filteredProducts"
                :key="`product-page-product-card-${index}`"
              >
                <ProductCard
                  :item="item"
                  class="cursor-pointer mb-8 md:mb-10 w-full animate-up"
                />
              </template>
              <p 
                v-if="!filteredProducts.length > 0"
                class="col-span-full text-center pt-8"
              >
                No other products available.
              </p>
            </div>
          </div>
        </section>
      </div>
      <bottom-navigation />
      <Loader v-show="isLoading" />
    </ion-content>
  </ion-page>
</template>

<script type="text/javascript">
import { ref, defineComponent, watch, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from "vuex";
import { IonPage, IonContent, IonSelect, IonSelectOption, IonButton, onIonViewWillEnter } from '@ionic/vue';
import TopNavigation from '@/partials/TopNavigation.vue';
import BottomNavigation from '@/partials/BottomNavigation.vue';
import ProductCard from './components/ProductCard.vue';
import useCms from "@/composables/useCms";
import Loader from '@/components/PageLoader.vue';

export default defineComponent({
  name: 'ProductPage',
  components: {
    IonPage,
    IonButton,
    IonContent,
    IonSelect,
    IonSelectOption,
    ProductCard,
    TopNavigation,
    BottomNavigation,
    Loader
  },
  setup() {

    const router = useRouter();
    const store = useStore();
    const { contentSlug, getCmsContent } = useCms();

    /*
    |--------------------------------------------------------------------------
    | @Data
    |--------------------------------------------------------------------------
    */

    const isMobile = ref(false);
    const products = ref([]);
    const categories = ref([]);
    const isLoading = ref(false);

    /* Filters */
    const filterOptions = ref([
      { id:'none', name:'No Filter' },
      { id:'is_best_seller', name:'Best Sellers' },
      { id:'for_subscription', name:'For Subscription' }
    ]);

    const sortOptions = ref([
      { id:'popularity', name:'Popularity' },
      { id:'lowToHigh', name:'Price: Low to High' },
      { id:'highToLow', name:'Price: High to Low' },
    ]);

    const filterBy = ref(filterOptions.value[0].id);
    const sortBy = ref(sortOptions.value[0].id);
    const categoryIds = ref([]);

    /*
    |--------------------------------------------------------------------------
    | @Computed
    |--------------------------------------------------------------------------
    */

    const fetchProductUrl = computed(() => store.state.api.routes.products);

    const filteredProducts = computed(() => {
      const items = ref(products.value);
      const search = router.currentRoute.value.query.search;
      if(search) {
        items.value = items.value.filter(x => x.name.toUpperCase().indexOf(search.toUpperCase()) != -1);
      }
      if(filterBy.value && filterBy.value != 'none') {
        items.value = items.value.filter(x => x[filterBy.value]);
      }
      switch(sortBy.value) {
        case 'popularity': 
          items.value = items.value.sort((a,b) => a.ratingAverage < b.ratingAverage ? 1 : -1);
          break;
        case 'lowToHigh': 
          items.value = items.value.sort((a,b) => parseFloat(a.price_per_case) > parseFloat(b.price_per_case) ? 1 : -1);
          break;
        case 'highToLow': 
          items.value = items.value.sort((a,b) => parseFloat(a.price_per_case) < parseFloat(b.price_per_case) ? 1 : -1);
          break;
      }
      if(categoryIds.value.length) {
        items.value = items.value.filter(x => categoryIds.value.includes(x.category_id));
      }
      return items.value || [];
    });

    /*
    |--------------------------------------------------------------------------
    | @Methods
    |--------------------------------------------------------------------------
    */

    const selectedProduct = (id) => {
      router.push('/shop/' + id);
    }

    const toggleCatMenu = () => {
      var catMenu = document.querySelector('.filter-cat__hldr');
      catMenu.classList.toggle('active');
    }

    const fetch = (url = null) => {
      isLoading.value = true;
      window.axios.post(url || fetchProductUrl.value, { branchId: store.state.address.branchId, platform: 'web' })
        .then((result) => {
          products.value = result.data.products;
          categories.value = result.data.categories;
          isLoading.value = false;
        }).catch(() => {
            isLoading.value = false;
        })
    }

    const addCheck = (categoryId) => {
      const index = categoryIds.value.indexOf(categoryId);
      if(index === -1) categoryIds.value.push(categoryId);
      else categoryIds.value.splice(index, 1);
    }

    const checkAll = () => {
      if (document.getElementById('all').checked) {
        for (let i = 0; i < categories.value.length; i++) {
          categoryIds.value.push(categories.value[i].id);
          document.getElementById('cat-' + categories.value[i].id).checked = true;
        }
      } else {
        categoryIds.value.splice(0);
        for (let i = 0; i < categories.value.length; i++) {
          document.getElementById('cat-' + categories.value[i].id).checked = false;
        }
      }
    }

    /*
    |--------------------------------------------------------------------------
    | @Watch
    |--------------------------------------------------------------------------
    */

    watch(
      () => fetchProductUrl.value, 
      (url) => {
        if(typeof url === 'string') fetch(url);
      }, 
      { immediate: true }
    );

    watch(
      () => store.state.address.branchId,
      (id) => {
        if(id) fetch();
      }
    );

    onMounted(() => {
        isMobile.value = window.innerWidth < 1024;
        contentSlug.value = 'shop';
        document.getElementById('all').checked = true;
    });

    onIonViewWillEnter(() => {
      document.getElementById('all').checked = true;
    })

    return {
      isMobile,
      filteredProducts,
      categories,
      filterOptions,
      sortOptions,
      toggleCatMenu,
      selectedProduct,
      addCheck,
      checkAll,
      filterBy,
      sortBy,
      fetchProductUrl,
      getCmsContent,
      isLoading
    }
  }
})
</script>
<style lang="scss">
    .product-catalog__hldr {

        .filter-cat__hldr {
            height: fit-content;
        }

        @media (max-width: 768px) {
          .filter-cat__hldr {
            position: fixed;
            right: 0;
            top: 132px;
            background-color: #FFFFFF;
            border-left: 1px solid #C8CFDA;
            border-radius: 0;
            height: calc(100vh - 132px);
            width: 65vw;
            z-index: 10;
            transform: translateX(100vw);
            transition: all .5s ease;

            &.active {
              transform: translateX(0);
            }
          }
        }
    }
</style>
