<template>
  <div class="lg:col-span-2 col-span-full lg:p-16 p-6 py-10">
    <div class="h-[400px]">
      <div class="mb-3">
          <p class="text-base">
            Please find your exact location on the map below by clicking and dragging the location pin.
          </p>
      </div>
      
        <!-- GOOGLE MAPS -->
        <!-- <ion-button
          shape="round"
          class="btn-link map text--primary h-6"
          @click="getCoordinates"
        >
          <span class="underline font-normal text-sm">Use current location</span>
        </ion-button> -->
        <span class="font-normal text-red-500">{{ errorMessage }}</span>
        <location-map v-model="form" />

        <!-- copied only from step two -->
      <div class="flex items-center justify-between">
        <div>
          <ion-button
            size="large"
            class="btn-primary mr-4"
            :disabled="cannotContinue"
            @click="saveLocation"
          >
            Submit
          </ion-button>
          <ion-button
            size="large"
            class="btn-primary mr-4"
            @click="saveLocation"
          >
            Skip
          </ion-button>
          <ion-button
            shape="round"
            class="btn-link text--primary"
            @click="previousStep"
          >
            <span class="underline font-normal">Go Back</span>
          </ion-button>
        </div>
        <div>
          <p class="text--secondary text-sm">
            <span class="font-bold">03</span>/03
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { computed, onMounted, ref, watch } from 'vue';
import { useStore } from "vuex";
import useLocalStorage from '@/composables/useLocalStorage';

import { IonButton } from '@ionic/vue';
import LocationMap from '@/components/LocationMap.vue';

export default {
  name: 'StepThree',
  components: {
    IonButton,
    LocationMap
  },
  props:{
    currentStep:{
      type:Number,
      default:1
    }
  },
  emits:[ 'previous', 'close' ],
  setup(props, { emit }) {

    /*
    |--------------------------------------------------------------------------
    | @Data
    |--------------------------------------------------------------------------
    */

    const store = useStore();
    const { localStorage } = useLocalStorage();

    // const form = ref({ lat: 14.599223, lng: 120.98136 });
    const form = ref({ lat: null, lng: null });
    const payload = ref({
      street: null,
      unitNo: null,
      zipCode: null
    });
    const errorMessage = ref(null);
    const isLoading = ref(false);

    const cannotContinue = computed(() => !form.value.lat || !form.value.lng);

    /*
    |--------------------------------------------------------------------------
    | @Methods
    |--------------------------------------------------------------------------
    */
    
    const previousStep = () => { emit('previous'); };
    
    const getCoordinates = async () => {
      navigator.geolocation.getCurrentPosition(
        ({ coords }) => {
          form.value.lat = coords.latitude;
          form.value.lng = coords.longitude;
        }
      );
    };

    const saveLocation = () => {
      const code = store.state.address.form.barangay;
      window.axios.post(store.state.api.routes.fetchBranch, { code })
        .then(({ data }) => {
          errorMessage.value = null;
          store.commit('address/setBranch', data.branch_id);
          localStorage.value.set('branch', data.branch_id);
          localStorage.value.set('location', store.state.address.form);
          setBranchMethods(data.branch_methods);
          emit('close');
        })
        .catch(({ response }) => {
          errorMessage.value = response.data.message;
        });
    };

    const setBranchMethods = async (data) => {
      localStorage.value.set('branchMethods', data);
      store.commit('settings/setDeliveryMethods', await localStorage.value.get('branchMethods'));
    }

    /*
    |--------------------------------------------------------------------------
    | @Watch
    |--------------------------------------------------------------------------
    */

    watch(
      () => form.value,
      (coords) => {
        console.log(coords)
        if(Object.keys(coords).length !== 0) {
          store.commit('address/setCoordinates', { latitude: coords.lat, longitude: coords.lng });
        }
      },
      { deep: true }
    );

    watch(
      () => payload.value,
      (value) => {
        if(value) {
          payload.value = store.state.address.form;
        }
      },
      { deep: true }
    );

    /*
    |--------------------------------------------------------------------------
    | @Return
    |--------------------------------------------------------------------------
    */

    onMounted(() => {
      const { latitude, longitude } = store.state.address.form;
      if(latitude && longitude) {
        form.value.lat = latitude;
        form.value.lng = longitude;
      }
    });

    const showMap = ref(false);

    return {
      form,
      payload,
      errorMessage,
      isLoading,
      previousStep,
      saveLocation,
      cannotContinue,
      getCoordinates,
      showMap,
    }
  }
}
</script>