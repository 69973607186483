<!-- eslint-disable -->
<template>
    <ion-page>
        <top-navigation />
        <ion-content :fullscreen="true" class="ion-padding">
            <account-side-navigation>
                <slot>
                    <div class="w-full space-y-4">
                        <h4 class="text--gray--900 font-bold">Edit Order</h4>
                    </div>

                    <div class="w-full pb-6 border-b">
                        <table class="table-fixed">
                            <tbody>
                                <tr>
                                    <td class="pr-4 py-2">
                                        <p class="text--gray--800 font-bold">
                                            Order Number:
                                        </p>
                                    </td>
                                    <td class="pr-4 py-2">
                                        <p class="text--gray-800 font-normal">
                                            {{ invoice.invoice_no }}
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="pr-4 py-2">
                                        <p class="text--gray--800 font-bold">
                                            Ordered On:
                                        </p>
                                    </td>
                                    <td class="pr-4 py-2">
                                        <p class="text--gray-800 font-normal">
                                            {{ dateFormat(invoice.created_at) }}
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="pr-4 py-2">
                                        <p class="text--gray--800 font-bold">
                                            Status
                                        </p>
                                    </td>
                                    <td class="pr-4 py-2">
                                        <!-- <p :class="invoice.formatted_order_status == 'Refunded' ? 'text--red--400' : 'text--gray-800 font-normal'">
                      {{ invoice.formatted_order_status }}
                    </p> -->
                                        <p class="text--gray-800 font-normal">
                                            {{ invoice.formatted_order_status }}
                                        </p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div
                        class="flex md:flex-row flex-col justify-between w-full py-6"
                    >
                        <div class="flex flex-col gap-2 w-1/2">
                            <p class="text--gray--800 font-bold">
                                Contact Information
                            </p>
                            <p class="text--gray-800 font-normal">
                                {{ invoice.full_name }}
                            </p>
                            <p class="text--gray-800 font-normal">
                                {{ invoice.email }}
                            </p>
                            <p class="text--gray-800 font-normal">
                                +63{{ invoice.mobile_number }}
                            </p>
                        </div>
                        <div class="flex flex-col gap-2 w-1/2 md:mt-0 mt-6">
                            <p class="text--gray--800 font-bold">
                                Delivery Method
                            </p>
                            <p class="text--gray-800 font-normal">
                                {{ invoice.delivery_label }}
                            </p>
                        </div>
                    </div>
                    <hr class="my-6" />

                    <div>
                        <DeliveryAddress
                            title="Edit Address"
                            classTitle="text-xl"
                            :branch-id="branchId"
                            @selectAddress="changeAddress"
                            :addressId="addressId"
                        />
                        <hr class="mt-10 mb-8" />
                    </div>

                    <div>
                        <SubscriptionItem
                            :subscription="subscription"
                            :items="items"
                        />
                        <hr class="mt-14 mb-8" />
                    </div>

                    <div>
                        <PaymentMethod
                            title="Edit Payment Method"
                            classTitle="text-xl"
                            :paymentMethod="paymentMethod"
                            @changePayment="changePayment"
                        />
                        <hr class="mt-14 mb-8" />
                    </div>

                    <div>
                        <table class="w-full table-auto">
                            <tbody>
                                <tr>
                                    <td class="align-top pb-2">
                                        <p
                                            class="text--gray--800 font-bold text-base"
                                        >
                                            Points Earned
                                        </p>
                                    </td>
                                    <td class="align-top pb-2 text-right">
                                        <p
                                            class="text--gray--800 font-norma text-sm"
                                        >
                                            {{ invoice.points_earned }}
                                            Droplet/s
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="align-top pb-2">
                                        <p
                                            class="text--gray--800 font-bold text-base"
                                        >
                                            Discount/s
                                        </p>
                                    </td>
                                    <td class="align-top pb-2 text-right">
                                        <p
                                            class="text--gray--800 font-norma text-sm"
                                        >
                                            {{
                                                moneyFormat(
                                                    invoice.shipping_discount,
                                                    2,
                                                    "₱"
                                                )
                                            }}
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="align-top pb-2">
                                        <p
                                            class="text--gray--800 font-bold text-base"
                                        >
                                            Points Redeemed
                                        </p>
                                    </td>
                                    <td class="align-top pb-2 text-right">
                                        <p
                                            class="text--gray--800 font-norma text-sm"
                                        >
                                            {{ invoice.points_redeemed }}
                                            Droplet/s ~
                                            {{
                                                moneyFormat(
                                                    invoice.points_redeemed,
                                                    2,
                                                    "₱"
                                                )
                                            }}
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="align-top pb-2">
                                        <p
                                            class="text--gray--800 font-bold text-base"
                                        >
                                            Subtotal
                                        </p>
                                    </td>
                                    <td class="align-top pb-2 text-right">
                                        <p
                                            class="text--gray--800 font-norma text-sm"
                                        >
                                            {{
                                                moneyFormat(
                                                    invoice.subtotal,
                                                    2,
                                                    "₱"
                                                )
                                            }}
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="align-top pb-2">
                                        <p
                                            class="text--gray--800 font-bold text-base"
                                        >
                                            Delivery Fee
                                        </p>
                                    </td>
                                    <td class="align-top pb-2 text-right">
                                        <p
                                            class="text--gray--800 font-norma text-sm"
                                        >
                                            {{
                                                moneyFormat(
                                                    invoice.delivery_fee,
                                                    2,
                                                    "₱"
                                                )
                                            }}
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="align-top pb-2">
                                        <p
                                            class="text--gray--800 font-bold text-base"
                                        >
                                            Other Fee/s
                                        </p>
                                    </td>
                                    <td class="align-top pb-2 text-right">
                                        <p
                                            class="text--gray--800 font-norma text-sm"
                                        >
                                            {{
                                                moneyFormat(
                                                    invoice.other_fees,
                                                    2,
                                                    "₱"
                                                )
                                            }}
                                        </p>
                                    </td>
                                </tr>
                                <tr class="border-t">
                                    <td class="align-top pt-4 pb-2.5">
                                        <p
                                            class="text--gray--800 font-bold text-xl uppercase"
                                        >
                                            Total
                                        </p>
                                    </td>
                                    <td
                                        class="align-top pt-4 pb-2.5 text-right"
                                    >
                                        <p
                                            class="text--gray--800 font-normal text-xl"
                                        >
                                            {{
                                                moneyFormat(
                                                    invoice.total,
                                                    2,
                                                    "₱"
                                                )
                                            }}
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        colspan="2"
                                        class="text--gray--300 text-sm text-right"
                                    >
                                        <b>Note:</b> Updated information and
                                        fees will apply in your next delivery.
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="mt-10 flex justify-between items-center">
                        <a
                            href="/subscription"
                            class="font--h6 text--gray--300 cstm-link"
                            >&lt; Return to Subscription</a
                        >
                        <ion-button
                            shape="round"
                            size="large"
                            class="flex items-center font--main m-0 capitalized"
                            @click="applyChanges"
                        >
                            Apply Changes
                        </ion-button>
                    </div>
                </slot>
            </account-side-navigation>
            <bottom-navigation />
        </ion-content>
    </ion-page>
</template>

<script type="text/javascript">
// import Datepicker from 'vue3-date-time-picker';
// import 'vue3-date-time-picker/dist/main.css';
import "@vuepic/vue-datepicker/dist/main.css";

import { computed, defineComponent, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { Duration, DateTime } from "luxon";
import useFormat from "@/composables/useFormat";

import { IonPage, IonContent, IonButton } from "@ionic/vue";
import BottomNavigation from "@/partials/BottomNavigation.vue";
import TopNavigation from "@/partials/TopNavigation.vue";
import AccountSideNavigation from "@/partials/AccountSideNavigation.vue";

import DeliveryAddress from "./components/DeliveryAddress.vue";
import PaymentMethod from "./components/PaymentMethod.vue";
import SubscriptionItem from "./components/SubscriptionItem.vue";

export default defineComponent({
    name: "SubscriptionEditPage",
    components: {
        IonContent,
        IonPage,
        IonButton,
        BottomNavigation,
        TopNavigation,
        AccountSideNavigation,
        DeliveryAddress,
        PaymentMethod,
        SubscriptionItem,
    },
    setup() {
        const store = useStore();
        const router = useRouter();
        const { moneyFormat, dateFormat } = useFormat();

        const subscriptionId = router.currentRoute.value.query.id;
        const subscription = ref({ schedules: [] });
        const items = ref([]);
        const date = ref(null);
        const paymentMethod = ref(null);
        const addressId = ref(null);
        const selectedAddress = ref(null);
        const selectedPaymentMethod = ref(null);

        const fetchSubscriptionUrl = computed(
            () => store.state.api.routes.subscriptionShow
        );
        // const submitRouteUrl = computed(() => store.state.api.routes.subscriptionUpdate);

        const schedule = computed(() =>
            subscription.value.schedules.length
                ? subscription.value.schedules[0]
                : {}
        );
        const percentage = computed(
            () =>
                (subscription.value.processed_orders /
                    subscription.value.total_orders) *
                100
        );
        const invoices = computed(() => subscription.value.invoices || []);
        const invoice = computed(() => invoices.value[0] || []);

        const productImage = computed(() =>
            items.value.length
                ? items.value[0].product.initial_image
                : "/assets/image/prd-img1.png"
        );
        const productNames = computed(() => {
            let name = "";
            if (items.value.length) {
                const names = items.value.map(
                    (x) => `${x.product.name} x ${x.quantity}`
                );
                name = names.join(", ");
            }
            return name;
        });

        const minDate = computed(() => {
            const luxon = DateTime.now().plus(Duration.fromObject({ days: 3 }));
            return new Date(luxon);
        });

        const fetch = (url) => {
            window.axios
                .post(url || fetchSubscriptionUrl.value, { subscriptionId })
                .then(({ data }) => {
                    subscription.value = data.subscription;
                    items.value = data.items;
                    paymentMethod.value =
                        data.subscription.shipping_address.payment_method;
                    selectedPaymentMethod.value =
                        data.subscription.shipping_address.payment_method;
                    addressId.value =
                        data.subscription.shipping_address.address_id;
                });
        };

        watch(
            () => fetchSubscriptionUrl.value,
            (url) => {
                if (typeof url === "string") fetch(url);
            },
            { immediate: true }
        );

        const changeAddress = (val) => {
            selectedAddress.value = val;
        };

        const changePayment = (val) => {
            selectedPaymentMethod.value = val;
        };

        const applyChanges = () => {
            window.axios
                .post(process.env.VUE_APP_API_URL + "/subscriptions/update", {
                    subscriptionId: subscriptionId,
                    address_id: selectedAddress.value,
                    payment_method: selectedPaymentMethod.value,
                    products: items.value,
                })
                .then(() => {
                    window.location.reload();
                });
        };

        return {
            date,
            subscription,
            items,
            productNames,
            productImage,
            minDate,
            schedule,
            percentage,
            invoices,
            moneyFormat,
            dateFormat,
            invoice,
            paymentMethod,
            applyChanges,
            changeAddress,
            changePayment,
            selectedAddress,
            selectedPaymentMethod,
            addressId,
        };
    },
});
</script>
