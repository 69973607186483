<template>
  <ion-page>
    <top-navigation />
    <ion-content
      :fullscreen="true"
      class="ion-padding"
    >
      <account-side-navigation>
        <slot>
          <div class="mb-4 space-y-4">
            <h4 class="text--gray--900 font-bold">
              Account Details
            </h4>
            <p class="text--secondary text-xl font-bold">
              Profile
            </p>
          </div>
          <div class="w-full">
            <profile-form />
          </div>
        </slot>
      </account-side-navigation>
      <bottom-navigation />
    </ion-content>
  </ion-page>
</template>
<script type="text/javascript">
import ProfileForm from '../components/ProfileForm.vue';
import { IonPage, IonContent } from '@ionic/vue';
import BottomNavigation from '@/partials/BottomNavigation.vue';
import TopNavigation from '@/partials/TopNavigation.vue';
import AccountSideNavigation from '@/partials/AccountSideNavigation.vue';

export default {
    name: 'ProfileIndex',
    components: {
        ProfileForm,
        IonContent, 
        IonPage, 
        BottomNavigation, 
        TopNavigation, 
        AccountSideNavigation,
    }
}
</script>