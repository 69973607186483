<template>
    <div>
        <div 
        class="w-20 cursor-pointer" 
        @click="openModal">
            <div class="relative w-full pt-[100%]">
                <img 
                class="absolute top-0 left-0 object-cover rounded"
                :src="item.file_path" 
                alt="proof-of-delivery">
            </div>
            <!-- <p class="text-xs text--secondary mt-1">PoD.png</p> -->
        </div>
    
        <TransitionRoot as="template" :show="isOpen">
            <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="closeModal">
                <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <button type="button" @click="closeModal" class="absolute left-[10%] top-[15%] border-0 ring-0 outline-none z-10">
                        <img src="/assets/icon/ic-close-white.svg">
                    </button>
                    <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                        <DialogOverlay class="fixed inset-0 bg-black bg-opacity-20 transition-opacity" />
                    </TransitionChild>

                    <!-- This element is to trick the browser into centering the modal contents. -->
                    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                    <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <div class="relative inline-block bg-white p-4 overflow-auto shadow-xl transform transition-all align-middle my-8 lg:w-[420px] w-11/12 h-full scrollbar-style">
                            <div>
                                <img 
                                :src="item.file_path" 
                                alt="proof-of-delivery"
                                class="w-full">
                            </div>
                        </div>
                    </TransitionChild>
                </div>
            </Dialog>
        </TransitionRoot>
    </div>
</template>
<script>

import { defineComponent, ref } from "@vue/runtime-core";
import { Dialog, DialogOverlay, TransitionChild, TransitionRoot } from '@headlessui/vue'

export default defineComponent({
    name:'ProofOfDeliveryModal',
    components:{
        Dialog,
        DialogOverlay,
        TransitionChild,
        TransitionRoot,
    },
    props:{
        item:{
            type:Object,
            default:() => ({})
        },
    },
    setup() {


        const isOpen = ref(false)

		function closeModal() {
			isOpen.value = false
		}
		function openModal() {
			isOpen.value = true
		}
        
        return {
            isOpen,
            closeModal,
            openModal
        }
    },
})
</script>