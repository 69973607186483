<template>
  <div>
    <p class="flex flex-wrap items-start mb-4">
      <img
        src="/assets/icon/ic-contact-location.svg"
        alt="location-icon"
        width="20"
        height="20"
        class="object-contain block mr-3"
      >
      <!-- href="https://www.google.com/maps/place/Nature+Spring+Mineral+Water,+Mandaue+City,+Cebu/@10.32817,123.9503292,17z/data=!3m1!4b1!4m5!3m4!1s0x33a999b55f66e5af:0xddd5b85177acd66f!8m2!3d10.3279571!4d123.9525289" -->
      <a
        :href="addressLink"
        target="_blank"
        rel="noopener noreferrer"
      ><p v-html="address"></p></a>
    </p>
    <p class="flex flex-wrap items-start mb-4">
      <img
        src="/assets/icon/ic-contact-mobile.svg"
        alt="phone-icon"
        width="20"
        height="20"
        class="object-contain block mr-3"
      >
      <a
        :href="phoneNumerLink"
        target="_blank"
        rel="noopener noreferrer"
      ><p v-html="phoneNumber"></p></a>
    </p>
    <p class="flex flex-wrap items-start">
      <img
        src="/assets/icon/ic-contact-email.svg"
        alt="email-icon"
        width="20"
        height="20"
        class="object-contain block mr-3"
      >
      <a
        :href="emailLink"
        target="_blank"
        rel="noopener noreferrer"
      ><p v-html="email"></p></a>
    </p>
  </div>
</template>

<script type="text/javascript">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'ContactDetails',
    props: {
      address: {
        type: String,
        default: null
      },
      addressLink: {
        type: String,
        default: null
      },
      phoneNumber: {
        type: String,
        default: null
      },
      phoneNumerLink: {
        type: String,
        default: null
      },
      email: {
        type: String,
        default: null
      },
      emailLink: {
        type: String,
        default: null
      },
    }
});
</script>