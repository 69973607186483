<template>  
    <div class="lg:col-span-1 col-span-full flex flex-col items-center justify-center">
        <div class="w-[147px] h-[147px] rounded-full bg-[#02ADF0]/[.1] flex items-center justify-center">
            <img
            :src="imgSrc"
            class="w-[75px] h-[75px]"
            >
        </div>
        <h4 class="text--secondary text-2xl text-center font-bold mt-7 mb-2">
            {{ title }}
        </h4>
        <p class="text-center text-base">
            {{ description }}
        </p>
    </div>
</template>
<script type="text/javascript">
export default {
    name: 'FeatureCard',
    props: {
        title: {
            type: String,
            default: null
        },
        imgSrc: {
            type: String,
            default: null
        },
        description: {
            type: String,
            default: null
        }
    }
}
</script>