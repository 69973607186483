<template>
  <ion-page>
    <top-navigation />

    <ion-content
      :fullscreen="true"
      class="ion-padding"
    >
      <div class="w-11/12 mx-auto" :key="pageKey">
        <section class="spacer">
          <h1 class="frm-title text--secondary font-bold mb-0">
            {{ getCmsContent('header_pageTitle') || 'My Cart' }}
          </h1>
          <span class="font--h6 text--gray--400 cursor-pointer" @click="() => router.push('/')">Home</span>
          <span class="font--h6 text--secondary mx-1">></span>
          <span class="font--h6 text--gray--400 cursor-pointer" @click="() => router.push('/shop')">Nature’s Spring Puri...</span>
          <span class="font--h6 text--secondary mx-1">></span>
          <span class="font--h6 text--secondary font-bold">My Cart</span>
        </section>
        <section class="my-cart__hldr flex flex-wrap mt-8 mb-12">
          <div class="w-full md:w-2/3">
            <!-- <div class="promotional-tag bg--primary--50 border-radius-14 p-6 w-full flex items-center mb-4 md:mb-8">
              <img
                width="25"
                height="25"
                src="/assets/icon/ic-info.svg"
                alt="icon"
              >
              <h5 class="text--gray--800 ml-2">
                You’re <span class="font-bold text--secondary">₱100.00</span> away from getting FREE SHIPPING on your order! (Minimum fee: ₱500.00)
              </h5>
            </div> -->
            <div class="w-full flex justify-between items-center">
              <h5 class="text--gray--800 font-bold">
                Number of items : {{ cartItems.length }}
              </h5>
              <ion-button
                slot="end"
                shape="round"
                class="font--main btn-link text--gray--400 my-0 capitalized font-normal text-sm underline"
                @click="removeAllItems"
              >
                Remove All
              </ion-button>
            </div>
            <hr class="mt-4 mb-6 md:my-6">
            <OneTimePurchase 
              :items="oneTimePuchaseItems"
              :is-mobile="isMobile"
              @choose-replacement="openReplacementModal"
              @refresh="fetchCart"
            />
            <hr class="my-6">
            <Subscriptions 
              :items="subscriptionItems"
              :is-mobile="isMobile"
              @choose-replacement="openReplacementModal"
              @refresh="fetchCart"
            />
            <hr class="my-6">
          </div>
          <div class="w-full md:w-1/3 pl-0 md:pl-8 cart-sumnary__hldr">
            <CartSummary 
              :cart="cart"
            />
          </div>
        </section>
        <ReplacementModal
          :is-open="showReplacementModal"
          @close="closeReplacementModal"
        />
      </div>
      <bottom-navigation />
    </ion-content>
    <Loader v-show="cartIsLoading" />
  </ion-page>
</template>

<script type="text/javascript">

import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import useCart from '@/composables/useCart.js';
import useCheckout from '@/composables/useCheckout.js';
import useCms from "@/composables/useCms";
import { useRouter } from 'vue-router';

import {
  IonPage,
  IonContent,
  IonButton
} from '@ionic/vue';
import Loader from '@/components/PageLoader.vue';
import TopNavigation from '@/partials/TopNavigation.vue';
import BottomNavigation from '@/partials/BottomNavigation.vue';
import ReplacementModal from '@/views/checkout/components/OrderDetails/ReplacementModal.vue';
import CartSummary from './components/CartSummary.vue';
import OneTimePurchase from './components/OneTimePurchase.vue';
import Subscriptions from './components/Subscriptions.vue';
import useLocalStorage from "@/composables/useLocalStorage";

export default defineComponent({
  name: 'MyCartPage',
  components: {
    IonPage,
    IonContent,
    IonButton,
    TopNavigation,
    BottomNavigation,
    CartSummary,
    OneTimePurchase,
    Subscriptions,
    ReplacementModal,
    Loader
  },
  setup() {
    
    const store = useStore();
    const { cartIsLoading, fetchCart, removeAllItems } = useCart();
    const { updateCheckout } = useCheckout();
    const { localStorage } = useLocalStorage();

    const router = useRouter();

    const { contentSlug, getCmsContent } = useCms();

    const isMobile = ref(false);
    const showReplacementModal = ref(false);
    const pageKey = ref(null);

    const cart = computed(() => store.state.cart.data);
    const cartItems = computed(() => store.state.cart.items);
    const oneTimePuchaseItems = computed(() => cartItems.value.filter(x => x.type == 1));
    const subscriptionItems = computed(() => cartItems.value.filter(x => x.type == 2));

    // const sumReplacementItems = computed(() => cartItems.value.map((items) => items.replacement ? items.quantity * items.replacement.price_per_case : 0));
    // const balanceForReplacement = computed(() => sumReplacementItems.value.reduce( (a, c) => a + c, 0));
    const balanceForReplacement = computed(() => cartItems.value
        .filter((a) => a.not_available_option == 4)
        .reduce((a,r) => a + parseFloat(r.replacement.price_per_case * r.quantity - r.discounted_price), 0))

    const openReplacementModal = () => { showReplacementModal.value = true; };
    const closeReplacementModal = (replaced) => {
      showReplacementModal.value = false;
      if(!replaced) generatePageKey();
    };

    const generatePageKey = () => (pageKey.value = `MyCartPage${window.luxon.now().toISO()}`);

    watch( balanceForReplacement,
      (value) => {
        localStorage.value.set("replacementAmount", value);
        store.commit('replacement/setAmount', value)
      }
    )

    watch(
      () => store.state.cart.data,
      () => updateCheckout(),
      { immediate: true, deep: true }
    );

    onMounted(() => {
      const width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
      isMobile.value = width < 768 ? true : false;
      contentSlug.value = 'my_cart';
      generatePageKey();
    });

    return {
      pageKey,
      cartIsLoading,
      cart,
      cartItems,
      oneTimePuchaseItems,
      subscriptionItems,
      balanceForReplacement,
      isMobile,
      showReplacementModal,
      fetchCart,
      removeAllItems,
      openReplacementModal,
      closeReplacementModal,
      getCmsContent,
      router
    }
  }
})
</script>