<template>
    <div>
        <!-- card -->
        <div 
        class="product-card__hldr p-2 h-full flex flex-col"
        :class="disabled ? 'disabled' : ''"
        >
            <!-- image -->
            <div class="relative w-full mb-2 product-img__hldr">
                <div class="relative w-full pt-[100%]"
                @click="selectedProduct(item.id)">
                    <img
                        :src="item.initial_image || '/assets/image/prd-img1.png'"
                        alt="product-img"
                        class="absolute top-0 left-0 w-full h-full object-cover"
                    >
                </div>
                <div 
                @click.prevent="source == 'productPage' ? addToWishlistHandler(item.id) : removeFromWishlistHandler(item.id)"
                class="w-8 h-8 md:w-14 md:h-14 rounded-full flex items-center justify-center cursor-pointer absolute top-4 right-4"
                :class="isRelated ? 'bg-white' : 'bg-white'"
                >
                    <img
                        width="25"
                        height="25"
                        src="/assets/icon/ic-star-blue.svg"
                        class="w-4 h-4 md:w-6 md:h-6"
                        alt="wishlist"
                    >
                </div>

                <div
                v-if="!disableHover"
                class="overlay__hldr lg:block hidden"
                >
                    <div class="flex">
                        <div
                        class="add-to-cart_ic cursor-pointer"
                        @click.prevent="openAddToCartModal()"
                        >
                            <img
                                width="25"
                                height="25"
                                src="/assets/icon/ic-bag.svg"
                                alt="add-to-cart"
                            >
                        </div>
                    </div>
                </div>
            </div>
            <!-- details -->
            <div>
                <p @click="selectedProduct(item.id)" class="font-bold text--primary mb-1" :class="isRelated ? 'text--white' : ''">
                    {{ item.name }}
                </p>
                <p class="product-price mb-1" :class="isRelated ? 'text--white' : ''">
                    {{ moneyFormat(item.price_per_case) }}
                </p>
            </div>
            <div class="mt-auto">

                <template v-if="!disableTag">
                    <template v-if="hasNoStock">
                        <div class="py-1 px-2 inline-flex bg--red--400">
                            <p class="text-xs font-bold text--white">
                                OUT OF STOCK
                            </p>
                        </div>
                    </template>
                    <template v-else>
                        <div v-if="item.is_best_seller" class="py-1 px-2 inline-flex bg--secondary">
                            <p class="text-xs font-bold text--white">
                                BEST SELLER
                            </p>
                        </div>
                        <!-- :class="item.is_best_seller ? 'product-tag1__hldr' : 'product-tag__hldr'" -->
                        <div v-if="item.promo" class="py-1 px-2 block bg--green--250 mt-1" >
                            <p class="text-xs font-bold text--white">
                                {{ item.promo }}
                            </p>
                        </div>
                    </template>
                </template>
            </div>
            <div class="mt-4 mobile-cart">
                <ion-button fill="clear" class="font--main btn-primary m-0 text-white" expand="block" @click.prevent="openAddToCartModal()">
                    Add to Cart
                </ion-button>
            </div>
        </div>
        <add-to-cart-modal 
            :is-visible="showAddToCartModal" 
            :itemId="item.id"
            :stock="item.stock"
            @close="showAddToCartModal = false"
        >
        </add-to-cart-modal>
    </div>

</template>

<script type="text/javascript">

import useCart from '@/composables/useCart';
import useWishlist from '@/composables/useWishlist';
import AddToCartModal from '@/components/AddToCartModal.vue';
import useFormat from "@/composables/useFormat";
import {
  IonButton,
} from '@ionic/vue';

import { computed, defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default defineComponent({
    name: 'ProductCard',

    components: {
        AddToCartModal,
        IonButton
    },
    
    emits:[ 'select-product' ],
    
    props: {
        source : {
            default: 'productPage',
            type: String
        },
        disableTag : {
            default: false,
            type: Boolean
        },
        disableHover : {
            default: false,
            type: Boolean
        },
        toEmit:{
            type:Boolean,
            default:false
        },
        isRelated : {
            default: false,
            type: Boolean
        },
        disabled : {
            default: false,
            type: Boolean
        },
        item: {
            type: Object,
            default() {
                return {};
            }
        }
    },
    setup(props, { emit }) {

        const store = useStore();
        const router = useRouter();
        
        const { addToCart, cartItemPayload, cartItemType } = useCart();
        const { addToWishlist, removeToWishlist } = useWishlist();
        const { moneyFormat } = useFormat();

        const hasNoStock = computed(() => props.item.stock == 0);

        const addToCartHandler = () => {
            addToCart(
                Object.assign(cartItemPayload, {
                    sessionId: store.state.user.guestId,
                    branchId: store.state.address.branchId,
                    productId: props.item.id,
                    type: cartItemType.value.ONE_TIME_PURCHASE,
                    quantity: 1,
                    originalPrice: props.item.price,
                    discountedPrice: props.item.price
                })
            );
        }

        const addToWishlistHandler = (id) => (addToWishlist(id).catch(() => router.push({ name:'login' })));
        const removeFromWishlistHandler = (id) => (removeToWishlist(id));

        function selectedProduct(id) {
            if (!props.disabled) {
                if(props.toEmit) emit('select-product', id);
                else window.location.href = `/shop/${id}`;
            }
        }
        
        const showAddToCartModal = ref(false);
        const openAddToCartModal = () => {
            showAddToCartModal.value = true;
        }

        return {
            hasNoStock,
            addToCartHandler,
            selectedProduct,
            addToWishlistHandler,
            removeFromWishlistHandler,
            showAddToCartModal,
            openAddToCartModal,
            moneyFormat
        }
    }
})
</script>
<style lang="scss">
    .product-card__hldr {
        position: relative;
        transition: all 1s ease-in-out;

        &.disabled {
            opacity: .5;
            cursor: not-allowed;
        }

        .mobile-cart {
            display: none;
        }

        .product-img__hldr {
            // margin: auto;
            // width: 250px;
            // height: 250px;
            // .img {
            //   border-radius: 5px;
            //   max-width: 100%;
            //   height: 100%;
            //   object-fit: cover;
            // }
        }

        .product-details__hldr {
            width: 250px;
            margin: 1rem auto 0;
        }

        .product-tag__hldr {
            position: absolute;
            left: 8px;
            top: 20px;
            padding: 10px 12px;
        }

        .product-tag1__hldr {
            position: absolute;
            left: 8px;
            top: 65px;
            padding: 10px 12px;
        }

        @media (hover: hover) and (pointer: fine) {
            &:hover {
                transition: all 0.5s ease-in-out;

                .wishlist-button {
                    opacity: 0.5;
                    z-index: 100;
                    transition: all 0.5s ease-in-out;
                    &:hover {
                        opacity: 1;
                    }
                }

                .product-img__hldr, .product-tag__hldr {
                    opacity: .7;
                    transition: all 0.5s ease-in-out;
                }

                .overlay__hldr {
                    opacity: 1;
                    transition: all 0.5s ease-in-out;
                }

                .product-img__hldr {
                    position: relative;
                    transition: all 0.5s ease-in-out;

                    &::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        background-color: rgba(255, 255, 255, .1);
                        transition: all 0.5s ease-in-out;
                    }
                }
            }
        }

        // &:hover {
        //   transition: all 1s ease-in-out;

        //   .product-img__hldr, .product-tag__hldr {
        //     opacity: .7;
        //     transition: all 1s ease-in-out;
        //   }

        //   .overlay__hldr {
        //     opacity: 1;
        //     transition: all 1s ease-in-out;
        //   }

        //   .product-img__hldr {
        //     position: relative;
        //     transition: all 1s ease-in-out;

        //     &::before {
        //       content: '';
        //       position: absolute;
        //       top: 0;
        //       right: 0;
        //       bottom: 0;
        //       left: 0;
        //       background-color: rgba(255, 255, 255, .1);
        //       transition: all 1s ease-in-out;
        //     }
        //   }
        // }

        .overlay__hldr {
            opacity: 0;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            transition: all .5s ease;

            .rate__ic, .add-to-cart_ic {
                width: 58px;
                height: 58px;
                background-color: #FFFFFF;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    
    }
    @media screen and (max-width: 768px) {
        .mobile-cart {
            display: block !important;
        }
    }
    @media screen and (max-width: 468px) {
        .product-card__hldr {
            .product-tag__hldr {
                h6 {
                    font-size: 10px;
                    line-height: 1;
                }
            }
            .product-img__hldr {
                width: 163px;
                height: 163px;
            }
            .product-details__hldr {
                width: 100%;
            }
        }
    }
</style>