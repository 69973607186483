<template>
    <ion-page>
        <ion-content fullscreen>
            <div class="flex items-center justify-center h-screen">
                Please Wait..
                <hr>
                <a class="block" href="/">Back to Home</a>
            </div>
        </ion-content>
        <Loader v-show="loader" />
    </ion-page>
</template>
<script>
import { defineComponent, computed, ref, onMounted } from 'vue'
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

import { createCheckoutPayment } from '@/vendor/PaymentService/services/Paymaya.js';
import { IonPage, IonContent, onIonViewWillEnter } from '@ionic/vue';
import useLocalStorage from "@/composables/useLocalStorage";
import Loader from '@/components/PageLoader.vue';

export default defineComponent({
    components:{ IonPage, IonContent, Loader },
    setup() {

        const store = useStore();
        const route = useRoute();
        const { localStorage } = useLocalStorage();
        const referenceNumber = ref()
        const totalAmount = ref(0);
        const address = computed(() => store.state.address.form);

        const payload = computed(() => {
            const user = store.state.user.data;
            return {
                firstName: user.first_name, 
                lastName: user.last_name, 
                mobileNumber: `+63${user.mobile_number}`, 
                email: user.email, 
                amount: totalAmount.value,
                street: address.value.street,
                unitNo: address.value.unitNo ?? address.value.unit_no,
                barangay: address.value.barangayLabel ?? address.value.barangay,
                city: address.value.cityLabel ?? address.value.city,
                province: address.value.provinceLabel ?? address.value.province,
                zipCode: address.value.zipCode ?? '',
            };
        });
        
        const loader = ref(false);

        const init = () => {
            const refNumber = route.params.ref;
            loader.value = true

            createCheckoutPayment(payload.value, totalAmount.value, refNumber)
                .then(data => {
                    loader.value = false
                    console.log(data)
                    referenceNumber.value = refNumber;
                    store.commit('checkout/set', { referenceNumber: refNumber });
                    window.location.href = data.redirectUrl;
                })
                .catch(() => loader.value = false);  
        };

        onMounted( async() => {
            totalAmount.value = await localStorage.value.get('total') || 0;
            const user = await localStorage.value.get('user') || {};
            const payload = await localStorage.value.get('checkout') || {};
            payload.items = await localStorage.value.get('checkoutItems') || [];
            store.commit('user/setData', user);
            store.commit('checkout/set', payload);
        })

        onIonViewWillEnter( async() => {
            totalAmount.value = await localStorage.value.get('total') || 0;
            const user = await localStorage.value.get('user') || {};
            const checkoutPayload = await localStorage.value.get('checkout') || {};
            checkoutPayload.items = await localStorage.value.get('checkoutItems') || [];
            store.commit('user/setData', user);
            store.commit('checkout/set', checkoutPayload);

            init()

            return { loader }
        })

    },
})
</script>