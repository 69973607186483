import { createStore } from "vuex"

// Modules
import { address } from "./modules/address";
import { api } from "./modules/api";
import { cart } from "./modules/cart";
import { checkout } from "./modules/checkout";
import { content } from "./modules/content";
import { gender } from "./modules/gender";
import { products } from "./modules/products";
import { settings } from "./modules/settings";
import { user } from "./modules/user";
import { wishlist } from "./modules/wishlist";
import { replacement } from "./modules/replacement";
import { invoices } from "./modules/invoices";

const store = createStore({
  modules: {
    address,
    api,
    cart,
    checkout,
    content,
    gender,
    products,
    settings,
    user,
    wishlist,
    replacement,
    invoices
  },
})

export default store