<template>
    <ion-page>
        <ion-header class="top-nav">
            <ion-toolbar class="bg-white top-nav-chat px-7" color="white">
                <div class="flex justify-between items-center">
                    <p class="text-neutral-600 font-bold text-2xl m-0 truncate w-[85%]">{{ riderName }}</p>
                    <div @click="$emit('close')">
                        <XIcon class="w-5 h-5"/>
                    </div>
                </div>
            </ion-toolbar>
        </ion-header>
        <ion-content :fullscreen="true" class="ion-padding bg--chat">
            <div class="chat-container" v-if="chat.messages">
                <template v-for="(message, index) in chat.messages" :key="index">
                    <div class="chat-sent" v-if="message.user_type == 'App\\Models\\Users\\User'">
                        <img class="chat-head" :src="message.user.profile_photo_url">
                        <p class="text-xs leading-5">{{ message.message }} </p>
                    </div>

                    <div class="chat-received" v-else>
                        <img class="chat-head" :src="message.user.profile_photo_url">
                        <p class="text-xs leading-5">{{ message.message }} </p>
                    </div>
                </template>
            </div>
            <div class="quick-response-container">
                <ul class="quick-response-list">
                    <li class="quick-response-item" @click.prevent="sendQuickResponse('Where are you now?')">Where are you now?</li>
                </ul>
            </div>
        </ion-content>

        <ion-footer>
            <ion-toolbar>
                <div class="flex items-end space-x-2 px-4 py-2">
                    <ion-textarea
                    v-model="form.message"
                    rows="1"
                    autoGrow
                    placeholder="Talk to the rider..."
                    class="chat-textarea"></ion-textarea>
                    <button class="border-0 h-11 leading-3" @click.prevent="submit">
                        <ion-icon src="/assets/icon/ic-send.svg" class="w-6 h-6"></ion-icon>
                    </button>
                </div>
            </ion-toolbar>
        </ion-footer>
        <!-- <Loader v-if="chatLoader" /> -->
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonContent,
    IonHeader,
    IonToolbar,
    IonFooter,
    IonTextarea,
    IonIcon,
    onIonViewWillEnter,
    onIonViewDidEnter,
    onIonViewWillLeave
} from "@ionic/vue";
import { defineComponent, ref, computed, watch, onMounted } from "vue";
import { XIcon } from '@heroicons/vue/outline';
import { useRouter } from "vue-router";
// import { useApiStore } from "@/store/api.js";
import { useStore } from "vuex";
import { useChatStore } from "@/store/modules/chat.js";
import useChat from "@/composables/useChat";
// import Loader from '@/components/Loader.vue';
export default defineComponent({
    name: "ChatComponent",
    components: { 
        IonPage,
        IonContent,
        IonHeader,
        IonToolbar,
        IonFooter,
        IonTextarea,
        IonIcon,
        XIcon,
        // Loader
    },
    emits: ["close"],
    props: {
        isVisible: {
            type: Boolean,
            default: false
        },
        invoiceId : {
            default: null,
            type : Number
        },
        riderName : {
            default: null,
            type : String
        }
    },
    setup(props, { emit }) {

        const router = useRouter();
        // const route = useRoute();

        // const apiStore = useApiStore();
        const store = useStore();
        const chatStore = useChatStore();
        
        const { fetchChat, sendChat, chat, chatLoader } = useChat();

        const fetchChatUrl = computed(() => store.state.api.routes.fetchChat);
        const sendChatUrl = computed(() => store.state.api.routes.sendChat);

        const form = ref({
            invoice_id: props.invoiceId,
        })
        const submit = () => {
            sendChat(sendChatUrl.value, form.value);

            form.value.message = null;
        }

        const sendQuickResponse = (val) => {
            form.value.message = val;
            sendChat(sendChatUrl.value, form.value);
            form.value.message = null;
        }

        const fetchChatMsgs = setInterval(() => {
            fetchChat(fetchChatUrl.value, props.invoiceId)}, 
            1000
        )

        watch(
            () => fetchChatUrl.value,
            (url) => {
                if (typeof url === "string") fetchChat(url, props.invoiceId);
            }
        );

        onIonViewWillEnter(() => {
            if (typeof fetchChatUrl.value === "string")
                fetchChat(fetchChatUrl.value, props.invoiceId);
        });

        onIonViewDidEnter(() => {
            window.Echo.channel(`user-chat.${props.invoiceId}`)
            .listen('.rider.chat-received', (e) => {
                console.log("receiving message...")
                console.log(e.message);
                fetchChat(fetchChatUrl.value, props.invoiceId);
            })
        });

        onIonViewWillLeave(() => {
            clearInterval(fetchChatMsgs)

            window.Echo.channel(`rider-chat.${props.invoiceId}`)
            .stopListening('.rider.chat-received')
        });
        const open = ref(false);

        const closeThis =()=> open.value = false;

        watch(
            () => props.isVisible,
            (bool) => (open.value = bool), {
                immediate: true,
            }
        );

        watch(
            () => open.value,
            (bool) => {
                if (!bool) emit("close");
            }
        );

        onMounted(() => {
            if (typeof fetchChatUrl.value === "string")
                fetchChat(fetchChatUrl.value, props.invoiceId);
        });

        return {
            open,
            closeThis,
            router,
            submit,
            chatStore,
            chat,
            form,
            chatLoader,
            sendQuickResponse
        }
    },
});
</script>
