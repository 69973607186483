<template>
    
    <ion-button
        :size="size"
        :expand="expand"
        :fill="fill"
        :disabled="disabled"
        :class="[
            customClass
        ]"
        :color="color"
        class="ion-no-margin"
    >
        <slot name="content"></slot>
    </ion-button>

</template>
<script>
import { IonButton } from '@ionic/vue'

export default {
    components: {
        IonButton
    },
    props: {
        color: {
            type: String,
            default: 'primary'
        },
        expand: {
            type: String,
            default: null // block, full
        },
        fill: {
            type: String,
            default: 'solid' // clear, outline, solid
        },
        size: {
            type: String,
            default: 'large' // small, default, large
        },
        disabled: {
            type: Boolean,
            default: false
        },
        customClass: {
            type: String,
            default: null
        }
    },
    computed: {
        // buttonType() {
        //     return {
        //         'primary': 'btn-primary',
        //         'secondary': 'btn-secondary',
        //         'white': 'btn-white',
        //         'blue': 'btn-blue',
        //         'red': 'btn-red'
        //     }[this.color]
        // }
    }
}
</script>
