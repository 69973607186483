<template>
  <!-- <a :href="href"> -->
    <div
      class="bg-[#E6F8FF] p-6 mb-[18px] rounded-[10px]"
      :class="isActive ? 'border-active' : ''"
    >
      <div class="flex items-start justify-start gap-4">
        <template v-if="isEdit">
          <div class="flex-initial">
            <div class="flex items-center h-5">
              <template v-if="radio">
                <input
                  type="radio"
                  :id="`address-radio-${id}`"
                  :name="`address-${id}`" 
                  :value="id"
                  v-model="selected"
                  :disabled="isDisabled"
                >
              </template>
              <template v-else>
                <input
                  id="checkbox"
                  name="checkbox"
                  type="checkbox"
                  class="focus:ring-[#02ADF0] h-4 w-4 text-[#02ADF0] border-gray-300 rounded"
                  v-model="toggle"
                >
              </template>
            </div>
          </div>
        </template>
        <div class="flex-1">
          <p class="text-base text--gray--900 font-bold">{{ addressName }}</p>
          <p class="text-sm text--gray--900 font-normal">{{ name }}</p>
          <p class="text-sm text--gray--900 font-normal" v-if="hasMobileNumber">{{ formattedMobileNumber }}</p>
          <!-- <p class="text-sm text--gray--900 font-normal">{{ addressDetails.mobile_number }}</p> -->
          <p class="text-sm text--gray--900 font-normal">{{ address }}</p>
          <a v-if="href" :href="href" class="text--secondary font-bold block mt-4">Edit</a>
          <ion-button v-else
            :disabled="!isActive" 
            @click="toggleEditAddress()" 
            class="mt-4">
            Edit
          </ion-button>
        </div>
        <template v-if="isDefault">
          <div class="flex-initial justify-self-start self-start">
            <p class="text--primary text-base font-bold">Default</p>
          </div>
        </template>

      </div>
    </div>
    <Loader v-if="loader" />
  <!-- </a> -->
    <!-- Start of New Address Form -->
    <template v-if="showEditAddressForm && isActive">
      <AddressLocationForm v-model="form" :withMobile="true" />
      <div class="flex justify-between items-center mb-3">
        <ion-button
          shape="round"
          size="large"
          fill="outline"
          class="flex items-center font--main m-0 capitalized"
          :disabled="!addressFormIsComplete"
          @click="openConfirmationModal"
        >
          Save
        </ion-button>
      </div>
    </template>
        <!-- End of New Address Form -->
</template>
<script type="text/javascript">
import { ref } from '@vue/reactivity';
import { watch, computed, onMounted } from '@vue/runtime-core';
import { modalController } from '@ionic/vue';
import { useStore } from 'vuex';

import useAuth from '@/composables/useAuth';
import useLocalStorage from '@/composables/useLocalStorage';
import Modal from '@/components/Modal';
import Loader from '@/components/PageLoader.vue';
import useCart from '@/composables/useCart';
import AddressLocationForm from '@/views/account-details/address-book/AddressLocationForm.vue';

export default {
  name: 'AddressCard',
  components: { AddressLocationForm, Loader },
  props: {
    modelValue:{
      type: Number,
      default: null
    },
    addressDetails:{
      type: Object,
      default: null
    },
    id:{
      type: Number,
      default: null
    },
    addressName: {
      type: String,
      default: null
    },
    name: {
      type: String,
      default: null
    },
    mobileNumber: {
      type: String,
      default: null
    },
    address: {
      type: String,
      default: null
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    isDefault: {
      type: [Boolean,Number],
      default: false
    },
    href: {
      type: String,
      default: null
    },
    radio: {
      default: false,
      type: Boolean
    },
    isActive: {
      default: false,
      type: Boolean
    },
    isDisabled:{
      default: false,
      type: Boolean
    },
    selectedAddressId:{
      default: null,
      type: String
    }
  },
  emits:[ 'add', 'remove', 'update:modelValue' ],
  setup(props, { emit }) {

    const store              = useStore();
    const { checkCartItems } = useCart();
    const { localStorage }   = useLocalStorage();
    const { logout }         = useAuth();

    const selected            = ref(props.isActive);
    const selectedAddress     = ref(props.addressDetails);
    const toggle              = ref(false);
    const showEditAddressForm = ref(false);
    const shouldSave          = ref(false);
    const loader              = ref(false);


    const form = ref({
      id           : selectedAddress.value?.id,
      provinceCode : selectedAddress.value?.province_code,
      cityCode     : selectedAddress.value?.city_code,
      barangayCode : selectedAddress.value?.barangay_code,
      unitNo       : selectedAddress.value?.unit_no,
      street       : selectedAddress.value?.street,
      zipCode      : selectedAddress.value?.zip_code,
      latitude     : selectedAddress.value?.latitude,
      longitude    : selectedAddress.value?.longitude,
      mobileNumber : selectedAddress.value?.mobile_number,
      email        : null,
      firstName     : null,
      lastName     : null,
      isDefault    : false,
      isCheckout   : true,
    });

    const addressFormIsComplete = computed(() => {
      return (
        form.value.provinceCode !== 'Province' && 
        form.value.cityCode !== 'City' && 
        form.value.barangayCode !== 'Barangay' && 
        form.value.unitNo && 
        form.value.street && 
        form.value.zipCode &&
        form.value.mobileNumber
      );
    });

    const hasMobileNumber = computed(() => parseInt(props.mobileNumber) > 0);
    const formattedMobileNumber = computed(() => `+63${props.mobileNumber}`);
    const user = computed(() => store.state.user.data)

    /*
    |--------------------------------------------------------------------------
    | @Modals
    |--------------------------------------------------------------------------
    */

    const openConfirmationModal = async () => {
      const modal = await modalController.create({
        component: Modal,
        cssClass: 'gen-modal',
        componentProps: {
          title           : "Are you sure?",
          description     : "Updating your address might affect the item stocks in your cart.",
          buttonText      : "Update Address",
          cancelButtonText: 'Cancel',
          cancelButton    : true,
          emitButton      : true,
          imageLink       : "/assets/icon/ic-status-warning.svg",
          imageWidth      : '64',
          imageHeight     : '64',
          shouldAutoClose : false
        }
      });

      modal.onDidDismiss().then(({ data }) => {
        if(data == 'emit') submit();
      });

      return modal.present();
    };

    const setBranch = (id) => {
      if (id) {
        checkCartItems(id);
        store.commit('address/setBranch', id);
        localStorage.value.set('branch', id);
      }
    };

    const updateAddressUrl = computed(() => store.state.api.routes.updateAddress);

    const submit = () => {
      loader.value = true;

      form.value.id           = selectedAddress.value.id;
      form.value.latitude     = selectedAddress.value.latitude;
      form.value.longitude    = selectedAddress.value.longitude;
      form.value.email        = selectedAddress.value.email;
      form.value.firstName     = selectedAddress.value.first_name;
      form.value.lastName     = selectedAddress.value.last_name;
      form.value.isDefault    = selectedAddress.value.is_default;
      form.value.name         = selectedAddress.value.name;
      
      window.axios.post(updateAddressUrl.value, form.value)
        .then(( {data} ) => {
          fetch()
          setBranch(data.branch_id)
          loader.value = false;
          showEditAddressForm.value = false;
        })
        .catch(({ response }) => {
          if(response.status == 401) logout();
          loader.value = false;
          showEditAddressForm.value = false;
        });
    };

		const fetchAddressUrl = computed(() => store.state.api.routes.fetchAddress);
    const fetch = () => {
				window.axios.post(fetchAddressUrl.value)
					.then(({ data }) => {
						store.commit('address/setItems', data.items);
					})
					.catch(({ response }) => {
						if(response.status == 401) logout();
					});
			};

    const toggleEditAddress = () => {
				showEditAddressForm.value = !showEditAddressForm.value;

				if(showEditAddressForm.value) {
          form.value.unitNo       = selectedAddress.value.unit_no
          form.value.street       = selectedAddress.value.street
          form.value.zipCode      = selectedAddress.value.zip_code
          form.value.provinceCode = selectedAddress.value.province_code
          form.value.cityCode     = selectedAddress.value.city_code
          form.value.barangayCode = selectedAddress.value.barangay_code
          form.value.mobileNumber = selectedAddress.value.mobile_number
				} 
			};
    
    watch(
      () => shouldSave.value, 
      (bool) => form.value.toSave = bool
    );

    watch(
      () => toggle.value, 
      (bool) => {
        if(bool) emit('add', props.id);
        else emit('remove', props.id);
      });

    watch(
      () => selected.value, 
      (bool) => {
        if(typeof bool == 'number') {
          setTimeout(() => {
            document.getElementById(`address-radio-${bool}`).click();
          }, 100)
        }
        if(bool) emit('update:modelValue', props.id);
      });

    watch(
      () => props.isActive, 
      (bool) => {
        selected.value = bool;
        if(bool) {
          document.getElementById(`address-radio-${props.selectedAddressId}`).click()
        }
      }
    );

    onMounted(() => {
      loader.value = false

      if(!selectedAddress.value.mobile_number || selectedAddress.value.mobile_number?.length < 10 && !user.value.mobile_number || user.value.mobile_number?.length < 10) {
        showEditAddressForm.value = true;
      }
      
      // if(!user.value.mobile_number || user.value.mobile_number?.length < 10) {
      //   showEditAddressForm.value = true;
      // }
      
      setTimeout(() => {
        document.getElementById(`address-radio-${props.selectedAddressId}`).click();
      }, 100)
    })

    return {
      selected,
      toggle,
      hasMobileNumber,
      formattedMobileNumber,
      // edit address
      toggleEditAddress,
      showEditAddressForm,
      addressFormIsComplete,
      openConfirmationModal,
      shouldSave,
      selectedAddress,
      form,
      loader
    }
  }
}
</script>