<!-- This example requires Tailwind CSS v2.0+ -->
<template>
    <TransitionRoot
    as="template"
    :show="open"
    >
        <Dialog
            as="div"
            class="fixed z-10 inset-0 overflow-y-auto"
            @close="closeThis"
        >
            <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <TransitionChild
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                >
                    <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </TransitionChild>

                <!-- This element is to trick the browser into centering the modal contents. -->
                <span
                    class="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                >&#8203;</span>
                <TransitionChild
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enter-to="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100 translate-y-0 sm:scale-100"
                    leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                    <div class="relative inline-block align-top bg-white rounded-[10px] text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle lg:w-[500px] sm:w-[90%] w-full">
                        <div class="p-14">
                            <div class="flex items-center justify-center">
                                <img src="/assets/icon/ic-status-check.svg" class="w-9 h-9"/>
                            </div>
                            <div class="flex flex-col items-center justify-center space-y-6 mt-6">
                                <h4 class="font-bold text-center text-2xl text-[#181D25]">Code Copied to Clipboard</h4>
                                <p class="font-normal text-center text-base text-[#181D25]">
                                    Send this code to your friends to receive rewards.
                                </p>
                            </div>
                            <div class="flex items-center justify-center gap-2.5 mt-6">
                                <ion-button @click="closeThis" size="large" class="btn-primary">
                                    Okay
                                </ion-button>
                            </div>
                        </div>
                    </div>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>

</template>

<script>

import { ref, watch } from 'vue';
import { Dialog, DialogOverlay, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { IonButton } from '@ionic/vue';

export default {
    name: 'ReferralModal',
    components: {
        Dialog,
        DialogOverlay,
        TransitionChild,
        TransitionRoot,
        IonButton
    },
    emits:[ 'close' ],
    props:{
        isVisible:{
            type:Boolean,
            default:false
        }
    },
    setup(props, { emit }) {
    
        const open = ref(false);

        const closeThis = () => {
            open.value = false;
        };

        watch(
            () => props.isVisible,
            (bool) => open.value = bool, {
                immediate: true
            }
        );

        watch(
            () => open.value,
            (bool) => {
                if (!bool) emit('close');
            }
        );
    
        return {
            open,
            closeThis,
        }
    },
}
</script>