<template>
  <ion-page>
    <top-navigation />

    <ion-content
      :fullscreen="true"
      class="ion-padding"
    >
      <div class="w-11/12 mx-auto">
        <section class="spacer text-center mb-20">
          <h1 class="frm-title text--secondary font-bold mb-5 md:mb-3">
            {{ getCmsContent('order_confirmed_title') || 'Order Confirmed' }}
          </h1>
          <p v-html="getCmsContent('order_confirmed_message1') || 'Your order is now being processed. You may continue shopping or view your order/s. Your order numbers are:'"></p>
          <div class="bg--primary--50 px-5 py-6 border-radius-10 my-6 w-full text-left md:w-[510px] mx-auto">
            <div 
              class="flex items-center gap-4"
              v-for="invoice in invoices"
              :key="`invoice-summary-${invoice.id}`"
            >
              <p class="flex-none font-bold pb-2">
                #{{ invoice.invoice_no }}
              </p>
              <p
                class="flex-1 w-full pb-2"
                v-if="isOneTimePurchase(invoice)"
              >
                One time purchase
              </p>
              <p
                class="flex-1 w-full pb-2"
                v-else
              >
                Subscription: {{ getSubscriptionLabel(invoice) }}
              </p>
            </div>
          </div>
          <p v-html="getCmsContent('order_confirmed_message2') || 'Thank you for shopping at Nature’s Spring.'"></p>
          <div class="flex justify-center mt-8 md:mt-6">
            <ion-button
              shape="round"
              fill="outline"
              size="large"
              class="flex items-center font--main m-0 capitalized px-0 mr-3"
              href="/shop"
            >
              {{ getCmsContent('order_confirmed_button1_text') || 'Continue Shopping' }}
            </ion-button>
            <ion-button
              v-if="isAuthenticated"
              shape="round"
              size="large"
              class="flex items-center font--main m-0 capitalized px-0"
              href="/order-history"
            >
              {{ getCmsContent('order_confirmed_button2_text') || 'View Orders' }}
            </ion-button>
          </div>
        </section>
      </div>
      <bottom-navigation />
    </ion-content>
  </ion-page>
</template>

<script type="text/javascript">
/* eslint-disable */
import { ref, defineComponent, onMounted } from 'vue';
import useAuth from '@/composables/useAuth';
import useCms from "@/composables/useCms";
import useLocalStorage from '@/composables/useLocalStorage';

import {
  IonPage,
  IonContent,
  IonButton,
} from '@ionic/vue';
import TopNavigation from '@/partials/TopNavigation.vue';
import BottomNavigation from '@/partials/BottomNavigation.vue';

export default defineComponent({
  name: 'OrderSuccessPage',
  components: {
    IonPage,
    IonContent,
    IonButton,
    TopNavigation,
    BottomNavigation,
  },
  setup() {
    const { isAuthenticated } = useAuth();
    const { contentSlug, getCmsContent } = useCms();
    const { localStorage } = useLocalStorage();
    const invoices = ref();
    
    const isOneTimePurchase = (invoice) => {
      return invoice.group_type != 'App\\Models\\Subscriptions\\Subscription';
    };

    const getSubscriptionLabel = (invoice) => {
      if(isOneTimePurchase(invoice)) return '';
      return invoice?.items[0]?.subscription_label || '-';
    };

    onMounted(async() => {
      invoices.value = await localStorage.value.get('orders');

      if(!invoices.length == 0) window.location.href = '/shop';
      
      setTimeout(async() => {
        localStorage.value.delete("orders");
        window.location.href = '/shop'
      }, 3000);

      contentSlug.value = 'checkout'
    });

    return {
      invoices,
      isAuthenticated,
      isOneTimePurchase,
      getSubscriptionLabel,
      getCmsContent
    }
  }
})
</script>