<template>
  <ion-page>
    <top-navigation />
    <ion-content
      :fullscreen="true"
      class="ion-padding"
    >
      <div class="flex items-center justify-center pt-[85px] pb-24">
        <div class="w-[90%] md:w-[35%] mx-auto">
          <div class="ion-no-padding mb-10 md:mb-8 text-center">
            <h1 class="leading-12 font-size--xx-large font-bold text--secondary">
              Reset Password
            </h1>
          </div>

          <div class="mb-4 relative">
            <input
              v-model="form.password"
              :type="show ? 'text' : 'password'"
              name="password"
              id="password"
              class="focus:ring-[#02adf0] focus:border-[#02adf0] block w-full border-gray-300 placeholder-gray-400 rounded-[10px] h-12"
              placeholder="Password"
            >
            <ion-icon
              class="password-ic"
              :src="show ? '/assets/icon/ic-pass-show.svg' : '/assets/icon/ic-pass-hide.svg'"
              @click="show = !show"
            />
          </div>

          <div class="mb-6 relative">
            <input
              v-model="form.confirmPassword"
              :type="show ? 'text' : 'password'"
              name="confirm_password"
              id="confirm_password"
              class="focus:ring-[#02adf0] focus:border-[#02adf0] block w-full border-gray-300 placeholder-gray-400 rounded-[10px] h-12"
              placeholder="Confirm Password"
            >
            <ion-icon
              class="password-ic"
              :src="show ? '/assets/icon/ic-pass-show.svg' : '/assets/icon/ic-pass-hide.svg'"
              @click="show = !show"
            />
          </div>


          <div class="ion-no-padding">
            <div class="w-full flex justify-center items-center">
              <ion-button
                @click="submit"
                class="font--main btn-primary m-0 capitalized"
                shape="round"
                size="large"
              >
                Submit
              </ion-button>
            </div>
          </div>
        </div>
      </div>
      <bottom-navigation />
    </ion-content>
  </ion-page>
</template>

<script type="text/javascript">
import {
    IonPage,
    IonContent,
    IonButton,
    IonIcon,
    // modalController,
} from '@ionic/vue';
import { onMounted } from '@vue/runtime-core';
import { ref, defineComponent } from 'vue';
// import { useRouter } from 'vue-router';
// import { FacebookLogin } from "@capacitor-community/facebook-login";
import TopNavigation from '@/partials/TopNavigation.vue';
import BottomNavigation from '@/partials/BottomNavigation.vue';
// import Modal from '@/components/Modal.vue';

export default defineComponent({
    name: 'Login',
    components: {
        IonContent,
        IonPage,
        IonButton,
        IonIcon,
        TopNavigation,
        BottomNavigation
    },
    setup() {
        // const router = useRouter();

        const show = ref(false);

        // Modal variables
        // const title = ref('Oops!');
        // const message = ref('Something went wrong.');
        // const buttonText = ref('Close');
        // const imageLink = ref('/assets/images/anim-warning.gif');
        
        // const openErrorModal = async () => {
        //     const modal = await modalController.create({
        //         component: Modal,
        //         cssClass: 'gen-modal',
        //         componentProps: {
        //         title: title.value,
        //         description: message.value,
        //         buttonText: buttonText.value,
        //         imageLink: imageLink.value,
        //         closeModalButton: true,
        //         buttonClass: 'btn-primary',
        //         imageWidth: '28',
        //         imageHeight: '28',
        //         full: 'full'
        //         }
        //     });
        //     return modal.present();
        // };

        const form = ref({
            password: null,
            confirmPassword: null,
        });

        // const clearFields = () => {
        //     form.value.password = null;
        //     form.value.confirmPassword = null;
        // };

        const submit = async () => {

        }


        onMounted(() => {
            console.log("onMounted ...");
        })

        return {
            submit,
            form,
            show
        };
    },
})
</script>