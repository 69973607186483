<template>
  <div class="hdr-locationCon lg:order-none order-first lg:pl-0 lg:pt-0 pl-[1.25rem] pt-[1.25rem]">
    <p
      class="hdr-locationLink flex flex-wrap items-center cursor-pointer"
      @click="open"
    >
		<!-- <img
			src="/assets/icon/ic-location-outline.svg"
			alt="location-icon"
			width="20"
			height="20"
			class="object-contain block"
		> -->
		<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M10 11.333C11.3807 11.333 12.5 10.2137 12.5 8.83301C12.5 7.4523 11.3807 6.33301 10 6.33301C8.61929 6.33301 7.5 7.4523 7.5 8.83301C7.5 10.2137 8.61929 11.333 10 11.333Z" stroke="#02ADF0" stroke-linecap="round" stroke-linejoin="round"/>
			<path d="M10.0002 2.1665C8.23205 2.1665 6.53636 2.86888 5.28612 4.11913C4.03588 5.36937 3.3335 7.06506 3.3335 8.83317C3.3335 10.4098 3.6685 11.4415 4.5835 12.5832L10.0002 18.8332L15.4168 12.5832C16.3318 11.4415 16.6668 10.4098 16.6668 8.83317C16.6668 7.06506 15.9644 5.36937 14.7142 4.11913C13.464 2.86888 11.7683 2.1665 10.0002 2.1665V2.1665Z" stroke="#02ADF0" stroke-linecap="round" stroke-linejoin="round"/>
		</svg>

      <span 
        class="
			text-sm 
			ml-1 
			inline-block 
			overflow-ellipsis 
			whitespace-nowrap 
			overflow-hidden"
      >
        {{ addressLabel }}
      </span>
    </p>

    <LocationModal :is-visible="showModal" @close="showModal = false" />
  </div>
</template>
<script>

import { computed, defineComponent, onMounted, ref, watch } from "@vue/runtime-core";
import { useStore } from "vuex";
import useLocalStorage from "@/composables/useLocalStorage";
import useLocation from "@/composables/useLocation";

import LocationModal from './LocationModal/Index.vue';
import { useRouter } from "vue-router";

export default defineComponent({
	name: 'LocationBar',
	components:{
		LocationModal
	},
	setup() {
		
		const store = useStore();
		const router = useRouter();

		const { localStorage } = useLocalStorage();
		const { addressForm, checkCityOptions, checkBarangayOptions } = useLocation();

		/*
		|--------------------------------------------------------------------------
		| @Data
		|--------------------------------------------------------------------------
		*/

		const showModal = ref(false);

		const fetchLocationUrl = computed(() => store.state.api.routes.fetchLocations);

		const addressLabel = computed(() => {
			const city = store.state.address.cities.find(x => x.code == (addressForm.value.city || addressForm.value.cityCode)) || {};
			const barangay = store.state.address.barangays.find(x => x.code == (addressForm.value.barangay || addressForm.value.barangayCode)) || {};

			const cityLabel = city.name || addressForm.value.city_label;
			const barangayLabel = barangay.name || addressForm.value.barangay_label;

			return barangayLabel && cityLabel ? `${barangayLabel}, ${cityLabel}` : null;
		});

		/*
		|--------------------------------------------------------------------------
		| @Methods
		|--------------------------------------------------------------------------
		*/

		const open = () => {
			if(router.currentRoute.value.name != 'checkout') showModal.value = true;
		};

		const initLocations = async () => {
			if(!store.state.address.provinces.length) {
				const provinces = await localStorage.value.get('provinces');
				store.commit('address/setProvinces', provinces);
			}
			if(!store.state.address.cities.length) {
				const cities = await localStorage.value.get('cities');
				store.commit('address/setCities', cities);
			}
			if(!store.state.address.barangays.length) {
				const barangays = await localStorage.value.get('barangays');
				store.commit('address/setBarangays', barangays);
			}
		};

		const initAddress = async () => {
			const address = await localStorage.value.get('location');
			const branch = await localStorage.value.get('branch');
			
			store.commit('address/setForm', address);
			store.commit('address/setBranch', branch);
		};

		/*
		|--------------------------------------------------------------------------
		| @Watch
		|--------------------------------------------------------------------------
		*/

		watch(
			() => fetchLocationUrl.value,
			(url) => {
				if(typeof url === 'string') {
					checkCityOptions();
					checkBarangayOptions();
				}
			},
			{ immediate: true }
		);



		onMounted(async() => {
			await initAddress();
			await initLocations();
		});

		return {
			open,
			showModal,
			addressLabel
		}
	},
})
</script>