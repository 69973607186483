<template>
  <section class="genc-fr1 lg:pt-[105px] pt-16 lg:pb-[50px] pb-8 mt-0">
    <div class="text-center w-11/12 mx-auto">
      <h1 class="font-bold lg:mb-2 mb-0 text--secondary">
        {{ title }}
      </h1>
      <a
        href="/"
        class="text-sm brd-link"
      >Home</a><span class="text-sm mx-1">></span><span class="text-sm text--secondary font-bold">{{ title }}</span>
    </div>
  </section>
</template>

<script type="text/javascript">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'GenericFrameOne',
  props: {
    title: {
      type: String,
      default: null
    },
  }
});
</script>