<template>
    <div class="ion-padding text-center py-15 px-20" :class="customClass">
        <img class="mx-auto" width="76" height="76" src="/assets/icon/droplets.gif" alt="Icon">
        <p class="text-sm my-1.5">
            You have earned
        </p>
        <p class="font-bold font--21 relative py-0 text-[#272682] mb-1.5">+ {{ droplets }} droplet/s</p>
        <p class="text-sm">
            for this order
        </p>
        <div class="flex items-start w-full mt-6">
            <div class="w-6/12 pr-1">
                <ion-button 
                    class="btn-light" 
                    shape="round" size="large" expand="full" 
                    @click="closeModal"
                >
                    Cancel
                </ion-button>
            </div>
            <div class="w-6/12 pl-1">
                <ion-button 
                    class="btn-primary" 
                    shape="round" size="large" expand="full" 
                    :href="itemHref"
                >
                    View Order
                </ion-button>
            </div>
        </div>
    </div>
</template>

<script>
import { IonButton, modalController } from '@ionic/vue';
import { defineComponent } from 'vue';
// import { useRoute } from 'vue-router';

export default defineComponent({
    name: 'Modal',
    props: {
        customClass: {
            type: String,
            default: null
        },
        droplets: {
            type: String,
            default: null
        },
        itemHref: {
            type: String,
            default: null
        }
    },
    components: { IonButton },
    setup() {
        // const route = useRoute();

        const closeModal = async () => {
            await modalController.dismiss();
        };

        return {
            closeModal
        };
    }
});
</script>