<template>
  <ion-accordion class="faqsfr1-accordion mb-5">
    <ion-item slot="header">
      <ion-label class="faqsfr1-accordionTitle">
        {{ title }}
      </ion-label>
    </ion-item>

    <ion-list
      slot="content"
      class="px-6 py-5"
    >
      <p v-html="description"></p>
    </ion-list>
  </ion-accordion>
</template>

<script type="text/javascript">
import { IonAccordion, IonItem, IonLabel, IonList } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'FaqsList',
    props: {
        title: {
            type: String,
            default: null
        },
        description: {
            type: String,
            default: null
        }
    },
    components: { IonAccordion, IonItem, IonLabel, IonList}
});
</script>