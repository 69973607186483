<template>
    <ion-button
        @click="login()"
        shape="round"
        size="large"
        fill="outline"
        class="flex items-center font--main m-0 capitalized"
    >
        <img
            class="mr-2"
            src="/assets/icon/ic-gmail.svg"
        >
        Gmail
    </ion-button>
</template>
<script>

import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { defineComponent, onMounted } from "@vue/runtime-core";
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import useAuth from '@/composables/useAuth';
import useGuest from '@/composables/useGuest';
// import usePushNotifications from '@/composables/usePushNotifications';

import { IonButton, onIonViewWillEnter } from '@ionic/vue';

export default defineComponent({
    name:'GoogleLoginButton',
    components:{
        IonButton
    },
    setup() {
        
        const store = useStore();
        const router = useRouter();

        const { setToken } = useAuth();
        const { clearGuestId } = useGuest();
        // const { postPushToken } = usePushNotifications();

        const login = async () => {
            const result = await GoogleAuth.signIn();
            if(result) submit(result);
        };

        const submit = (profile) => {
            window.axios.post(store.state.api.routes.socialLogin, {
                sessionId: store.state.user.guestId,
                branchId: store.state.address.branchId,
                provider: 'google',
                provider_id: profile.id,
                locationAddress: store.state.address.form,
                profile
            })
            .then(({ data }) => {
                setToken('Bearer ' + data.access_token, data.user);
                // postPushToken();
                clearGuestId();
                router.push({ name: 'home' });
            });
        };


        onMounted(() => {
            GoogleAuth.initialize();
        });

        onIonViewWillEnter(() => {
            GoogleAuth.initialize({
                clientId: process.env.VUE_APP_GOOGLE_ID,
                scopes: ['profile', 'email'],
                grantOfflineAccess: true,
            });
        })
        onMounted(async() => {
            await GoogleAuth.initialize({
                clientId: process.env.VUE_APP_GOOGLE_ID,
                scopes: ['profile', 'email'],
                grantOfflineAccess: true,
            });
        });

        return {
            login
        }
    },
})
</script>