<template>
  <ion-page>
    <top-navigation />
    <ion-content
      :fullscreen="true"
      class="ion-padding"
    >
      <div class="flex items-center justify-center pt-[85px] pb-24">
        <div class="w-[90%] md:w-[35%] mx-auto"> 
          <div class="ion-no-padding mb-10 md:mb-8 text-center">
            <h1 class="leading-12 font-size--xx-large font-bold text--secondary">
              Sign Up
            </h1>
          </div>

          <div class="mb-4">
            <input
              v-model="form.email"
              type="email"
              name="email"
              id="email"
              class="focus:ring-[#02adf0] focus:border-[#02adf0] block w-full border-gray-300 placeholder-gray-400 rounded-[10px] h-12"
              :class="form.email ? 'border-green-400' : ''"
              placeholder="Email"
            >
            <span v-if="!form.email" class="text-xs text-red-500">*required</span>
          </div>

          <div class="mb-4">
            <input
              v-model="form.firstName"
              type="text"
              name="firstName"
              id="firstName"
              class="focus:ring-[#02adf0] focus:border-[#02adf0] block w-full border-gray-300 placeholder-gray-400 rounded-[10px] h-12" 
              :class="form.firstName ? 'border-green-400' : ''"
              placeholder="First Name"
            >
            <span v-if="!form.firstName" class="text-xs text-red-500">*required</span>
          </div>

          <div class="mb-4">
            <input
              v-model="form.lastName"
              type="text"
              name="lastName"
              id="lastName"
              class="focus:ring-[#02adf0] focus:border-[#02adf0] block w-full border-gray-300 placeholder-gray-400 rounded-[10px] h-12" 
              :class="form.lastName ? 'border-green-400' : ''"
              placeholder="Last Name"
            >
            <span v-if="!form.lastName" class="text-xs text-red-500">*required</span>
          </div>

          <!-- <div class="mb-4">
            <label
              for="gender"
              class="sr-only"
            >Gender</label>
            <select
              id="gender"
              name="gender"
              class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-[#02ADF0] focus:border-[#02ADF0] rounded-md"
              v-model="form.gender"
            >
              <option
                selected
                disabled
              >
                Gender
              </option>
              <option 
                v-for="(gender,index) in genders" 
                :key="`gender-option-${index}`"
                :value="gender"
              >
                {{ gender }}
              </option>
            </select>
          </div> -->

          <div class="mb-4">
            <div class="flex rounded-[10px]">
              <span 
                class="inline-flex items-center px-3 rounded-l-[10px] border border-gray-300 border-r-0 text-gray-400" 
                :class="form.mobileNumber ? 'border-green-400' : ''" 
              >
                + 63
              </span>
              <span class="inline-flex items-center border-t border-b border-gray-300 text-gray-400">|</span>
              <input 
                v-model="form.mobileNumber"
                type="text" 
                name="mobileNumber" 
                id="mobileNumber" 
                class="flex-1 min-w-0 focus:ring-[#02adf0] focus:border-[#02adf0] block w-full border-l-0 border-gray-300 placeholder-gray-400 rounded-r-[10px] h-12" 
                :class="form.mobileNumber ? 'border-green-400' : ''"
                placeholder="Mobile Number"
                maxlength="10"
                @keydown="numberOnlyInput"
              >
            </div>
            <span v-if="!form.mobileNumber" class="text-xs text-red-500">*required</span>
          </div>

          <div class="mb-4 relative">
            <input
              @input="showSuggestedPass = true"
              @focusin="!form.password ? showSuggestedPass = true : showSuggestedPass = false"
              @keydown="showSuggestedPass = false"
              @mouseenter="showSuggestedPass = false"
              v-model="form.password"
              :type="show ? 'text' : 'password'"
              name="password"
              id="password"
              class="focus:ring-[#02adf0] focus:border-[#02adf0] block w-full border-gray-300 placeholder-gray-400 rounded-[10px] h-12" 
              :class="form.password ? 'border-green-400' : ''"
              placeholder="Password"
            >
            <ion-icon
              class="password-ic"
              :src="show ? '/assets/icon/ic-eye.svg' : '/assets/icon/ic-eye-off.svg'"
              @click="show = !show"
            />
            <span v-if="!form.password" class="text-xs text-red-500">*required</span>
            <div
              v-if="showSuggestedPass"
              class="absolute top-10 z-50 w-full rounded-lg bg-white border shadow-lg"
            >
              <div
                class="px-3 py-2 bg-[#EEEEEE]"
                @click="useSuggestedPassword"
                style="cursor:pointer;"
              >
                <div class="flex items-center justify-between">
                  <p class="text-gray-800 text-xs">
                    Use suggested Password
                  </p>
                  <p class="text-gray-600 text-xs">
                    {{ suggestedPassword }}
                  </p>
                </div>
              </div>
              <div class="p-3">
                <p class="text-gray-800 text-xs">
                  Password will be saved on your Google account. You won’t have to<br> remember it.
                </p>
              </div>
            </div>
          </div>
          <div class="mb-4 relative">
            <input
              v-model="form.password_confirmation"
              :type="show2 ? 'text' : 'password'"
              name="password"
              id="password"
              class="focus:ring-[#02adf0] focus:border-[#02adf0] block w-full border-gray-300 placeholder-gray-400 rounded-[10px] h-12" 
              :class="form.password_confirmation ? 'border-green-400' : ''"
              placeholder="Confirm Password"
            >
            <span v-if="!form.password_confirmation" class="text-xs text-red-500">*required</span>
            <ion-icon
              class="password-ic"
              :src="show2 ? '/assets/icon/ic-eye.svg' : '/assets/icon/ic-eye-off.svg'"
              @click="show2 = !show2"
            />
          </div>

          <div class="mb-6">
            <div class="flex">
              <ion-checkbox
                v-model="form.termsAgreed"
                slot="start"
                class="mr-2 w-20 md:w-10"
              />
              <p class="mb-0 text--gray--400 font-size--small">
                By clicking the sign up button below, I hereby agree to and accept Nature Spring’s <span
                  class="cursor-pointer hover:text-[#272682]"
                  @click="() => router.push('/terms-and-conditions')"
                >Terms and Conditions</span> governing my use of this website.
              </p>
            </div>
          </div>

          <div class="ion-no-padding">
            <div class="w-full flex justify-center items-center">
              <ion-button
                @click="signup"
                class="font--main btn-primary m-0 capitalized"
                shape="round"
                size="large"
                :disabled="loader"
              >
                Sign Up
              </ion-button>
            </div>
          </div>

          <div class="ion-no-padding">
            <div class="w-full flex justify-between items-center my-7">
              <hr class="w-full">
              <p class="or-text text-xs text-center text--gray--400 font--main">
                or register via
              </p>
              <hr class="w-full">
            </div>
          </div>

          <div class="ion-no-padding mb-12 md:mb-4">
            <div class="w-full flex flex-wrap justify-center">
              <!-- <div class="w-[49%]">
                <FacebookLoginButton />
              </div> -->
              <div class="w-[49%]">
                <GoogleLoginButton />
              </div>
            </div>
          </div>

          <div class="ion-no-padding mb-4">
            <div class="w-full flex flex-row items-center justify-center">
              <span class="font--main m-0 font-size--small text-gray-400">Already have an account?
                <a
                  class="font--main font-bold font-size--x-small text--secondary my-0 mr-0"
                  href="/login"
                >Login now</a>
                .
              </span>
            </div>
          </div>
        </div>
      </div>
      <bottom-navigation />
    </ion-content>
    <Loader v-show="loader" />
  </ion-page>
</template>

<script type="text/javascript">

import { onMounted } from '@vue/runtime-core';
import { ref, computed, defineComponent} from 'vue';
import { useStore } from 'vuex';
import useFormat from '@/composables/useFormat';

import { useRouter } from 'vue-router';

import {
    IonPage,
    IonContent,
    IonButton,
    IonIcon,
    IonCheckbox,
    modalController,
} from '@ionic/vue';
import Modal from '@/components/Modal.vue';
import TopNavigation from '@/partials/TopNavigation.vue';
import BottomNavigation from '@/partials/BottomNavigation.vue';
// import FacebookLoginButton from './components/FacebookLoginButton.vue';
import GoogleLoginButton from './components/GoogleLoginButton.vue';
import Loader from '@/components/PageLoader.vue';

export default defineComponent({
    name: 'Register',
    components: {
        IonContent,
        IonPage,
        IonButton,
        IonIcon,
        IonCheckbox,
        TopNavigation,
        BottomNavigation,
        // FacebookLoginButton,
        GoogleLoginButton,
        Loader
    },
    setup() {
        // const router = useRouter();
        const store = useStore();

        const show = ref(false);
        const show2 = ref(false);
        const { numberOnlyInput } = useFormat();
        const showSuggestedPass = ref(false);

        // Modal variables
        const title = ref('Oops!');
        const message = ref('Something went wrong.');
        const buttonText = ref('Close');
        const imageLink = ref('/assets/icon/ic-status-warning.svg');

        const loader = ref(false);

        const router = useRouter();
        
        const openErrorModal = async () => {
            const modal = await modalController.create({
                component: Modal,
                cssClass: 'gen-modal',
                componentProps: {
                title: title.value,
                description: message.value,
                buttonText: buttonText.value,
                imageLink: imageLink.value,
                buttonClass: 'btn-primary',
                closeModalButton: true,
                imageWidth: '28',
                imageHeight: '28',
                full: 'full'
                }
            });
            return modal.present();
        };

        const openEmailVerificationModal = async () => {
            const modal = await modalController.create({
                component: Modal,
                cssClass: 'gen-modal email-verification',
                componentProps: {
                  title: "Account Created",
                  description: "Your account has been successfully made. Kindly verify your identity by accessing the code we sent to your email/mobile number",
                  buttonText: "Okay",
                  buttonLink: `/verification/${form.value.email}`,
                  imageLink: "/assets/icon/ic-status-check.svg",
                  imageWidth: '40',
                  imageHeight: '40',
                  full: 'full',
                  shouldAutoClose: false,
                }
            });
            return modal.present();
        };

        const form = ref({
            email: null,
            firstName: null,
            lastName: null,
            mobileNumber: null,
            password: null,
            password_confirmation: null,
            termsAgreed: null,
        });

        const clearFields = () => {
          form.value = {
            email: null,
            firstName: null,
            lastName: null,
            mobileNumber: null,
            password: null,
            password_confirmation: null,
            sessionId: null,
            branchId: null,
            termsAgreed: false
          };
        };

        const signup = async () => {
          loader.value = true;
          
          form.value.sessionId = store.state.user.guestId;
          form.value.branchId = store.state.address.branchId;
          form.value = Object.assign(form.value, store.state.address.form);

          window.axios
          .post(store.state.api.routes.register, form.value)
          .then((result) => {
            openEmailVerificationModal();
              store.commit("user/setVerificationId", result.data.user.id);
              console.log(result)

              if (window.PasswordCredential) {
                /* eslint-disable */ 
                const passwordCredential = new PasswordCredential({ id: form.value.email, password: form.value.password });
                navigator.credentials.store(passwordCredential);
              }

              clearFields();
              loader.value = false;
          }).catch((error) => {
            if(error.response.data.message) {
              loader.value = false;
              message.value = error.response.data.message;
              }
              openErrorModal();
              console.log(error);
          })
        }

        const generatePassword = () => {
          let password = "";
          let lowerChar = "abcdefghijklmnopqrstuvwxyz"
          let upperChar = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
          let specialChar = "?!@#$%&"
          // let specialChar = "!\"#$%&'()*+,-./:;<=>?@[\\]^_`{|}~"
          let numbers = "0123456789"

          for (let i = 0; i < 4; i++) {
                password += lowerChar.charAt(Math.floor(Math.random() * lowerChar.length))
                password += upperChar.charAt(Math.floor(Math.random() * upperChar.length))
                password += specialChar.charAt(Math.floor(Math.random() * specialChar.length))
                password += numbers.charAt(Math.floor(Math.random() * numbers.length))
          }

          return password;
        }

        const suggestedPassword = generatePassword()

        const useSuggestedPassword = () => {
            showSuggestedPass.value = false;
            form.value.password = suggestedPassword
            form.value.password_confirmation = suggestedPassword
        }
        /*
        |--------------------------------------------------------------------------
        | @Computed
        |--------------------------------------------------------------------------
        */
        const genders = computed(() => store.state.gender.options);

        onMounted(() => {
            console.log("onMounted ...");
        })

        return {
            signup,
            form,
            show,
            show2,
            showSuggestedPass,
            suggestedPassword,
            openEmailVerificationModal,
            useSuggestedPassword,
            numberOnlyInput,
            loader,
            genders,
            router
        };
    },
})
</script>
<style lang="scss">
    .or-text {
        width: 250px;
    }
</style>