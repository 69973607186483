<template>
  <ion-page>
    <top-navigation />
    <ion-content
      :fullscreen="true"
      class="ion-padding"
    >
      <account-side-navigation>
        <slot>
          <div class="mb-4 space-y-4">
            <h4 class="text--gray--900 font-bold">
              Announcements
            </h4>
            <p class="text-base text--gray--800">
                Choose your preferred method to receive our announcements:
            </p>
          </div>
          <div class="w-full">
              <label class="p-4 rounded-lg bg--primary--50 mb-4 block w-full font-bold text--gray--300" for="pushNotif">
                  <input v-model="form.toggle_push_notif" type="checkbox" class="mr-2" id="pushNotif">
                  Push Notifications
              </label>
              <label class="p-4 rounded-lg bg--primary--50 mb-4 block w-full font-bold text--gray--300" for="email">
                  <input v-model="form.toggle_email_blast" type="checkbox" class="mr-2" id="email">
                  Email
              </label>
              <label class="p-4 rounded-lg bg--primary--50 mb-4 block w-full font-bold text--gray--300" for="sms">
                  <input v-model="form.toggle_sms_blast" type="checkbox" class="mr-2" id="sms">
                  SMS
              </label>
          </div>
          <div>
            <ion-button
              class="btn-primary"
              shape="round"
              size="large"
              fill="outline"
              @click="submit"
              :disabled="loader"
            >
              Save
            </ion-button>
          </div>
        </slot>
        <Loader v-show="loader" />

      </account-side-navigation>
      <bottom-navigation />
    </ion-content>
  </ion-page>
</template>
<script type="text/javascript">
import { IonPage, IonContent, IonButton, modalController } from '@ionic/vue';
import BottomNavigation from '@/partials/BottomNavigation.vue';
import TopNavigation from '@/partials/TopNavigation.vue';
import AccountSideNavigation from '@/partials/AccountSideNavigation.vue';
import Modal from '@/components/Modal';
import Loader from '@/components/PageLoader.vue';
import useAuth from '@/composables/useAuth';
import useLocalStorage from "@/composables/useLocalStorage";
import { useStore } from "vuex";
import { ref, watch, computed } from 'vue';

export default {
    name: 'AnnouncementsIndex',
    components: {
        IonContent, 
        IonPage, 
        IonButton,
        BottomNavigation, 
        TopNavigation, 
        AccountSideNavigation,
        Loader
    },
    setup() {
      const { localStorage } = useLocalStorage();
      const { logout } = useAuth();
      const store = useStore();

      const loader = ref(false);
      const form = ref({});
      const errorMessage = ref(null);
      const fetchProfileUrl = computed(() => store.state.api.routes.fetchProfile);
      const updatePreferenceUrl = computed(() => store.state.api.routes.updateAnnouncement);

      /*
      |--------------------------------------------------------------------------
      | @Modals
      |--------------------------------------------------------------------------
      */

      const openModal = async () => {
        const modal = await modalController.create({
          component: Modal,
          cssClass: 'gen-modal email-verification',
          componentProps: {
            title: "Success!",
            description: "Your announcement preferences has been updated.",
            buttonText: "Okay",
            imageLink: "/assets/icon/ic-status-check.svg",
            closeModalButton: true,
            imageWidth: '64',
            imageHeight: '64'
          }
        });
        return modal.present();
      };

      const openErrorModal = async () => {
        const modal = await modalController.create({
          component: Modal,
          cssClass: 'gen-modal',
          componentProps: {
          title: 'Oops!',
          description: errorMessage.value,
          buttonText: 'Close',
          imageLink: '/assets/icon/ic-status-warning.svg',
          closeModalButton: true,
          buttonClass: 'btn-primary',
          imageWidth: '28',
          imageHeight: '28',
          full: 'full'
          }
        });
        return modal.present();
      };
      /*
      |--------------------------------------------------------------------------
      | @Methodss
      |--------------------------------------------------------------------------
      */
      const fetchUserData = (url = null) => {
        window.axios.post(url || fetchProfileUrl.value, form.value)
          .then(({ data }) => {
            form.value.toggle_email_blast = data.item.toggle_email_blast ? true : false;
            form.value.toggle_push_notif = data.item.toggle_push_notif ? true : false;
            form.value.toggle_sms_blast = data.item.toggle_sms_blast ? true : false;
            store.commit('user/setData', data.item);
            localStorage.value.set('user', data.item);
          })
          .catch(({ response }) => {
            if(response.status == 401) logout();
            else {
              errorMessage.value = response.data.message;
              openErrorModal();
            }
          });
      };

      const submit = () => {
        loader.value = true;
        window.axios.post(updatePreferenceUrl.value, form.value)
          .then(() => {
            openModal();
            fetchUserData();
            loader.value = false;
          })
          .catch(({ response }) => {
            loader.value = false;
            errorMessage.value = response.data.message;
            openErrorModal();
          });
      };

      watch(
        () => fetchProfileUrl.value,
        (url) => {
          if(typeof url === 'string') fetchUserData(url);
        },
        { immediate: true }
      );

    return {
      form,
      loader,
      submit
    }
  }
}
</script>