<template>
  <div
    id="hdr-cartCon"
    class="hdr-cartCon bg--white md:px-[40px] px-4 py-7"
    :class="cartSideStatus ? 'active' : ''"
  >
    <div
      class="hdr-cartTopCon flex flex-wrap align-center justify-between mb-5"
    >
      <h4 class="text-xl text-[#343A40] font-bold">
        Cart ({{ cartItems.length }})
      </h4>
      <img
        src="/assets/icon/ic-close.svg"
        alt="close-icon"
        width="20"
        height="20"
        class="object-contain block cursor-pointer hdr-cart-close"
        @click="sideCloseCart"
      />
    </div>
    <hr />
    <div
      class="flex lg:flex-wrap flex-nowrap mt-6 mb-5 lg:overflow-x-hidden lg:whitespace-normal overflow-x-auto whitespace-nowrap lg:pb-0 pb-4"
    >
      <div class="md:block inline-block">
        <ion-button
          @click="selectTab('all')"
          shape="round"
          class="button tight mr-3 btn-primary"
          :class="selectedTab == 'all' ? 'text--white' : ''"
          :fill="selectedTab !== 'all' ? 'outline' : ''"
        >
          All
        </ion-button>
      </div>
      <div class="md:block inline-block">
        <ion-button
          @click="selectTab('one-time')"
          shape="round"
          class="button tight mr-3 btn-primary"
          :class="selectedTab == 'one-time' ? 'text--white' : ''"
          :fill="selectedTab !== 'one-time' ? 'outline' : ''"
        >
          One-time Purchase
        </ion-button>
      </div>
      <div class="md:block inline-block">
        <ion-button
          @click="selectTab('subscription')"
          shape="round"
          class="button tight mr-3 btn-primary"
          :class="selectedTab == 'subscription' ? 'text--white' : ''"
          :fill="selectedTab !== 'subscription' ? 'outline' : ''"
        >
          Subscription
        </ion-button>
      </div>
    </div>
    <div class="hdr-cartMidCon">
      <!-- One Time -->
      <template v-if="selectedTab == 'one-time' || selectedTab == 'all'">
        <h5 class="font-bold text--secondary">
          One Time Purchase
          <span v-if="oneTimePuchaseItems.length"
            >({{ oneTimePuchaseItems.length }})</span
          >
        </h5>
        <div class="hdr-cartList mt-3">
          <template
            v-for="(item, index) in oneTimePuchaseItems"
            :key="`cart-nav-one-time-${index}-${item.updated_at}`"
          >
            <CartItem
              :item="item"
              disable-replacement
              disable-input
              class="hdr-cartItems"
              :is-readonly-input="true"
              @refresh="fetchCart"
              :id="`cart-time-${index}-${item.updated_at}`"
            />
          </template>
          <div v-if="selectedTab == 'one-time'" class="hdr-cartBotCon mt-5 w-full bg-white pt-4 border-t pr-5">
              <div class="flex justify-between mb-4">
                <h5 class="text-xl font-bold text--gray--800">Total</h5>
                <h5 class="text-xl font-bold text--gray--800">
                  {{ moneyFormat(total, 2, "₱") }}
                </h5>
              </div>
              <div class="flex align-items-center">
                <ion-button
                  class="btn-primary w-full mr-3"
                  shape="round"
                  size="large"
                  fill="outline"
                  href="/my-cart"
                >
                  View Cart
                </ion-button>
                <ion-button
                  class="btn-primary w-full"
                  shape="round"
                  size="large"
                  @click="redirectCheckout"
                  :disabled="total == 0"
                >
                  Checkout Now
                </ion-button>
              </div>
            </div>
        </div>
        <hr v-if="selectedTab == 'all'" class="my-4" />
      </template>
      <!-- Subscription -->
      <template v-if="selectedTab == 'subscription' || selectedTab == 'all'">
        <h5 class="font-bold text--secondary">
          Subscription
          <span v-if="subscriptionItems.length"
            >({{ subscriptionItems.length }})</span
          >
        </h5>
        <div class="hdr-cartList mt-3">
          <template
            v-for="(item, index) in subscriptionItems"
            :key="`cart-nav-subscription-${index}-${item.updated_at}`"
          >
            <CartItem
              :item="item"
              is-subscription
              disable-replacement
              disable-input
              class="mb-7 hdr-cartItems"
              :is-readonly-input="true"
              @refresh="fetchCart"
            />
          </template>
        </div>
        
        <div class="hdr-cartBotCon mt-5 w-full bg-white pt-4 border-t pr-5">
          <div class="flex justify-between mb-4">
            <h5 class="text-xl font-bold text--gray--800">Total</h5>
            <h5 class="text-xl font-bold text--gray--800">
              {{ moneyFormat(total, 2, "₱") }}
            </h5>
          </div>
          <div class="flex align-items-center">
            <ion-button
              class="btn-primary w-full mr-3"
              shape="round"
              size="large"
              fill="outline"
              href="/my-cart"
            >
              View Cart
            </ion-button>
            <ion-button
              class="btn-primary w-full"
              shape="round"
              size="large"
              @click="redirectCheckout"
            >
              Checkout Now
            </ion-button>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script type="text/javascript">
import { ref, defineComponent, computed, watch, onMounted } from "vue";
import { useStore } from "vuex";
import useCart from "@/composables/useCart";
import useFormat from "@/composables/useFormat";
import useLocalStorage from "@/composables/useLocalStorage";

import { IonButton } from "@ionic/vue";
import CartItem from "@/views/cart/components/CartItem.vue";

export default defineComponent({
  name: "CartSideNavigation",
  components: {
    IonButton,
    CartItem,
  },
  props: {
    isCartActive: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["close-side-cart"],
  setup(props, { emit }) {
    const store = useStore();
    const { fetchCart, cartIsLoading, cartItemType } = useCart();
    const { makeGuestId, moneyFormat } = useFormat();
    const { localStorage } = useLocalStorage();

    /*
    |--------------------------------------------------------------------------
    | @Data
    |--------------------------------------------------------------------------
    */

    const selectedTab = ref("all");
    const cartItems = computed(() => store.state.cart.items);

    const total = computed(() =>
      cartItems.value.reduce((a, x) => a + parseFloat(x.subtotal), 0)
    );
    const oneTimePuchaseItems = computed(() =>
      cartItems.value.filter(
        (x) => x.type == cartItemType.value.ONE_TIME_PURCHASE
      )
    );
    const subscriptionItems = computed(() =>
      cartItems.value.filter((x) => x.type == cartItemType.value.SUBSCRIPTION)
    );

    const fetchCartUrl = computed(() => {
      return store.state.user.token
        ? store.state.api.routes.cart
        : store.state.api.routes.guestCart;
    });

    const payload = computed(() => ({
      branchId: store.state.address.branchId,
      sessionId: store.state.user.guestId,
    }));

    const cartSideStatus = computed(() => props.isCartActive);

    /*
    |--------------------------------------------------------------------------
    | @Methods
    |--------------------------------------------------------------------------
    */

    const sideCloseCart = () => {
      emit("close-side-cart");

      // var element = document.getElementById("hdr-cartCon");
      // var element2 = document.getElementById("cart-side-navigation__overlay");
      // element.classList.toggle("active");
      // element2.classList.toggle("active");
    };

    const selectTab = (val) => {
      selectedTab.value = val;
    };

    const redirectCheckout = () => {
      if(store.state.user.token) window.location.href = '/checkout';
      else window.location.href = '/login?user=guest';
    };

    watch(
      () => {
        return (
          typeof fetchCartUrl.value === "string" &&
          payload.value.branchId &&
          (store.state.user.token || payload.value.sessionId)
        );
      },
      (bool) => {
        if (bool) {
          cartIsLoading.value = false;
          fetchCart();
        }
      },
      { immediate: true }
    );

    onMounted(async () => {
      const id = (await localStorage.value.get("guestId")) || makeGuestId(20);
      const token = await localStorage.value.get("authToken");

      store.commit("user/setGuestId", id);
      store.commit("user/setToken", token);

      if (id) localStorage.value.set("guestId", id);
    });

    return {
      total,
      cartItems,
      oneTimePuchaseItems,
      subscriptionItems,
      sideCloseCart,
      selectTab,
      selectedTab,
      moneyFormat,
      fetchCart,
      cartSideStatus,
      redirectCheckout
    };
  },
});
</script>
