<template>
  <ion-page>
    <top-navigation />
    <ion-content
      :fullscreen="true"
      class="ion-padding"
    >
      <account-side-navigation>
        <slot>
          <div class="mb-4 space-y-4">
            <h4 class="text--gray--900 font-bold">
              Account Details
            </h4>
            <div class="flex items-center justify-between">
              <p class="text--secondary text-xl font-bold">
                Address Book
              </p>
              <div class="flex items-center justify-end gap-3">
                <!-- <ion-button
                  fill="clear"
                  class="ion-no-margin no-ripple btn-link"
                  @click="toggleEdit"
                >
                  <span class="text--gray--200 text-base font-normal">
                    <template v-if="isEdit">Delete</template>
                    <template v-else>Edit</template>
                  </span>
                </ion-button> -->
                <ion-button
                  fill="clear"
                  class="ion-no-margin no-ripple btn-link"
                  href="/address-book/edit"
                >
                  <span class="text--primary text-base font-normal">Add New Address</span>
                </ion-button>
              </div>
            </div>
          </div>
          <div class="w-full">
            <form action="">
              <template v-if="items.length">
                <address-card
                  v-for="item in items"
                  :key="`address-${item.id}`"
                  :id="item.id"
                  :address-name="item.name"
                  :name="item.user_full_name"
                  :mobile-number="item.mobile_number"
                  :address="item.full_address"
                  :href="`/address-book/edit?i=${item.id}`"
                  :is-edit="isEdit"
                  :is-default="item.is_default"
                  @add="addItem"
                  @remove="removeItem"
                />
              </template>
              <template v-else>
                No addresses found.
              </template>
              <div>
                <ion-button
                  v-if="isEdit"
                  class="btn-primary"
                  shape="round"
                  size="large"
                  fill="outline"
                  @click="destroy"
                >
                  Delete
                </ion-button>
              </div>
            </form>
          </div>
        </slot>
      </account-side-navigation>
      <bottom-navigation />
    </ion-content>
  </ion-page>
</template>
<script type="javascript">

import { ref } from '@vue/reactivity';
import { computed, watch } from '@vue/runtime-core';
import { useStore } from 'vuex';
import useLocalStorage from '@/composables/useLocalStorage';

import { IonButton, IonPage, IonContent } from '@ionic/vue';
import BottomNavigation from '@/partials/BottomNavigation.vue';
import TopNavigation from '@/partials/TopNavigation.vue';
import AccountSideNavigation from '@/partials/AccountSideNavigation.vue';
import AddressCard from '../components/AddressCard.vue';

export default {
  name: 'AddressBookIndex',
  components: {
    IonButton,
    AddressCard,
    IonContent, 
    IonPage, 
    BottomNavigation, 
    TopNavigation, 
    AccountSideNavigation,
  },
  setup() {

    /*
    |--------------------------------------------------------------------------
    | @Data
    |--------------------------------------------------------------------------
    */

    const store = useStore();
    const { localStorage } = useLocalStorage();

    const isEdit = ref(false);
    const items = ref([]);
    const ids = ref([]);

    /*
    |--------------------------------------------------------------------------
    | @Computed
    |--------------------------------------------------------------------------
    */

    const fetchAddressUrl = computed(() => store.state.api.routes.fetchAddress);
    const deleteAddressUrl = computed(() => store.state.api.routes.deleteAddress);

    /*
    |--------------------------------------------------------------------------
    | @Methods
    |--------------------------------------------------------------------------
    */

    const toggleEdit = () => {
      isEdit.value = !isEdit.value;
    };

    const addItem = (id) => {
      ids.value.push(id);
    };

    const removeItem = (id) => {
      const index = ids.value.findIndex(x => x.id == id);
      ids.value.splice(index, 1);
    };

    const fetch = (url) => {
      window.axios.post(url || fetchAddressUrl.value)
        .then(({ data }) => {
          items.value = data.items;

          if(data.items.length) {
            const item = data.items.find(x => x.is_default) || data.items[0] || {};
            store.commit('address/setForm', {
              province: item.province_code,
              province_label: item.province,
              city: item.city_code,
              city_label: item.city,
              barangay: item.barangay_code,
              barangay_label: item.barangay,
              latitude: item.latitude,
              longitude: item.longitude,
              street: item.street,
              unitNo: item.unit_no,
              zipCode: item.zip_code,
            });
            localStorage.value.set('location', store.state.address.form);
          }
        })
        .catch(({ response }) => {
          console.log(response);
        });
    };

    const destroy = () => {
      window.axios.post(deleteAddressUrl.value, { addressIds: ids.value })
        .then(() => {
          fetch();
          toggleEdit();
        })
        .catch(({ response }) => {
          console.log(response);
        });
    };

    /*
    |--------------------------------------------------------------------------
    | @Watch
    |--------------------------------------------------------------------------
    */

    watch(
      () => fetchAddressUrl.value,
      (url) => {
        if(typeof url === 'string') fetch(url);
      },
      { immediate: true }
    );

    /*
    |--------------------------------------------------------------------------
    | @Return
    |--------------------------------------------------------------------------
    */

    return {
      toggleEdit,
      addItem,
      removeItem,
      destroy,
      isEdit,
      items
    }
  }
}
</script>