<template>
<div class="flex flex-col items-center justify-center">
    <div class="mb-9 w-full">
        <label
          for="email"
          class="sr-only"
        >Email</label>
        <input
            v-model="form.email"
            type="email"
            name="email"
            id="email"
            class="focus:ring-[#02adf0] focus:border-[#02adf0] block w-full border-gray-300 placeholder-gray-400 rounded-[10px] h-12"
            placeholder="you@example.com"
        >
    </div>
    <ion-button
    size="large"
    class="btn-light"
    @click="submit"
    :disabled="loader"
    >
        <span class="text-[#02ADF0]">Submit</span>
    </ion-button>
  <Loader v-show="loader" />
</div>
</template>
<script type="text/javascript">
import { defineComponent, onMounted, ref } from 'vue';
import { IonButton, modalController } from '@ionic/vue';
import { useStore } from 'vuex';
import Modal from '@/components/Modal.vue';
import Loader from '@/components/PageLoader.vue';

export default defineComponent({
  name: 'NewsletterClientsForm',
  components: { 
    IonButton, 
    Loader,
  },
  setup() {
    const store = useStore();

    const form = ref({});

    const loader = ref(false);
    const errors = ref(null);

    const openSuccessModal = async () => {
      const modal = await modalController.create({
              component: Modal,
              cssClass: 'gen-modal',
              componentProps: {
              title: 'Success!',
              description: 'Thank you for reaching us.',
              imageLink: '/assets/icon/ic-check.svg',
              closeModalButton: true,
              imageWidth: '28',
              imageHeight: '28',
              full: 'full'
          }
      });
      return modal.present();
    };

    /* Modal */
    const title = ref('Oops!');
    const message = ref('Something went wrong.');
    const buttonText = ref('Close');
    const imageLink = ref('/assets/icon/ic-warning.svg');
    const openErrorModal = async () => {
      const modal = await modalController.create({
              component: Modal,
              cssClass: 'gen-modal',
              componentProps: {
              title: title.value,
              description: message.value,
              buttonText: buttonText.value,
              imageLink: imageLink.value,
              closeModalButton: true,
              imageWidth: '28',
              imageHeight: '28',
              full: 'full'
          }
      });
      return modal.present();
    };
    
    const submit = () => {
      loader.value = true;
      window.axios
        .post(store.state.api.routes.newsletterClientsSubmit, form.value)
        .then(({ data }) => {
          form.value = {};
          message.value = data.message;
          loader.value = false;

          openSuccessModal();
        }).catch((error) => {
            loader.value = false;
            message.value = null;
            errors.value = null;
            if(error) {
                message.value = error.response.data.error;
            }
            if(error.response.data.message) {
                message.value = error.response.data.message;
            }
            if(error.response.data.errors) {
                errors.value = error.response.data.errors;
            }
            openErrorModal()
        });
    };

    onMounted(() => {

    });

    return {
      form,
      submit,
      loader,
      errors,
    }
  }
})
</script>