<template>
  <header class="hdr-frame">
    <div class="hdr-topCon">
      <div class="text-center w-11/12 mx-auto lg:block flex">
        <template v-if="!isMobile">
          <p class="text--white text-sm">
            {{  getCmsContent('header_reminderText')
              || 'Free delivery to key areas to nationwide for orders P500 and above!' }}
          </p>
        </template>
        <template v-else>
          <marquee direction="left">
            <span class="text-white lg:text-sm text-xs">{{  getCmsContent('header_reminderText')
              || 'Free delivery to key areas to nationwide for orders P500 and above!' }}</span>
          </marquee>
        </template>
      </div>
      <GoogleTranslator />
    </div>
    <div class="hdr-botCon py-3 lg:border-0 border-b">
      <div class="w-11/12 mx-auto flex lg:flex-wrap flex-nowrap items-center justify-between">
        <div class="hdr-logoCon">
          <a href="/">
            <img
              id="brand-logo"
              :src="getCmsImage('generic_brandLogo') || '/assets/image/Logo.png'"
              alt="natures-spring-logo"
              width="150"
              class="transition-all object-contain block lg:w-[150px] w-[100px]"
            >
          </a>
        </div>
        <div
          v-if="isMobile"
          class="flex items-center justify-between"
        >
          <CartHeaderIcon @open-side-cart="sideCart" />
          <img
            src="/assets/icon/ic-hamburger.svg"
            class="h-5 w-7 ml-4"
            
            @click="openMenu"
          >
        </div>
        <div
          class="hdr-menuCon flex lg:flex-row flex-col flex-wrap lg:items-center items-start lg:border-0 border-r"
          id="hdr-menuCon"
        >
          <div class="hdr-menuList flex lg:flex-row flex-col flex-wrap lg:items-center item-start lg:w-auto w-full">
            <!-- Add Active on current page -->
            <template v-if="isMobile">
              <div class="p-6">
                <div class="mt-1 relative rounded-lg shadow-sm">
                  <input
                    type="text"
                    name="search"
                    id="search"
                    class="focus:ring-[#02ADF0] focus:border-[#02ADF0] block w-full pr-10 sm:text-sm border-gray-300 rounded-lg"
                    placeholder="Search"
                    v-model="searchInput"
                    @keyup.enter="search"
                  >
                  <div class="absolute inset-y-0 right-0 z-10 pr-3 flex items-center pointer-events-none">
                    <img
                      src="/assets/icon/ic-search-grey.svg"
                      class="h-5 w-5"
                    >
                  </div>
                </div>
              </div>
            </template>
            <a
              v-if="!isCheckout"
              href="/"
              class="hdr-menuLink uppercase lg:text-sm text-base lg:py-0 py-2 lg:pl-0 pl-5"
              :class="{ active: isActive == '/' }"
            >Home</a>
            <a
              v-if="!isCheckout"
              href="/shop"
              class="hdr-menuLink uppercase lg:text-sm text-base lg:py-0 py-2 lg:pl-0 pl-5"
              :class="{ active: isActive == '/shop' }"
            >Shop</a>
            <a
              v-if="!isCheckout"
              href="/faqs"
              class="hdr-menuLink uppercase lg:text-sm text-base lg:py-0 py-2 lg:pl-0 pl-5"
              :class="{ active: isActive == '/faqs' }"
            >Faqs</a>
            <a
              v-if="!isCheckout"
              href="/contact-us"
              class="hdr-menuLink uppercase lg:text-sm text-base lg:py-0 py-2 lg:pl-0 pl-5"
              :class="{ active: isActive == '/contact-us' }"
            >Contact Us</a>
          </div>
          <hr
            v-if="isMobile"
            class="my-4 w-full px-5"
          >
          <template v-if="isMobile">
            <div
              @click="openMenu"
              class="absolute top-0 right-0 p-3"
            >
              <img
                src="/assets/icon/ic-close-empty-field.svg"
                class="h-6 w-6"
              >
            </div>
          </template>
          
          <!-- Current Location Label -->
          <LocationBar />

          <div v-if="!isCheckout" class="hdr-shortcutCon flex lg:flex-row flex-col flex-wrap lg:items-center items-start">
            <p
              v-if="!isMobile"
              class="hdr-shortcutLink mr-7"
              @click="searchButton"
            >
              <!-- <img
                src="/assets/icon/ic-search.svg"
                alt="search-icon"
                width="20"
                height="20"
                class="object-contain block"
              > -->
              <svg width="20" height="20" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.5003 17.9998L13.762 14.2548L17.5003 17.9998ZM15.8337 9.24984C15.8337 11.1285 15.0874 12.9301 13.759 14.2585C12.4306 15.5869 10.6289 16.3332 8.75033 16.3332C6.87171 16.3332 5.07004 15.5869 3.74165 14.2585C2.41327 12.9301 1.66699 11.1285 1.66699 9.24984C1.66699 7.37122 2.41327 5.56955 3.74165 4.24116C5.07004 2.91278 6.87171 2.1665 8.75033 2.1665C10.6289 2.1665 12.4306 2.91278 13.759 4.24116C15.0874 5.56955 15.8337 7.37122 15.8337 9.24984V9.24984Z" stroke="#A9B5C6" stroke-width="1.15" stroke-linecap="round"/>
              </svg>
            </p>
            
            <WishlistHeaderIcon v-if="isAuthenticated" :is-mobile="isMobile" />

            <CartHeaderIcon v-if="!isMobile" @open-side-cart="sideCart" class="mr-7 text-gray-400"/>

            <template v-if="!isMobile">
              <a
                :href="userRoute"
                class="hdr-shortcutLink mr-7"
              >
                <!-- <img
                  src="/assets/icon/ic-account.svg"
                  alt="account-icon"
                  width="25"
                  height="25"
                  class="object-contain block"
                > -->
                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.5 21.875C10.0136 21.875 7.62903 20.8873 5.87087 19.1291C4.11272 17.371 3.125 14.9864 3.125 12.5V12.5C3.125 10.0136 4.11272 7.62903 5.87087 5.87087C7.62903 4.11272 10.0136 3.125 12.5 3.125V3.125C14.9864 3.125 17.371 4.11272 19.1291 5.87087C20.8873 7.62903 21.875 10.0136 21.875 12.5V12.5C21.875 14.9864 20.8873 17.371 19.1291 19.1291C17.371 20.8873 14.9864 21.875 12.5 21.875V21.875ZM12.5 7.29167C11.6759 7.29167 10.8703 7.53604 10.1851 7.99388C9.49992 8.45172 8.96587 9.10246 8.6505 9.86382C8.33514 10.6252 8.25262 11.463 8.41339 12.2712C8.57417 13.0795 8.971 13.8219 9.55372 14.4046C10.1364 14.9873 10.8789 15.3842 11.6871 15.5449C12.4954 15.7057 13.3332 15.6232 14.0945 15.3078C14.8559 14.9925 15.5066 14.4584 15.9645 13.7732C16.4223 13.088 16.6667 12.2824 16.6667 11.4583C16.6667 10.3533 16.2277 9.29346 15.4463 8.51205C14.6649 7.73065 13.6051 7.29167 12.5 7.29167ZM14.7604 14.9479C14.0891 15.3893 13.3034 15.6244 12.5 15.6244C11.6966 15.6244 10.9109 15.3893 10.2396 14.9479C9.24766 15.2701 8.33639 15.8013 7.56728 16.5058C6.79818 17.2102 6.18913 18.0714 5.78125 19.0312C6.6471 19.9208 7.681 20.6296 8.82295 21.1164C9.9649 21.6032 11.1922 21.8584 12.4335 21.8671C13.6749 21.8758 14.9056 21.6379 16.0543 21.1672C17.203 20.6965 18.2467 20.0023 19.125 19.125L19.2188 19.0312C18.8109 18.0714 18.2018 17.2102 17.4327 16.5058C16.6636 15.8013 15.7523 15.2701 14.7604 14.9479V14.9479Z" stroke="#A9B5C6" stroke-width="1.15" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </a>
            </template>
            <template v-else>
              <a
                :href="userRoute"
                class="hdr-shortcutLink mr-7 pl-5 py-2 border-l-[5px] border-transparent text-base"
              >
                LOGIN
              </a>
            </template>
          </div>
        </div>
      </div>
    </div>

    <!-- Search Contianer -->
    <div
      id="hdr-searchCon"
      class="hdr-searchCon py-10"
    >
      <input
        type="text"
        name="search"
        placeholder="What are you looking for?"
        v-model="searchInput"
        @keyup.enter="search"
      >
      <button @click="search">
        <img
          src="/assets/icon/ic-right-arrow.svg"
          alt="arrow-right"
          width="35"
          height="35"
          class="object-contain block"
        >
      </button>
    </div>
    <div class="hdr-overlay" @click="searchButton" />
    <div
      id="cart-side-navigation__overlay"
      class="cart-side-navigation__overlay"
      :class="isCartActive ? 'active' : ''"
      @click="closeSideCart"
    />

    <CartSideNavigation :isCartActive="isCartActive" @close-side-cart="closeSideCart" />

  </header>
</template>
<script type="text/javascript">
import { ref, defineComponent, watch, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import useAuth from '@/composables/useAuth';
import useCms from "@/composables/useCms";
import useLocalStorage from "@/composables/useLocalStorage";

import GoogleTranslator from '@/components/GoogleTranslator';
import WishlistHeaderIcon from './WishlistHeaderIcon.vue';
import CartHeaderIcon from './CartHeaderIcon.vue';
import CartSideNavigation from './CartSideNavigation.vue';
import LocationBar from './LocationBar.vue';

export default defineComponent({
  name: 'TopNavigation',
  components: {  
    WishlistHeaderIcon,
    CartHeaderIcon,
    CartSideNavigation,
    LocationBar,
    GoogleTranslator,
  },
  data: () => ({
    isMobile: false,
    // showMenu: false,
  }),
  mounted() {
    if (window.innerWidth < 1024) {
      this.isMobile = true;
    }
  },
  methods: {
    // openMenu() {
    //   this.showMenu = !this.showMenu;
    // }
  },
  setup() {

    const router = useRouter();

    const { isAuthenticated } = useAuth();
    const { localStorage } = useLocalStorage();
    const { contentSlug, getCms, getCmsContent, getCmsImage } = useCms();
    
    const userRoute = ref('/login');
    const searchInput = ref(null);

    const isCartActive = ref(false);
    const isCheckout = ref(false);

    localStorage.value.get('user').then(result => {
      if(result) userRoute.value = '/profile';
    });

    function openMenu() {
      var element = document.getElementById("hdr-menuCon");
      element.classList.toggle("open");
    }

    function searchButton() {
      var element = document.getElementById("hdr-searchCon");
      element.classList.toggle("active");
    }

    function sideCart() {
      isCartActive.value = true;

      // var element = document.querySelector("#hdr-cartCon");
      // var element2 = document.querySelector("#cart-side-navigation__overlay");
      // element.classList.toggle("active");
      // element2.classList.toggle("active");
    }

    function closeSideCart() {
      isCartActive.value = false;

      // var element = document.getElementById("hdr-cartCon");
      // var element2 = document.getElementById("cart-side-navigation__overlay");
      // element.classList.toggle("active");
      // element2.classList.toggle("active");
    }

    const isActive = ref(window.location.pathname);

    const search = () => {
      window.location.href = `/shop?search=${searchInput.value}`;
    };

    watch(
      () => router.currentRoute.value.query.search,
      (search) => {
        if(search) searchInput.value = search;
      }
    );

    onMounted(() => {
      contentSlug.value = 'header_footer';
      getCms(contentSlug.value);
      
      if (router.currentRoute.value.name == 'checkout') {
        isCheckout.value = true;
      }
    });

    return {
      searchInput,
      searchButton,
      sideCart,
      closeSideCart,
      isActive,
      userRoute,
      getCmsContent,
      getCmsImage,
      search,
      isAuthenticated,
      openMenu,
      isCartActive,
      isCheckout
    }
  }
})
</script>
<style lang="scss" scoped>
  
  .cart-side-navigation__overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,0.25);
    display: none;

    &.active {
      display: block;
    }
  }
</style>