<template>
  <ion-page>
    <top-navigation />
    <ion-content
      :fullscreen="true"
      class="ion-padding"
    >
      <account-side-navigation>
        <slot>
          <section>
            <template v-if="!isMobile">
              <div class="mb-4 flex items-center justify-between">
                <h4 class="text--gray--900 font-bold">
                  Order History
                </h4>
                <p class="font-bold text--gray--900 flex items-center justify-center">
                  <img
                    src="/assets/icon/ic-water-drop.svg"
                    class="w-5 h-5 mr-2"
                  >
                  Your Points: <span class="ml-2">{{ points }} Droplet/s</span>
                </p>
              </div>
            </template>
            <div class="flex items-center justify-between mb-8">
              <div class="flex flex-nowrap items-center lg:overflow-x-hidden lg:whitespace-normal overflow-x-auto whitespace-nowrap lg:pb-0 pb-4">
                <!-- Remove fill attribute if selected -->
                <div 
                  class="md:block inline-block"
                  v-for="tab in tabs"
                  :key="`order-history-tab-${tab.id}`"
                >
                  <ion-button
                    shape="round"
                    class="button tight mr-2.5 m-0 btn-primary"
                    @click="selectTab(tab.id)"
                    :class="getClass(tab.id, 'text--white')"
                    :fill="getClass(tab.id, '', 'outline')"
                  >
                    <span class="text-sm font-normal">{{ tab.name }}</span>
                    <span 
                      class="
                        w-5 
                        h-5 
                        bg--red--400 
                        rounded-full 
                        text-white
                        text-xs
                        flex
                        items-center
                        justify-center
                        ml-2"
                      v-if="getInvoicesByTab(tab.id).length > 0"
                    >
                      {{ getInvoicesByTab(tab.id).length }}
                    </span>
                  </ion-button>
                </div>
              </div>
              <template v-if="!isMobile">
                <div>
                  <div class="relative rounded-md">
                    <input
                      type="text"
                      name="account-number"
                      id="account-number"
                      class="focus:ring-[#02ADF0] focus:border-[#02ADF0] block w-full pr-10 border-gray-300 placeholder-gray-400 rounded-[10px] h-12"
                      placeholder="Search ..."
                      v-model="searchInput"
                    >
                    <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                      <SearchIcon
                        class="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </div>
                  </div>
                </div>
              </template>
            </div>
            <div class="flex lg:flex-row flex-col items-start lg:gap-0 gap-5">
              <template v-if="isMobile">
                <p class="font-bold text--gray--900 flex items-center justify-center">
                  <img
                    src="/assets/icon/ic-water-drop.svg"
                    class="w-5 h-5 mr-2"
                  >
                  Your Points: <span class="ml-2">{{ points }} Droplet/s</span>
                </p>
                <div class="w-full">
                  <div class="mt-1 relative rounded-md shadow-sm">
                    <input
                      type="text"
                      name="account-number"
                      id="account-number"
                      class="focus:ring-[#02ADF0] focus:border-[#02ADF0] block w-full pr-10 sm:text-sm border-gray-300 rounded-md"
                      placeholder="Search ..."
                      v-model="searchInput"
                    >
                    <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                      <SearchIcon
                        class="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </div>
                  </div>
                </div>
              </template>
              <ion-select
                v-model="selectedFilter"
                interface="popover"
                class="frm-select mr-4 lg:w-auto w-full"
                placeholder="Filter: --"
              >
                <ion-select-option 
                  v-for="filter in filters"
                  :key="`order-history-filter-option-${filter.id}`"
                  :value="filter.id"
                >
                  {{ filter.name }}
                </ion-select-option>
              </ion-select>
            </div>
          </section>

          <section class="mt-6">
            <order-history-card
              v-for="invoice in filteredInvoices"
              :key="`invoice-${invoice.id}`"
              :item="invoice"
            />
          </section>
        </slot>
      </account-side-navigation>
      <bottom-navigation />
    </ion-content>
    <Loader v-show="isLoading" />
  </ion-page>
</template>
<script type="text/javascript">

import { ref, watch, defineComponent, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import useAuth from '@/composables/useAuth';
import useEnum from '@/composables/useEnum';
import useLocalStorage from '@/composables/useLocalStorage';

import {
  IonButton,
  IonPage,
  IonContent,
  IonSelect,
  IonSelectOption,
} from '@ionic/vue';
import { SearchIcon } from '@heroicons/vue/outline';
import BottomNavigation from '@/partials/BottomNavigation.vue';
import TopNavigation from '@/partials/TopNavigation.vue';
import AccountSideNavigation from '@/partials/AccountSideNavigation.vue';
import OrderHistoryCard from './components/OrderHistoryCard.vue';
import Loader from '@/components/PageLoader.vue';

export default defineComponent({
  name: 'OrderHistoryIndex',
  components: {
    IonContent, 
    IonPage,
    IonSelect,
    IonSelectOption,
    IonButton,
    BottomNavigation, 
    TopNavigation, 
    AccountSideNavigation,
    SearchIcon,
    OrderHistoryCard,
    Loader
  },
  setup() {

    const store = useStore();
    const { logout } = useAuth();
    const { getStatusValue } = useEnum();
    const { localStorage } = useLocalStorage();

    /*
    |--------------------------------------------------------------------------
    | @Data
    |--------------------------------------------------------------------------
    */

    const tabs = [
      { id: 'all', name: 'All' },
      { id: 'to-pay', name: 'To Pay' },
      { id: 'to-ship', name: 'To Ship' },
      { id: 'to-receive', name: 'To Receive' },
      { id: 'completed', name: 'Completed' },
      { id: 'cancelled', name: 'Cancelled' },
      // { id: 'refunded', name: 'Refunded' },
    ];

    const filters = [
      { id: 'all', name: 'All' },
      { id: 'one-time', name: 'One-Time Purchase' },
      { id: 'subscription', name: 'Subscription' },
    ];

    const isMobile = ref(false);
    const selectedTab = ref('all');
    const selectedFilter = ref('all');
    const searchInput = ref(null);
    const invoices = ref([]);
    const isLoading = ref(false);

    const points = computed(() => store.state.user.data.points);
    const fetchInvoiceUrl = computed(() => store.state.api.routes.fetchInvoices);

    const filteredInvoices = computed(() => {
      let items = getInvoicesByTab(selectedTab.value);
      /* Filter by Group Type */
      switch(selectedFilter.value) {
        case 'one-time': items = items.filter(x => x.group_type == 'App\\Models\\Carts\\Cart'); break;
        case 'subscription': items = items.filter(x => x.group_type == 'App\\Models\\Subscriptions\\Subscription'); break;
      }
      /* Filter by Search */
      if(searchInput.value) {
        items = items.filter(x => {
          return x.formatted_order_status.toUpperCase().indexOf(searchInput.value.toUpperCase()) !== -1
            || x.formatted_payment_status.toUpperCase().indexOf(searchInput.value.toUpperCase()) !== -1
            || x.items[0].product_name.toUpperCase().indexOf(searchInput.value.toUpperCase()) !== -1;
        })
      }
      return items;
    });

    /*
    |--------------------------------------------------------------------------
    | @Methods
    |--------------------------------------------------------------------------
    */

    const getClass = (tab, resolve, reject = '') => {
      return selectedTab.value == tab ? resolve : reject;
    };

    const getInvoicesByTab = (tab) => {
      let items = invoices.value;
      /* Filter by Status */
      switch(tab) {
        case 'to-pay': 
          items = items.filter(x => 
            x.payment_status == getStatusValue('paymentStatuses', 'UNPAID') &&
            x.order_status != getStatusValue('orderStatuses', 'CANCELLED') &&
            x.order_status != getStatusValue('orderStatuses', 'COMPLETED') &&
            x.order_status != getStatusValue('orderStatuses', 'REFUNDED') 
          ); 
          break;

        case 'to-ship': 
          items = items.filter(x => {
            const statuses = [ 
              getStatusValue('orderStatuses', 'ASSIGNED'), 
              getStatusValue('orderStatuses', 'CONFIRMED') 
            ];
            return statuses.includes(x.order_status);
          }); 
          break;

        case 'to-receive': 
          items = items.filter(x => {
            return x.order_status == getStatusValue('orderStatuses', 'READY')
              || x.order_status == getStatusValue('orderStatuses', 'IN_TRANSIT');
          }); 
          break;

        case 'completed': 
          items = items.filter(x => x.order_status == getStatusValue('orderStatuses', 'COMPLETED')); 
          break;

        case 'cancelled': 
          items = items.filter(x => x.order_status == getStatusValue('orderStatuses', 'CANCELLED')); 
          break;

        case 'refunded': 
          items = items.filter(x => x.order_status == getStatusValue('orderStatuses', 'REFUNDED')); 
          break;
      }
      return items;
    };

    const selectTab = (tab) => {
      selectedTab.value = tab;
    }

    const fetch = (url) => {
      isLoading.value = true;
      window.axios.post(url || fetchInvoiceUrl.value)
        .then(({ data }) => {
          isLoading.value = false
          invoices.value = data.items;
          store.commit('user/setData', { points: data.points });
          localStorage.value.set('invoices', data.items);
          localStorage.value.set('user', store.state.user.data);
        })
        .catch(({ response }) => {
          isLoading.value = false
          if(response.status == 401) logout();
        });
    };

    /*
    |--------------------------------------------------------------------------
    | @Watch
    |--------------------------------------------------------------------------
    */

    watch(
      () => fetchInvoiceUrl.value,
      (url) => {
        if(typeof url === 'string') fetch();
      }
    );

    onMounted(() => {
      if(window.innerWidth < 1024) isMobile.value = true;
    });

    return {
      isMobile,
      tabs,
      filters,
      selectedTab,
      selectedFilter,
      searchInput,
      points,
      filteredInvoices,
      getClass,
      selectTab,
      getInvoicesByTab,
      isLoading
    }
  }
})
</script>