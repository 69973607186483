'use strict';

const { initPaymaya } = require('./services/Paymaya');
const { initDragonpay } = require('./services/Dragonpay');

function initializePayment(options) {
    return new Promise((resolve, reject) => {
        switch(options.paymentMethod) {
            
            case 'CASH': resolve(); break;
            
            case 'PAYMAYA': 
            case 'PAYMAYA_CREDIT_CARD': 
                initPaymaya(options)
                    .then(
                        (response) => {
                            resolve(response);
                        },
                        () => reject()
                    ); 
                break;

            case 'DRAGONPAY': initDragonpay(options).then(() => resolve()); break;
            
            default: reject();
        }
    });
}

module.exports = {
    initializePayment
};