<template>
    <div class="ion-padding p-6 px-5" :class="customClass">
        <img v-if="imageLink" class="mx-auto mb-4" :width="imageWidth" :height="imageHeight" :src="imageLink" alt="Icon">

        <p class="font-bold text-center mb-4" v-if="title">
            {{ title }}
        </p>
        
        <p class="mb-4 text-sm text-center" v-if="description">
            {{ description }}
        </p>
        
        <div class="flex space-x-4 justify-center" v-if="actionButtons">
            <div class="w-[40%]" v-if="cancelButton">
                <button-component
                fill="outline"
                size="default"
                color="medium"
                :expand="expand"
                @click="closeModal">
                    <template #content>
                        {{ cancelButtonText }}
                    </template>
                </button-component>
                
            </div>
            
            <div class="w-[40%]" v-show="!closeModalButton">
                <button-component
                v-if="buttonLink"
                :href="buttonLink"
                :class="cancelButton ? 'btn-primary' : 'btn-primary btn-min'"
                :color="buttonColor"
                size="default"
                :expand="expand">
                    <template #content>
                        {{ buttonText }}
                    </template>
                </button-component>


                <button-component
                v-else
                @click="approveModal"
                :class="cancelButton ? 'btn-primary' : 'btn-primary btn-min'"
                :color="buttonColor"
                size="default"
                :expand="expand">
                    <template #content>
                        {{ buttonText }}
                    </template>
                </button-component>
            </div>
        </div>
    </div>
</template>

<script>
import {
    modalController 
} from "@ionic/vue";
import { defineComponent } from "vue";
import ButtonComponent from '@/components/ButtonComponent.vue';

export default defineComponent({
    name: "modal-generic",
    components: { 
        ButtonComponent
    },
    props: {
        customClass: {
            type: String,
            default: null,
        },
        title: {
            type: String,
            default: null,
        },
        description: {
            type: String,
            default: null,
        },
        actionButtons: {
            type: Boolean,
            default: false,
        },
        buttonText: {
            type: String,
        },
        buttonLink: {
            type: String,
            default: null,
        },
        buttonColor: {
            type: String,
            default: "primary",
        },
        expand: {
            type: String,
            default: null,
        },
        imageLink: {
            type: String,
            default: null,
        },
        imageWidth: {
            type: String,
            default: null,
        },
        imageHeight: {
            type: String,
            default: null,
        },
        cancelButton: {
            type: Boolean,
            default: false,
        },
        cancelButtonText: {
            type: String,
            default: "Cancel",
        },
        closeModalButton: {
            type: Boolean,
            default: false,
        },
        confirmLink: { type: Function },
    },
    data() {
        return {
            content: "Content",
        };
    },
    methods: {
        async closeModal() {
            await modalController.dismiss({
                type: "cancel",
            });
        },

        async approveModal() {
            await modalController.dismiss({
                type: "approved",
            });
        },
    },
    
});
</script>
