<template>
  <ion-page>
    <top-navigation />
    <ion-content
      :fullscreen="true"
      class="ion-padding"
    >
      <div class="flex items-center justify-center pt-[85px] pb-24">
        <div class="w-[90%] md:w-[35%] mx-auto">
          <div class="ion-no-padding mb-10 md:mb-8 text-center">
            <h1 class="leading-12 font-size--xx-large font-bold text--secondary">
              Sign Up
            </h1>
          </div>

          <div class="mb-16">
            <p class="text-base text-center">
              Kindly enter the 6-digit code we sent to your email/mobile number.
            </p>
          </div>
          <div class="mb-5">
            <ion-grid>
              <ion-row>
                <ion-col size="12">
                  <div class="flex items-center justify-center gap-2 md:gap-4">
                    <!-- add active class if input has value -->
                    <input
                      v-model="codes[0]"
                      class="otp-input"
                      type="text"
                      id="input-1"
                      @input='digitValidate(1)'
                      @keyup='tabChange(1)'
                      maxlength="1"
                      autofocus
                    >
                    <input
                      v-model="codes[1]"
                      class="otp-input"
                      type="text"
                      id="input-2"
                      @input='digitValidate(2)'
                      @keyup='tabChange(2)'
                      maxlength="1"
                    >
                    <input
                      v-model="codes[2]"
                      class="otp-input"
                      type="text"
                      id="input-3"
                      @input='digitValidate(3)'
                      @keyup='tabChange(3)'
                      maxlength="1"
                    >
                    <input
                      v-model="codes[3]"
                      class="otp-input"
                      type="text"
                      id="input-4"
                      @input='digitValidate(4)'
                      @keyup='tabChange(4)'
                      maxlength="1"
                    >
                    <input
                      v-model="codes[4]"
                      class="otp-input"
                      type="text"
                      id="input-5"
                      @input='digitValidate(5)'
                      @keyup='tabChange(5)'
                      maxlength="1"
                    >
                    <input
                      v-model="codes[5]"
                      class="otp-input"
                      type="text"
                      id="input-6"
                      @input='digitValidate(6)'
                      @keyup='tabChange(6)'
                      maxlength=6
                    >
                  </div>
                  <div class="text-center" v-if="otpError">
                      <label class="text-red-600 text-xs">{{ otpError }}</label>
                  </div>
                </ion-col>
              </ion-row>
            </ion-grid>
          </div>
          <div class="mb-12">
            <p class="text-center text-[#A9B5C6]">
              Didn’t receive a code?
              <a
                @click="resend"
                class="underline font-bold cursor-pointer"
              >Send again</a>
            </p>
          </div>
          <div class="ion-no-padding">
            <div class="w-full flex justify-center items-center">
              <ion-button
                @click="verify"
                class="font--main btn-primary m-0 capitalized"
                shape="round"
                size="large"
              >
                Sign Up
              </ion-button>
            </div>
          </div>

          <div class="ion-no-padding">
            <div class="w-full flex justify-between items-center my-7">
              <hr class="w-full">
              <p class="or-text text-xs text-center text--gray--400 font--main">
                or register via
              </p>
              <hr class="w-full">
            </div>
          </div>

          <div class="ion-no-padding mb-12 md:mb-4">
            <div class="w-full flex justify-between">
              <div class="w-[49%]">
                <ion-button
                  @click="fbLogin()"
                  shape="round"
                  size="large"
                  fill="outline"
                  class="flex items-center font--main m-0 capitalized"
                >
                  <img
                    class="mr-2"
                    src="/assets/icon/ic-fb.svg"
                  >
                  Facebook
                </ion-button>
              </div>
              <div class="w-[49%]">
                <ion-button
                  shape="round"
                  size="large"
                  fill="outline"
                  class="flex items-center font--main m-0 capitalized"
                >
                  <img
                    class="mr-2"
                    src="/assets/icon/ic-gmail.svg"
                  >
                  Gmail
                </ion-button>
              </div>
            </div>
          </div>

          <div class="ion-no-padding mb-4">
            <div class="w-full flex flex-row items-center justify-center">
              <span class="font--main m-0 font-size--small text-gray-400">Already have an account?
                <a
                  class="font--main font-bold font-size--x-small text--secondary my-0 mr-0"
                  href="/login"
                >Login now</a>
                .
              </span>
            </div>
          </div>
        </div>
      </div>
      <bottom-navigation />
    </ion-content>
    <Loader v-show="loader" />
  </ion-page>
</template>

<script type="text/javascript">

import { defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

import {
    IonPage,
    IonContent,
    IonButton,
    IonGrid,
    IonRow,
    IonCol,
    modalController,
} from '@ionic/vue';
import Modal from '@/components/Modal.vue';
import TopNavigation from '@/partials/TopNavigation.vue';
import BottomNavigation from '@/partials/BottomNavigation.vue';
import Loader from '@/components/PageLoader.vue';

export default defineComponent({
    name: 'EmailVerification',
    components: {
        IonContent,
        IonPage,
        IonButton,
        IonGrid,
        IonRow,
        IonCol,
        TopNavigation,
        BottomNavigation,
        Loader
    },
    setup() {
        const store = useStore();
        const route = useRoute();

        const loader = ref(false);

        // Modal variables
        const title = ref('Oops!');
        const message = ref('Something went wrong.');
        const buttonText = ref('Close');
        const imageLink = ref('/assets/icon/ic-status-warning.svg');

        const otpError = ref(null);
        
        const openErrorModal = async () => {
            const modal = await modalController.create({
                component: Modal,
                cssClass: 'gen-modal',
                componentProps: {
                title: title.value,
                description: message.value,
                buttonText: buttonText.value,
                imageLink: imageLink.value,
                buttonClass: 'btn-primary',
                closeModalButton: true,
                imageWidth: '28',
                imageHeight: '28',
                full: 'full'
                }
            });
            return modal.present();
        };

        const openSuccessModal = async () => {
            const modal = await modalController.create({
                component: Modal,
                cssClass: 'gen-modal',
                componentProps: {
                title: 'Success!',
                description: 'Verification code has been resent to your email/mobile number.',
                buttonText: 'Okay',
                imageLink: '/assets/icon/ic-status-check.svg',
                buttonClass: 'btn-primary',
                closeModalButton: true,
                imageWidth: '28',
                imageHeight: '28',
                full: 'full'
                }
            });
            return modal.present();
        };

        const verificationSuccess = async () => {
            const modal = await modalController.create({
                component: Modal,
                cssClass: 'gen-modal email-verification',
                componentProps: {
                title: "Success!",
                description: "Your account has been successfully made and verified. You may now log in. Enjoy shopping!",
                buttonText: "Okay",
                buttonLink: "/login",
                imageLink: "/assets/icon/ic-status-check.svg",
                imageWidth: '40',
                imageHeight: '40',
                full: 'full'
                }
            });
            return modal.present();
        };

        const codes = ref([]);

        const userId = store.state.user.verificationId;

        const verify = () => {
          loader.value = true;
          window.axios
            .post(store.state.api.routes.verify, {
              email: route.params.email,
              code: codes.value.join(''),
              userId: userId
            })
            .then((result) => {
              console.log(result);
              verificationSuccess();
              loader.value = false;
            }).catch((error) => {
              loader.value = false;
              if(error.response.data.error) {
                message.value = error.response.data.error;
                otpError.value = error.response.data.error;
              }
              openErrorModal();
              console.log(error);
            })
        }

        const resend = () => {
          loader.value = true;
          window.axios
            .post(store.state.api.routes.resend, {
              email: route.params.email,
            })
            .then((result) => {
              console.log(result);
              openSuccessModal();
              loader.value = false;
            }).catch((error) => {
              loader.value = false;
              if(error.response.data.error) {
                message.value = error.response.data.error;
              }
              openErrorModal();
              console.log(error);
            })
        }

        const digitValidate = (val) => {
            const element = document.querySelector('#input-'+val);
            console.log(element.value);
            element.value = element.value.replace(/[^0-9]/g,'');
        }

        const tabChange = (val) => {
            const ele = document.querySelectorAll('.otp-input');
            if(ele[val-1].value != ''){
                ele[val].focus()
            }else if(ele[val-1].value == ''){
                ele[val-2].focus()
            }   
        }   

        return {
            verificationSuccess,
            codes,
            verify,
            resend,
            loader,
            digitValidate,
            tabChange,
            otpError
        };
    },
})
</script>
<style lang="scss">
  .or-text {
      width: 250px;
  }
  .otp-input {
    border-bottom: 1px solid #6b7280;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    text-align: center;
    color: #343A40;
    font-size: 2.25rem !important;
    line-height: 2.5rem;
    font-weight: 700;
    width: 50px;
    box-shadow: unset !important;
    outline: 0;

    &.active,
    &:focus,
    &:focus-within {
      border-bottom: 3px solid #02ADF0 !important;
    }
  }
</style>