export const gender = {
	namespaced: true,
	state: {
		options: [],  
	},
	mutations: {
		setOptions(state, value) {
			state.options = value || {};
		},
	},
};
  