export const user = {
    namespaced: true,
    state: {
      token: null,
      pushToken: null,
      auth: false,
      data: {},
      verificationId: null,
      guestId: null,
      deleteReasons: {}
    },
    mutations: {
      setToken(state, value) {
        state.auth = true;
        state.token = value;
      },
      setPushToken(state, value) {
        state.pushToken = value;  
      },
      clearAuth(state) {
        state.auth = false;
        state.token = null;
      },
      setData(state, value) {
        if(!value) return;
        Object.keys(value).forEach(key => {
          state.data[key] = value[key];
        });
      },
      setVerificationId(state, value) {
        state.verificationId = value;
      },
      setGuestId(state, value) {
        state.guestId = value;
      },
      setDeleteReasons(state, value) {
        state.deleteReasons = value || {};
      },
    },
  };
  