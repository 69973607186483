<template>
  <ion-page>
    <top-navigation />
    <ion-content
      :fullscreen="true"
      class="ion-padding"
    >
      <div class="flex items-center justify-center pt-[40px] pb-24">
        <div class="w-[90%] md:w-[35%] mx-auto">
          <div class="ion-no-padding mb-10 md:mb-8">
            <h1 class="leading-12 font-size--xx-large font-bold text--secondary text-center mb-6">
              Delete Account
            </h1>
            <div class="text-sm-all">
              <p class="font-bold mb-4">This action will permanently delete your account.</p>
              <p class="mb-4">Your stored information including your name, email, contact and delivery details, and password will be removed from our system.</p>
              <p class="font-bold mb-4">What else you need to know:</p>
              <ul class="list-disc pl-5 mb-4">
                  <li>Certain transactional information such as order history, invoice, and previous order will be retained in the system</li>
                  <li>Gift Cards and points earned and accumulated will be removed from our system</li>
                  <li>Your product ratings, reviews and comments will be removed</li>
                  <li>Orders in progress including COD orders, subscription orders and prepaid orders will be cancelled</li>
                  <li>If you just wish to make some changes to your information, you may check and update through the Settings button instead of deleting your account</li>
                  <li>There will be no option to retrieve your account and information once this action is completed</li>
              </ul>
            </div>

             <!-- label="value"
              name="value" -->
            <Selector
              :options="options"
              v-model="selectedReason"
              id="reason-for-request"
              placeholder="Select Reasons"
              class="mb-4"
            />
            <textarea 
              v-model="otherReasons"
              type="text" 
              class="focus:ring-[#02adf0] focus:border-[#02adf0] block w-full border-gray-300 placeholder-gray-400 rounded-[10px] mb-6" 
              placeholder="Other reason"
            ></textarea>
          </div>
          <div class="w-full flex justify-center items-center">
            <ion-button
              @click="submit"
              class="font--main btn-primary m-0 capitalized"
              shape="round"
              size="large"
            >
              Send Verification
            </ion-button>
          </div>
        </div>
      </div>
      <bottom-navigation />
    </ion-content>
    <Loader v-show="loader" />
  </ion-page>
</template>

<script type="text/javascript">
import {
    IonPage,
    IonContent,
    IonButton,
    modalController,
} from '@ionic/vue';
import { defineComponent, ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import TopNavigation from '@/partials/TopNavigation.vue';
import BottomNavigation from '@/partials/BottomNavigation.vue';
import Selector from '@/components/Selector.vue';
import Loader from '@/components/PageLoader.vue';
import Modal from '@/components/Modal.vue';

export default defineComponent({
    name: 'DeleteAccount',
    components: {
        IonContent,
        IonPage,
        IonButton,
        TopNavigation,
        BottomNavigation,
        Selector,
        Loader
    },
    setup() {
        const store = useStore();
        const router = useRouter();
        const loader = ref(false);

        const options = ref([
          { id: 1, name: 'Concerns with data privacy'},
          { id: 2, name: 'Dissatisfied with the product/service'},
          { id: 3, name: 'No need for the product/service within the foreseeable future'}
        ])

        // Modal variables
        const title = ref('Oops!');
        const message = ref('Something went wrong.');
        const buttonText = ref('Close');
        const imageLink = ref('/assets/icon/ic-status-warning.svg');

        // const otpError = ref(null);
        
        const openErrorModal = async () => {
            const modal = await modalController.create({
                component: Modal,
                cssClass: 'gen-modal',
                componentProps: {
                title: title.value,
                description: message.value,
                buttonText: buttonText.value,
                imageLink: imageLink.value,
                buttonClass: 'btn-primary',
                closeModalButton: true,
                imageWidth: '28',
                imageHeight: '28',
                full: 'full'
                }
            });
            return modal.present();
        };

        const openSuccessModal = async () => {
            const modal = await modalController.create({
                component: Modal,
                cssClass: 'gen-modal',
                componentProps: {
                title: 'Success!',
                description: 'Verification code has been sent to your email.',
                buttonText: 'Okay',
                imageLink: '/assets/icon/ic-status-check.svg',
                buttonClass: 'btn-primary',
                closeModalButton: true,
                imageWidth: '28',
                imageHeight: '28',
                full: 'full'
                }
            });
            return modal.present();
        };

        const otherReasons = ref();
        const selectedReason = ref(options.value[0].id);
        const selected = computed(() => (options.value.find(x => x.id === selectedReason.value) || {}).name);

        const submit = () => { 
          loader.value = true;
          const formData = {
            deleteReason: selected.value,
            otherReasons: otherReasons.value
          }

          window.axios
            .post(store.state.api.routes.sendOtpDeletion, formData)
            .then(() => {
                loader.value = false;
                store.commit('user/setDeleteReasons', formData);
                openSuccessModal();
                router.push(`/delete-account/otp`)
            }).catch((error) => {
              loader.value = false;
              if(error) {
                message.value = error.response.data.error;
              }
              openErrorModal();
            });
        }

        return {
            loader,
            options,
            selectedReason,
            otherReasons,
            submit,
            selected
        };
    },
})
</script>