<template>
  <div class="subsHistory-con py-6 border-b border-[#C8CFDA] border-solid">
    <h5 class="font-bold mb-1 text-[#343A40]">
      {{ item.formatted_created_at }}
    </h5>
    <p class="mb-1 text-[#343A40]">
      {{ name }}
    </p>
    <p class="text-[#343A40]">
      Courier: {{ item.rider_name || 'Unassigned' }}
    </p>
  </div>
</template>

<script type="text/javascript">
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'SubscriptionHistory',
  props:{
    item:{
      type:Object,
      default:() => ({}),
    },
    name:{
      type:String,
      default:null
    }
  },
  setup() {
    return {
      //
    }
  }
})
</script>