import { Device } from "@capacitor/device";

import { useStore } from "vuex";
import useLocalStorage from "./useLocalStorage";
import { PushNotifications } from "@capacitor/push-notifications";

export default function usePushNotifications() {
  const store = useStore();
  const { localStorage } = useLocalStorage();

  const postPushToken = async () => {
    const token = store.state.user.pushToken;
    const device = await Device.getInfo();

    return window.axios.post(
      process.env.VUE_APP_API_URL + "/push/register",
      { token: token, platform: device.platform }
    );
  };

  const setPushToken = async (token) => {
    localStorage.value.set("pushToken", token);
    store.commit("user/setPushToken", token);
    // post if user is logged in
    if (await localStorage.value.get("authToken")) {
      postPushToken();
    }
  };


  const addListeners = async () => {
    await PushNotifications.addListener("registration", (token) => {
      console.info("Registration token: ", token.value);
      setPushToken(token.value);
    });

    await PushNotifications.addListener("registrationError", (err) => {
      console.error("Registration error: ", err.error);
    });

    await PushNotifications.addListener(
      "pushNotificationReceived",
      (notification) => {
        console.log("Push notification received: ", notification);
      }
    );

    await PushNotifications.addListener(
      "pushNotificationActionPerformed",
      (notification) => {
        console.log(
          "Push notification action performed",
          notification.actionId,
          notification.inputValue
        );
      }
    );

    return Promise.resolve();
  };

  const registerNotifications = async () => {
    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === "prompt") {
      permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive !== "granted") {
      throw new Error("User denied permissions!");
    }

    return PushNotifications.register();
  };

  const setupNotifications = () => {
    return addListeners().then(() => {
      return registerNotifications();
    });
  };

  const getDeliveredNotifications = async () => {
    const notificationList =
      await PushNotifications.getDeliveredNotifications();
    console.log("delivered notifications", notificationList);
  };

  return {
    setPushToken: setPushToken,
    postPushToken: postPushToken,
    setupNotifications: setupNotifications,
    getDeliveredNotifications: getDeliveredNotifications,
  };
}
