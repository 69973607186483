<template>
    <TransitionRoot as="template" :show="visible">
        <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="closeThis">
            <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                    <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </TransitionChild>

                <!-- This element is to trick the browser into centering the modal contents. -->
                <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                    <div class="relative inline-block align-bottom bg-white rounded-lg px-4 pt-6 pb-6 text-left overflow-auto shadow-xl transform transition-all sm:my-8 sm:align-middle lg:w-[845px] w-full sm:px-10 max-h-[500px] scrollbar-style">
                        <button type="button" @click="closeThis" class="absolute right-10">
                            <img src="/assets/icon/ic-close.svg">
                        </button>
                        <div>
                            <div class="mt-3 text-left sm:mt-6">
                                <DialogTitle as="h3" class="text-2xl leading-6 font-bold text--secondary">Leave a Review</DialogTitle>
                                <template
                                    v-for="(item,index) in items"
                                    :key="`review-invoice-item-${index}`"
                                >
                                    <div class="mt-6">
                                        <div class="flex items-center justify-between">
                                            <div class="flex items-start gap-3">
                                                <img src="/assets/image/prd-img5.png" class="rounded" width="50" height="auto">
                                                <div>
                                                    <p class="text-[#343A40] text-sm font-bold">{{ item.product_name }}</p>
                                                    <p class="text-[#A9B5C6] text-sm font-normal">{{ item.subscription_id ? 'Subscription' : 'One-Time Purchase' }}</p>
                                                </div>
                                            </div>
                                            <div class="text-right">
                                                <p class="text--secondary text-sm font-bold">{{ moneyFormat(item.discount_price) }}</p>
                                                <p class="text-[#A9B5C6] text-sm font-normal">x{{ item.quantity }}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-6">
                                        <div class="flex items-center gap-2">
                                            <h4 class="text-xl font-bold">Your rating:</h4>
                                            <div class="flex items-center gap-1">
                                                <template v-for="i in 5" :key="`item-rating-score-${item.id}-${i}`">
                                                    <img 
                                                        class="w-4 h-4"
                                                        :src="`/assets/icon/ic-star-${ getItemScore(item.product_id) >= i ? 'fill' : 'outline' }.svg`" 
                                                        @click="toggleItemScore(item.product_id, i)"
                                                    >
                                                </template>
                                            </div>
                                        </div>
                                        <div class="flex items-center gap-1 mt-4">
                                            <template v-for="(badge,index) in itemShortRatings" :key="`item-badge-${index}`">
                                                <a 
                                                    class="py-1 px-4 border text-sm font-normal rounded-full"
                                                    :class="selectedItemBadge(item.product_id, badge.id) ? 'border-[#02ADF0] text--primary' : 'border-gray-200 text-gray-200'"
                                                    href="#" @click.prevent="toggleItemBadge(item.product_id, badge.id)"
                                                >
                                                    {{ badge.name }}
                                                </a>
                                            </template>
                                        </div>
                                        <div class="mt-6">
                                            <textarea 
                                                rows="4" name="review" id="review" 
                                                class="focus:ring-[#02adf0] focus:border-[#02adf0] block w-full border-gray-300 placeholder-gray-400 rounded-[10px]" 
                                                placeholder="Type your review here ..."
                                                v-model="itemRatings[index].review"
                                            />
                                        </div>
                                    </div>
                                </template>
                            </div>
                            <hr class="my-10">
                            <div class="mt-3 text-left sm:mt-6">
                                <DialogTitle as="h3" class="text-2xl leading-6 font-bold text--secondary">Rate your driver</DialogTitle>
                                <div class="mt-6">
                                    <div class="flex items-center justify-between">
                                        <div class="flex items-start gap-3">
                                            <img :src="riderAvatar" class="rounded" width="50" height="auto">
                                            <div>
                                                <p class="text-[#343A40] text-sm font-bold">{{ invoice.rider_name }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-6">
                                    <div class="flex items-center gap-2">
                                        <h4 class="text-xl font-bold">Your rating:</h4>
                                        <div class="flex items-center gap-1">
                                            <template v-for="i in 5" :key="i">
                                                <img 
                                                    class="w-4 h-4"
                                                    :src="`/assets/icon/ic-star-${ riderRating.score >= i ? 'fill' : 'outline' }.svg`"
                                                    @click="riderRating.score = i"
                                                >
                                            </template>
                                        </div>
                                    </div>
                                    <div class="flex items-center gap-1 mt-4">
                                        <template v-for="(badge, index) in riderShortRatings" :key="`rider-rating-${index}`">
                                            <a 
                                                class="py-1 px-4 border text-sm font-normal rounded-full"
                                                :class="(riderRating.badges || []).findIndex(x => x == badge.id) !== -1 ? 'border-[#02ADF0] text--primary' : 'border-gray-200 text-gray-200'"
                                                href="#" @click.prevent="toggleRiderBadge(badge.id)"
                                            >
                                                {{ badge.name }}
                                            </a>
                                        </template>
                                    </div>
                                    <div class="mt-6">
                                        <textarea 
                                            rows="4" name="review" id="review" 
                                            class="focus:ring-[#02adf0] focus:border-[#02adf0] block w-full border-gray-300 placeholder-gray-400 rounded-[10px]" 
                                            placeholder="Type your review here ..."
                                            v-model="riderRating.remarks"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-center mt-6">
                            <ion-button
                                @click="submit"
                                class="font--main btn-primary m-0 capitalized"
                                shape="round"
                                size="large"
                            >
                                Submit
                            </ion-button>
                        </div>
                    </div>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>
</template>
<script>

import { computed, defineComponent, ref, watch } from "@vue/runtime-core";
import useFormat from '@/composables/useFormat';
import useResponseHandler from '@/composables/useResponseHandler';

import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { IonButton } from '@ionic/vue';
import { useStore } from "vuex";

export default defineComponent({
    name:'ReviewModal',
    components:{
        Dialog,
        DialogOverlay,
        DialogTitle,
        TransitionChild,
        TransitionRoot,
        IonButton,
    },
    emits:[ 'close' ],
    props:{
        visible:{
            type:Boolean,
            default:false
        },
        invoice:{
            type:Object,
            default:() => ({})
        },
        items:{
            type:Array,
            default:() => ([])
        },
        riderReview:{
            type:Object,
            default:() => ({})
        }
    },
    setup(props, { emit }) {

        const store = useStore();
        
        const { moneyFormat } = useFormat();
        const { successHandler, errorHandler } = useResponseHandler();

        const isLoading = ref(false);
        const itemRatings = ref([]);
        const riderRating = ref({});
        
        const riderAvatar = computed(() => (props.invoice.rider || {}).profile_photo_url || '/assets/image/sample-avatar.png');
        const riderShortRatings = computed(() => store.state.settings.shortRatings.filter(x => x.type == 2));
        const itemShortRatings = computed(() => store.state.settings.shortRatings.filter(x => x.type == 1));

        const payload = computed(() => ({
            invoiceId: props.invoice.id,
            riderId: riderRating.value.rider_id,
            riderRating: riderRating.value.score,
            riderReview: riderRating.value.remarks,
            riderBadges: riderRating.value.badges,
            productReviews: itemRatings.value.map(rating => ({
                id: rating.product_id,
                rating: rating.score,
                review: rating.review,
                badges: rating.badges
            }))
        }));

        const initReview = () => {
            for(const invoiceItem of props.items) {
                const { review, rating, badgeArray } = invoiceItem.review || {};
                itemRatings.value.push({
                    product_id: invoiceItem.product_id,
                    score: rating || 5,
                    review: review || null,
                    badges: badgeArray || []
                });
            }
            const { remarks, rating, badgeArray } = props.riderReview || {};
            riderRating.value = {
                rider_id: props.invoice.rider_id,
                score: rating || 5,
                remarks: remarks || null,
                badges: badgeArray || []
            };
        };

        const getItemScore = (productId) => ((itemRatings.value.find(x => x.product_id == productId) || {}).score);
        const getItemRatingIndex = (productId) => (itemRatings.value.findIndex(x => x.product_id == productId));
        const getItemRatingIndices = (productId, badgeId) => {
            const index = getItemRatingIndex(productId);
            const badge = index !== -1 ? itemRatings.value[index].badges.findIndex(x => x == badgeId) : -1;
            return { index, badge };
        };

        const selectedItemBadge = (productId, badgeId) => {
            const indices = getItemRatingIndices(productId, badgeId);
            return indices.badge !== -1;
        };

        const toggleRiderBadge = (badgeId) => {
            const index = riderRating.value.badges.findIndex(x => x == badgeId);
            if(index === -1) riderRating.value.badges.push(badgeId);
            else riderRating.value.badges.splice(index, 1);
        };

        const toggleItemBadge = (productId, badgeId) => {
            const { index, badge } = getItemRatingIndices(productId, badgeId);
            if(badge === -1) itemRatings.value[index].badges.push(badgeId);
            else if(index !== -1) itemRatings.value[index].badges.splice(badge, 1);
        };

        const toggleItemScore = (productId, score) => {
            const index = getItemRatingIndex(productId);
            if(index !== -1) itemRatings.value[index].score = score;
        };

        const submit = () => {
            if(isLoading.value) return;
            isLoading.value = true;
            window.axios
                .post(store.state.api.routes.rateAndReview, payload.value)
                .then(({ data }) => {
                    isLoading.value = false;
                    closeThis();
                    successHandler(data.message);
                })
                .catch(({ response }) => {
                    isLoading.value = false;
                    errorHandler(response);
                    closeThis();
                });
        };

        const closeThis = () => (emit('close'));

        watch(
            () => props.visible,
            (bool) => {
                if(bool) initReview();
            }
        );
        
        return {
            moneyFormat,
            riderAvatar,
            riderShortRatings,
            itemShortRatings,
            itemRatings,
            riderRating,
            closeThis,
            getItemScore,
            selectedItemBadge,
            toggleRiderBadge,
            toggleItemBadge,
            toggleItemScore,
            submit
        }
    },
})
</script>