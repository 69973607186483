<template>
  <ion-page>
    <top-navigation />
    <ion-content
      :fullscreen="true"
      class="ion-padding"
    >
      <generic-frame-one
        :title="getCmsContent('header_pageTitle') || 'FAQs'"
      />
      <section class="faqs-fr1">
        <div class="w-11/12 max-w-screen-md mx-auto">
          <ion-accordion-group class="faqsfr1-accordionCon">
            <faqs-list 
              v-for="(item,index) in items"
              :key="`faq-item-${index}`"
              :title="item.question"
              :description="item.answer"
            />
          </ion-accordion-group>
        </div>
      </section>
      <section class="faqs-fr2 mt-16 lg:mb-28 mb-[90px]">
        <div class="w-11/12 max-w-4xl mx-auto border-t border-solid text-center pt-12">
          <p>
            Found an unsatisfactory answer? <a
              href="/contact-us"
              class="cstm-link font-bold text--primary"
            >Contact our Customer service support</a>
          </p>
        </div>
      </section>
      
      <bottom-navigation />
    </ion-content>
  </ion-page>
</template>

<script type="text/javascript">
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';

import { IonPage, IonAccordionGroup, IonContent } from '@ionic/vue';
import GenericFrameOne from '@/components/GenericFrame1.vue';
import BottomNavigation from '@/partials/BottomNavigation.vue';
import TopNavigation from '@/partials/TopNavigation.vue';
import FaqsList from './components/FaqsList.vue';
import useCms from "@/composables/useCms";

export default defineComponent({
  name: 'FaqsPage',
  components: { 
    IonPage, IonAccordionGroup, IonContent, 
    BottomNavigation, TopNavigation, GenericFrameOne, 
    FaqsList 
  },
  setup() {
    const { contentSlug, getCmsContent } = useCms();
    
    const store = useStore();
    
    const items = ref([]);
    const fetchFaqUrl = computed(() => store.state.api.routes.fetchFaqs);
    
    const fetch = (url) => {
      window.axios.post(url || fetchFaqUrl.value)
        .then(({ data }) => {
          items.value = data.items;
        });
    };
    
    watch(
      () => fetchFaqUrl.value,
      (url) => {
        if(typeof url === 'string') fetch(url);
      },
      { immediate: true }
    );
    
    onMounted(() => {
        contentSlug.value = 'faqs';
    });

    return {
      items,
      getCmsContent
    }
  }
}) 
</script>