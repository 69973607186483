<template>

  <div
    :class="[
      (step == 1 || step == 3) && isMobile ? 'ml-24' : '',
      step == 2 && isMobile ? 'mr-24' : ''
    ]"
  >
    <div class="flex flex-col items-center justify-center">
      <div 
        class="w-14 h-14 bg-[#272682] rounded-full flex items-center justify-center"
        :class="[ step == 2 && !isMobile ? 'order-2 mt-6 mb-10' : '']"
      >
        <p class="text-3xl text-white font-bold">{{ step }}</p>
      </div>
      <div 
        class="max-w-[200px] mb-10"
        :class="[ step == 2 && !isMobile ? 'order-3' : 'mt-6']"
      >
        <h4 class="text-center text-white font-bold leading-none mb-4 text-2xl">
          {{ title }}
        </h4>
      </div>
      <div 
        class="w-52 h-52 rounded-full border-[16px] border-[#B3E9FE] bg-white flex items-center justify-center"
        :class="[ step == 2 && !isMobile ? 'order-1' : '']"
      >
        <img
          :src="imgSrc"
          class="h-28 w-28"
        >
      </div>
    </div>
  </div>
  <!-- <div class="h-full lg:mx-0 mx-auto rounded-md bg-white lg:w-[20rem] w-[90%] relative z-10 mt-8 flex items-center justify-center">
    <div class="absolute z-10 top-[-16%] rounded-full border-[6px] border-[#B3E9FE] w-24 h-24 bg-white flex items-center justify-center">
      <img
        :src="imgSrc"
        class="h-14 w-14"
      >
    </div>
    <div class="pt-16 pb-12 px-8">
      <h4 class="text-center text--secondary font-bold leading-none mb-4">
        {{ title }}
      </h4>
      <p class="text-center">
        {{ description }}
      </p>
    </div>
  </div> -->
</template>
<script type="text/javascript">
export default {
  name: 'SubscriptionCard',
  props: {
      title: {
          type: String,
          default: null
      },
      step: {
        type: String,
        default: null
      },
      imgSrc: {
          type: String,
          default: null
      },
      description: {
          type: String,
          default: null
      }
  },
  data: () => ({
    isMobile: false,
  }),
  mounted() {
    if (window.innerWidth < 1024) {
      this.isMobile = true;
    }
  }
}
</script>