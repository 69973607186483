<template>
  <h3 class="text--secondary font-bold mb-6">
    Ratings and Reviews
  </h3>
  <div class="summary-ratings__hldr flex flex-wrap">
    <div class="w-full md:w-1/4 text-center">
      <h2 class="font-bold text--secondary">
        {{ ratingAverage }}
      </h2>
      <ul class="flex justify-center mb-4">
        <template
          v-for="(star, index) in stars"
          :key="`star-option-${index}`"
        >
          <li>
            <!-- Not Filled -->
            <!-- <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="star" class="w-4 text-yellow-500" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
              <path fill="#F2C94C" d="M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM388.6 312.3l23.7 138.4L288 385.4l-124.3 65.3 23.7-138.4-100.6-98 139-20.2 62.2-126 62.2 126 139 20.2-100.6 98z"></path>
            </svg> -->

            <!-- Filled -->
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="star"
              class="w-4 text-yellow-500 mr-1"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 576 512"
            >
              <path
                fill="#F2C94C"
                d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"
              />
            </svg>
          </li>
        </template>
      </ul>
      <h6 class="text--gray--400">
        (based on {{ productRatings.length }} reviews)
      </h6>
    </div>
    <div class="w-full md:w-2/3">
      <div class="block md:hidden h-[50px]" />
      <template
        v-for="(rate, index) in ratings"
        :key="`rating-summary-${index}`"
      >
        <div class="progress-item flex items-center mb-2">
          <div class="w-40">
            <h6 class="text--gray--700">
              {{ rate }}
            </h6>
          </div>
          <div class="w-full">
            <div class="w-full bg--primary--50 h-1 border-radius-5">
              <div
                class="bg--primary h-1 border-radius-5"
                :style="`width: ${getRatingPercentage(rate)}%`"
              />
            </div>
          </div>
          <div class="w-10 ml-4">
            <h6 class="text--gray--700">
              ({{ getRatingCount(rate) }})
            </h6>
          </div>  
        </div>
      </template>
    </div>
  </div>
  <div 
    class="w-full mt-8"
    v-if="!isLoggedIn"
  >
    <h6 class="text--gray--700">
      You need to be logged in before you can leave a review. <a
        href="/login"
        class="font-bold text--primary"
      >LOGIN NOW</a>
    </h6>
  </div>

  <div class="w-full mt-8">
    <ul class="rating-item__list">
      <template
        v-for="(item, index) in productRatings"
        :key="`product-review-${index}`"
      >
        <li class="rating-item  bg--primary--50 border-radius-5 px-11 py-10 mb-4">
          <h5 class="font-bold text--secondary mb-1">
            {{ item.customerName }}
          </h5>
          <div class="w-full flex items-center justify-between mb-10">
            <ul class="flex">
              <template
                v-for="(star, idx) in item.rating"
                :key="`rating-star-icon-${idx}`"
              >
                <li>
                  <!-- Not Filled -->
                  <!-- <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="star" class="w-4 text-yellow-500" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                    <path fill="#F2C94C" d="M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM388.6 312.3l23.7 138.4L288 385.4l-124.3 65.3 23.7-138.4-100.6-98 139-20.2 62.2-126 62.2 126 139 20.2-100.6 98z"></path>
                  </svg> -->

                  <!-- Filled -->
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="star"
                    class="w-4 text-yellow-500 mr-1"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 576 512"
                  >
                    <path
                      fill="#F2C94C"
                      d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"
                    />
                  </svg>
                </li>
              </template>
            </ul>
            <!-- positioning on this @ mobile is at the bottom -->
            <h6 class="hidden md:block text--gray--700">
              Posted on {{ item.formatted_created_at }}
            </h6>
          </div>
          <p 
            v-for="(badge,index) in item.badges"
            :key="`short-rating-${item.id}-${index}`"
            class="
              inline-flex
              font-normal
              text-sm
              text-[#02ADF0]
              px-4
              py-0.5
              border
              border-[#02ADF0]
              rounded-full
              my-4
              whitespace-nowrap
            "
            :class="{ 'ml-1': index > 0 }"
          >
            {{ badge.name }}
          </p>
          <div class="w-full mb-4">
            <h6 class="text--gray--800">
              {{ item.review }}
            </h6>
          </div>
          <div 
            class="w-full"
            v-if="item.imageUrls.length"
          >
            <ul class="rating-img__list flex">
              <template
                v-for="(img, idx2) in item.imageUrls"
                :key="`review-image-${idx2}`"
              >
                <li class="rating-img__item mr-3">
                  <img
                    width="50"
                    height="50"
                    :src="img"
                    alt="rating-img"
                    class="border-radius-5"
                  >
                </li>
              </template>
            </ul>
          </div>
          <h6 class="block md:hidden text--gray--300 mt-7">
            Posted on {{ item.formatted_created_at }}
          </h6>
        </li>
      </template>
    </ul>
  </div>
</template>
<script type="text/javascript">
import { ref, defineComponent } from 'vue';
export default defineComponent({
  name: 'RatingsAndReview',
  props: {
      productRatings: {
          default: null,
          type: Object
      },
      isLoggedIn: {
          default: false,
          type: Boolean
      },
      ratingAverage: {
        default: '0.0',
        type: String
      },
  },
  setup(props) {
    
    const stars = ref(5);
    const ratings = ref(5);

    const getRatingCount = score => (props.productRatings.filter(x => x.rating == score).length);
    const getRatingPercentage = score => getRatingCount(score) / props.productRatings.length * 100;

    return {
      stars,
      ratings,
      getRatingCount,
      getRatingPercentage
    }
  }
})
</script>
<style lang="scss">

  .rating-item__list {
    .rating-item {

      .rating-img__list {

        .rating-img__item {

          img {
            object-fit: contain;
          }
        }
      }
    }
  }

</style>