import { ref, computed } from "vue";
import { useStore } from "vuex"
import { useRouter } from "vue-router";
import useFormat from "@/composables/useFormat";
import useLocalStorage from "@/composables/useLocalStorage";
import useResponseHandler from "@/composables/useResponseHandler";

export default function useCheckout() {
    
    const store = useStore();
    const router = useRouter();
    
    const { makeGuestId } = useFormat();
    const { localStorage } = useLocalStorage();
    const { errorHandler } = useResponseHandler();

    const checkoutLoader = ref(false);
    const fetchCheckoutItemsUrl = computed(() => store.state.api.routes.fetchCheckoutItems);
    const user = computed(() => store.state.user.data);

    const checkout = (payload) => {
        if(checkoutLoader.value) return Promise.resolve(false);
        if(!store.state.user.token) payload = addGuestData(payload);
        checkoutLoader.value = true;
        const url = store.state.user.token ? store.state.api.routes.checkout : store.state.api.routes.guestCheckout;
        return window.axios
            .post(url, payload)
            .then(({ data }) => {
                checkoutLoader.value = false;
                return data;
            })
            .catch(requestFail);
    }

    const fetchCheckoutItems = async (url) => {
        if(checkoutLoader.value) return Promise.resolve(false);
        checkoutLoader.value = true;

        const checkoutItems = store.state.checkout.items.length != 0 ? store.state.checkout.items : store.state.cart.items;
        // const checkoutItems = store.state.checkout.items;
        return window.axios
            .post(url || fetchCheckoutItemsUrl.value, {
                cart: store.state.cart.data.id,
                items: checkoutItems.map(item => item.id),
                platform: 'web',
                userId: user.value.id
            })
            .then(({ data }) => {
                const dataItems = checkoutItems.map(item => data.items.find(x => x.id == item.id)  || item);
                store.commit('checkout/set',  { dataItems });

                const replacementAmount = data.items.map((items) => items.replacement ? items.quantity * items.replacement.price_per_case : 0)
                store.commit('replacement/setAmount', replacementAmount.reduce( (a, c) => a + c, 0))
                store.commit('checkout/setFirstPurchaseDiscount', data.firstPurchaseDiscount)

                checkoutLoader.value = false;
                // return data.items;
            })
            .catch(() => router.push({ name: 'home' }));
    };

    const addGuestData = (payload) => {
        payload.guestId = store.state.user.guestId;
        payload.guest = Object.assign(store.state.user.data, store.state.address.form);
        return payload; 
    };

    const updateCheckout = () => {
        const cart = ref(store.state.cart.data);
        const cartItems = store.state.checkout.items.length != 0 ? store.state.checkout.items : store.state.cart.items;
        const items = cartItems.filter(x => !x.unavailable);
        const checkoutItems = store.state.checkout.items;
        
        cart.value.subtotal = items.reduce((acc,item) => acc + parseFloat(item.subtotal), 0);
        cart.value.points_earned = items.reduce((acc,item) => acc + parseInt(item.product.points) * item.quantity, 0);

        if(checkoutItems) {
            // const replacementBalance = checkoutItems.reduce((acc,item) => acc + item.price_per_case * item.quantity, 0);
            // store.commit('replacement/setAmount', replacementBalance)
        }

        store.commit('cart/set', cart.value);
        store.commit('checkout/set', { cartId: cart.value.id });

        if(!['checkout','login'].includes(router.currentRoute.value.name)) localStorage.value.set('checkoutItems', items);
    };

    const updateCheckoutFromBranch = (newItems) => {
        const items = [...newItems];
        store.state.checkout.items.forEach(item => {
            if(newItems.findIndex(x => x.product_id == item.product_id) === -1) {
                items.push(Object.assign(item, { unavailable: true }));
            }
        });
        store.commit('checkout/set', { items });
        updateCheckout();
    };

    const addToCheckout = (item) => {
        store.commit('checkout/addItem', item);
        updateCheckout();
    };

    const removeFromCheckout = (item) => {
        store.commit('checkout/removeItem', item);
        updateCheckout();
    };

    const updateCheckoutItem = (item) => {
        store.commit('checkout/updateItem', item);
        updateCheckout();
    };

    const clearCheckout = () => {
        store.commit('user/setGuestId', makeGuestId());
        store.commit('replacement/setAmount', 0)
        store.commit('checkout/set', {
            cartId: null,
            items: [],
            referralCode: null,
            paymentMethod: null,
            paymentStatus: 1,
            address: {},
            oneTimePayload: {},
            subscriptionPayload: [],
            replacements: [],
            referenceNumber: null,
            notes: null,
        });
        localStorage.value.set('guestId', store.state.user.guestId);
        localStorage.value.delete('checkout');
        localStorage.value.delete('referral');
    };

    const requestFail = ({ response }) => {
        checkoutLoader.value = false;
        errorHandler(response);
        return false;
    };

    return {
        checkoutLoader,
        fetchCheckoutItemsUrl,
        checkout,
        fetchCheckoutItems,
        addToCheckout,
        removeFromCheckout,
        updateCheckoutItem,
        updateCheckout,
        updateCheckoutFromBranch,
        clearCheckout
    }
}