<template>
  <div class="delivery-address__hldr mb-8 md:mb-16">
    <h3 class="font-bold mb-4">
      {{ title }}
    </h3>
    <div class="grid grid-cols-2 gap-4 mb-4">
		<div class="lg:col-span-1 col-span-full">
			<label
				for="first_name"
				class="sr-only"
			>First Name</label>
			<input
				type="text"
				name="first_name"
				id="first_name"
				class="focus:ring-[#02adf0] focus:border-[#02adf0] block w-full border-gray-300 placeholder-gray-400 rounded-[10px] h-12"
				placeholder="First Name"
				v-model="form.first_name"
				:class="form.email ? 'border-green-400' : ''"
			>
			<span v-if="!form.first_name" class="text-xs text-red-500">*required</span>
		</div>
		<div class="lg:col-span-1 col-span-full">
			<label
				for="last_name"
				class="sr-only"
			>Last Name</label>
			<input
				type="text"
				name="last_name"
				id="last_name"
				class="focus:ring-[#02adf0] focus:border-[#02adf0] block w-full border-gray-300 placeholder-gray-400 rounded-[10px] h-12"
				placeholder="Last Name"
				:class="form.email ? 'border-green-400' : ''"
				v-model="form.last_name"
			>
			<span v-if="!form.last_name" class="text-xs text-red-500">*required</span>
		</div>
		<div class="lg:col-span-1 col-span-full">
			<label
				for="email"
				class="sr-only"
			>Email</label>
			<input
				type="email"
				name="email"
				id="email"
				class="focus:ring-[#02adf0] focus:border-[#02adf0] block w-full border-gray-300 placeholder-gray-400 rounded-[10px] h-12"
				:class="form.email ? 'border-green-400' : ''"
				placeholder="Email"
				v-model="form.email"
			>
			<span v-if="!form.email" class="text-xs text-red-500">*required</span>
		</div>
		<div class="lg:col-span-1 col-span-full">
			<label
				for="mobileNumber"
				class="sr-only"
			>Mobile Number</label>
			<div class="flex rounded-[10px]">
				<span class="inline-flex items-center px-3 rounded-l-[10px] border border-gray-300 border-r-0 text-gray-400">+ 63</span>
				<span class="inline-flex items-center border-t border-b border-gray-300 text-gray-400">|</span>
				<input 
					v-model="form.mobile_number"
					type="text" 
					name="mobileNumber" 
					id="mobileNumber" 
					class="flex-1 min-w-0 focus:ring-[#02adf0] focus:border-[#02adf0] block w-full border-l-0 border-gray-300 placeholder-gray-400 rounded-r-[10px] h-12" 
					placeholder="Mobile Number"
					:class="form.email ? 'border-green-400' : ''"
					maxlength="10"
				>
			</div>
			<span v-if="!form.mobile_number" class="text-xs text-red-500">*required</span>
		</div>
    </div>
    <AddressLocationForm v-model="form" />
    <div class="flex justify-start items-center">
		<ion-button
			shape="round"
			size="large"
			fill="outline"
			class="flex items-center font--main m-0 capitalized"
			:disabled="!barangayHasChanged"
			@click="changeLocation"
		>
			Change Location
		</ion-button>
    </div>
	<span v-if="barangayHasChanged" class="text-sm text--gray--600 ml-1 italic">Please click <u>Change Location</u> to save changes.</span>
  </div>
</template>
<script>

// copied from DeliveryAddress.vue
import { ref } from '@vue/reactivity';
import { computed, defineComponent, onMounted, watch } from '@vue/runtime-core';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import useCart from '@/composables/useCart';
import useLocalStorage from '@/composables/useLocalStorage';
import useLocation from '@/composables/useLocation';

import { modalController } from '@ionic/vue';
import Modal from '@/components/Modal';
import AddressLocationForm from '@/views/account-details/address-book/AddressLocationForm.vue';

export default defineComponent({
	name: 'GuestDeliveryAddress',
	components:{
		AddressLocationForm,
	},
	props: {
		title: {
			type:String,
			default:null
		}
	},
	setup() {
		
		const store = useStore();
		const route = useRoute();

		const { checkCartItems } = useCart();
		const { localStorage } = useLocalStorage();
		const { saveLocation, storeAddress } = useLocation();
			
		/*
		|--------------------------------------------------------------------------
		| @Data
		|--------------------------------------------------------------------------
		*/

		const form = ref({
			provinceCode: 'Province',
			cityCode: 'City',
			barangayCode: 'Barangay',
			first_name: null, 
			last_name: null, 
			email: null, 
			mobile_number: null,
			street : null, unitNo: null,
		});

		const barangayHasChanged = computed(() => form.value.barangayCode != store.state.address.form.barangay);

		/*
		|--------------------------------------------------------------------------
		| @Modals
		|--------------------------------------------------------------------------
		*/

		const initForm = async() => {
			
			let address = store.state.address.form;
			if(!address.barangay || !address.barangayCode) address = await localStorage.value.get('location');
			
			address = Object.assign(address, {
				barangayCode: address.barangay,
				cityCode: address.city,
				provinceCode: address.province,
				latitude: address.latitude,
				longitude: address.longitude
			});

			form.value = Object.assign(form.value, address);

			/* Get user data from localStorage when redirected from a payment gateway */
			if(route.query.paymentStatus) {
				
				let user = store.state.user.data;
				if(!Object.keys(user).length || !user.first_name) user = await localStorage.value.get('user');

				const { first_name, last_name, email, mobile_number } = user;
				form.value = Object.assign(address, { first_name, last_name, email, mobile_number });
			}
		};

		const changeLocation = async () => {
			const modal = await modalController.create({
				component: Modal,
				cssClass: 'gen-modal order-confirmation',
				componentProps: {
					title: 'Change delivery address?',
					description: 'Changing your delivery location might affect the availability of some items in your cart.',
					imageLink: '/assets/icon/ic-status-warning.svg',
					cancelButtonText: 'Go Back',
					cancelButtonClass: 'btn-primary',
					cancelButton: true,
					emitButton: true,
					cancelButtonFill: 'outline',
					buttonClass: 'btn-danger',
					buttonText: 'Change Address',
					imageWidth: '36',
					imageHeight: '36',
				}
			});
			modal.onDidDismiss().then(({ data }) => {
				if(data == 'emit') changeBranch();
            });
			return modal.present();
		};

		const changeBranch = async () => {
			await localStorage.value.set('oldBranchId', store.state.address.branchId);
			saveLocation()
				.then(async() => {
					const newBranchId = store.state.address.branchId;
					const oldBranchId = await localStorage.value.get('oldBranchId');
					checkCartItems(newBranchId, oldBranchId)
					.then(() => {
						const data = Object.assign(form.value, {
							barangay: form.value.barangayCode,
							city: form.value.cityCode,
							province: form.value.provinceCode,
						});
						storeAddress(data);
					})
				});
		};

		/*
		|--------------------------------------------------------------------------
		| @Watch
		|--------------------------------------------------------------------------
		*/

		watch(
			() => form.value,
			(data) => {
				
				// const user = {};
				// const address = {};

				// [ 'first_name', 'last_name', 'email', 'mobile_number' ].forEach(key => {
				// 	if(data[key]) user[key] = data[key];
				// });

				// [ 
				// 	'barangay', 'barangayCode', 'city', 'cityCode', 'province', 'provinceCode', 
				// 	'street', 'unitNo', 'zipCode', 'latitude', 'longitude' 
				// ].forEach(key => {
				// 	if(data[key]) address[key] = data[key];
				// });
				
				store.commit('user/setData', {
					first_name: data.first_name,
					last_name: data.last_name,
					email: data.email,
					mobile_number: data.mobile_number,
				});

				store.commit('address/setForm', {
					barangay: data.barangay,
					barangayCode: data.barangayCode,
					city: data.city,
					cityCode: data.cityCode,
					province: data.province,
					provinceCode: data.provinceCode,
					street: data.street,
					unitNo: data.unitNo,
					zipCode: data.zipCode,
					latitude: data.latitude,
					longitude: data.longitude,
				});
			},
			{ deep: true }
		);

		onMounted(() => {
			initForm();
		});

		return {
			barangayHasChanged,
			form,
			initForm,
			changeLocation
		}
	}
});
</script>