export const address = {
	namespaced: true,
	state: {
		branchId: null,
		form: {},
		items: [],
		provinces: [],
		cities: [],
		barangays: [],
	},
	mutations: {
		setForm(state, value) {
			state.form = value || {};
		},
		setItems(state, items) {
			state.items = items || [];
		},
		setCoordinates(state, coords) {
			state.form.latitude = coords.latitude;
			state.form.longitude = coords.longitude;
		},
		setProvinces(state, value) {
			state.provinces = value || [];
		},
		setCities(state, value) {
			state.cities = value || [];
		},
		setBarangays(state, value) {
			state.barangays = value || [];
		},
		setBranch(state, id) {
			state.branchId = id;
		}
	},
};
