import { computed, ref } from "vue";
import { useStore } from "vuex"
import useCheckout from "./useCheckout.js";
import useLocalStorage from './useLocalStorage.js';
import useResponseHandler from './useResponseHandler';

export default function useCart() {
    
    const store = useStore();
    
    const { updateCheckoutFromBranch } = useCheckout();
    const { localStorage } = useLocalStorage();
    const { successHandler, errorHandler, addToCartResponseHandler } = useResponseHandler();

    /*
    |--------------------------------------------------------------------------
    | @Ref
    |--------------------------------------------------------------------------
    */

    const cartIsLoading = ref(false);
    const cartItemPayload = {
        productId: null,
        quantity: 1,
        type: 1,
        originalPrice: null,
        discountedPrice: null,
        notAvailableOption: null,
        replacementItemId: null,
        frequency: null,
        duration: null,
        branchId: null
    };

    /*
    |--------------------------------------------------------------------------
    | @Computed
    |--------------------------------------------------------------------------
    */

    const cartItemType = computed(() => store.state.settings.cartItemTypes || {});

    const fetchCartUrl = computed(() => {
        return store.state.user.token 
            ? store.state.api.routes.cart
            : store.state.api.routes.guestCart;
    });

    const addCartItemUrl = computed(() => {
        return store.state.user.token
            ? store.state.api.routes.cartItemAdd
            : store.state.api.routes.guestCartItemAdd;
    });

    const removeAllItemsUrl = computed(() => {
        return store.state.user.token 
            ? store.state.api.routes.cartItemRemoveAll
            : store.state.api.routes.guestCartItemRemoveAll;
    });

    const checkCartItemsUrl = computed(() => store.state.user.token ? store.state.api.routes.cartItemCheck : store.state.api.routes.guestCartItemCheck);

    const payload = computed(() => ({
        branchId: store.state.address.branchId,
        sessionId: store.state.user.guestId,
        platform: 'web',
        checkout: store.state.checkout.items.map(x => x.id)
    }));

    /*
    |--------------------------------------------------------------------------
    | @Methods
    |--------------------------------------------------------------------------
    */

    const fetchCart = () => {
        if(cartIsLoading.value) return;
        cartIsLoading.value = true;
        window.axios.post(fetchCartUrl.value, payload.value)
            .then(response => {
                const cart = response.data.cart;
                if(!store.state.user.token && cart.session_id) {
                    store.commit('user/setGuestId', cart.session_id);
                    localStorage.value.set('guestId', cart.session_id);
                }
                updateStoreCart(response);
            })
            .catch(requestFail);
    };

    const addToCart = (payload) => {
        if(cartIsLoading.value) return Promise.resolve(false);
        cartIsLoading.value = true;
        return window.axios
            .post(addCartItemUrl.value, payload)
            .then(response => {
                updateStoreCart(response);
                addToCartResponseHandler({ });
                return true;
            })
            .catch(requestFail);
    };

    const removeAllItems = () => {
        if(cartIsLoading.value) return;
        cartIsLoading.value = true;
        window.axios.post(removeAllItemsUrl.value, payload.value)
            .then(response => {
                store.commit('checkout/set', {items: []});
                updateStoreCart(response);
                successHandler({ description: response.data.message });
            })
            .catch(requestFail);
    };

    const checkCartItems = (newBranchId, oldBranchId = null) => {
        if(cartIsLoading.value) return;
        cartIsLoading.value = true;
        return window.axios.post(checkCartItemsUrl.value, {
                sessionId: store.state.user.guestId,
                checkout: store.state.checkout.items.map(x => ({ productId: x.product_id, type: x.type })),
                oldBranchId: oldBranchId || payload.value.branchId,
                newBranchId
            })
            .then(response => {
                cartIsLoading.value = false;
                updateStoreCart(response);
                if(store.state.checkout.items.length) {
                    updateCheckoutFromBranch(
                        response.data.cartItems.filter(
                            cartItem => store.state.checkout.items.map(checkoutItem => checkoutItem.product_id).includes(cartItem.product_id)
                        )
                    );
                }
                successHandler({ description: response.data.message });
            })
            .catch(requestFail);
    };

    const checkGuestCart = () => {
        if(cartIsLoading.value) return;
        cartIsLoading.value = true;

        const branchId = store.state.address.branchId || 1;
        const sessionId = store.state.user.guestId;

        return window.axios
            .post(store.state.api.routes.guestCartCheck, { branchId, sessionId })
            .then((response) => {
                cartIsLoading.value = false;
                updateStoreCart(response)
                fetchCart();
            })
            .catch(requestFail);
    };

    const updateStoreCart = (response) => {
        cartIsLoading.value = false;
        const { data } = response;
        store.commit('cart/set', data.cart || {});
        store.commit('cart/setItems', data.cartItems || []);
        store.commit('cart/setSubscriptions', data.subscriptions || []);
    };

    const requestFail = ({ response }) => {
        cartIsLoading.value = false;
        errorHandler(response);
        return false;
    };

    return {
        cartIsLoading,
        cartItemPayload,
        cartItemType,
        fetchCart,
        addToCart,
        removeAllItems,
        checkCartItems,
        checkGuestCart
    }
}