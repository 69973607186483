import { computed, ref } from "vue";
import pinia from "@/store/pinia.js";
// import { useApiStore } from '@/store/api.js';
import { useChatStore } from '@/store/modules/chat.js';
import useLocalStorage from "@/composables/useLocalStorage.js";
import useModal from "@/composables/modal";
import { useStore } from "vuex";

export default function useChat() {

    // const apiStore = useApiStore(pinia);
    const store = useStore();
    const chatStore = useChatStore(pinia);
    const { localStorage } = useLocalStorage();
    const modal = useModal();

    const chatLoader = ref(false);
    const errors = ref(false);

    const chat = computed(() => chatStore.chat);

    // const fetchChatUrl = computed(() => apiStore.route("fetchChat"));
    // const sendChatUrl = computed(() => apiStore.route("sendChat"));

    const fetchChatUrl = computed(() => store.state.api.routes.fetchChat);
    const sendChatUrl = computed(() => store.state.api.routes.sendChat);

    const fetchChat = (url, payload) => {
        if(chatLoader.value) return;
        chatLoader.value = true;
        
        window.axios
            .post(url || fetchChatUrl.value, { invoice_id : payload })
            .then((response) => {
                updateStoreChat(response.data)
            })
            .catch((error) => {
                requestFail(error.response.data.message)
            });
    };

    const sendChat = (url, payload) => {
        if(chatLoader.value) return;
        chatLoader.value = true;

        window.axios
            .post(url || sendChatUrl.value, payload)
            .then((response) => {
                console.log(response.data)
                updateStoreChat(response.data)
            })
            .catch((error) => {
                requestFail(error.response.data.message)
            });
    };

    /**
     * Success handler to update store chat
     * 
     * @param   object { data }
     * @returns void
     */
    
    const updateStoreChat = (data) => {
        chatStore.chat = data.chat ? data.chat : chatStore.chat ;

        localStorage.value.set('chat', data.chat ? data.chat : chatStore.chat );
        
        chatLoader.value = false;
    }

    const requestFail = (error) => {
        errors.value = error;
        modal.error(errors.value);

        chatLoader.value = false;
    };

    return {
        chatLoader,

        fetchChatUrl,
        sendChatUrl,

        fetchChat,
        sendChat,

        chat,
    }
}