import { useStore } from "vuex"

export default function useEnum() {
    
    const store = useStore();

    const getStatusValue = (object, key) => {
        return store.state.settings[object][key];
    };

    const getStatusLabel = (object, value) => {
        return store.state.settings[object].indexOf(value);
    };

    const hasStatus = (object, key, value) => {
        return getStatusValue(object, key) == value;
    };

    return {
        getStatusValue,
        getStatusLabel,
        hasStatus
    }
}