<template>
  <ion-page>
    <top-navigation />
    <ion-content
      :fullscreen="true"
      class="ion-padding"
    >
      <div class="flex items-center justify-center pt-[85px] pb-24">
        <div class="w-[90%] md:w-[35%] mx-auto">
          <div class="ion-no-padding mb-10 md:mb-8 text-center">
            <h1 class="leading-12 font-size--xx-large font-bold text--secondary">
              Forgot Password
            </h1>
          </div>

          <template v-if="step == 1">
            <div class="mb-6" >
              <input
                v-model="form.email"
                type="email"
                name="email"
                id="email"
                class="focus:ring-[#02adf0] focus:border-[#02adf0] block w-full border-gray-300 placeholder-gray-400 rounded-[10px] h-12"
                :class="{ 'border-rose-500': hasError('email') }"
                placeholder="Email"
              >
            </div>
          </template>

          <template v-if="step == 2">
            <div class="mb-6">
              <input
                v-model="code"
                type="text"
                name="code"
                id="code"
                class="focus:ring-[#02adf0] focus:border-[#02adf0] block w-full border-gray-300 placeholder-gray-400 rounded-[10px] h-12"
                :class="{ 'border-rose-500': hasError('code') }"
                placeholder="One-Time Password"
                @keydown="numberOnlyInput"
              >
            </div>
          </template>

          <template v-if="step == 3">
            <div class="mb-2 relative">
              <input
                v-model="form.password"
                :type="show ? 'text' : 'password'"
                name="password"
                id="password"
                class="focus:ring-[#02adf0] focus:border-[#02adf0] block w-full border-gray-300 placeholder-gray-400 rounded-[10px] h-12"
                :class="{ 'border-rose-500': hasError('password') }"
                placeholder="Password"
              >

              <ion-icon
                class="password-ic"
                :src="show ? '/assets/icon/ic-eye.svg' : '/assets/icon/ic-eye-off.svg'"
                @click="show = !show"
              />
            </div>
            <div class="mb-6 relative">
              <input
                v-model="form.password_confirmation"
                :type="show1 ? 'text' : 'password'"
                name="password_confirmation"
                id="password_confirmation"
                class="focus:ring-[#02adf0] focus:border-[#02adf0] block w-full border-gray-300 placeholder-gray-400 rounded-[10px] h-12"
                :class="{ 'border-rose-500': hasError('password_confirmation') }"
                placeholder="Password Confirmation"
              >

              <ion-icon
                class="password-ic"
                :src="show1 ? '/assets/icon/ic-eye.svg' : '/assets/icon/ic-eye-off.svg'"
                @click="show1 = !show1"
              />
            </div>
          </template>

          <template v-if="step == 4">
            <div class="mb-6">
              <p>You have successfully reset your password!</p>
            </div>
          </template>

          <div class="ion-no-padding mb-12 md:mb-4" v-if="step != 4">
            <div class="w-full flex justify-center items-center">
              <ion-button
                @click="submit"
                class="font--main btn-primary m-0 capitalized"
                shape="round"
                size="large"
                :disabled="loader"
              >
                Submit
              </ion-button>
            </div>
          </div>

          <div v-if="step == 2" class="text-center mt-7">
              <p class="text-xs text-gray-500 mb-4">Code expires in : <span class="text-secondary-600 font-semibold">{{ displayCountDown }}</span> </p>
              <p class="text-xs text-gray-500 mb-4">Didn’t receive code? <span class="text-secondary-600 font-semibold" @click="resend">Resend Code</span> </p>
          </div>

          <div class="ion-no-padding mb-4">
            <div class="w-full flex flex-row items-center justify-center">
              <span class="font--main m-0 font-size--small text-gray-400">Already have an account?
                <a
                  class="font--main font-bold font-size--x-small text--secondary my-0 mr-0"
                  href="/login"
                >Login now</a>
                .
              </span>
            </div>
          </div>
        </div>
      </div>
      <bottom-navigation />
    </ion-content>
    <Loader v-show="loader" />
  </ion-page>
</template>

<script type="text/javascript">

import { ref, defineComponent } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import useFormat from '@/composables/useFormat';

import {
    IonPage,
    IonContent,
    IonButton,
    IonIcon,
    modalController
} from '@ionic/vue';
import Modal from '@/components/Modal';
import TopNavigation from '@/partials/TopNavigation.vue';
import BottomNavigation from '@/partials/BottomNavigation.vue';
import Loader from '@/components/PageLoader.vue';

export default defineComponent({
  name: 'ForgotPassword',
  components: {
      IonContent,
      IonPage,
      IonButton,
      IonIcon,
      TopNavigation,
      BottomNavigation,
      Loader
  },
  setup() {

    const store = useStore();
    const { numberOnlyInput } = useFormat();
    const router = useRouter();
      
    const step = ref(1);
    const form = ref({});
    const code = ref(null);

    const errors = ref({});
    const title = ref(null);
    const message = ref(null);
    const image = ref(null);

    const loader = ref(false);

    const countDownTimeout = ref(null);
    const countDown = ref(120);
    const displayCountDown = ref('00:00');

    const show = ref(false);
    const show1 = ref(false);

    function countDownTimer() {
        if(countDown.value > 0) {
            countDownTimeout.value = setTimeout(() => {
                countDown.value -= 1;

                let minutes = parseInt(countDown.value / 60, 10);
                let seconds = parseInt(countDown.value % 60, 10);

                minutes = minutes < 10 ? "0" + minutes : minutes;
                seconds = seconds < 10 ? "0" + seconds : seconds;

                displayCountDown.value = minutes + ":" + seconds;
                countDownTimer()
            }, 1000)
        } else {
            clearTimeout(countDownTimeout.value);
        }
    }

    const openModal = async () => {
      const modal = await modalController.create({
        component: Modal,
        cssClass: 'gen-modal',
        componentProps: {
          title: title.value,
          description: message.value,
          imageLink: image.value,
          closeModalButton: true,
          imageWidth: '64',
          imageHeight: '64',
        }
      });
      return modal.present();
    };

    const submit = () => {
      switch(step.value) {
        case 1: forgetPassword(); break;
        case 2: verify(); break;
        case 3: resetPassword(); break;
      }
    }

    const hasError = (key) => {
      return typeof errors.value[key] !== 'undefined';
    };

    const errorHandler = (data) => {
      errors.value = data.errors || [];
      title.value = 'Oops!';
      message.value = data.message != null ? data.message : data.error;
      image.value = '/assets/icon/ic-status-warning.svg';
      openModal();
    };

  const forgetPassword = () => {
      loader.value = true;
      window.axios.post(store.state.api.routes.forgotPassword, form.value)
        .then(() => {
          step.value = 2;
          title.value = 'Success!';
          message.value = 'Verification code has been sent to your email/mobile number.';
          image.value = '/assets/icon/ic-status-check.svg';
          openModal();
          loader.value = false;
          countDownTimer();
        })
        .catch(({ response }) => {
          loader.value = false;
          errorHandler(response.data);
        });
    };

    const verify = () => {
      loader.value = true;
      window.axios.post(store.state.api.routes.verifyOtp, { 
        mobileNumber: form.value.email,
          code: code.value 
        })
        .then(() => {
          step.value = 3;
          title.value = 'Success!';
          message.value = 'Verification success.';
          image.value = '/assets/icon/ic-status-check.svg';
          openModal();
          loader.value = false;
        })
        .catch(({ response }) => {
          loader.value = false;
          errorHandler(response.data);
        });
    };

    const resetPassword = () => {
      loader.value = true;
      window.axios.post(store.state.api.routes.resetPassword, form.value)
        .then(() => {
          step.value = 1;
          form.value = {};
          
          title.value = 'Success!';
          message.value = 'You have successfully updated your password';
          image.value = '/assets/icon/ic-status-check.svg';
          openModal();
          loader.value = false;

          router.push('/login')
        })
        .catch(({ response }) => {
          errorHandler(response.data);
          loader.value = false;
        });
    };

    const resend = async () => {
        loader.value = true;
        const email = form.value.email;
        window.axios
        .post(store.state.api.routes.resendOtp, { email: email} )
        .then(({ data }) => {
            console.log(data)
            loader.value = false;
            openModal();
            countDown.value = 120;
            countDownTimer();

        }).catch((error) => {
            loader.value = false;
            message.value = null;
            errors.value = null;
            if(error) {
                message.value = error.response.data.error;
                errorHandler(message.value);
            }
            if(error.response.data.message) {
                message.value = error.response.data.message;
                errorHandler(message.value);
            }
            if(error.response.data.errors) {
                errors.value = error.response.data.errors;
                errorHandler(errors.value);
            }
        });
    };

    return {
      step,
      form,
      code,
      submit,
      hasError,
      numberOnlyInput,
      loader,
      resend,
      displayCountDown,
      show,
      show1
    };
  },
})
</script>