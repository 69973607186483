<template>
  <div
    class="bg-image"
    style="background-image: url('/assets/image/bg-image.png');"
  />
  <div class="related-product__hldr w-11/12 mx-auto">
    <h3 class="text--white font-bold mb-6">
      Related Items
    </h3>
    <div class="grid grid-cols-4 gap-4">
      <template
        v-for="(item, index) in relatedItems"
        :key="`related-item-${index}`"
      >
        <!-- add disabled prop if oos -->
        <ProductCard
          :item="item"
          is-related
          class="lg:col-span-1 col-span-2 cursor-pointer w-full mb-4 md:mb-0"
          @click.prevent="selectedProduct(item.id)"
        />
      </template>
    </div>
  </div>
</template>
<script type="text/javascript">
import { defineComponent } from 'vue';
import ProductCard from './ProductCard.vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'RelatedItems',
  components: {
    ProductCard,
  },
  props: {
      relatedItems: {
          default: null,
          type: Object
      },
  },
  setup() {
    const router = useRouter();

    function selectedProduct(id) {
      router.push('/shop/' + id);
    }

    return {
      selectedProduct
    }
  }
})
</script>
<style lang="scss" scoped>

  .related-product__hldr {
    padding-top: 250px;
    padding-bottom: 100px;
  }

  .bg-image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -1;
  }
</style>