<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <TransitionRoot
    as="template"
    :show="open"
  >
    <Dialog
      as="div"
      class="fixed z-10 inset-0 overflow-y-auto"
      @close="closeThis"
    >
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >&#8203;</span>
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div class="relative inline-block align-top bg-white rounded-2xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle lg:w-[824px] sm:w-[90%] w-full">
            <div class="grid grid-cols-3">
              <div class="col-span-1 lg:block hidden">
                <img
                  src="/assets/image/hm-modal.png"
                  class="h-full w-full object-cover"
                >
              </div>
              <template v-if="step == 1">
                <step-one
                  :current-step="step"
                  @fetch-provinces="fetchProvinces"
                  @fetch-cities="fetchCities"
                  @fetch-barangays="fetchBarangays"
                  @next="step++"
                />
              </template>
              <template v-else-if="step == 2">
                <step-two
                  :current-step="step"
                  @previous="step--"
                  @next="step++"
                />
              </template>
              <template v-else>
                <step-three
                  :current-step="step"
                  @previous="step--"
                  @close="closeThis"
                />
              </template>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>

import { onMounted, ref, watch } from 'vue'
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import useLocalStorage from '@/composables/useLocalStorage';
import useLocation from '@/composables/useLocation';

import { Dialog, DialogOverlay, TransitionChild, TransitionRoot } from '@headlessui/vue'
import StepOne from './StepOne.vue';
import StepTwo from './StepTwo.vue';
import StepThree from './StepThree.vue';

export default {
  name: 'LocationModal',
  components: {
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
    StepOne,
    StepTwo,
    StepThree,
  },
  emits:[ 'close' ],
  props:{
    isVisible:{
      type:Boolean,
      default:false
    }
  },
  setup(props, { emit }) {
    
    const store = useStore();
    const router = useRouter();
    const { localStorage } = useLocalStorage();
    const { fetchProvinces, fetchCities, fetchBarangays } = useLocation();
    
    const open = ref(false);
    const step = ref(1);

    const closeThis = () => {
      if(store.state.address.branchId) {
        open.value = false;
        step.value = 1;
      }
    };

    watch(
      () => props.isVisible,
      (bool) => open.value = bool,
      { immediate: true }
    );

    watch(
      () => open.value,
      (bool) => {
        if(!bool) emit('close');
      }
    );

    onMounted(async () => {
      const location = await localStorage.value.get('location');
      const user = await localStorage.value.get('user');
      
      if(!['login','register','forgot-password'].includes(router.currentRoute.value.name)) {
        open.value = !(user || location);
      }
    });
    
    return {
      open,
      step,
      closeThis,
      fetchProvinces, 
      fetchCities, 
      fetchBarangays
    }
  },
}
</script>