<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <Listbox
    as="div"
    v-model="selected"
    class="w-full"
  >
    <div class="relative border border-gray-300 rounded-[10px]">
      <ListboxButton class="bg-white relative w-full border border-gray-300 rounded-[10px] pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring focus:ring-[#02ADF0] focus:border-[#02ADF0] sm:text-sm h-12">
        <span class="block truncate text-gray-600">{{ selectedItem[itemName] }}</span>
        <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
          <ChevronDownIcon
            class="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </span>
      </ListboxButton>

      <transition
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
          <ListboxOption
            as="template"
            v-for="(option, index) in options"
            :key="`${id}-${index}`"
            :value="option[itemKey]"
            v-slot="{ active, selected }"
          >
            <li :class="[active ? 'text-white bg-[#01adf0]' : 'text-gray-600', 'cursor-default select-none relative py-1 pl-3 pr-9']">
              <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate text-sm']">
                {{ option[itemName] }}
              </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>

<script>
import { computed, ref, watch } from 'vue'
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/solid'

export default {
  components: {
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
    ChevronDownIcon,
  },
  emits:[ 'update:modelValue' ],
  props:{
    modelValue:{
      type:[String,Number],
      default:null
    },
    id:{
      type:String,
      default:'selector'
    },
    options:{
      type:Array,
      default:() => ([])
    },
    itemKey:{
      type:String,
      default:'id'
    },
    itemName:{
      type:String,
      default:'name'
    }
  },
  setup(props, { emit }) {
    
    const selected = ref(props.modelValue);
    const selectedItem = computed(() => {
      return props.options.find(x => x[props.itemKey] == selected.value) || {};
    });

    watch(
      () => selected.value,
      (value) => emit('update:modelValue', value)
    );
    
    return {
      selected,
      selectedItem
    }
  },
}
</script>