import { useStore } from "vuex";
import useLocalStorage from "./useLocalStorage.js";

export default function useAuth() 
{    
    const store = useStore();
    const { localStorage } = useLocalStorage();

    const fetchGuestId = () => {
        return localStorage.value.get("guestId") !== null ? localStorage.value.get("guestId") : null;
    }

    const clearGuestId = () => {
        store.commit('user/setGuestId', null);
        localStorage.value.delete("guestId");
    }

    return {
        fetchGuestId: fetchGuestId,
        clearGuestId: clearGuestId,
    }
}
