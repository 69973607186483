'use strict'

var CryptoJS = require("crypto-js");

function requestDragonpay(options) {
    const payload = createPayload(options);
    const query = generateQueryString(payload);
    const redirectUrl = `${config('DRAGONPAY_URL')}?${query}`;
    return Promise.resolve({
        redirectUrl,
        txnid: payload.txnid
    });
}

function createPayload({ amount, ccy, description, email, txnid }) {
    const vars = {
        merchantid: config('DRAGONPAY_MERCHANT_ID'),
        txnid,
        amount,
        ccy,
        description,
        email
    };
    vars.digest = generateChecksum(vars);
    return vars;
}

function generateQueryString(options) {
    const values = [];
    const keys = Object.keys(options);
    keys.forEach(key => {
        values.push(`${key}=${options[key]}`);
    });
    return values.join('&');
}

function generateChecksum(options) {
    const values = [];
    const keys = Object.keys(options);
    keys.forEach(key => {
        const value = options[key];
        values.push(value);
    });
    values.push(config('DRAGONPAY_MERCHANT_PASSWORD'));
    const message = values.join(':');
    return CryptoJS.SHA1(message);
}

function config(key) {
    return process.env[key] || process.env[`VUE_APP_${key}`];
}

module.exports = {
    requestDragonpay
};