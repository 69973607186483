<template>
  <div class="w-full h-[230px] mb-6 relative">
    <input 
		type="text" list="places" class="w-1/2 absolute top-0 rounded-lg z-[999] text-sm shadow-lg"
		placeholder="Search cities or landmarks"
		v-model="mapSearchInput"
	>
	<datalist id="places">
		<option 
			v-for="(suggestion,index) in mapSearchSuggestions"
			:key="`map-search-suggestion-${index}`"
			:value="suggestion.place_name"
		/>
	</datalist>
    <div ref="mapRef" class="w-full h-full object-cover rounded-lg" />
  </div>
</template>
<script>

import { ref, onMounted, watch } from 'vue';
import useMap from '@/composables/useMap';

export default {
	name: 'LocationMap',
	props:{
		modelValue:{
            type:[ Object, Array ],
            default:() => ({}),
        },
		zoom:{
			type: Number,
			default: 17
		},
		disableUI:{
			type: Boolean,
			default: true
		}
	},
	emits:[ 'update:modelValue' ],
	setup(props, { emit }) {

		const { 
            initMap, addMapMarker, setMapView, moveMapMarker, 
            startForwardGeocoding, mapRef
        } = useMap();
		
		/*
		|--------------------------------------------------------------------------
		| @Data
		|--------------------------------------------------------------------------
		*/
		
		const isInitialized = ref(false);
		const marker = ref(null);
		const mapSearchInput = ref(null);
        const mapSearchSuggestions = ref([]);

		/*
		|--------------------------------------------------------------------------
		| @Methods
		|--------------------------------------------------------------------------
		*/

		const initMapHandler = (coords) => {
			initMap(initMapCallback, {
				zoom: props.zoom,
				coords: {
					latitude: coords.lat,
					longitude: coords.lng,
				}
			});
		};
		
		const initMapCallback = (coords) => {
			updateCoordinates(coords);
            addMapMarker({ coords }, addMapMarkerCallback);
			isInitialized.value = true;
        };

        const addMapMarkerCallback = (pin) => {
			marker.value = pin;
            marker.value.on('dragend', () => updateCoordinates(marker.value.getLatLng()));
        };

        const updateCoordinates = (coords) => (emit('update:modelValue', coords));
        const pinToInput = () => {
			const { geometry } = mapSearchSuggestions.value.find(x => x.place_name === mapSearchInput.value) || {};
            if(geometry) {
                const { coordinates } = geometry;
                moveMapMarker({
                    marker: marker.value, 
                    coords: { latitude: coordinates[1], longitude: coordinates[0] }
                }, updateCoordinates);
            }
			mapSearchInput.value = null;
			mapSearchSuggestions.value = [];
        };
		
		/*
		|--------------------------------------------------------------------------
		| @Watch
		|--------------------------------------------------------------------------
		*/

		watch(
            () => mapSearchInput.value,
            (input) => {
				const result = mapSearchSuggestions.value.find(x => x.place_name === input);
				if(!result) startForwardGeocoding(input, (items) => (mapSearchSuggestions.value = items));
				else pinToInput();
			}
        );

		watch(
			() => props.modelValue,
			(coords) => {
				if(!coords && (!coords.lat || !coords.lng)) return;
				if(isInitialized.value) {
					setMapView(coords);
					moveMapMarker({
						marker: marker.value, 
						coords: { latitude: coords.lat, longitude: coords.lng }
					}, () => {});
				}
				else {
					initMapHandler(coords);
				}
			},
			{ deep: true }
		);

		const pinUserLocation = () => {
			if(navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(
					position => {
						initMapHandler({ lat: position.coords.latitude, lng: position.coords.longitude });
					},
					error => {
						console.log(error.message);
					},
				)   
			}
		}

		onMounted(async() => {
			if(!isInitialized.value) {
				if(props.modelValue.lat && props.modelValue.lng) {
					initMapHandler(props.modelValue);
				}
				else {
					pinUserLocation();
					// initMapHandler({ lat: 14.599223, lng: 120.98136 });
				}
			}
        });

		return {
			mapRef,
			mapSearchInput,
            mapSearchSuggestions,
			pinToInput
		}
	}
}
</script>