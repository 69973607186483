<template>
  <div class="flex flex-col items-start pr-6 border-r h-[420px] overflow-y-auto scrollbar-style">
    <img :src="img" class="rounded-md w-full h-56 object-cover">
    <h4 class="text--primary font-bold">
      {{ name }}
    </h4>
    <p class="text-[#A9B5C6] text-sm">
      {{ title }}
    </p>
      <div class="flex flex-wrap items-center gap-2 my-4">
        <p v-for="badge in shortRating.split(',')" :key="badge"
          class="font-normal whitespace-nowrap text-sm text-[#02ADF0] px-4 py-0.5 border border-[#02ADF0] block rounded-full">
          {{ badge }}
        </p>
      </div>
    <p class="text-[#343A40] text-base leading-7">
      {{ review }}
    </p>
  </div>
</template>
<script type="text/javascript">
export default {
    name: 'ReviewCard',
    props: {
        name: {
            type: String,
            default: null
        },
        title: {
            type: String,
            default: null
        },
        shortRating: {
          type: String,
          default: null
        },
        review: {
            type: String,
            default: null
        },
        img: {
          type: String,
          default: null
        }
    }
}
</script>