export const content = {
    namespaced: true,
    state: {
        items: [],  
    },
    mutations: {
        set(state, value) {
            state.items = value || [];
        },
    },
};
