import { ref } from 'vue';

import L from "leaflet";
import "leaflet/dist/leaflet.css";

/*
|--------------------------------------------------------------------------
| @useMap
| 
| Initialize map. Expose `mapRef` as the container's ref.
|--------------------------------------------------------------------------
*/

export default function useMap() {

    const accessToken = process.env.VUE_APP_MAPBOX_TOKEN;

    const map = ref(null);
    const mapRef = ref(null);
    
    /**
     * Initialize Leaflet Map
     * 
     * @param   function callback 
     * @param   object options 
     * 
     * @returns void
     */
    const initMap = async (callback, options = {}) => {

        const zoom = options.zoom || 17;
        const { coords } = options;
        
        const coordinates = L.latLng(coords.latitude, coords.longitude);

        map.value = L.map(mapRef.value).setView(coordinates, zoom);
        map.value.removeControl(map.value.zoomControl);

        L.tileLayer(`https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=${accessToken}`, {
                maxZoom: zoom,
                id: 'mapbox/streets-v11',
                tileSize: options.tileSize || 512,
                zoomOffset: -1,
                accessToken
            }).addTo(map.value);

        callback(coordinates);
    };

    /**
     * Move map center
     * 
     * @param   LatLng coordinates 
     * @returns void
     */
    const setMapView = (coordinates) => {
        if(map.value) map.value.setView(coordinates);
    };

    /**
     * Set map bounds
     * 
     * @param   LatLngBounds bbox 
     * @returns void
     */
    const setMapBounds = (bbox) => (map.value.fitBounds([ [ bbox[0], bbox[1] ], [ bbox[2], bbox[3] ] ], { padding: [40, 50] }));

    /**
     * Add map marker
     * 
     * @param   object options 
     * @param   function callback 
     * 
     * @returns void
     */
    const addMapMarker = (options, callback, draggable = true) => {

        const iconExtender = L.Icon.extend({ iconSize: [63, 63] });
        const icon = new iconExtender({ iconUrl: '/assets/icon/ic-location.svg' });
        const marker = L.marker(options.coords, { icon, draggable: draggable }).addTo(map.value);
        
        callback(marker);
    };
    
    /**
     * Add rider map marker
     * 
     * @param   object options 
     * @param   function callback 
     * 
     * @returns void
     */
    const addRiderMapMarker = (options, callback, draggable = true) => {
        const iconExtender = L.Icon.extend({ iconSize: [20, 20] });
        const icon = new iconExtender({ iconUrl: '/assets/icon/ic-rider-location.svg' });
        const marker = L.marker([options.latitude, options.longitude], { icon, draggable: draggable }).addTo(map.value);
        
        callback(marker);
    };
    /**
     * Move map marker
     * 
     * @param   object options 
     * @param   function callback 
     * 
     * @returns void
     */
    const moveMapMarker = (options, callback) => {
        const { coords } = options;
        const coordinates = L.latLng(coords.latitude, coords.longitude);
        options.marker.setLatLng(coordinates);
        
        setMapView(coordinates);
        callback(coordinates);
    };

    /**
     * Search places via Mapbox
     * 
     * @param   string input 
     * @param   function callback 
     * 
     * @returns void
     */
    const startForwardGeocoding = (input, callback) => {
        window.axios
            .get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${input}.json?autocomplete=true&country=PH&access_token=${accessToken}`)
            .then(({ data }) => callback(data.features));
    };

    /**
     * Get addresss from coordinates via Mapbox
     * 
     * @param   object { lat, lng } 
     * @param   function callback 
     * 
     * @returns void
     */
    const startReverseGeocoding = ({ lat, lng }, callback) => {
        window.axios
            .get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?country=PH&access_token=${accessToken}`)
            .then(({ data }) => callback(data.features));
    };

    return {
        mapRef,
        initMap,
        setMapView,
        setMapBounds,
        addMapMarker,
        moveMapMarker,
        startForwardGeocoding,
        startReverseGeocoding,
        addRiderMapMarker,
    }
}