/* eslint-disable */
<template>
    <div class="absolute right-0 top-0">
        <select name="language" id="language" v-model="selectedLanguage" class="h-[34px] lg:h-10 block w-full pl-3 pr-10 py-2 text-sm focus:outline-none focus:ring-[#02ADF0] focus:border-[#02ADF0] sm:text-sm border-0">
            <option v-for="country in countries" :key="country.title" :value="country.code">{{ country.title }}</option>
        </select>
    </div>
</template>
<script>
import { computed, defineComponent, onMounted, ref, watch } from 'vue'
import { useRouter } from 'vue-router';
import useLocalStorage from '@/composables/useLocalStorage';

export default defineComponent({
    emits:[ 'translate' ],
    setup(props, { emit }) {

        const router = useRouter();
        const { localStorage } = useLocalStorage();

        const initialized = ref(false);
        const selectedLanguage = ref(null);
        
        const countries = computed(() => [
            { code: 'en|en', title: 'English' },
            { code: 'en|zh-CN', title: 'Chinese (Simplified)' },
            { code: 'en|zh-TW', title: 'Chinese (Traditional)' },
            { code: 'en|ja', title: 'Japanese' },
            { code: 'en|ko', title: 'Korean' }
        ]);

        const doTranslation = async (code, oldCode) => {
            await localStorage.value.set('lang', code);
            if(typeof window !== 'undefined') {
                window.location = `${router.currentRoute.value.fullPath}#googtrans(${code})`;
                if(initialized.value) location.reload();
                else initialized.value = true;
                emit('translate');
            }
            if(oldCode === 'en|en') {
                window.location.href = router.currentRoute.value.path;
                location.reload();
            }
        };

        watch(
            () => selectedLanguage.value,
            (code, oldCode) => {
                if(typeof code === 'string') doTranslation(code, oldCode);
            }
        );

        onMounted(() => {
            localStorage.value
                .get('lang')
                .then(result => selectedLanguage.value = result || 'en|en');
        })

        return {
            selectedLanguage,
            countries,
            doTranslation
        }
    },
})
</script>
