<!-- eslint-disable -->
<template>
    <div class="product-purchase__subscription">
        <div class="flex items-center justify-between mb-5">
            <p class="text-xl font-bold">Edit Items</p>
            <p
                class="text-sm underline text--secondary cursor-pointer"
                @click="showReplacementModal = true"
            >
                Add Items
            </p>
        </div>
        <div class="flex justify-between items-center md:mb-5 mb-4">
            <h5 class="font-bold">
                Frequency: {{ subscription.frequency_label }} / Duration:
                {{ subscription.duration_label }}
            </h5>
        </div>
        <div class="checkout-items__hldr flex flex-col space-y-8">
            <template
                v-for="(item, index) in products"
                :key="`subscription-item-${item.id}`"
            >
                <subscription-cart-item
                    :item="item"
                    :product-count="products.length"
                    @removeItem="removeItem(index)"
                />
            </template>
        </div>
        <add-items-modal
            :is-open="showReplacementModal"
            @close="closeReplacementModal"
            @addItems="addItems"
            :duration="subscription.duration"
        />
    </div>
</template>
<script type="text/javascript">
import "@vuepic/vue-datepicker/dist/main.css";

import { defineComponent, ref, watch } from "vue";

import SubscriptionCartItem from "./SubscriptionCartItem.vue";
import AddItemsModal from "./AddItemsModal.vue";

export default defineComponent({
    name: "SubscriptionCartItemPage",
    components: {
        SubscriptionCartItem,
        AddItemsModal,
    },
    props: {
        subscription: {
            type: Object,
            default: () => {},
        },
        items: {
            type: Array,
            default: () => [],
        },
    },
    setup(props) {
        const products = ref([]);

        const addItems = (items) => {
            items.forEach((item) => products.value.push(item));
        };

        const showReplacementModal = ref(false);

        const closeReplacementModal = () => {
            showReplacementModal.value = false;
        };

        const removeItem = (index) => {
            products.value.splice(index, 1);
        };

        watch(
            () => props.items,
            (val) => (products.value = val)
        );

        return {
            showReplacementModal,
            closeReplacementModal,
            products,
            addItems,
            removeItem,
        };
    },
});
</script>
