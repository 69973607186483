import { DateTime } from "luxon";
export default function useFormat() {
    const moneyFormat = (value = 0, decimals = 2, prefix = 'P') => {
        value = stringToFloat(value);
        return prefix + (parseFloat(value).toFixed(decimals).replace(/\d(?=(\d{3})+\.)/g, '$&,')).toString();
    }

    const stringToFloat = (value) => {
        if(typeof value === 'string' && value.indexOf(',') !== -1) {
            value = value.replace(',', '');
        }
        return parseFloat(value);
    };
    
    const dateFormat = (date, format = "DDD") => {
        if(!date) {
            date = DateTime.now();
        }
        return DateTime.fromISO(date).toFormat(format);
    }

    const numberOnlyInput = (event) => {
        const result =
            event.ctrlKey ||
            event.altKey ||
            (47 < event.keyCode && event.keyCode < 58 && event.shiftKey == false) ||
            (95 < event.keyCode && event.keyCode < 106) ||
            event.keyCode == 8 ||
            event.keyCode == 9 ||
            (event.keyCode > 34 && event.keyCode < 40) ||
            event.keyCode == 46;

        if (!result) {
            event.preventDefault();
        }
        
        return true;
    };
    
    const camelize = (str) => {
        
        const isSnake = str.indexOf('_') != -1;
        const isKebab = str.indexOf('-') != -1;
        const isNone = !isSnake && !isKebab;
        
        let delimiter = '';

        if(isSnake) delimiter = '_';
        if(isKebab) delimiter = '-';

        let splits = str.split(delimiter);
        if(!isNone) splits = splits.map((x,i) => i != 0 ? x.charAt(0).toUpperCase() + x.slice(1) : x);

        return splits.join('');
    };

    const makeGuestId = (length) => {
        let result           = '';
        let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for(let i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * 
            charactersLength));
       }
       return result;
    }

    return {
        moneyFormat,
        dateFormat,
        numberOnlyInput,
        camelize,
        stringToFloat,
        makeGuestId
    }
}
