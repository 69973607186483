<template>
    <div>
        <ion-button
        class="btn-primary"
        shape="round"
        size="large"
        @click="openChat = !openChat"
        >
            <ion-icon
            src="/assets/icon/chat.svg"
            class="w-6 h-6"></ion-icon>
            <span class="ml-4 text-sm">Chat with your rider</span>
        </ion-button>

        <!--------------------------------------- 
            CHAT MENU
        ---------------------------------------->
        <SideChatMenu
            :invoice-id="invoiceId"
            :rider-name="riderName"
            :is-visible="openChat"
            @close="openChat = false"
        />
    </div>
</template>

<script type="text/javascript">

import {
    IonIcon,
    IonButton
} from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import SideChatMenu from '@/components/chat/SideChatMenu.vue';

export default defineComponent({
    name: 'ChatButton',
    components: {
        IonIcon,
        IonButton,
        SideChatMenu
    },
    props : {
        invoiceId : {
            default: null,
            type : Number
        },
        riderName : {
            default: null,
            type : String
        }
    },
    setup() {

        const openChat = ref(false);

        return {
            openChat
        }
    },
});

</script>