<template>
  <div class="lg:col-span-2 col-span-full lg:p-16 p-6 py-10">
    <div class="h-[400px]">
      <div class="mb-3">
        <h2 class="text--secondary font-bold">
          Delivery Address
        </h2>
        <p class="text-base">
          Your location allows us to tailor-fit our offered products to your needs.
        </p>
      </div>
      <div class="flex flex-col gap-2.5 mb-6">
        <!-- Province -->
        <menu-selector
          label="Province"
          item-id="loc-province"
          :options="provinces"
          item-value="code"
          item-label="name"
          v-model="form.province"
        />
        <!-- City -->
        <menu-selector
          label="City"
          item-id="loc-city"
          :options="cities"
          item-value="code"
          item-label="name"
          v-model="form.city"
        />
        <!-- Barangay -->
        <menu-selector
          label="Barangay"
          item-id="loc-barangay"
          :options="barangays"
          item-value="code"
          item-label="name"
          v-model="form.barangay"
        />
      </div>
      <div class="flex items-center justify-between">
        <ion-button
          size="large"
          class="btn-primary"
          @click="nextStep"
          :disabled="cannotContinue"
        >
          Continue
        </ion-button>
        <p class="text--secondary text-sm">
          <span class="font-bold">01</span>/03
        </p>
      </div>
    </div>
  </div>
</template>
<script>

import { computed, ref, watch } from 'vue'
import { useStore } from "vuex";

import { IonButton } from '@ionic/vue';
import MenuSelector from '@/components/MenuSelector.vue';

export default {
  name: 'StepOne',
  components:{
    IonButton,
    MenuSelector
  },
  emits:[ 'next', 'fetch-provinces', 'fetch-cities', 'fetch-barangays' ],
  props:{
    currentStep:{
      type: Number,
      default: 1
    }
  },
  setup(props, { emit }) {

    /*
    |--------------------------------------------------------------------------
    | @Data
    |--------------------------------------------------------------------------
    */
      
    const store = useStore();
    
    const provinces = computed(() => (store.state.address.provinces || []).sort((a,b) => a.name > b.name ? 1 : -1));
    const cities = computed(() => (store.state.address.cities || []).sort((a,b) => a.name > b.name ? 1 : -1));
    const barangays = computed(() => (store.state.address.barangays || []).sort((a,b) => a.name > b.name ? 1 : -1));

    const form = ref({
      province: 'Province',
      city: 'City',
      barangay: 'Barangay'
    });

    /*
    |--------------------------------------------------------------------------
    | @Computed
    |--------------------------------------------------------------------------
    */

    const fetchLocationUrl = computed(() => store.state.api.routes.fetchLocations);
    const cannotContinue = computed(
      () => form.value.province === 'Province' 
        || form.value.city === 'City' 
        || form.value.barangay === 'Barangay'
    );

    /*
    |--------------------------------------------------------------------------
    | @Methods
    |--------------------------------------------------------------------------
    */

    const nextStep = () => {
      store.commit('address/setForm', form.value);
      emit('next');
    };

    /*
    |--------------------------------------------------------------------------
    | @Watch
    |--------------------------------------------------------------------------
    */
    
    watch(
      () => fetchLocationUrl.value, 
      (url) => {
        if(typeof url === 'string') emit('fetch-provinces');
      }, 
      { immediate: true }
    );

    watch(
      () => props.currentStep, 
      (step) => {
        if(step != 1) return;
        
        if(Object.keys(store.state.address.form).length !== 0) {
          form.value = store.state.address.form;
        }
      }, 
      { immediate: true }
    );

    watch(() => form.value.province, (id) => emit('fetch-cities', id));
    watch(() => form.value.city, (id) => emit('fetch-barangays', id));

    /*
    |--------------------------------------------------------------------------
    | @Return
    |--------------------------------------------------------------------------
    */

    return {
      provinces,
      cities,
      barangays,
      form,
      nextStep,
      cannotContinue
    }
  }
}
</script>