<template>
  <div class="cart-summary__hldr bg--primary--50 border-radius-14 px-4 md:px-6 py-6 md:py-8">
    <h4 class="font-bold text--secondary mb-4">
      Order Summary
    </h4>
    <div class="flex justify-between" v-if="isAuthenticated">
      <h5 class="font-bold text--gray--800">
        Points to earn
      </h5>
      <h5 class="text--gray--800">
        {{ cart.points_earned || 0 }} Droplet/s
      </h5>
    </div>
    <hr class="my-6">
    <template v-if="isCheckout">
      <div class="flex justify-between">
        <h5 class="font-bold text--gray--800">
          Payment Method
        </h5>
        <h5 class="text--gray--800">
          {{ paymentMethod.name }}
        </h5>
      </div>
      <hr class="my-6">
    </template>
    <!-- <div class="flex justify-between mb-2">
      <h5 class="font-bold text--gray--800">
        Balance (for replacement items)
      </h5>
      <h5 class="text--gray--800">
        {{ replacementBalance != 0 ? moneyFormat((replacementBalance) , 2, '₱') : moneyFormat(0, 2, '₱') }}
      </h5>
    </div> -->
    <div class="flex justify-between mb-2">
      <h5 class="font-bold text--gray--800">
        Subtotal
      </h5>
      <h5 class="text--gray--800">
        <!-- {{ moneyFormat(cart.subtotal, 2, '₱') }}  -->
        <!-- {{ moneyFormat(cartSubtotal != 0 ? cartSubtotal: cart.subtotal, 2, '₱') }}  -->
        {{ moneyFormat(cartSubtotal != 0 ? cartSubtotal: cart.subtotal, 2, '₱') }} 
      </h5>
    </div>

    <template v-if="isCheckout">
      <div 
        class="flex justify-between mb-2" 
        v-for="fee in extraFees" 
        :key="`extra-fee-${fee.id}`"
      >
        <h5 class="font-bold text--gray--800">
          {{ fee.name }}
        </h5>
        <h5 class="text--gray--800">
          + {{ moneyFormat(fee.amount * invoiceCount, 2, '₱') }}
        </h5>
      </div>
      <div
        class="flex justify-between mb-2"
        v-if="shippingFee > 0"
      >
        <h5 class="font-bold text--gray--800">
          Shipping
        </h5>
        <h5 class="text--gray--800">
          + {{ moneyFormat(shippingFee, 2, '₱') }}
        </h5>
      </div>
      <div 
        class="flex justify-between mb-2" 
        v-if="referralDiscount > 0"
      >
        <h5 class="font-bold text--gray--800">
          Referral Code
        </h5>
        <h5 class="text--gray--800">
          - {{ moneyFormat(referralDiscount, 2, '₱') }}
        </h5>
      </div>
      <div
        class="flex justify-between mb-2"
        v-if="appliedPoints > 0"
      >
        <h5 class="font-bold text--gray--800">
          Points Used
        </h5>
        <h5 class="text--gray--800">
          - {{ moneyFormat(appliedPoints, 2, '₱') }}
        </h5>
      </div>
      <div
        class="flex justify-between mb-2"
        v-if="firstPurchaseDiscount > 0"
      >
        <h5 class="font-bold text--gray--800">
          1st Purchase Discount
        </h5>
        <h5 class="text--gray--800">
          - {{ firstPurchaseDiscount != 0 ? moneyFormat(firstPurchaseDiscount, 2, '₱') : 0 }}
        </h5>
      </div>
      <div
        class="flex justify-between mb-2"
        v-if="giftCardAmount > 0"
      >
        <h5 class="font-bold text--gray--800">
          Gift Card Discount
        </h5>
        <h5 class="text--gray--800">
          - {{ moneyFormat(giftCardAmount, 2, '₱') }}
        </h5>
      </div>
      <div
        class="flex justify-between mb-6"
        v-if="voucherDiscount > 0"
      >
        <h5 class="font-bold text--gray--800">
          Voucher Discount
        </h5>
        <h5 class="text--gray--800">
          - {{ moneyFormat(voucherDiscount, 2, '₱') }}
        </h5>
      </div>
    </template>

    <template v-else>
      <div class="flex justify-between mb-6">
        <h5 class="font-bold text--gray--800">
          Shipping
        </h5>
        <h5 class="text--gray--800">
          Calculated at checkout
        </h5>
      </div>
    </template>
    <div class="flex justify-between mb-6">
      <h4 class="font-bold text--gray--800">
        Total
      </h4>
      <h4 class="font-bold text--gray--800">
        {{ moneyFormat(total, 2, '₱') }}
      </h4>
    </div>
    <div class="w-full">
      <template v-if="!isCheckout">
        <ion-button
          class="font--main btn-primary capitalized mb-4 w-full"
          shape="round"
          size="large"
          @click="redirectCheckout"
          :disabled="checkoutIsEmpty"
        >
          Proceed to Checkout
        </ion-button>
        <ion-button
          shape="round"
          size="full"
          class="font--main btn-link text--primary my-0 capitalized font-size--x-small"
          href="/shop"
        >
          Return to Shopping
        </ion-button>
      </template>
      <template v-else>
        <ion-button
          @click="checkout"
          class="font--main btn-primary capitalized mb-4 w-full"
          shape="round"
          size="large"
          :disabled="!hasItems || hasUnavailableItems || incompleteGuestInfo"
        >
          Place Order
        </ion-button>
      </template>
    </div>
  </div>
</template>
<script type="text/javascript">
/* eslint-disable */
import { computed, defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import useAuth from '@/composables/useAuth';
import useFormat from '@/composables/useFormat.js';

import { IonButton } from '@ionic/vue';

export default defineComponent({
  name: 'CartSummary',
  components: {
    IonButton
  },
  emits:[ 'checkout' ],
  props:{
    isCheckout:{
      default: false,
      type: Boolean
    }
  },
  setup(props, { emit }) {
    
    const store = useStore();
    const router = useRouter();

    const { isAuthenticated } = useAuth();
    const { moneyFormat, stringToFloat } = useFormat();

    /*
		|--------------------------------------------------------------------------
		| @Computed
		|--------------------------------------------------------------------------
		*/

    const options = computed(() => {
      let items = [...store.state.checkout.subscriptionPayload];
      items.push(store.state.checkout.oneTimePayload);
      return items;
    });

    const replacementBalance = computed(() => cartItems.value
        .filter((a) => a.not_available_option == 4)
        .reduce((a,r) => a + parseFloat(r.replacement.price_per_case * r.quantity - r.discounted_price), 0))

    const cart = computed(() => store.state.cart.data);
    const cartSubtotal = computed(() => store.state.checkout.dataItems.reduce((a,r) => a + parseFloat(r.subtotal), 0));
        // {{ moneyFormat(cartSubtotal != 0 ? cartSubtotal: cart.subtotal, 2, '₱') }} 
    const discountAmount = computed(() => cart.value.subtotal - cartSubtotal.value)
    // const cartItems = computed(() => store.state.checkout.dataItems);
    const cartItems = computed(() => {
      return router.currentRoute.value.name == 'checkout'
        // ? store.state.checkout.items
        ? store.state.checkout.dataItems
        : store.state.cart.items;
    });

    const invoiceCount = computed(() => {
      const payload = cartItems.value.filter(x => x.type == 1).length > 0 ? 1 : 0;
      return payload + store.state.cart.subscriptions.filter(a => cartItems.value.map(b => b.subscription_id).includes(a.id)).length;
    });

    const extraFees = computed(() => store.state.settings.extraFees);
    const shippingFee = computed(() => {
      return options.value.reduce((acc,item) => {
        const option = store.state.settings.fees.find(x => x.id == item.deliveryMethod) || {};
        const minimum = parseFloat(option.free_delivery_fee) || 0;
        const fee =  parseFloat(option.delivery_fee) || 0;
        const subtotal = getItemArray(item.id).reduce((acc,item) => acc + parseFloat(item.subtotal), 0);
        return minimum > subtotal ? acc + fee : acc;
      }, 0);
    });

    const giftCardAmount = computed(() => {
      return options.value.reduce((acc,item) => acc + (item.giftCardAmount || 0), 0);
    });

    const appliedPoints = computed(() => {
      return options.value.reduce((acc,item) => acc + (item.points || 0), 0);
    });

    const referralDiscount = computed(() => {
      let discount = 0;
      if(store.state.checkout.referralCode) {
        const refs = store.state.settings.referrals || {};
        const amount = parseInt(refs.amount);
        switch(refs.type) {
          case 1: discount = cart.value.subtotal * (amount/100); break;
          case 2: discount = amount; break;
        }
      }
      return discount;
    });

    const paymentMethod = computed(() => {
      const method = store.state.checkout.paymentMethod;
      return store.state.settings.paymentOptions.find(x => x.code == method) || {};
    });

    const firstPurchaseDiscount = computed(() => store.state.checkout.firstPurchaseDiscount)

    // const voucherDiscount = computed(() => store.state.checkout.voucherDiscount)
    const voucherDiscount = computed(() => {
      return options.value.reduce((acc,item) => parseFloat(acc) + (parseFloat(item.voucherAmount) || 0), 0);
    });

    const total = computed(() => {
      const add = extraFees.value.reduce((a,x) => a + parseFloat(x.amount), 0) * invoiceCount.value + shippingFee.value;
      const subtract = parseFloat(giftCardAmount.value) + 
        parseFloat(appliedPoints.value) + 
        parseFloat(referralDiscount.value) + 
        parseFloat(firstPurchaseDiscount.value) + 
        parseFloat(voucherDiscount.value);
      // const subtotal = cart.value.subtotal || 0;
      const cartTotal = cart.value.subtotal || 0;
      const subtotal = cartSubtotal.value ? cartSubtotal.value : cartTotal;
      if(props.isCheckout) {
        return stringToFloat(subtotal) + add - subtract
      } else return stringToFloat(subtotal);
    });

    const checkoutIsEmpty = computed(() => !store.state.checkout.items.length);
    const hasUnavailableItems = computed(() => cartItems.value.findIndex(x => x.unavailable) !== -1);
    const hasItems = computed(() => cartItems.value.length > 0);

    const incompleteGuestInfo = computed(() => {
      const user = store.state.user.data;
      return isAuthenticated.value 
        ? false 
        : !user.first_name || !user.last_name || !user.email || !user.mobile_number;
    });


    /*
		|--------------------------------------------------------------------------
		| @Methods
		|--------------------------------------------------------------------------
		*/

    const getItemArray = (id) => {
      if(id) return cartItems.value.filter(x => x.subscription_id == id);
      return cartItems.value.filter(x => x.type == 1);
    };

    const redirectCheckout = () => {
      if(store.state.user.token) window.location.href = '/checkout';
      else window.location.href = '/login?user=guest'; 
    };

    const checkout = () => {
      emit('checkout');
    }
    
    return {
      cart,
      invoiceCount,
      extraFees,
      shippingFee,
      giftCardAmount,
      appliedPoints,
      referralDiscount,
      paymentMethod,
      total,
      checkoutIsEmpty,
      hasUnavailableItems,
      hasItems,
      incompleteGuestInfo,
      moneyFormat,
      isAuthenticated,
      redirectCheckout,
      checkout,
      replacementBalance,
      //
      cartSubtotal,
      discountAmount,
      firstPurchaseDiscount,
      voucherDiscount
    }
  }
})
</script>