<template>
  <div class="grid grid-cols-2 gap-4 mb-2">
    <div class="lg:col-span-1 col-span-full">
      <!-- Province -->
      <menu-selector 
        label="Province"
        :options="provinces" 
        item-value="code"
        item-label="name"
        :has-error="hasError('provinceCode')"
        v-model="form.provinceCode" 
				:class="form.provinceCode ? 'border-green-400' : ''"
      />
      <span v-if="!form.provinceCode" class="text-xs text-red-500">*required</span>
    </div>
    <div class="lg:col-span-1 col-span-full">
      <!-- City -->
      <menu-selector 
        label="City"
        :options="cities" 
        item-value="code"
        item-label="name"
        :has-error="hasError('cityCode')"
        v-model="form.cityCode" 
				:class="form.cityCode ? 'border-green-400' : ''"
      />
      <span v-if="!form.cityCode" class="text-xs text-red-500">*required</span>
    </div>
    <div class="lg:col-span-1 col-span-full">
      <!-- Barangay -->
      <menu-selector 
        label="Barangay"
        :options="barangays" 
        item-value="code"
        item-label="name"
        :class="hasError('barangayCode') ? 'border-red-400' : 'border-green-400'"
        v-model="form.barangayCode" 
      />
      <span v-if="!form.barangayCode" class="text-xs text-red-500">*required</span>
    </div>
    <div class="lg:col-span-1 col-span-full">
      <label
        for="houseNumber"
        class="sr-only"
      >House/Unit No. and house/building name</label>
      <input
        type="text"
        name="houseNumber"
        id="houseNumber"
        class="focus:ring-[#02ADF0] focus:border-[#02ADF0] block w-full border-gray-300 placeholder-gray-400 rounded-[10px] h-12"
        :class="hasError('unitNo') ? 'border-red-400' : 'border-green-400'"
        placeholder="House/Unit No. and house/building name"
        v-model="form.unitNo"
      >
      <span v-if="!form.unitNo" class="text-xs text-red-500">*required</span>
    </div>
    <div class="lg:col-span-1 col-span-full">
      <label
        for="street"
        class="sr-only"
      >Street</label>
      <input
        type="text"
        name="street"
        id="street"
        class="focus:ring-[#02ADF0] focus:border-[#02ADF0] block w-full border-gray-300 placeholder-gray-400 rounded-[10px] h-12"
        :class="hasError('street') ? 'border-red-400' : 'border-green-400'"
        placeholder="Street"
        v-model="form.street"
      >
      <span v-if="!form.street" class="text-xs text-red-500">*required</span>
    </div>
    <div class="lg:col-span-1 col-span-full">
      <label
        for="zipCode"
        class="sr-only"
      >ZIP Code</label>
      <input
        type="text"
        name="zipCode"
        id="zipCode"
        class="focus:ring-[#02ADF0] focus:border-[#02ADF0] block w-full border-gray-300 placeholder-gray-400 rounded-[10px] h-12"
        :class="hasError('zipCode') ? 'border-red-400' : 'border-green-400'"
        placeholder="Zip Code"
        v-model="form.zipCode"
        @keydown="numberOnlyInput"
      >
      <!-- <span class="text-xs text-red-500">*required</span> -->
    </div>
    <div class="lg:col-span-1 col-span-full" v-if="withMobile">
      <label for="mobileNumber" class="sr-only">Mobile Number</label>
      <div class="flex rounded-[10px]">
        <span class="inline-flex items-center px-3 rounded-l-[10px] border border-gray-300 border-r-0 text-gray-400">+ 63</span>
        <span class="inline-flex items-center border-t border-b border-gray-300 text-gray-400">|</span>
        <input 
          v-model="form.mobileNumber"
          type="text" 
          name="mobileNumber" 
          id="mobileNumber" 
          class="flex-1 min-w-0 focus:ring-[#02adf0] focus:border-[#02adf0] block w-full border-l-0 border-gray-300 placeholder-gray-400 rounded-r-[10px] h-12" 
          placeholder="Mobile Number"
          maxlength="10"
          @keydown="numberOnlyInput"
				:class="form.mobileNumber ? 'border-green-400' : ''"
        >
      </div>
      <span v-if="!form.mobileNumber" class="text-xs text-red-500">*required</span>
    </div>
  </div>
</template>
<script>

import { computed, ref, watch } from '@vue/runtime-core';
import { useStore } from 'vuex';
import useFormat from '@/composables/useFormat';
import useLocation from '@/composables/useLocation';

import MenuSelector from '@/components/MenuSelector.vue';

export default {
	name:'AddressLocationForm',
	components:{
		MenuSelector
	},
	props:{
		modelValue:{
			type:Object,
			default:null
		},
		withMobile:{
			type:Boolean,
			default:false
		},
		errors:{
			type:Object,
			default:null
		}
	},
	emits:[ 'update:modelValue' ],
	setup(props, { emit }) {
		
		const store = useStore();
		const { numberOnlyInput } = useFormat();
		const { fetchProvinces, fetchCities, fetchBarangays } = useLocation();

		/*
		|--------------------------------------------------------------------------
		| @Data
		|--------------------------------------------------------------------------
		*/
		
    const form = ref(props.modelValue);
		const errors = computed(() => props.errors);

		const provinces = computed(() => (store.state.address.provinces || []).sort((a,b) => a.name > b.name ? 1 : -1));
    const cities = computed(() => (store.state.address.cities || []).sort((a,b) => a.name > b.name ? 1 : -1));
    const barangays = computed(() => (store.state.address.barangays || []).sort((a,b) => a.name > b.name ? 1 : -1));

		const fetchLocationUrl = computed(() => store.state.api.routes.fetchLocations);

		/*
		|--------------------------------------------------------------------------
		| @Methods
		|--------------------------------------------------------------------------
		*/

		const hasError = (key) => {
			return errors.value && errors.value[key] && errors.value[key].length ? true : false;
		};

		/*
		|--------------------------------------------------------------------------
		| @Watch
		|--------------------------------------------------------------------------
		*/

    watch(
      () => props.modelValue,
      (data) => {
        [ 'barangayCode', 'cityCode', 'provinceCode', 'street', 'unitNo', 'zipCode' ].forEach(key => {
          if(data[key]) form.value[key] = data[key]
        })
        // form.value = { barangayCode, cityCode, provinceCode, street, unitNo, zipCode };
      },
      { immediate: true, deep: true }
    );

		watch(
			() => form.value,
			(content) => emit('update:modelValue', content),
			{ immediate: true, deep: true }
		);

		watch(
			() => fetchLocationUrl.value, 
			(url) => {
				if(typeof url === 'string' && !provinces.value.length) fetchProvinces();
			}, 
			{ immediate: true }
		);

		watch(() => form.value.provinceCode, (id) => {
      store.commit('address/setBarangays', []);
      fetchCities(id)
    }, 
    { immediate: true });
		watch(() => form.value.cityCode, (id) => {      
      if(!id) {
        form.value.barangay = null;
        form.value.barangayCode = null;
      }
      fetchBarangays(id)
    }, 
    { immediate: true });

		/*
		|--------------------------------------------------------------------------
		| @Watch
		|--------------------------------------------------------------------------
		*/

		return {
			provinces,
			cities,
			barangays,
			form,
			hasError,
			numberOnlyInput
		}
	}
}
</script>