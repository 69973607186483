import useAuth from './useAuth';
import { modalController, toastController } from "@ionic/vue";
import Modal from '@/components/Modal.vue';

export default function useResponseHandler() 
{
    const { logout } = useAuth();

    /**
     * Open information dialog
     * 
     * @param   object options
     * @returns Promise
     */
    const openResponseModal = async ({ cssClass, title, description, buttonText, imageLink }) => {
        const modal = await modalController.create({
                component: Modal,
                cssClass,
                componentProps: {
                title,
                description,
                buttonText,
                imageLink,
                closeModalButton: true,
                imageWidth: '28',
                imageHeight: '28',
                full: 'full'
            }
        });
        return modal.present();
    };

    /**
     * Handle success response
     * 
     * @param   string description
     * @returns void
     */
     const successHandler = ({ cssClass, title, description, buttonText, imageLink }) => {
        openResponseModal({
            cssClass: cssClass || 'gen-modal',
            title: title || 'Success!',
            description: description || 'This action was successful',
            buttonText: buttonText || 'Okay',
            imageLink: imageLink || '/assets/icon/ic-status-check.svg'
        });
    };

    /**
     * Handle Product Add to Cart response
     * 
     * @param   string description
     * @returns void
     */
     const addToCartResponseHandler = ({ cssClass, title, description, buttonText, imageLink }) => {
        openResponseModal({
            cssClass: cssClass || 'gen-modal add-to-cart',
            title: title || 'Product added to cart!',
            description: description || '',
            buttonText: buttonText || 'Okay',
            imageLink: imageLink || '/assets/icon/ic-status-check.svg'
        });
    };

    /**
     * Handler error response
     * 
     * @param   object response
     * @returns void
     */
    const errorHandler = (response) => {
        if(response.status === 401) logout();
        else {
            const { cssClass, title, message, error, buttonText, imageLink } = response.data;
            openResponseModal({
                cssClass: cssClass || 'gen-modal',
                title: title || 'Oops!',
                description: message || error || 'There was something wrong',
                buttonText: buttonText || 'Close',
                imageLink: imageLink || '/assets/icon/ic-warning.svg'
            });
        }
    };


    const openToast = async ({ message, duration, icon, color }) => {
        const toast = await toastController
        .create({
          message,
          duration: duration || 1500,
          icon,
          position: 'top',
          cssClass: color,
          buttons: [
            {
              text: 'Close',
              role: 'cancel',
              handler: () => {}
            }
          ]
        })

        await toast.present();
        await toast.onDidDismiss();
    };

    return {
        openResponseModal,
        successHandler,
        addToCartResponseHandler,
        errorHandler,
        openToast
    }
}