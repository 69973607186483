<template>
	<p
		class="hdr-shortcutLink hdr-cart-icon"
		@click="openSideCart"
	>
		<span 
			class="cartbadge font--h6 text--white text-center" 
			v-if="itemCount"
		>
			{{ itemCount }}
		</span>
		<!-- <img
			src="/assets/icon/ic-cart.svg"
			alt="cart-icon"
			width="22"
			height="22"
			class="object-contain block"
		> -->
		<svg width="22" height="22" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clip-path="url(#clip0_1201_16434)">
				<path d="M1.09961 2.1499H3.65161C3.87192 2.14969 4.08721 2.21563 4.26961 2.33919C4.452 2.46275 4.5931 2.63823 4.67461 2.8429L8.79961 13.1499L7.39161 15.9549C7.30681 16.1232 7.26665 16.3104 7.27501 16.4987C7.28336 16.687 7.33994 16.8699 7.43932 17.03C7.5387 17.1901 7.67755 17.322 7.84255 17.4131C8.00755 17.5041 8.19317 17.5512 8.38161 17.5499H18.6996" stroke="#A9B5C6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
				<path d="M5.71973 5.4502H20.6797H20.8997L17.8197 13.1502H8.79973" stroke="#A9B5C6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
				<path d="M9.84473 21.3999H9.95473" stroke="#A9B5C6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
				<path d="M16.4448 21.3999H16.5548" stroke="#A9B5C6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
			</g>
			<defs>
				<clipPath id="clip0_1201_16434">
					<rect width="22" height="22" fill="white" transform="translate(0 0.5)"/>
				</clipPath>
			</defs>
		</svg>
	</p>
</template>
<script>

import { computed, defineComponent } from "@vue/runtime-core";
import { useStore } from "vuex";

export default defineComponent({
	name: 'CartHeaderIcon',
	emits:[ 'open-side-cart' ],
	setup(props, { emit }) {
		const store = useStore();
		const itemCount = computed(() => store.state.cart.items.length);
		const openSideCart = () => {
			emit('open-side-cart');
		}
		return {
			itemCount,
			openSideCart
		}
	},
});
</script>