<template>
  <!-- Remove read class -->
  <div 
    class="notifList-con py-6 border-b border-[#C8CFDA] border-solid pl-6" 
    :class="{ read: !item.read_at }"
    @click="read"
  >
    <div class="flex flex-wrap align-center justify-between mb-2">
      <h5 class="notifList-title relative text-[#343A40]">
        {{ item.data.name }}
      </h5>
      <p class="text--gray--300">
        {{ item.created_at }}
      </p>
    </div>
    <p class="text-[#343A40]">
      {{ item.data.description }}
    </p>
  </div>
</template>

<script type="text/javascript">

import { defineComponent } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'NotificationList',
  emits:[ 'refresh' ],
  props:{
    item:{
      type:Object,
      default:() => ({})
    }
  },
  setup(props, { emit }) {
    const store = useStore();
    const read = () => {
      if(props.item.read_at) return;
      window.axios.get(store.state.api.routes.showNotification, {
        id: props.item.id
      })
      .then(() => {
        emit('refresh');
      });
    };
    return {
      read
    }
  }
})
</script>