<template>
    <ion-page>
        <ion-content fullscreen>Initiating Dragonpay...</ion-content>
    </ion-page>
</template>
<script>
import { computed, defineComponent, onMounted } from 'vue'
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { requestDragonpay } from '@/vendor/PaymentService/services/Dragonpay.js';
import useLocalStorage from '@/composables/useLocalStorage';
import useResponseHandler from '@/composables/useResponseHandler';

import { IonPage, IonContent } from '@ionic/vue';

export default defineComponent({
    components:{ IonPage, IonContent },
    setup() {
        const store = useStore();
        const router = useRouter();

        const { localStorage } = useLocalStorage();
        const { errorHandler } = useResponseHandler();

        const query = computed(() => router.currentRoute.value.query);

        const init = async () => {
            
            const amount = await localStorage.value.get('total') || 0;
            const user = await localStorage.value.get('user');
            const guestId = await localStorage.value.get('guestId');
            const userid = user.id || guestId;

            requestDragonpay({
                userid,
                amount: parseFloat(amount).toFixed(2),
                ccy: 'PHP',
                // description: `PHP${amount} transaction for ${user.value.name}`,
                description: `NS-WEB-${user.first_name}${user.last_name}`,
                email: user.email,
                txnid: query.value.ref
            })
            .then(({ redirectUrl, txnid }) => {
                store.commit('checkout/set', { referenceNumber: txnid });
                localStorage.value.set('checkout', store.state.checkout);
                window.location.href = redirectUrl;
            });
        };

        const verify = async () => {
            const data = await localStorage.value.get('checkout') || {};
            if(data.referenceNumber != query.value.txnid) {
                console.log('WRONG_REF');
                return;
            }

            if(['S','P'].includes(query.value.status)) {
                store.commit('checkout/set', { referenceNumber: query.value.refno, notes: query.value.message });
                const paymentStatus = query.value.status == 'S' ? 'success' : 'pending';
                router.replace({ name:'checkout', query:{ paymentMethod: data.paymentMethod, paymentStatus } });
            }
            else {
                errorHandler({ message: query.value.message });
                router.replace({ name:'checkout', query:{ paymentStatus: 'failure' } });
            }
        };
        
        onMounted(async() => {
            if(!query.value.txnid) init();
            else verify();
        });
    },
})
</script>
