import { useStore } from 'vuex';
import useLocalStorage from "./useLocalStorage.js";
import { useRouter } from 'vue-router';
import { computed } from 'vue';

export default function useAuth() {
    const store = useStore();
    const router = useRouter();
    const { localStorage } = useLocalStorage();

    const isAuthenticated = computed(() => store.state.user.token != null);

    const assignToken = () => {
        refreshToken();
        return localStorage.value.get("authToken")
            .then((token) => {
                if(typeof token == 'string' && token.indexOf('Bearer') != -1) {
                    window.axios.defaults.headers.common["Authorization"] = token;
                    return true;
                }
                return false;
        })
    };

    const refreshToken = () => {
        return localStorage.value.get("authToken")
        .then((token) => {
            if(typeof token == 'string' && token.indexOf('Bearer') != -1) {
                window.axios.defaults.headers.common["Authorization"] = token;
                return window.axios
                    .post(store.state.api.routes.refreshToken)
                    .then(({ data }) => {
                            // console.log(data)
                            setToken(`${data.token_type} ${data.access_token}`, data.user);
                            return true;
                        })
                        .catch(() => {
                            return false;
                        })
                }
                return false;
        })
    }

    const setToken = (token, user) => {
        window.axios.defaults.headers.common["Authorization"] = token;
        localStorage.value.set("authToken", token);
        store.commit("user/setToken", token);
        localStorage.value.set("user", user);
        store.commit("user/setData", user);
    }

    const clearAuth = () => {
        localStorage.value.delete("authToken");
        localStorage.value.delete("user");
        localStorage.value.delete("checkout");
        localStorage.value.delete("referral");
        localStorage.value.delete("cart");
        store.commit("user/setToken", null);
        store.commit("user/setData", null);
        store.commit("user/setDeleteReasons", null);
    };

    const logout = () => {
        clearAuth();

        // Redirect to the home page
        if(onAuthRoute()) {
            router.push("/login");
        }
    }

    const verifyPassword = (password) => {
        return window.axios
            .post(process.env.VUE_APP_API_URL + "/customer/customer-profile/check/password", {"current_password": password})
    }
    
    const changePassword = (form) => {
        return window.axios
            .post(process.env.VUE_APP_API_URL + "/customer/customer-profile/update/password", form ) 
    }

    const onAuthRoute = () => {
        return router.currentRoute.value.meta.needsAuth;
    };

    return {
        setToken,
        assignToken,
        logout,
        verifyPassword,
        changePassword,
        onAuthRoute,
        clearAuth,
        isAuthenticated
    }
}
