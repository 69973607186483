<template>
  <div
    class="product-purchase__one-time"
    v-if="items.length"
  >
    <div class="flex justify-between items-center">
      <h5 class="font-bold">
        One-Time Purchase ({{ items.length }})
      </h5>
      <h5 class="font--21 font-bold text--gray--200">
        Total <span class="font-bold text--secondary">{{ moneyFormat(subtotal, 2, '₱') }}</span>
      </h5>
    </div>
    <FreeShippingReminder 
      :outstanding-amount="outstanding"
      :minimum-required-amount="minimum"
    />
    <FreeShippingCongratulations v-if="forDelivery && outstanding <= 0" />
    <div class="checkout-items__hldr mt-2">
      <template
        v-for="(item, index) in items"
        :key="`one-time-purchase-${index}`"
      >
        <CheckoutItem
          :item="item"
          :is-mobile="isMobile"
          is-checkout-item
          @choose-replacement="openReplacementModal"
          @refresh="refresh"
        />
      </template>
      <DeliveryOptions 
        :type="TYPE_ONE_TIME_PURCHASE" 
        v-model="deliveryMethod" 
      />
      <DiscountOptions 
        :branch-id="branchId"
        :type="TYPE_ONE_TIME_PURCHASE" 
        v-model="discountOptionForm" 
        :items="items"
      />
    </div>
  </div>
</template>
<script>
import { defineComponent, computed, ref, watch } from '@vue/runtime-core';
import { useStore } from 'vuex';
import useFormat from '@/composables/useFormat.js';

import CheckoutItem from './CheckoutItem.vue';
import FreeShippingReminder from './FreeShippingReminder.vue';
import FreeShippingCongratulations from './FreeShippingCongratulations.vue';
import DeliveryOptions from './DeliveryOptions.vue';
import DiscountOptions from './DiscountOptions.vue';

export default defineComponent({
	name: 'OneTimePurchase',
	components:{
    CheckoutItem,
    FreeShippingReminder,
    FreeShippingCongratulations,
    DeliveryOptions,
    DiscountOptions
  },
  emits:[ 'choose-replacement', 'refresh' ],
	props:{
    branchId:{
      type:Number,
      default:null
    },
		items:{
			type:Array,
			default:null
		},
		isMobile:{
			type:Boolean,
			default:false
		}
	},
	setup(props, { emit }) {

    const TYPE_ONE_TIME_PURCHASE = 1;

		const store = useStore();
		const { moneyFormat } = useFormat();

    /*
		|--------------------------------------------------------------------------
		| @Data
		|--------------------------------------------------------------------------
		*/

		const deliveryMethod = ref(null);
    const discountOptionForm = ref({});

    /*
		|--------------------------------------------------------------------------
		| @Computed
		|--------------------------------------------------------------------------
		*/

    const fees = computed(() => store.state.settings.fees);
    const forDelivery = computed(() => {
      const fee = fees.value.find(x => x.id == deliveryMethod.value) || {};
      return fee.is_delivery;
    });

		const minimum = computed(() => {
      const fee = fees.value.find(x => x.id == deliveryMethod.value) || {};
      return fee.free_delivery_fee || 0;
    });

		const subtotal = computed(() => props.items.filter(x => !x.unavailable).reduce((value,item) => value + parseFloat(item.subtotal), 0));
		const outstanding = computed(() => {
      const { giftCardAmount, points } = store.state.checkout.oneTimePayload || {};
      return minimum.value - (subtotal.value - ((giftCardAmount || 0) + (points || 0)));
    });

    /*
		|--------------------------------------------------------------------------
		| @Methods
		|--------------------------------------------------------------------------
		*/

    const openReplacementModal = () => (emit('choose-replacement'));
    const refresh = () => (emit('refresh'));

    /*
		|--------------------------------------------------------------------------
		| @Watch
		|--------------------------------------------------------------------------
		*/

    watch(
    () => discountOptionForm.value,
    (data) => store.commit('checkout/setOneTimePurchase', data),
    { deep: true });

    watch(
    () => deliveryMethod.value,
    (deliveryMethod) => store.commit('checkout/setOneTimePurchase', { deliveryMethod }));

    watch(
    () => store.state.checkout.oneTimePayload,
    (data) => {
      deliveryMethod.value = data.deliveryMethod || deliveryMethod.value;
      [ 'notes', 'points', 'code', 'giftCardId' ].forEach(key => {
        if(data[key]) discountOptionForm.value[key] = data[key];
      });
    },
    { immediate: true, deep: true });

    return {
      TYPE_ONE_TIME_PURCHASE,
			subtotal,
			outstanding,
      minimum,
      deliveryMethod,
      discountOptionForm,
      forDelivery,
			moneyFormat,
      openReplacementModal,
      refresh
		}
	},
})
</script>
