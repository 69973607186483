<!-- eslint-disable -->
<template>
    <div>
        <div class="flex items-start">
            <div
                class="block md:flex hidden items-center w-[94px] pr-5 shrink-0"
            >
                <img
                    width="74"
                    height="74"
                    class="border-radius-5"
                    :src="
                        item.product.initial_image ||
                        '/assets/image/prd-img1.png'
                    "
                    alt="cart-item-img"
                />
            </div>
            <div
                class="w-full flex md:flex-row flex-col justify-between items-center"
            >
                <div class="flex flex-col md:w-auto w-full">
                    <div class="flex items-center justify-between">
                        <h5 class="text--gray--800">
                            <!-- <h5 :class="item.unavailable ? 'text--gray--400' : 'text--gray--800'"> -->
                            {{ product.product.name }}
                        </h5>
                        <img
                            width="42"
                            height="42"
                            class="border-radius-5 md:hidden"
                            :src="
                                item.product.initial_image ||
                                '/assets/image/prd-img1.png'
                            "
                            alt="cart-item-img"
                        /><!-- :src="item.product.initial_image || '/assets/image/prd-img1.png'" -->
                    </div>
                    <div
                        class="flex md:flex-col md:mt-0 mt-2 md:w-auto w-full justify-between"
                    >
                        <h5
                            :class="
                                product.unavailable
                                    ? 'text--gray--400'
                                    : 'font-bold'
                            "
                        >
                            <span
                                class="font-regular text--gray--200 font-normal line-through pr-2"
                            >
                                <strike>{{
                                    moneyFormat(product.original_price, 2, "₱")
                                }}</strike>
                            </span>
                            <span>{{
                                moneyFormat(product.discounted_price, 2, "₱")
                            }}</span>
                        </h5>
                        <span
                            class="text-[#A9B5C6] text-sm underline text-right md:hidden"
                            @click="removeItem"
                        >
                            Remove
                        </span>
                    </div>
                    <h5 class="text--gray--200 font-bold">
                        {{ product.product.points }} Droplet/s
                    </h5>
                </div>
                <div class="flex flex-col justify-between md:w-auto w-full">
                    <div class="product-quantity__option">
                        <button
                            v-if="!updateDisabled"
                            class="minus-qty__btn"
                            @click="decrement(product.product_id)"
                        >
                            -
                        </button>
                        <input
                            class="input-qty text-center font-bold font--21"
                            type="number"
                            :readonly="isReadonlyInput"
                            v-model="product.quantity"
                        />
                        <button
                            v-if="!updateDisabled"
                            class="add-qty__btn"
                            @click="increment(product.product_id)"
                        >
                            +
                        </button>
                    </div>
                    <span
                        v-if="productCount > 1"
                        class="text-[#A9B5C6] text-sm underline text-right mt-5 md:block hidden"
                        @click="$emit('removeItem')"
                    >
                        Remove
                    </span>
                </div>
            </div>
        </div>
        <div class="md:pl-[94px] w-full mt-4">
            <h6 class="text--gray--200 mb-1">
                In case the item is unavailable:
            </h6>
            <ion-select
                :interfaceOptions="popoverSize"
                interface="popover"
                class="frm-select bg--white w-full mb-1"
                placeholder="--"
                v-model="unavailableAction"
            >
                <ion-select-option
                    v-for="option in unavailableOptions"
                    :key="`unavailable-option-${option.id}`"
                    :value="option.id"
                >
                    {{ option.value }}
                </ion-select-option>
            </ion-select>
            <div class="flex justify-between mb-4" v-if="replacementItem.id">
                <h6 class="text--gray--200">
                    <span class="font-bold">Replacement Item: </span>
                    <span>{{ replacementItem.name }}</span>
                </h6>
                <h6
                    class="text--gray--200 cursor-pointer underline font-bold"
                    @click="openReplacementModal"
                >
                    Change
                </h6>
            </div>
        </div>
    </div>
</template>
<script type="text/javascript">
import { computed, defineComponent, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import useAuth from "@/composables/useAuth";
import useCheckout from "@/composables/useCheckout";
import useFormat from "@/composables/useFormat";

import { IonSelect, IonSelectOption, modalController } from "@ionic/vue";
import Modal from "@/components/Modal.vue";

export default defineComponent({
    name: "CartItem",
    components: {
        IonSelect,
        IonSelectOption,
    },
    emits: ["choose-replacement", "refresh", "removeItem"],
    props: {
        isSubscription: {
            default: false,
            type: Boolean,
        },
        productCount: {
            default: 0,
            type: Number,
        },
        checkedAll: {
            type: Boolean,
            default: false,
        },
        item: {
            default: null,
            type: Object,
        },
        disableReplacement: {
            default: false,
            type: Boolean,
        },
        isCheckoutItem: {
            default: false,
            type: Boolean,
        },
        disableInput: {
            default: false,
            type: Boolean,
        },
        isMobile: {
            default: false,
            type: Boolean,
        },
        isReadonlyInput: {
            default: false,
            type: Boolean,
        },
    },
    setup(props, { emit }) {
        const store = useStore();
        const router = useRouter();

        const { isAuthenticated } = useAuth();
        const { addToCheckout, removeFromCheckout, updateCheckoutItem } =
            useCheckout();
        const { moneyFormat } = useFormat();

        /*
    |--------------------------------------------------------------------------
    | @Data
    |--------------------------------------------------------------------------
    */
        const product = ref(props.item);
        const isLoading = ref(false);
        const selected = ref(props.checkedAll);
        const quantity = ref(props.item.quantity);
        const unavailableAction = ref(props.item.not_available_option || 1);
        const frequency = ref(null);
        const duration = ref(null);

        const popoverSize = {
            size: "cover",
        };

        const title = ref(null);
        const message = ref(null);
        const image = ref(null);

        const subscription = computed(
            () =>
                store.state.cart.subscriptions.find(
                    (x) => x.id == props.item.subscription_id
                ) || {}
        );
        const unavailableOptions = computed(
            () => store.state.settings.unavailableOptions
        );
        const frequencyOptions = computed(
            () => store.state.settings.subscriptionOptions.frequencies
        );
        const durationOptions = computed(
            () => store.state.settings.subscriptionOptions.durations
        );

        const updateDisabled = computed(
            () => router.currentRoute.value.name == "checkout"
        );

        /*
    |--------------------------------------------------------------------------
    | @Computed
    |--------------------------------------------------------------------------
    */

        const updateItemUrl = computed(() => {
            return store.state.user.token
                ? store.state.api.routes.cartItemUpdate
                : store.state.api.routes.guestCartItemUpdate;
        });

        const removeItemUrl = computed(() => {
            return store.state.user.token
                ? store.state.api.routes.cartItemRemove
                : store.state.api.routes.guestCartItemRemove;
        });

        const updateSubscriptionUrl = computed(() => {
            return store.state.user.token
                ? store.state.api.routes.cartItemSubscriptionUpdate
                : store.state.api.routes.guestCartItemSubscriptionUpdate;
        });

        const discountedSubtotal = computed(() => {
            return props.item.original_price * props.item.quantity;
        });

        const replacementOption = computed(() => {
            return (
                store.state.checkout.replacements.find(
                    (x) => x.productId == props.item.product_id
                ) || {}
            );
        });

        const replacementItem = computed(() => {
            return (
                store.state.products.items.find(
                    (x) => x.id == props.item.replacement_item_id
                ) || {}
            );
        });

        const cartItem = computed(() => {
            const option = unavailableAction.value;
            const rid =
                option == 4 ? replacementOption.value.replacementItemId : null;
            return {
                id: props.item.id,
                productId: props.item.product_id,
                subscriptionId: props.item.subscription_id,
                type: props.item.type,
                quantity: quantity.value,
                originalPrice: props.item.original_price,
                discountedPrice: props.item.discounted_price,
                notAvailableOption: option,
                replacementItemId: rid,
                branchId: store.state.address.branchId,
                frequency: frequency.value,
                duration: duration.value,
                sessionId: store.state.user.guestId,
            };
        });

        const subscriptionItem = computed(() => ({
            id: props.item.id,
            sessionId: store.state.user.guestId,
            frequency: frequency.value,
            duration: duration.value,
        }));

        /*
    |--------------------------------------------------------------------------
    | @Modals
    |--------------------------------------------------------------------------
    */

        const openModal = async () => {
            const modal = await modalController.create({
                component: Modal,
                cssClass: "gen-modal",
                componentProps: {
                    title: title.value,
                    description: message.value,
                    imageLink: image.value,
                    closeModalButton: true,
                    imageWidth: "64",
                    imageHeight: "64",
                },
            });
            return modal.present();
        };

        const openReplacementModal = () => {
            store.commit("checkout/initReplacement", props.item.product_id);
            emit("choose-replacement");
        };

        /*
    |--------------------------------------------------------------------------
    | @Methods
    |--------------------------------------------------------------------------
    */

        const increment = () => {
            product.value.quantity++;
        };
        const decrement = () => {
            if (quantity.value > 1) quantity.value--;
        };

        const reset = () => {
            unavailableAction.value = 1;
            quantity.value = props.item.quantity;
            frequency.value = subscription.value
                ? subscription.value.frequency
                : null;
            duration.value = subscription.value
                ? subscription.value.duration
                : null;
        };

        const updateItem = () => {
            isLoading.value = true;
            window.axios
                .post(updateItemUrl.value, cartItem.value)
                .then(() => {
                    isLoading.value = false;
                    emit("refresh");
                })
                .catch(({ response }) => {
                    isLoading.value = false;
                    title.value = "Oops!";
                    message.value = response.data.message;
                    image.value = "/assets/icon/ic-status-warning.svg";
                    openModal();
                    reset();
                });
        };

        const updateSubscription = () => {
            window.axios
                .post(updateSubscriptionUrl.value, subscriptionItem.value)
                .then(() => {
                    emit("refresh");
                })
                .catch(({ response }) => {
                    title.value = "Oops!";
                    message.value = response.data.message;
                    image.value = "/assets/icon/ic-status-warning.svg";
                    openModal();
                });
        };

        const removeItem = () => {
            window.axios
                .post(removeItemUrl.value, {
                    itemId: props.item.id,
                    branchId: store.state.address.branchId,
                    sessionId: store.state.user.guestId,
                })
                .then(({ data }) => {
                    title.value = "Success!";
                    message.value = data.message;
                    image.value = "/assets/icon/ic-status-check.svg";
                    openModal();
                    emit("refresh");
                    removeFromCheckout(props.item);
                })
                .catch(({ response }) => {
                    title.value = "Oops!";
                    message.value = response.data.message;
                    image.value = "/assets/icon/ic-status-warning.svg";
                    openModal();
                });
        };

        /*
    |--------------------------------------------------------------------------
    | @Watch
    |--------------------------------------------------------------------------
    */

        // watch(
        //   () => quantity.value,
        //   () => updateItem()
        // );

        watch(
            () => props.item,
            (item) => updateCheckoutItem(item),
            { immediate: true }
        );

        watch(
            () => props.checkedAll,
            (bool) => (selected.value = bool)
        );

        watch(
            () => selected.value,
            (bool) => {
                if (bool) addToCheckout(props.item);
                else removeFromCheckout(props.item);
            }
        );

        watch(
            () => unavailableAction.value,
            (option) => {
                if (option == 4) openReplacementModal();
                else if (option) updateItem();
            }
        );

        watch(
            () => replacementOption.value.replacementItemId,
            (id) => {
                if (id && !isLoading.value && !props.disableReplacement)
                    updateItem();
            }
        );

        watch(
            () => subscription.value,
            (data) => {
                frequency.value = data.frequency;
                duration.value = data.duration;
            },
            { immediate: true, deep: true }
        );

        watch(
            () => frequency.value,
            (value, oldValue) => {
                if (value && oldValue && value != oldValue)
                    updateSubscription();
            }
        );

        watch(
            () => duration.value,
            (value, oldValue) => {
                if (value && oldValue && value != oldValue)
                    updateSubscription();
            }
        );

        return {
            selected,
            quantity,
            frequency,
            duration,
            unavailableAction,
            discountedSubtotal,
            replacementItem,
            unavailableOptions,
            frequencyOptions,
            durationOptions,
            moneyFormat,
            isAuthenticated,
            increment,
            decrement,
            removeItem,
            openReplacementModal,
            popoverSize,
            updateDisabled,
            product,
        };
    },
});
</script>
