<template>
  <div 
    class="promotional-tag bg--primary--50 border-radius-14 p-6 w-full flex items-center mt-4 mb-6"
    v-if="outstandingAmount > 0"
  >
    <img
      width="25"
      height="25"
      src="/assets/icon/ic-info.svg"
      alt="icon"
    >
    <h6 class="text--gray--800 ml-2">
      You’re <span class="font-bold text--secondary">{{ moneyFormat(outstandingAmount, 2, '₱') }}</span> away from getting FREE SHIPPING on your order! (Minimum fee: {{ moneyFormat(minimumRequiredAmount, 2, '₱') }})
    </h6>
    <a
      href="/shop"
      class="font-bold text--secondary font--h6 ml-auto"
    >Shop more</a>
  </div>
</template>
<script>
import { defineComponent } from "@vue/runtime-core";
import useFormat from '@/composables/useFormat.js';
export default defineComponent({
    name: 'FreeShippingReminder',
    props:{
        outstandingAmount:{
            type:[ Number, String ],
            default:null
        },
        minimumRequiredAmount:{
            type:[ Number, String ],
            default:null
        },
    },
    setup() {
        const { moneyFormat } = useFormat();
        return {
            moneyFormat
        }
    }
});
</script>