<template>
  <ion-page>
    <top-navigation />
    <ion-content
      :fullscreen="true"
      class="ion-padding"
    >
      <account-side-navigation>
        <slot>
          <div class=" w-full mb-4 space-y-4">
            <h4 class="text--gray--900 font-bold">
              Order History
            </h4>
          </div>

          <div class="w-full pb-6 border-b">
            <table class="table-fixed">
              <tbody>
                <tr>
                  <td class="pr-4 py-2">
                    <p class="text--gray--800 font-bold">
                      Order Number:
                    </p>
                  </td>
                  <td class="pr-4 py-2">
                    <p class="text--gray-800 font-normal">
                      {{ invoice.invoice_no }}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td class="pr-4 py-2">
                    <p class="text--gray--800 font-bold">
                      Ordered On:
                    </p>
                  </td>
                  <td class="pr-4 py-2">
                    <p class="text--gray-800 font-normal">
                      {{ invoice.formatted_created_at }}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td class="pr-4 py-2">
                    <p class="text--gray--800 font-bold">
                      Status
                    </p>
                  </td>
                  <td class="pr-4 py-2">
                    <p :class="invoice.formatted_order_status == 'Refunded' ? 'text--red--400' : 'text--gray-800 font-normal'">
                      {{ invoice.formatted_order_status }}
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="w-full py-6 border-b">
            <table class="w-[70%] lg:table-fixed table-auto">
              <tbody>
                <tr :class="[ isMobile ? 'flex flex-col' : '' ]">
                  <td class="align-top pb-8">
                    <div class="flex flex-col gap-2">
                      <p class="text--gray--800 font-bold">
                        Contact Information
                      </p>
                      <p class="text--gray-800 font-normal">
                        {{ invoice.full_name }}
                      </p>
                      <p class="text--gray-800 font-normal">
                        {{ invoice.email }}
                      </p>
                      <p class="text--gray-800 font-normal">
                        +63{{ invoice.mobile_number }}
                      </p>
                    </div>
                  </td>
                  <td class="align-top pb-8">
                    <div class="flex flex-col gap-2">
                      <p class="text--gray--800 font-bold">
                        Shipping Information
                      </p>
                      <p class="text--gray-800 font-normal">
                        {{ invoice.full_address }}
                      </p>
                    </div>
                  </td>
                </tr>
                <tr :class="[ isMobile ? 'flex flex-col' : '' ]">
                  <td class="align-top pb-8">
                    <div class="flex flex-col gap-2">
                      <p class="text--gray--800 font-bold">
                        Payment Method
                      </p>
                      <p class="text--gray-800 font-normal">
                        {{ invoice.payment_method_label }}
                      </p>
                    </div>
                    <div class="flex flex-col gap-2 mt-4" v-if="invoice.reference_no && invoice.notes">
                      <p class="text--gray--800 font-bold">
                        Additional Notes
                      </p>
                      <p class="text--gray-800 font-normal">
                        <em>{{ invoice.notes }}</em><br>
                        <!-- <em>#{{ invoice.reference_no }}</em> -->
                      </p>
                    </div>
                  </td>
                  <td class="align-top pb-8">
                    <div class="flex flex-col gap-2 mb-3">
                      <p class="text--gray--800 font-bold">
                        Delivery Method
                      </p>
                      <p class="text--gray-800 font-normal">
                        {{ invoice.delivery_label }}
                      </p>
                    </div>
                    <div v-if="invoice.delivery_label == 'Pick Up'" class="flex flex-col gap-2">
                      <p class="text--gray--800 font-bold">
                        Pick Up Branch
                      </p>
                      <p class="text--gray-800 font-normal">
                        {{ invoice.branch?.address }}
                      </p>
                      <p class="text--gray--800 font-bold">
                        Branch Location
                      </p>
                      <p class="text--gray-800 font-normal">
                        {{ invoice.delivery_label }}
                      </p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- <div
            class="w-full py-6 border-b"
            v-if="moreInvoices.length"
          >
            <p class="text--gray--800 font-bold mb-4">
              You checked out this item along with the following:
            </p>
            <table class="lg:w-[70%] w-full table-auto">
              <tbody>
                <tr
                  v-for="tmp in moreInvoices"
                  :key="`more-invoice-${tmp.id}`"
                >
                  <td class="align-top">
                    <div class="flex items-start gap-4">
                      <img
                        :src="tmp.items[0].product.initial_image || '/assets/image/prd-img1.png'"
                        class="w-14 h-14 object-cover border-radius-5"
                      >
                      <div>
                        <p class="text--secondary font-bold text-sm">
                          {{ tmp.items[0].product_name }}
                        </p>
                        <p
                          class="text--gray--300 text-sm font-normal"
                          v-if="tmp.group_type == 'App\\Models\\Subscriptions\\Subscription'"
                        >
                          Subscription: {{ tmp.items[0].subscription_label }}
                        </p>
                        <p
                          class="text--gray--300 text-sm font-normal"
                          v-else
                        >
                          One-Time Purchase
                        </p>
                        <p class="text--gray--300 text-sm font-normal">
                          Order No.: {{ tmp.invoice_no }}
                        </p>
                        <template v-if="isMobile">
                          <div class="flex flex-row items-end justify-end gap-4 mt-4">
                            <p class="text--secondary font-bold text-sm">
                              {{ moneyFormat(tmp.items[0].subtotal, 2, '₱') }}
                            </p>
                            <p class="text--gray--300 text-sm font-normal">
                              x{{ tmp.items[0].quantity }}
                            </p>
                          </div>
                        </template>
                      </div>
                    </div>
                  </td>
                  <template v-if="!isMobile">
                    <td class="align-top">
                      <div class="flex flex-col items-end">
                        <p class="text--secondary font-bold text-sm">
                          {{ moneyFormat(tmp.items[0].subtotal, 2, '₱') }}
                        </p>
                        <p class="text--gray--300 text-sm font-normal">
                          x{{ tmp.items[0].quantity }}
                        </p>
                      </div>
                    </td>
                  </template>
                </tr>
              </tbody>
            </table>
          </div> -->

          <div class="w-full py-6 border-b">
            <div class="lg:w-[70%] w-full flex items-center justify-between mb-6">
              <p class="text--gray--800">
                Number of items: {{ invoice.items ? invoice.items.length : 0 }}
              </p>
            </div>
            <div 
              class="lg:w-[70%] w-full mb-8"
              v-for="item in invoiceItems"
              :key="`invoice-item-${item.id}`"
            >
              <div class="flex justify-between">
                <div class="flex items-start gap-4">
                  <img
                    :src="item.product.initial_image || '/assets/image/prd-img1.png'"
                    class="w-14 h-14 object-cover border-radius-5"
                  >
                  <div>
                    <p class="text--gray--800 font-bold text-sm">
                      {{ item.product_name }}
                    </p>
                    <p class="text--gray--300 text-sm font-normal">
                      {{ !item.subscription_id ? 'One-time Purchase' : 'Subscription' }}
                    </p>
                    <ion-button
                      class="btn-link"
                      v-if="item.status == 2"
                      @click="openReviewDialog = true"
                    >
                      <span class="text--gray--200 text-sm underline font-normal">
                        {{ item.review ? 'Edit review' : 'Leave a review' }}
                      </span>
                    </ion-button>
                    <template v-if="isMobile">
                      <div class="flex flex-row-reverse items-end justify-between">
                        <div class="flex gap-2">
                          <p class="text--secondary font-bold text-sm">
                            <span class="mr-1 text--gray--300" v-if="item.original_price != item.discount_price">
                              <!-- <strike>{{ moneyFormat(item.original_price, 2, '₱') }}</strike> -->
                              {{ moneyFormat(item.original_price, 2, '₱') }}
                            </span>
                            <span>{{ moneyFormat(item.discount_price, 2, '₱') }}</span>
                          </p>
                          <p class="text--gray--300 text-sm font-normal">
                            x{{ item.quantity }}
                          </p>
                        </div>
                        <p class="text--orange--100 text-sm font-normal">
                          {{ invoice.formatted_order_status == 'Refunded' ? 'Refunded' : item.status_label }}
                        </p>
                      </div>
                    </template>
                  </div>
                </div>
                <template v-if="!isMobile">
                  <div class="flex flex-col items-end">
                    <p class="text--secondary font-bold text-sm">
                      <span class="mr-1 text--gray--300" v-if="item.original_price != item.discount_price">
                        <strike>{{ moneyFormat(item.original_price, 2, '₱') }}</strike>
                        <!-- {{ moneyFormat(item.original_price, 2, '₱') }} -->
                      </span>
                      <span>{{ moneyFormat(item.discount_price, 2, '₱') }}</span>
                    </p>
                    <p class="text--gray--300 text-sm font-normal">
                      x{{ item.quantity }}
                    </p>
                    <p class="text--orange--100 text-sm font-normal">
                      {{ invoice.formatted_order_status == 'Refunded' ? 'Refunded' : item.status_label }}
                    </p>
                  </div>
                </template>
              </div>
              <div class="flex lg:flex-row flex-col justify-between mt-6">
                <p class="text--gray--800 font-bold text-sm lg:mb-0 mb-2">
                  In case the item is unavailable:
                </p>
                <div class="lg:text-right text-left">
                  <p class="text--gray--800 font-norma text-sm">
                    {{ getStatusLabel(item.not_available_option) }}
                  </p>
                  <p
                    class="text--gray--800 font-norma text-sm"
                    v-if="item.replacement_item_id"
                  >
                    {{ item.replacement.name }}
                  </p>
                </div>
              </div>
            </div>


            <table class="lg:w-[70%] w-full table-auto">
              <tbody>
                <tr>
                  <td class="align-top pb-2">
                    <p class="text--gray--800 font-bold text-base">
                      Subtotal
                    </p>
                  </td>
                  <td class="align-top pb-2 text-right">
                    <p class="text--gray--800 font-norma text-sm">
                      {{ moneyFormat(invoice.subtotal, 2, '₱') }}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td class="align-top pb-2">
                    <p class="text--gray--800 font-bold text-base">
                      Delivery Fee
                    </p>
                  </td>
                  <td class="align-top pb-2 text-right">
                    <p class="text--gray--800 font-norma text-sm">
                      {{ moneyFormat(invoice.delivery_fee, 2, '₱') }}
                    </p>
                  </td>
                </tr>
                <tr v-if="parseFloat(invoice.other_fees)">
                  <td class="align-top pb-2">
                    <p class="text--gray--800 font-bold text-base">
                      Other Fee/s
                    </p>
                  </td>
                  <td class="align-top pb-2 text-right">
                    <p class="text--gray--800 font-norma text-sm">
                      {{ moneyFormat(invoice.other_fees, 2, '₱') }}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td class="align-top pb-2">
                    <p class="text--gray--800 font-bold text-base">
                      Points Redeemed
                    </p>
                  </td>
                  <td class="align-top pb-2 text-right">
                    <p class="text--gray--800 font-norma text-sm">
                      -{{ moneyFormat(invoice.points_redeemed, 2, '₱') }}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td class="align-top pb-2">
                    <p class="text--gray--800 font-bold text-base">
                      Referral Discount
                    </p>
                  </td>
                  <td class="align-top pb-2 text-right">
                    <p class="text--gray--800 font-norma text-sm">
                      -{{ moneyFormat(invoice.referral_discount, 2, '₱') }}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td class="align-top pb-2">
                    <p class="text--gray--800 font-bold text-base">
                      1st Purchase Discount
                    </p>
                  </td>
                  <td class="align-top pb-2 text-right">
                    <p class="text--gray--800 font-norma text-sm">
                      -{{ moneyFormat(invoice.frst_pchse_dcnt, 2, '₱') }}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td class="align-top pb-2">
                    <p class="text--gray--800 font-bold text-base">
                      Gift Card Discount
                    </p>
                  </td>
                  <td class="align-top pb-2 text-right">
                    <p class="text--gray--800 font-norma text-sm">
                      -{{ moneyFormat(invoice.gift_card_amount, 2, '₱') }}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td class="align-top pb-2">
                    <p class="text--gray--800 font-bold text-base">
                      Voucher Discount
                    </p>
                  </td>
                  <td class="align-top pb-2 text-right">
                    <p class="text--gray--800 font-norma text-sm">
                      -{{ moneyFormat(invoice.total_voucher_discount, 2, '₱') }}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td class="align-top pb-2">
                    <p class="text--gray--800 font-bold text-base">
                      Promo Discount
                    </p>
                  </td>
                  <td class="align-top pb-2 text-right">
                    <p class="text--gray--800 font-norma text-sm">
                      -{{ moneyFormat(invoice.promo_discount_amount, 2, '₱') }}
                    </p>
                  </td>
                </tr>
 
   
                <!-- <tr v-if="parseFloat(invoice.delivery_fee)">
                  <td class="align-top pb-2">
                    <p class="text--gray--800 font-bold text-base">
                      Shipping Fee
                    </p>
                  </td>
                  <td class="align-top pb-2 text-right">
                    <p class="text--gray--800 font-norma text-sm">
                      {{ moneyFormat(invoice.delivery_fee, 2, '₱') }}
                    </p>
                  </td>
                </tr> -->
                <tr>
                  <td class="align-top pb-2">
                    <p class="text--gray--800 font-bold text-base">
                      Points to Earn
                    </p>
                  </td>
                  <td class="align-top pb-2 text-right">
                    <p class="text--gray--800 font-norma text-sm">
                      {{ invoice.points_earned }} Droplet/s
                    </p>
                  </td>
                </tr>
                <!-- <tr v-if="parseFloat(invoice.shipping_discount)">
                  <td class="align-top pb-2">
                    <p class="text--gray--800 font-bold text-base">
                      Discount/s
                    </p>
                  </td>
                  <td class="align-top pb-2 text-right">
                    <p class="text--gray--800 font-norma text-sm">
                      {{ moneyFormat(invoice.shipping_discount, 2, '₱') }}
                    </p>
                  </td>
                </tr> -->
                <!-- <tr v-if="invoice.points_redeemed">
                  <td class="align-top pb-2">
                    <p class="text--gray--800 font-bold text-base">
                      Points Applied
                    </p>
                  </td>
                  <td class="align-top pb-2 text-right">
                    <p class="text--gray--800 font-norma text-sm">
                      {{ invoice.points_redeemed }} Droplet/s ~ {{ moneyFormat(invoice.points_redeemed, 2, '₱') }}
                    </p>
                  </td>
                </tr> -->
                <tr class="border-t">
                  <td class="align-top py-4">
                    <p class="text--gray--800 font-bold text-base uppercase">
                      Total
                    </p>
                  </td>
                  <td class="align-top py-4 text-right">
                    <p class="text--gray--800 font-bold text-sm">
                      {{ moneyFormat(invoice.total, 2, '₱') }}
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="w-full py-6 border-b">
            <p class="mb-6 font-bold">Proof of Delivery</p>
            <div class="flex space-x-6">
              <template 
              v-for="(img, index) in invoice.proof_of_delivery_img" 
              :key="index">
                <ProofOfDeliveryModal
                :item="img"
                />
              </template>
            </div>
          </div>

          <div class="pt-6">
            <a
              href="/order-history"
              class="text--gray--200 text-base font-normal underline"
            >&#60; Return to Order History</a>
          </div>
        </slot>
      </account-side-navigation>
      
      <ReviewModal
        :visible="openReviewDialog"
        :invoice="invoice"
        :items="invoiceItems"
        :rider-review="riderReview"
        @close="openReviewDialog = false"
      />

      <bottom-navigation />
    </ion-content>
  </ion-page>
</template>
<script type="text/javascript">

import { ref } from '@vue/reactivity';
import { computed, onMounted, watch } from '@vue/runtime-core';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import useAuth from '@/composables/useAuth';
import useFormat from '@/composables/useFormat';
import useLocalStorage from '@/composables/useLocalStorage';

import { IonPage, IonContent, IonButton } from '@ionic/vue';
import AccountSideNavigation from '@/partials/AccountSideNavigation.vue';
import BottomNavigation from '@/partials/BottomNavigation.vue';
import TopNavigation from '@/partials/TopNavigation.vue';
import ReviewModal from './components/ReviewModal';
import ProofOfDeliveryModal from './components/ProofOfDeliveryModal';

export default {
  name: 'OrderHistoryView',
  components: {
    IonContent, 
    IonPage, 
    IonButton,
    BottomNavigation, 
    TopNavigation, 
    AccountSideNavigation,
    ReviewModal,
    ProofOfDeliveryModal
  },
  setup() {
    
    const router = useRouter();
    const store = useStore();
    const { logout } = useAuth();
    const { moneyFormat } = useFormat();
    const { localStorage } = useLocalStorage();

    /*
		|--------------------------------------------------------------------------
		| @Data
		|--------------------------------------------------------------------------
		*/

    const isMobile = ref(false);
    const openReviewDialog = ref(false);
    const invoice = ref({});
    const invoices = ref([]);
    const invoiceItems = ref([]);
    const riderReview = ref({});

    const showInvoiceUrl = computed(() => store.state.api.routes.showInvoice);
    const moreInvoices = computed(() => {
      return invoices.value.filter(x => x.cart_id == invoice.value.cart_id && x.id != invoice.value.id) || [];
    });

    /*
		|--------------------------------------------------------------------------
		| @Methods
		|--------------------------------------------------------------------------
		*/

    const fetch = (url) => {
      const id = router.currentRoute.value.query.i;
      window.axios.post(url || showInvoiceUrl.value, { id })
        .then(({ data }) => {
          invoice.value = data.item;
          invoiceItems.value = data.invoiceItems;
          riderReview.value = data.riderReview;
        })
        .catch(({ response }) => {
          if(response.status == 401) logout();
        });
    }

    const getStatusLabel = (value) => {
      let option = {};
      const options = store.state.settings.unavailableOptions;
      if(value) option = options.find(x => x.id == value) || {};
      else option = options[0];
      return option.value;
    };

    /*
		|--------------------------------------------------------------------------
		| @Watch
		|--------------------------------------------------------------------------
		*/

    watch(
      () => showInvoiceUrl.value,
      (url) => {
        if(typeof url === 'string') fetch(url);
      },
      { immediate: true }
    );
    
    onMounted(async() => {
      invoices.value = await localStorage.value.get('invoices') || [];
      if(window.innerWidth < 1024) isMobile.value = true;
    });
    
    return {
      isMobile,
      openReviewDialog,
      invoice,
      invoiceItems,
      riderReview,
      moreInvoices,
      moneyFormat,
      getStatusLabel,
    }
  }
}
</script>