<template>
  <div>
    <select
      :id="itemId"
      name="location"
      class="block w-full pl-3 pr-10 py-2 text-base focus:outline-none focus:ring-[#02ADF0] focus:border-[#02ADF0] rounded-[10px] h-12"
      :class="hasError ? 'border-red-400' : 'border-gray-300'"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
    >
      <option
        selected
        disabled
      >
        {{ label }}
      </option>
      <option 
        v-for="option in options" 
        :key="`${label}-${option[itemValue]}`"
        :value="option[itemValue]"
      >
        {{ option[itemLabel] }}
      </option>
    </select>
  </div>
</template>
<script type="text/javascript">
export default {
  name: 'MenuSelector',
  props:{
    
    modelValue:{
      type: [String,Number],
      default: null
    },
    itemId:{
      type: String,
      default: null
    },
    
    itemLabel:{
      type: String,
      default:'id'
    },
    
    itemValue:{
      type: String,
      default:'value'
    },

    label:{
      type: String,
      default: null
    },
    
    options:{
      type: Array,
      default: null
    },

    hasError:{
      type:Boolean,
      default:false
    }
  },
  emits:[ 'update:modelValue' ]
}
</script>