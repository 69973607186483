export const invoices = {
    namespaced: true,
    state: {
        items:[],
        payment:{},
        order:[],
    },
    mutations: {
        setItems(state, value) {
            state.items = value || [];
        },
        setPayment(state, value) {
            state.payment = value || {};
        },
        setOrder(state, value) {
            state.order = value || [];
        },
    },
};
