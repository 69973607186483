<template>
  <div class="my-account">
    <div class="w-11/12 mx-auto">
      <div class="myacc-topCon mb-8">
        <h2 class="font--h1 font-bold text--secondary mb-4" v-if="user">
          Hi, {{ user.first_name }}
        </h2>
        <template v-if="isMobile">
          <div
            @click="openMenu"
            class="flex items-center justify-start gap-2"
          >
            <img
              src="/assets/icon/ic-menu-cat.svg"
              class="h-5 w-5"
            >
            <template v-if="isOnRoutes(['order-history-index', 'order-history-view'])">
              <p class="text-base text--secondary font-bold">
                Order History
              </p>
            </template>
            <template v-else-if="isOnRoutes(['subscriptionIndex', 'subscriptionView'])">
              <p class="text-base text--secondary font-bold">
                Subscriptions
              </p>
            </template>
            <template v-else-if="isOnRoutes(['Notification'])">
              <p class="text-base text--secondary font-bold">
                Notifications
              </p>
            </template>
            <template v-else-if="isOnRoutes(['profile-index'])">
              <p class="text-base text--secondary font-bold">
                Profile
              </p>
            </template>
            <template v-else-if="isOnRoutes(['address-book-index','address-book-edit'])">
              <p class="text-base text--secondary font-bold">
                Address Book
              </p>
            </template>
            <template v-else-if="isOnRoutes(['change-password-index'])">
              <p class="text-base text--secondary font-bold">
                Change Password
              </p>
            </template>
            <template v-else>
              <p class="text-base text--secondary font-bold">
                Menu
              </p>
            </template>
          </div>
        </template>
      </div>
      <div class="flex flex-wrap align-start justify-between">
        <div
          class="myacc-sideNav px-5 py-8"
          :class="{ open: showMenu }"
        >
          <template v-if="isMobile">
            <div
              @click="showMenu = !showMenu"
              class="absolute top-0 right-0 z-10 p-3"
            >
              <img
                src="/assets/icon/ic-close-empty-field.svg"
                class="h-6 w-6"
              >
            </div>
            <div class="mb-5 flex items-center justify-start gap-2">
              <img
                src="/assets/icon/ic-account.svg"
                class="w-5 h-5"
              >
              <p class="text-base font-bold">
                Hi, Sam!
              </p>
            </div>
          </template>
          <div class="mb-5">
            <a
              href="/order-history"
              class="myacc-navLink"
              :class="{ active: isOnRoutes(['order-history-index', 'order-history-view']) }"
            >
              Order History
            </a>
          </div>
          <div class="mb-5">
            <a
              href="/subscription"
              class="myacc-navLink"
              :class="{ active: isOnRoutes(['subscriptionIndex', 'subscriptionView']) }"
            >
              Subscriptions
            </a>
          </div>
          <div class="mb-5">
            <a
              href="/notifications"
              class="myacc-navLink"
              :class="{ active: isOnRoutes(['Notification']) }"
            >
              Notifications
            </a>
          </div>
          <div class="mb-5">
            <a
              href="/profile"
              class="myacc-navLink cursor-pointer"
              :class="{ active: isOnRoutes(['profile-index','address-book-index','address-book-edit','change-password-index']) }"
            >
              Account Details
            </a>
            <div 
              class="myacc-subNav" 
              :class="[
                isOnRoutes(['profile-index','address-book-index','address-book-edit','change-password-index'])
                ? 'block' 
                : 'hidden'
              ]"
            >
              <div class="mt-3 pl-4">
                <a
                  href="/profile"
                  class="myacc-navSubLink"
                  :class="{ active: isOnRoutes(['profile-index']) }"
                >
                  Profile
                </a>
              </div>
              <div class="mt-3 pl-4">
                <a
                  href="/address-book"
                  class="myacc-navSubLink"
                  :class="{ active: isOnRoutes(['address-book-index','address-book-edit']) }"
                >
                  Address Book
                </a>
              </div>
              <div class="mt-3 pl-4">
                <a
                  href="/change-password"
                  class="myacc-navSubLink"
                  :class="{ active: isOnRoutes(['change-password-index']) }"
                >
                  Change Password
                </a>
              </div>
            </div>
          </div>
          <div class="mb-5">
            <a
              href="/announcements"
              class="myacc-navLink"
              :class="{ active: isOnRoutes(['Announcements']) }"
            >
              Announcements
            </a>
          </div>
          <div class="mb-5">
            <a
              href="/refer-a-friend"
              class="myacc-navLink"
              :class="{ active: isOnRoutes(['ReferAFriend']) }"
            >
              Refer a Friend
            </a>
          </div>
          <div class="mt-16 mb-5">
            <a
              @click.prevent="logout"
              class="myacc-navLink cursor-pointer"
            >
              Log Out
            </a>
          </div>
        </div>
        <div class="myacc-sideContent lg:pl-8">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">

import { onMounted, ref, defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import useAuth from "@/composables/useAuth";
import { useStore } from "vuex";

export default defineComponent({
  name: 'AccountSideNavigation',
  components: {

  },
  data:()=> ({
    isMobile: false,
    showMenu: false,
    showTab: false,
  }),
  mounted() {
    if (window.innerWidth < 1024) {
      this.isMobile = true;
    }
  },
  methods: {
    openMenu() {
      this.showMenu = !this.showMenu;
    },
    openTab() {
      window.location.href = '/profile'
    }
  },
  setup() {

    const route = useRoute();
    const { logout } = useAuth();
    const store = useStore();

    const isOnRoutes = (routes) => (routes.includes(route.name));

    const user = ref(null);
    const getUser = async () => {
        user.value = await store.state.user.data;
    }

    onMounted(() => {
        getUser();
    })

    return {
      logout,
      isOnRoutes,
      user
    }
  }
})
</script>
