<!-- This example requires Tailwind CSS v2.0+ -->
<template>
    <TransitionRoot
    as="template"
    :show="open" 
    >
        <Dialog
            as="div"
            class="fixed z-10 inset-0 overflow-y-auto"
            @close="closeThis"
        >
            <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <TransitionChild
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                >
                    <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </TransitionChild>

                <!-- This element is to trick the browser into centering the modal contents. -->
                <span
                    class="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                >&#8203;</span>
                <TransitionChild
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enter-to="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100 translate-y-0 sm:scale-100"
                    leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                    <div class="relative inline-block align-top bg-white rounded-[10px] text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle lg:w-[500px] sm:w-[90%] w-full">
                        <div class="p-6 lg:p-14">
                            <div class="flex items-center justify-center">
                                <img src="/assets/image/referral.svg" class="w-24 h-24"/>
                            </div>
                            <div class="flex flex-col items-center justify-center space-y-2 mt-6">
                                <h4 class="font-bold text-center text-2xl text-[#181D25]">Get Referred. Get Rewarded.</h4>
                                <p class="font-normal text-center text-base text-[#181D25]">
                                    Referred by a friend? Input their referral code here and get exclusive deals on our quality products!
                                </p>
                            </div>
                            <div class="flex items-center justify-center mt-6">
                                <input
                                    type="text"
                                    class="focus:ring-[#02adf0] focus:border-[#02adf0] block w-full border-gray-300 placeholder-gray-400 rounded-[10px] h-12"
                                    placeholder="Enter Referral Code Here"
                                    v-model="referralCode"
                                >
                            </div>
                            <div class="flex flex-wrap items-center justify-center gap-2.5 mt-10">
                                <ion-button @click="submit" size="large" class="btn-primary w-full lg:w-auto">
                                    Use Code
                                </ion-button>
                                <ion-button @click="closeThis" size="large" class="btn-danger w-full lg:w-auto">
                                    I don't have a code
                                </ion-button>
                            </div>
                        </div>
                    </div>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>

    <referral-success-modal
        :is-visible="showReferralSuccessModal" 
        @close="showReferralSuccessModal = false"
    ></referral-success-modal>

</template>

<script>

import { ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import useLocalStorage from '@/composables/useLocalStorage';
import useResponseHandler from '@/composables/useResponseHandler';

import { DateTime } from "luxon";
import { IonButton } from '@ionic/vue';
import { Dialog, DialogOverlay, TransitionChild, TransitionRoot } from '@headlessui/vue';
import ReferralSuccessModal from "@/components/ReferralSuccessModal.vue";
import useAuth from "@/composables/useAuth";

export default {
    name: 'ReferralModal',
    components: {
        Dialog,
        DialogOverlay,
        TransitionChild,
        TransitionRoot,
        ReferralSuccessModal,
        IonButton
    },
    emits:[ 'close' ],
    props:{
        isVisible:{
            type:Boolean,
            default:false
        }
    },
    setup(props, { emit }) {

        const store = useStore();
        const route = useRoute();
        const { errorHandler } = useResponseHandler();
        const { localStorage } = useLocalStorage();
    
        const open = ref(false);
        const showReferralSuccessModal = ref(false);
        const referralCode = ref(null);

        const submit = () => {
            window.axios
                .post(store.state.api.routes.useReferralCode, { code: referralCode.value })
                .then(() => {
                    store.commit('checkout/set', { referralCode: referralCode.value });
                    localStorage.value.set('referral', store.state.checkout.referralCode);
                    refModalCancelledDate();
                    openReferralSuccessModal();
                })
                .catch(({ response }) => {
                    closeThis();
                    errorHandler(response);
                });
        };

        const refModalCancelledDate = () => {
            if(route.name == 'home') {
                localStorage.value.set('referral-prompt-cancelled', DateTime.now().toFormat('yyyy-MM-dd HH:mm:ss'));
            } else {
                localStorage.value.set('referral-prompt-checkout', DateTime.now().toFormat('yyyy-MM-dd HH:mm:ss'));
            }
        }

        const openReferralSuccessModal = async () => {
            closeThis();
            showReferralSuccessModal.value = true;
        }

        const closeThis = () => {
            refModalCancelledDate();
            open.value = false;
        };

        const { isAuthenticated } = useAuth();
        // watch(
        //     () => store.state.settings.referrals,
        //     (refs) => (open.value = refs.is_active 
        //                 && !store.state.checkout.referralCode
        //                 && !route.query.paymentStatus
        //                 && store.state.user.token != null
        //                 && showRefModal()
        //             ),
        //     { deep: true }
        // );

        const checkIfDayHasPassed = ( dateTime ) => {
            if(!isAuthenticated.value || DateTime.now().diff(DateTime.fromJSDate(new Date(dateTime)), 'hours').values.hours < 24) {
                return true;
            }
        }

        watch(
            () => store.state.settings.referrals,
            async (refs) => {
               const cancelledAt = await localStorage.value.get('referral-prompt-cancelled')
               const cancelledAtCheckout= await localStorage.value.get('referral-prompt-checkout')

                if (route.name == 'home' && checkIfDayHasPassed(cancelledAt)) return;
                
                if(route.name == 'checkout' && checkIfDayHasPassed(cancelledAtCheckout)) return;
 
                open.value = refs.is_active 
                    && !store.state.checkout.referralCode
                    && !route.query.paymentStatus
                    && store.state.user.token != null
             },
            { deep: true }
        );

        watch(
            () => store.state.checkout,
            () => {
                localStorage.value
                    .get('referral')
                    .then(referralCode => {
                        store.commit('checkout/set', { referralCode });
                    });
            },
            { deep: true }
        );

        watch(
            () => open.value,
            (bool) => {
                if (!bool) emit('close');
            }
        );

        return {
            open,
            referralCode,
            showReferralSuccessModal,
            submit,
            openReferralSuccessModal,
            closeThis,
        }
    },
}
</script>