<template>
  <ion-page>
    <top-navigation />
    <ion-content
      :fullscreen="true"
      class="ion-padding"
    >
      <div class="flex items-center justify-center pt-[85px] pb-24">
        <div class="w-[90%] md:w-[35%] mx-auto"> 
          <div class="ion-no-padding mb-10 md:mb-8 text-center">
            <h1 class="leading-12 font-size--xx-large font-bold text--secondary">
              Login
            </h1>
          </div>

          <div class="mb-4">
            <input
              v-model="form.email"
              type="email"
              name="email"
              id="email"
              class="focus:ring-[#02adf0] focus:border-[#02adf0] block w-full border-gray-300 placeholder-gray-400 rounded-[10px] h-12"
              placeholder="Email"
              autocomplete="email"
            >
          </div>

          <div class="mb-0 relative">
            <input
              v-model="form.password"
              :type="show ? 'text' : 'password'"
              name="password"
              id="password"
              class="focus:ring-[#02adf0] focus:border-[#02adf0] block w-full border-gray-300 placeholder-gray-400 rounded-[10px] h-12"
              placeholder="Password"
              @keydown.enter="login"
              autocomplete="password"
            >
            <ion-icon
              class="password-ic"
              :src="show ? '/assets/icon/ic-eye.svg' : '/assets/icon/ic-eye-off.svg'"
              @click="show = !show"
            />
          </div>

          <div class="ion-no-padding mb-16 md:mb-6 flex justify-between">
            <div class="flex mt-2">
              <ion-checkbox
                  v-model="form.remember_me"
                  slot="start"
                  class="mr-2 w-auto"
                />
                <p class="mb-0 text--gray--400 font-size--small">
                  Keep me logged in 
                </p>
            </div>
            <ion-button
              slot="end"
              shape="round"
              class="font--main btn-link font--13 font-weight-regular text--gray--400 my-0 capitalized font-size--x-small float-right"
              href="/forgot-password"
            >
              Forgot password?
            </ion-button>
          </div>

          <div class="ion-no-padding">
            <div class="w-full flex justify-center items-center">
              <ion-button
                @click="login"
                class="font--main btn-primary m-0 capitalized"
                shape="round"
                size="large"
                :disabled="loader"
              >
                Login
              </ion-button>
            </div>
          </div>

          <div class="ion-no-padding">
            <div class="w-full flex justify-between items-center my-7">
              <hr class="w-full">
              <p class="or-text text-xs text-center text--gray--400 font--main">
                or login via
              </p>
              <hr class="w-full">
            </div>
          </div>

          <div class="ion-no-padding mb-12 md:mb-4">
            <div class="w-full flex flex-wrap justify-center">
              <!-- <div class="w-[49%]">
                <FacebookLoginButton />
              </div> -->
              <div class="w-[49%]">
                <GoogleLoginButton />
              </div>
              <div 
                class="w-full mt-5" 
                v-if="guestParam"
              >
                <ion-button
                    shape="round"
                    size="large"
                    fill="outline"
                    class="flex items-center font--main m-0 capitalized"
                    @click="openGuestModal"
                >
                    <img
                        class="mr-2"
                        src="/assets/icon/ic-user-guest.svg"
                    >
                    Continue as guest
                </ion-button>
              </div>
            </div>
          </div>

          <div class="ion-no-padding mb-4">
            <div class="w-full flex flex-row items-center justify-center">
              <span class="font--main m-0 font-size--small text--gray--400">Don't have an account?
                <a
                  class="font--main font-bold font-size--x-small text--secondary my-0 mr-0"
                  href="/register"
                >Sign up now</a>
                .
              </span>
            </div>
          </div>
        </div>
      </div>
      <bottom-navigation />
    </ion-content>
    <Loader v-show="loader" />
  </ion-page>
</template>

<script type="text/javascript">
/* eslint-disable */
import { onMounted } from '@vue/runtime-core';
import { ref, defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import useAuth from "@/composables/useAuth.js";
import useCart from "@/composables/useCart.js";
import useGuest from "@/composables/useGuest";
import useLocation from "@/composables/useLocation";
import usePushNotifications from "@/composables/usePushNotifications";
import { useStore } from 'vuex';

import {
    IonPage,
    IonContent,
    IonButton,
    IonIcon,
    IonCheckbox,
    modalController,
} from '@ionic/vue';
import Modal from '@/components/Modal.vue';
import TopNavigation from '@/partials/TopNavigation.vue';
import BottomNavigation from '@/partials/BottomNavigation.vue';
// import FacebookLoginButton from './components/FacebookLoginButton.vue';
import GoogleLoginButton from './components/GoogleLoginButton.vue';
import Loader from '@/components/PageLoader.vue';

export default defineComponent({
    name: 'Login',
    components: {
        IonContent,
        IonPage,
        IonButton,
        IonIcon,
        IonCheckbox,
        TopNavigation,
        BottomNavigation,
        // FacebookLoginButton,
        GoogleLoginButton,
        Loader
    },
    setup() {
        const store = useStore();
        const router = useRouter();
        const { setToken } = useAuth();
        const { checkGuestCart } = useCart();
        const { postPushToken } = usePushNotifications();
        const { fetchGuestId, clearGuestId } = useGuest();
        const { storeAddress, storeBranch } = useLocation();

        const show = ref(false);

        const loader = ref(false);

        const guestParam = ref();

        // Modal variables
        const title = ref('Oops!');
        const message = ref('Something went wrong.');
        const buttonText = ref('Close');
        const imageLink = ref('/assets/icon/ic-status-warning.svg');
        
        const openErrorModal = async () => {
            const modal = await modalController.create({
                component: Modal,
                cssClass: 'gen-modal',
                componentProps: {
                title: title.value,
                description: message.value,
                buttonText: buttonText.value,
                imageLink: imageLink.value,
                closeModalButton: true,
                buttonClass: 'btn-primary',
                imageWidth: '28',
                imageHeight: '28',
                full: 'full'
                }
            });
            return modal.present();
        };

        const openGuestModal = async () => {
            const modal = await modalController.create({
                component: Modal,
                cssClass: 'gen-modal order-confirmation',
                componentProps: {
                  title: 'Check out as a guest?',
                  description: 'All subscription items in your cart will be automatically converted into single-purchase items.',
                  buttonText: 'Continue as guest',
                  cancelButtonText: 'Login',
                  cancelButtonClass: 'btn-light',
                  imageLink: imageLink.value,
                  cancelButton: true,
                  buttonClass: 'btn-primary',
                  imageWidth: '36',
                  imageHeight: '36',
                  full: 'full',
                  emitButton: true
                }
            });
            modal.onDidDismiss().then(({ data }) => {
              if(data == 'emit') signInAsGuest();
            });
            return modal.present();
        };

        const signInAsGuest = () => {
            checkGuestCart().then(() => router.push('/checkout'));
        };

        // const locationWarningModal = async () => {
        //   const modal = await modalController.create({
        //     component: Modal,
        //     cssClass: 'gen-modal email-verification',
        //     componentProps: {
        //       title: "Oops!",
        //       description: "Items added to the cart may not be available on your account's default delivery address.",
        //       buttonText: "Okay",
        //       imageLink: "/assets/icon/ic-status-warning.svg",
        //       closeModalButton: true,
        //       imageWidth: '28',
        //       imageHeight: '28'
        //     }
        //   });
        //   return modal.present();
        // };

        // const form = ref({
        //     email: 'user@praxxys.ph',
        //     password: 'password',
        //     guestId: null,
        // });

        const form = ref({
            email: null,
            password: null,
            guestId: null,
            remember_me: false,
        });

        const clearFields = () => {
            form.value.email = null;
            form.value.password = null;
            form.value.remember_me = false;
        };

        const login = async () => {
          loader.value = true;
          form.value.branchId = store.state.address.branchId;
          form.value.guestId = await fetchGuestId();
          window.axios
          .post(store.state.api.routes.login, form.value)
          .then(({ data }) => {
              setToken('Bearer ' + data.access_token, data.user);
              // Update push token stored on server
              postPushToken();
              // Clear form values
              clearFields();
              // Clear guestId from local storage
              clearGuestId();
              
              // Store default address
              if(data.address) {
                storeBranch(data.address.branch_id);

                storeAddress({
                  id: data.address.id,
                  province: data.address.province_code,
                  city: data.address.city_code,
                  city_label: data.address.city,
                  barangay: data.address.barangay_code,
                  barangay_label: data.address.barangay,
                  latitude: data.address.latitude,
                  longitude: data.address.longitude,
                  street: data.address.street,
                  unitNo: data.address.unit_no,
                  zipCode: data.address.zip_code,
                });
              }
              
              // console.log(data);

              // locationWarningModal();

              // redirect to home
              router.push({ name: 'home' });
              loader.value = false;
          }).catch((error) => {
            loader.value = false;
            if(error.response.data.message) {
              message.value = error.response.data.message;
            }
            // if(error.response.data.error) {
            //   message.value = error.response.data.error;
            // }
            openErrorModal();
            // console.log(error);
          })
        }

        onMounted(() => {
          const queryString = window.location.search;
          const urlParams = new URLSearchParams(queryString);
          guestParam.value = urlParams.get('user')
        })

        return {
            login,
            form,
            show,
            loader,
            openGuestModal,
            guestParam
        };
    },
})
</script>
<style lang="scss">
    .or-text {
        width: 200px;
    }
</style>