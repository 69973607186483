<template>
  <div class="delivery-address__hldr mb-8 md:mb-16">
    <h3 class="font-bold mb-4">
      {{ title }}
    </h3>
    <!-- Start of Address List -->
	<template v-for="(address, index) in addresses">
		<address-card
			v-if="index < limit"
			:key="`address-${address.id}`"
			:id="address.id"
			:address-name="address.name"
			:address-details="address"
			:name="address.user_full_name"
			:mobile-number="address.mobile_number"
			:address="address.full_address"
			:is-default="address.is_default"
			:is-active="isSelected(address.id)"
			:is-disabled="showAddressForm"
			is-edit
			radio
			v-model="selected"
			:selected-address-id="selected"
		/>
	</template>
	<div class="w-full text-right">
		<ion-button
			v-if="addresses.length > limit"
			@click="limit += 3"
			slot="end"
			shape="round"
			class="font--main btn-link font-weight-regular text--secondary my-0 capitalized font-size--x-small"
		>
			Show More
		</ion-button>
    </div>
    <!-- End of Address List -->
    <div class="w-full">
      <ion-button
        @click="toggleNewAddress"
        slot="end"
        shape="round"
        class="font--main btn-link font-weight-regular text--secondary my-0 capitalized font-size--x-small"
      >
        {{ showAddressForm ? '- Cancel' : '+ Use a new address' }}
      </ion-button>
    </div>
    <!-- Start of New Address Form -->
    <template v-if="showAddressForm">
      <AddressLocationForm v-model="form" :withMobile="true" />
      <div class="flex justify-between items-center">
        <ion-button
		@click="setAddress()"
          shape="round"
          size="large"
          fill="outline"
          class="flex items-center font--main m-0 capitalized"
          :disabled="!addressFormIsComplete"
        >
          Save
        </ion-button>
        <!-- <ion-button
          slot="end"
          shape="round"
          class="font--main btn-link font--13 font-bold my-0 capitalized font-size--x-small float-right"
          :class="shouldSave ? 'text--gray--500' : 'text--gray--200'"
          @click="shouldSave = !shouldSave"
        >
          <input
            type="checkbox"
            class="border-gray-300 rounded mr-2"
            v-model="shouldSave"
          >
          Save to address book
        </ion-button> -->
      </div>
    </template>
    <!-- End of New Address Form -->
  </div>
</template>
<script>

import { ref } from '@vue/reactivity';
import { computed, defineComponent, onMounted, watch } from '@vue/runtime-core';
import { useStore } from 'vuex';
import useAuth from '@/composables/useAuth';
import useLocalStorage from '@/composables/useLocalStorage';

import { IonButton, modalController } from '@ionic/vue';
import Modal from '@/components/Modal';
import AddressCard from '@/views/account-details/components/AddressCard.vue';
import AddressLocationForm from '@/views/account-details/address-book/AddressLocationForm.vue';

export default defineComponent({
	name: 'DeliveryAddress',
	components:{
		IonButton,
		AddressCard,
		AddressLocationForm,
	},
	props:{
			branchId:{
					type:Number,
					default:null
			},
			title:{
					type:String,
					default: null
			}
	},
	emits:[ 'refresh' ],
	setup(props, { emit }) {
			
			/*
			|--------------------------------------------------------------------------
			| @Data
			|--------------------------------------------------------------------------
			*/

			const store = useStore();
			const { logout } = useAuth();
			const { localStorage } = useLocalStorage();

			const showAddressForm = ref(false);
			const shouldSave = ref(false);
			const updatedAddresses = computed(() => store.state.address.items);

			const limit = ref(3);
			const addresses = ref([]);
			const selected = ref(null);
			const form = ref({
				provinceCode: 'Province',
				cityCode: 'City',
				barangayCode: 'Barangay',
			});

			const title = ref('Oops!');
			const message = ref('Something went wrong');
			const image = ref('/assets/icon/ic-status-warning.svg');

			const fetchAddressUrl = computed(() => store.state.api.routes.fetchAddress);
			const tempAddressUrl = computed(() => store.state.api.routes.tempAddress);

			const selectedAddress = computed(() => addresses.value.find(x => x.id == selected.value) || {});
			const addressFormIsComplete = computed(() => {
				return (
					form.value.provinceCode !== 'Province' && 
					form.value.cityCode !== 'City' && 
					form.value.barangayCode !== 'Barangay' && 
					form.value.unitNo && 
					form.value.street && 
					form.value.zipCode
				);
			});

			const user = ref(null);
			const getUser = async () => {
				user.value = await store.state.user.data;
			}

			/*
			|--------------------------------------------------------------------------
			| @Modals
			|--------------------------------------------------------------------------
			*/

			const openConfirmationModal = async () => {
				const modal = await modalController.create({
					component: Modal,
					cssClass: 'gen-modal',
					componentProps: {
						title: "Are you sure?",
						description: "Changing your assigned branch might affect the item stocks in your cart.",
						buttonText: "Change Branch",
						cancelButtonText: 'Cancel',
						cancelButton: true,
						emitButton: true,
						imageLink: "/assets/icon/ic-status-warning.svg",
						imageWidth: '64',
						imageHeight: '64',
						shouldAutoClose: false
					}
				});

				modal.onDidDismiss().then(({ data }) => {
					if(data == 'emit') setNewAddress();
					else {
						const address = addresses.value.find(x => props.branchId == x.branch_id) || {};
						selected.value = address.id;
					}
				});

				return modal.present();
			};

			const openModal = async () => {
				const modal = await modalController.create({
					component: Modal,
					cssClass: 'gen-modal',
					componentProps: {
						title: title.value,
						description: message.value,
						imageLink: image.value,
						closeModalButton: true,
						imageWidth: '64',
						imageHeight: '64',
					}
				});
				return modal.present();
			};

			/*
			|--------------------------------------------------------------------------
			| @Methods
			|--------------------------------------------------------------------------
			*/

			const fetch = (url) => {
				window.axios.post(url || fetchAddressUrl.value)
					.then(({ data }) => {
						addresses.value = data.items;
						store.commit('address/setItems', data.items);
						setDraft();
					})
					.catch(({ response }) => {
						if(response.status == 401) logout();
					});
			};

			const selectDefaultAddress = () => {
				const vuexAddress = store.state.address.form;
				const storageAddress = addresses.value.find(x => vuexAddress.id == x.id);
				const defaultAddress = addresses.value.find(x => x.is_default) || {};
				const result = storageAddress || defaultAddress;
				selected.value = result.id;
				emit('refresh', result.branch_id);
			};

			const setDraft = () => {
				localStorage.value.get('location').then(result => {
					
					if(!result || !result.barangayCode) {
						selectDefaultAddress();
						return;
					}
					
					form.value = result || form.value;
					if(addressFormIsComplete.value) {
						// showAddressForm.value = true;
						selected.value = null;
					}
				});
			};

			const isSelected = (id) => {
				return selected.value == id;
			};

			const toggleNewAddress = () => {
				showAddressForm.value = !showAddressForm.value;
				if(showAddressForm.value) {
					selected.value = null;
				} else {
					const address = store.state.address.items.find(x => x.is_default) || {};
					emit('refresh', address.branch_id);
					saveAddress({
						id: address.id,
						province: address.province_code,
						city: address.city_code,
						city_label: address.city,
						barangay: address.barangay_code,
						barangay_label: address.barangay,
						latitude: address.latitude,
						longitude: address.longitude,
					});
					setTimeout(() => selected.value = address.id, 1000);
				}
			};

			const setNewAddress = () => {
					
				if(!showAddressForm.value) {
					emit('refresh', selectedAddress.value.branch_id);
					saveAddress({
						id: selectedAddress.value.id,
						province: selectedAddress.value.province_code,
						city: selectedAddress.value.city_code,
						city_label: selectedAddress.value.city,
						barangay: selectedAddress.value.barangay_code,
						barangay_label: selectedAddress.value.barangay,
						latitude: selectedAddress.value.latitude,
						longitude: selectedAddress.value.longitude,
					});

				} else {
					window.axios.post(tempAddressUrl.value, form.value)
					.then(({ data }) => {
						saveAddress(form.value);
						emit('refresh', data.branchId);
						showAddressForm.value = false
						selected.value = data.newAddressId

						store.commit('address/setItems', data.items);
					})
					.catch(({ response }) => {
						title.value = 'Warning!';
						message.value = response.data.error;
						openModal();
					});
				}
			};

			const storeAddressUrl = computed(() => store.state.api.routes.storeAddress);

			const setAddress = () => {
				console.log(form.value)

				form.value.email        = user.value.email;
				form.value.firstName    = user.value.first_name;
				form.value.lastName     = user.value.last_name;
				
				window.axios.post(storeAddressUrl.value, form.value)
					.then(( {data} ) => {
						console.log(data)
						fetch()
						form.value = null;
					})
					.catch(({ response }) => {
					if(response.status == 401) logout();
				
					});
			}



			const saveAddress = (data) => {
				store.commit('address/setForm', data);
				localStorage.value.set('location', data);
			};

			const setCheckoutAddress = (id) => {
				if(id) store.commit('checkout/setAddress', { id });
				else store.commit('checkout/setAddress', form.value);
			};

			/*
			|--------------------------------------------------------------------------
			| @Watch
			|--------------------------------------------------------------------------
			*/

			watch(
				() => fetchAddressUrl.value,
				(url) => {
					if(typeof url === 'string') fetch(url);
				},
				{ immediate: true }
			);

			watch(
				() => selected.value,
				(id) => {
					if(id !== null) {
						showAddressForm.value = false;
						if(
							selectedAddress.value && 
							props.branchId && 
							selectedAddress.value.branch_id != props.branchId
						) {
							// openConfirmationModal();
						}
					}
					setCheckoutAddress(id);
				}
			);

			watch(
				() => shouldSave.value, 
				(bool) => form.value.toSave = bool
			);

			watch(
				() => updatedAddresses.value, 
				() => addresses.value = updatedAddresses.value 
			);

			onMounted(() => {
				const vuexAddress = store.state.address.form;
				const storageAddress = addresses.value.find(x => vuexAddress.id == x.id);
				const defaultAddress = addresses.value.find(x => x.is_default) || {};
				const result = storageAddress || defaultAddress;
				selected.value = result.id;
				getUser();
			})

			return {
				limit,
				addressFormIsComplete,
				showAddressForm,
				shouldSave,
				addresses,
				selected,
				form,
				isSelected,
				toggleNewAddress,
				openConfirmationModal,
				fetch,
				setAddress
			}
	}
});
</script>