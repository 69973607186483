<template>
    <ion-page>
        <top-navigation
        :name="chatStore.customer_name"
        :back-button="true"
        :backUrl="`/orders/view/${invoice_id}`"
        ></top-navigation>
        <ion-content :fullscreen="true" class="ion-padding bg--chat">
            <div class="chat-container" v-if="chat.messages">
                <template v-for="(message, index) in chat.messages" :key="index">
                    <div class="chat-sent" v-if="message.user_type == 'App\\Models\\Users\\User'">
                        <p class="text-xs leading-5">{{ message.message }} </p>
                    </div>

                    <div class="chat-received" v-else>
                        <p class="text-xs leading-5">{{ message.message }} </p>
                    </div>
                </template>
            </div>
        </ion-content>

        <ion-footer>
            <ion-toolbar>
                <div class="flex items-end space-x-2 px-4 py-2">
                    <ion-textarea
                    v-model="form.message"
                    rows="1"
                    autoGrow
                    placeholder="Talk to the customer..."
                    class="chat-textarea"></ion-textarea>
                    <button class="border-0 h-11 leading-3" @click.prevent="submit">
                        <ion-icon src="/assets/icon/orders/ic-send.svg" class="w-6 h-6"></ion-icon>
                    </button>
                </div>
            </ion-toolbar>
        </ion-footer>
        <Loader v-if="chatLoader" />
    </ion-page>
</template>


<script type="text/javascript">
import {
    IonContent,
    IonPage, 
    IonFooter,
    IonToolbar,
    IonTextarea,
    IonIcon,
    onIonViewWillEnter,
    onIonViewDidEnter,
    onIonViewWillLeave
} from "@ionic/vue";
import { defineComponent, ref, computed, watch } from "vue";
import TopNavigation from "@/partials/TopNavigation.vue";
import { useRouter, useRoute } from "vue-router";
import pinia from "@/store/pinia.js";
// import { useApiStore } from "@/store/api.js";
import { useStore } from "vuex";
import { useChatStore } from "@/store/modules/chat.js";
import useChat from "@/composables/useChat";
import Loader from '@/components/PageLoader.vue';

export default defineComponent({
    name: "ChatPage",
    components: {
        IonContent,
        IonPage,
        IonFooter,
        IonToolbar,
        IonTextarea,
        IonIcon,
        TopNavigation,
        Loader
    },
    setup() {
        const router = useRouter();
        const route = useRoute();

        // const apiStore = useApiStore(pinia);
        const store = useStore();
        const chatStore = useChatStore(pinia);
        
        const invoice_id = route.params.id;

        const { fetchChat, sendChat, chat, chatLoader } = useChat();

        const fetchChatUrl = computed(() => store.state.api.routes.fetchChat);
        const sendChatUrl = computed(() => store.state.api.routes.sendChat);

        const form = ref({
            invoice_id: invoice_id,
        })
        const submit = () => {
            sendChat(sendChatUrl.value, form.value);

            form.value.message = null;
        }

        watch(
            () => fetchChatUrl.value,
            (url) => {
                if (typeof url === "string") fetchChat(url, invoice_id);
            }
        );

        onIonViewWillEnter(() => {
            if (typeof fetchChatUrl.value === "string")
                fetchChat(fetchChatUrl.value, invoice_id);
        });

        onIonViewDidEnter(() => {
            window.Echo.channel(`rider-chat.${invoice_id}`)
            .listen('.rider.chat-received', (e) => {
                console.log("receiving message...")
                console.log(e.message);
                fetchChat(fetchChatUrl.value, invoice_id);
            })
        });

        onIonViewWillLeave(() => {
            window.Echo.channel(`rider-chat.${invoice_id}`)
            .stopListening('.rider.chat-received')
        });

        return {
            router,
            invoice_id,
            submit,
            chatStore,
            chat,
            form,
            chatLoader
        };
    },
});
</script>
<style lang="scss">
.chat-textarea {
    textarea {
        &:focus {
            box-shadow: none;
        }
    }
}

.chat-container {
    position: relative;
    height: calc(100vh - 52px - 66px - 32px);
    overflow: auto;
}

.chat-sent,
.chat-received {
    padding: 10px;
    border-radius: 4px;
    width: max-content;
    max-width: 80%;
    margin-bottom: 16px;
}

.chat-sent {
    background: #27AE60;
    color: #FFFFFF;
    margin-left: auto;
}

.chat-received {
    background: #FFFFFF;
}
</style>