<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <TransitionRoot
    as="template"
    :show="open"
  >
    <Dialog
      as="div"
      class="fixed z-10 inset-0 overflow-y-auto"
      @close="close(false)"
    >
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >&#8203;</span>
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div class="relative inline-block align-bottom bg-white rounded-[10px] text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle md:w-[983px] w-[90%]">
            <div class="modal-content__hldr md:px-12 px-5 md:pb-12 pb-5 md:pt-8 mt-6">
              <img
                src="/assets/icon/ic-close.svg"
                alt="close-icon"
                width="20"
                height="20"
                class="object-contain block cursor-pointer mb-4 float-right"
                @click="close(false)"
              >
              <h3 class="font-bold text--secondary mb-6">
                Add Items
              </h3>
              <div class="flex md:flex-row flex-col">
                <div class="md:w-1/3 w-full md:pr-3">
                  <!-- Bug: options opening behind this modal -->
                  <ion-select
                    disabled
                    interface="popover"
                    class="frm-select bg--white w-full mr-4 mb-2"
                    v-model="category"
                  >
                    <ion-select-option 
                      v-for="option in categories" 
                      :key="`category-option-${option.id}`"
                      :value="option.id"
                    >
                      {{ option.name }}
                    </ion-select-option>
                  </ion-select>
                </div>
                <div class="md:w-2/3 w-full md:mt-0 mt-2.5">
                  <input
                    type="text"
                    name="search"
                    id="search"
                    class="focus:ring-[#02adf0] focus:border-[#02adf0] block w-full border-gray-300 placeholder-gray-400 rounded-[10px] h-12"
                    placeholder="Search..."
                    v-model="search"
                  >
                </div>
              </div>
              <div class="product-list__hldr--replacement flex flex-wrap mt-8 scrollbar-style">
                <template
                  v-for="(item, index) in filteredProducts"
                  :key="`replacement-modal-product-card-${index}`"
                >
                  <ProductCard
                    :item="item"
                    disable-tag
                    disable-hover
                    to-emit
                    class="md:w-1/3 w-1/2 mb-6"
                    @click="selectItem(item, index)"
                    :class="activeCards.includes(index) ? 'border border-[#02ADF0]' : 'none'"
                  />
                </template>
              </div>
              <div class="flex md:justify-end justify-center mt-8"
              v-if="filteredProducts.length > 0">
                <ion-button
                  shape="round"
                  size="large"
                  class="flex items-center font--main m-0 capitalized"
                  @click="addSelectedItems"
                >
                  Add Selected Items
                </ion-button>
              </div>
              <p 
                v-if="!filteredProducts.length > 0"
                class="col-span-full text-center pt-8"
              >
                No other products available.
              </p>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script type="text/javascript">

import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex';

import { IonSelect, IonSelectOption, IonButton } from '@ionic/vue';
import { Dialog, DialogOverlay, TransitionChild, TransitionRoot } from '@headlessui/vue'
import ProductCard from '@/views/products/components/ProductCard.vue';

export default {
  name: 'AddItemsModal',
  components: {
    IonSelect,
    IonSelectOption,
    IonButton,
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
    ProductCard
  },
  props: {
    isOpen: {
      default: false,
      type: Boolean
    },
    duration: {
      type: Number,
      default: null
    }
  },
  emits: [ 'close', 'addItems' ],
  setup(props, { emit }) {

    const store = useStore();

    /*
        |--------------------------------------------------------------------------
        | @Data
        |--------------------------------------------------------------------------
        */
    
    const products = ref([]);
    const categories = ref([]);
    const category = ref(null);
    const search = ref(null);
    const isActive= ref(false);
    const selectedItems = ref([]);
    const activeCards = ref([]);

    /*
        |--------------------------------------------------------------------------
        | @Computed
        |--------------------------------------------------------------------------
        */
    
    const open = computed(() => props.isOpen);
    const fetchProductUrl = computed(() => store.state.api.routes.products);
    
    const branchId = computed(() => store.state.address.branchId);
    const productId = computed(() => {
      const { productId } = store.state.checkout.replacements.find(x => !x.replacementItemId) || {};
      return productId;
    });

    const filteredProducts = computed(() => {
      return search.value
        ? products.value.filter(x => x.name.toUpperCase().indexOf(search.value.toUpperCase()) != -1)
        : products.value;
    });

    /*
        |--------------------------------------------------------------------------
        | @Methods
        |--------------------------------------------------------------------------
        */

    const close = (replaced = false) => { emit("close", replaced); };
    const fetch = () => {
      window.axios.post(fetchProductUrl.value, { branchId: branchId.value })
        .then(({ data }) => {
          products.value = data.products.filter(x => x.id != productId.value && x.stock > 0);
          categories.value = data.categories;
          categories.value.push({
            id: null,
            name: 'All Products'
          });

          store.commit('products/set', data.products);
          store.commit('products/setCategories', data.categories);
        });
    };

    const select = (id) => {
      store.commit('checkout/setReplacement', id);
      close(true);
    };

    const getDiscountPrice = (item) => {
      let x =  item.subscription_prices.find(x => x.duration == props.duration);
      return x.price;
    }

    const selectItem = (item, index) => {
      let tmp = activeCards.value.indexOf(index);

      if (!activeCards.value.includes(index)) {
        activeCards.value.push(index)

        let product = {
          discounted_price: getDiscountPrice(item),
          original_price: item.price_per_case,
          product_id: item.id,
          quantity: 1,
          discount_name: null,
          discount_amount: null,
          discount_type: null,
          discount_value: '0.00',
          subtotal: '0.00',
          product: item
        }

        selectedItems.value.push(product)

      } else {
        activeCards.value.splice(tmp, 1)
        selectedItems.value.splice(tmp, 1)
      }


    }

    const addSelectedItems = () => {
      emit('addItems', selectedItems.value)
      emit('close', true)
    }

    /*
        |--------------------------------------------------------------------------
        | @Watch
        |--------------------------------------------------------------------------
        */

    watch(
      () => fetchProductUrl.value && branchId.value,
      (bool) => {
        if(bool) fetch();
      },
      { immediate: true }
    );

    watch(
      () => props.isOpen,
      (bool) => open.value = bool,
      { immediate: true }
    );

    watch(
      () => productId.value,
      (id, oldId) => {
        if(id && !oldId) products.value = products.value.filter(x => x.id != id);
      }
    );

    return {
      productId,
      open,
      products,
      categories,
      category,
      search,
      isActive,
      filteredProducts,
      select,
      close,
      selectItem,
      selectedItems,
      addSelectedItems,
      activeCards
    }
  },
}
</script>
<style lang="scss">
  .product-list__hldr--replacement {
    max-height: 50vh;
    overflow: auto;
  }
</style>