<template>
  <ion-page>
    <top-navigation />
    <ion-content
      :fullscreen="true"
      class="ion-padding"
    >
      <account-side-navigation>
        <slot>
          <div class="mb-4 space-y-4">
            <h4 class="text--gray--900 font-bold lg:block hidden">
              Account Details
            </h4>
            <div class="flex lg:flex-row flex-col lg:gap-0 gap-4 lg:items-center items-start justify-between">
              <p class="text--secondary text-xl font-bold">
                {{ isNew ? 'New' : 'Edit' }} Address
              </p>
              <div class="flex items-center justify-end">
                <div class="relative flex items-center">
                  <div class="flex items-center h-5">
                    <input
                      :disabled="isDefaultAddress"
                      id="setDefault"
                      name="setDefault"
                      type="checkbox"
                      class="focus:ring-[#02ADF0] h-4 w-4 text-[#02ADF0] border-gray-300 rounded"
                      v-model="form.isDefault"
                    >
                  </div>
                  <div class="ml-3 text-sm">
                    <label
                      for="setDefault"
                      class="text-base"
                    >Set as default address</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full">
            <form action="">
              <div class="grid grid-cols-2 gap-4 mb-16">
                <div class="col-span-full">
                  <label
                    for="addressName"
                    class="sr-only"
                  >Address Name</label>
                  <input
                    type="text"
                    name="addressName"
                    id="addressName"
                    class="focus:ring-[#02ADF0] focus:border-[#02ADF0] block w-full placeholder-gray-400 rounded-[10px] h-12"
                    :class="hasError('name') ? 'border-red-400' : 'border-gray-300'"
                    placeholder="Address Name"
                    v-model="form.name"
                  >
                  <span class="text-xs text-red-500">*required</span>
                </div>
                <div class="lg:col-span-1 col-span-full">
                  <label
                    for="firstName"
                    class="sr-only"
                  >First Name</label>
                  <input
                    type="text"
                    name="firstName"
                    id="firstName"
                    class="focus:ring-[#02ADF0] focus:border-[#02ADF0] block w-full placeholder-gray-400 rounded-[10px] h-12"
                    :class="hasError('firstName') ? 'border-red-400' : 'border-gray-300'"
                    placeholder="First Name"
                    v-model="form.firstName"
                  >
                  <span class="text-xs text-red-500">*required</span>
                </div>
                <div class="lg:col-span-1 col-span-full">
                  <label
                    for="lastName"
                    class="sr-only"
                  >Last Name</label>
                  <input
                    type="text"
                    name="lastName"
                    id="lastName"
                    class="focus:ring-[#02ADF0] focus:border-[#02ADF0] block w-full placeholder-gray-400 rounded-[10px] h-12"
                    :class="hasError('lastName') ? 'border-red-400' : 'border-gray-300'"
                    placeholder="Last Name"
                    v-model="form.lastName"
                  >
                  <span class="text-xs text-red-500">*required</span>
                </div>
                <div class="lg:col-span-1 col-span-full">
                  <label
                    for="email"
                    class="sr-only"
                  >Email</label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    class="focus:ring-[#02ADF0] focus:border-[#02ADF0] block w-full placeholder-gray-400 rounded-[10px] h-12"
                    :class="hasError('email') ? 'border-red-400' : 'border-gray-300'"
                    placeholder="Email"
                    v-model="form.email"
                  >
                  <span class="text-xs text-red-500">*required</span>
                </div>
                <div class="lg:col-span-1 col-span-full">
                  <label
                    for="mobileNumber"
                    class="sr-only"
                  >Mobile Number</label>
                  <div class="flex rounded-[10px]">
                    <span class="inline-flex items-center px-3 rounded-l-[10px] border border-gray-300 border-r-0 text-gray-400">+ 63</span>
                    <span class="inline-flex items-center border-t border-b border-gray-300 text-gray-400">|</span>
                    <input 
                      v-model="form.mobileNumber"
                      type="text" 
                      name="mobileNumber" 
                      id="mobileNumber" 
                      class="flex-1 min-w-0 focus:ring-[#02adf0] focus:border-[#02adf0] block w-full border-l-0 border-gray-300 placeholder-gray-400 rounded-r-[10px] h-12" 
                      placeholder="Mobile Number"
                      maxlength="10"
                      @keydown="numberOnlyInput"
                    >
                  </div>
                  <span class="text-xs text-red-500">*required</span>
                </div>
                <div
                  v-if="isNew"
                  class="lg:col-span-1 col-span-full"
                >
                  <div class="relative flex items-center">
                    <div class="flex items-center h-5">
                      <input
                        id="sameAsAccount"
                        name="sameAsAccount"
                        type="checkbox"
                        class="focus:ring-[#02ADF0] h-4 w-4 text-[#02ADF0] border-gray-300 rounded"
                        v-model="sameAsAccountDetails"
                      >
                    </div>
                    <div class="ml-3 text-sm">
                      <label
                        for="sameAsAccount"
                        class="text-base text--gray--200"
                      >Same as Account Details</label>
                    </div>
                  </div>
                </div>
              </div>
              <!-- ADDRESS FORM -->
              <address-locaton-form 
                :errors="errors"
                v-model="form" 
              />
              <div class="w-full mb-12">
                <location-map v-model="coordinates" class="h-[443px]"/>
              </div>
              <div class="flex justify-between">
                <ion-button
                  class="btn-primary"
                  shape="round"
                  size="large"
                  @click="submit"
                  :disabled="loader"
                >
                  Save
                </ion-button>
                <ion-button
                  class="btn-danger"
                  shape="round"
                  size="large"
                  fill="outline"
                  @click="deleteAddress"
                  v-if="!isNew"
                >
                  Delete Address
                </ion-button>
              </div>
              <div class="text-center mt-10">
                <a href="/address-book" class="text-sm text-gray-300">&#60; Return to Address Book</a>
              </div>
            </form>
          </div>
        </slot>
      </account-side-navigation>
      <bottom-navigation />
    </ion-content>
    <Loader v-show="loader" />
  </ion-page>
</template>
<script>

import { computed, ref, watch } from '@vue/runtime-core';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import useFormat from '@/composables/useFormat';

import { IonPage, IonContent, IonButton, modalController } from '@ionic/vue';
import BottomNavigation from '@/partials/BottomNavigation.vue';
import TopNavigation from '@/partials/TopNavigation.vue';
import AccountSideNavigation from '@/partials/AccountSideNavigation.vue';
import LocationMap from '@/components/LocationMap.vue';
import Modal from '@/components/Modal';
import AddressLocatonForm from './AddressLocationForm.vue';
import Loader from '@/components/PageLoader.vue';

export default {
  name: 'AddressBookEdit',
  components: {
    IonContent, 
    IonPage, 
    IonButton,
    BottomNavigation, 
    TopNavigation, 
    AccountSideNavigation,
    LocationMap,
    AddressLocatonForm,
    Loader,
  },
  setup() {

    const loader = ref(false);

    const store = useStore();
    const router = useRouter();
    const { camelize } = useFormat();
    const { numberOnlyInput } = useFormat();

    /*
		|--------------------------------------------------------------------------
		| @Data
		|--------------------------------------------------------------------------
		*/

    const id = computed(() => router.currentRoute.value.query.i);
    const fetchAddressUrl = computed(() => store.state.api.routes.fetchOneAddress);
    const updateAddressUrl = computed(() => store.state.api.routes.updateAddress);
    const storeAddressUrl = computed(() => store.state.api.routes.storeAddress);

    const isNew = !id.value;
    const errors = ref({});
    const coordinates = ref({});
    const form = ref({
      provinceCode: 'Province',
      cityCode: 'City',
      barangayCode: 'Barangay',
    });
    const sameAsAccountDetails = ref(false);
    const isDefaultAddress = ref(false);

    const message = ref('This address has been successfully updated.');
    const errorMessage = ref(null);

    if(isNew) {
      message.value = 'This address has been successfully stored.';
    }

    /*
    |--------------------------------------------------------------------------
    | @Modals
    |--------------------------------------------------------------------------
    */

    const openModal = async () => {
      const modal = await modalController.create({
        component: Modal,
        cssClass: 'gen-modal',
        componentProps: {
          title: "Success!",
          description: message.value,
          buttonText: "Okay",
          imageLink: "/assets/icon/ic-status-check.svg",
          closeModalButton: true,
          imageWidth: '64',
          imageHeight: '64'
        }
      });
      return modal.present();
    };

    const openErrorModal = async () => {
      const modal = await modalController.create({
        component: Modal,
        cssClass: 'gen-modal',
        componentProps: {
        title: 'Oops!',
        description: errorMessage.value,
        buttonText: 'Close',
        imageLink: '/assets/icon/ic-status-warning.svg',
        closeModalButton: true,
        buttonClass: 'btn-primary',
        imageWidth: '28',
        imageHeight: '28',
        full: 'full'
        }
      });
      return modal.present();
    };

    const deleteAddress = async () => {
      const modal = await modalController.create({
        component: Modal,
        cssClass: 'gen-modal order-confirmation',
        componentProps: {
          title: 'Are you sure?',
          description: 'You will not be able to retrieve this information once it has been deleted.',
          imageLink: '/assets/icon/ic-status-warning.svg',
          cancelButtonText: 'Go Back',
          cancelButtonClass: 'btn-primary',
          cancelButton: true,
          emitButton: true,
          cancelButtonFill: 'outline',
          buttonClass: 'btn-danger',
          buttonText: 'Delete Address',
          imageWidth: '36',
          imageHeight: '36',
        }
      });

       modal.onDidDismiss().then(({ data }) => {
        if(data == 'emit') destroy();
      });

      return modal.present();
    };

    const destroy = () => {
      window.axios.post(store.state.api.routes.deleteAddress, { addressIds: [id.value] })
        .then(() => {
          router.push({ name: 'address-book-index' });
        })
        .catch(({ response }) => {
          console.log(response);
        });
    };

    /*
		|--------------------------------------------------------------------------
		| @Methods
		|--------------------------------------------------------------------------
		*/

    const checkDefault = () => {
      if(form.value.isDefault) {
        isDefaultAddress.value = true;

        store.commit('address/setForm', {
          province: form.value.provinceCode,
          province_label: form.value.province,
          city: form.value.cityCode,
          city_label: form.value.city,
          barangay: form.value.barangayCode,
          barangay_label: form.value.barangay,
          latitude: form.value.latitude,
          longitude: form.value.longitude,
          street: form.value.street,
          unitNo: form.value.unitNo,
          zipCode: form.value.zipCode,
        });

        localStorage.value.set('location', store.state.address.form);
      }
    };

    const getUserCoordinates = async () => {
      if(navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          ({ coords }) => {
            coordinates.value = {
              lat: coords.latitude,
              lng: coords.longitude,
            };
          }
        );
      }
    };

    const fetch = (url) => {
      window.axios.post(url || fetchAddressUrl.value, { id: id.value })
        .then(({ data }) => {
          const keys = Object.keys(data.item);
          keys.forEach(key => {
            let value = data.item[key];
            if(key == 'is_default') value = data.item[key] == 1 ? true : false;
            form.value[camelize(key)] = value;
            checkDefault();
          });

          if(data.item.latitude && data.item.longitude) {
            coordinates.value = {
              lat: data.item.latitude,
              lng: data.item.longitude,
            };
          }else{
            getUserCoordinates()
          }
        })
        .catch(({ response }) => {
          errorMessage.value = response.data.message;
          openErrorModal();
        });
    };

    const submit = () => {
      loader.value = true;
      const submitUrl = isNew ? storeAddressUrl.value : updateAddressUrl.value;
      form.value.latitude = coordinates.value.lat;
      form.value.longitude = coordinates.value.lng;
      window.axios.post(submitUrl, form.value)
        .then(() => {
          openModal();
          checkDefault();
          if(!isNew) fetch();
          else window.location.href = '/address-book';
          loader.value = false;
        })
        .catch(({ response }) => {
          loader.value = false;
          errorMessage.value = response.data.message;
          errors.value = response.data.errors || [];
          openErrorModal();
        });
    };

    const hasError = (key) => {
      return errors.value[key] && errors.value[key].length > 0 ? true : false;
    };

    /*
		|--------------------------------------------------------------------------
		| @Watch
		|--------------------------------------------------------------------------
		*/

    watch(
      () => fetchAddressUrl.value, 
      (url) => {
        if(typeof url === 'string' && !isNew) fetch(url);
      },
      { immediate: true }
    );

    watch(
      () => sameAsAccountDetails.value,
      (bool) => {
        if(bool) {
          const user = store.state.user.data;
          form.value.firstName = user.first_name;
          form.value.lastName = user.last_name;
          form.value.email = user.email;
          form.value.mobileNumber = parseInt(user.mobile_number);
        }
      }
    );

    return {
      isNew,
      sameAsAccountDetails,
      form,
      errors,
      coordinates,
      hasError,
      submit,
      numberOnlyInput,
      loader,
      deleteAddress,
      isDefaultAddress
    }
  }
}
</script>

<style scoped>
input[type="checkbox"]:disabled {
  color: #dddddd;
  cursor: not-allowed;
}
</style>