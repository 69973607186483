<template>
    <div class="cart-item__hldr--one-time">
        <FreeShippingReminder 
            v-if="items.length"
            :outstanding-amount="outstanding"
            :minimum-required-amount="minimum"
        />
        <FreeShippingCongratulations v-if="forDelivery && outstanding < 0" />
        <input
            class="rounded-sm mr-3 cursor-pointer"
            type="checkbox"
            id="oneTime"
            v-model="checkAll"
        >
        <label
            class="form-check-label inline-block font-bold text--secondary"
            for="oneTime"
        >
            One Time Purchase ({{ items.length }})
        </label>
        <div class="cart-item__list mt-8 ml-8">
            <template
                v-for="(item, index) in items"
                :key="`cart-one-time-item-${index}-${item.updated_at}`"
            >
                <CartItem
                    class="mb-8"
                    :item="item"
                    :is-mobile="isMobile"
                    :checked-all="checkAll"
                    @choose-replacement="openReplacementModal"
                    @refresh="refresh"
                />
            </template>
        </div>
    </div>
</template>
<script>

import { computed, defineComponent, ref, watch } from "@vue/runtime-core";
import { useStore } from "vuex";
import useCheckout from '@/composables/useCheckout';

import CartItem from './CartItem.vue';
import FreeShippingReminder from '@/views/checkout/components/OrderDetails/FreeShippingReminder.vue';
import FreeShippingCongratulations from '@/views/checkout/components/OrderDetails/FreeShippingCongratulations.vue';

export default defineComponent({
    name: 'OneTimePurchase',
    components:{
        CartItem,
        FreeShippingReminder,
        FreeShippingCongratulations
    },
    emits:[ 'choose-replacement', 'refresh' ],
    props:{
        items:{
            type:Array,
            default:() => ([])
        },
        isMobile:{
            type:Boolean,
            default:false
        },
    },
    setup(props, { emit }) {
        
        const store = useStore();
        const { addToCheckout } = useCheckout();

        const checkAll = ref(true);

        /*
		|--------------------------------------------------------------------------
		| @Computed
		|--------------------------------------------------------------------------
		*/

        const fee = computed(() => store.state.settings.fees.find(x => x.id == 1) || {});
        const forDelivery = computed(() => fee.value.is_delivery);
        const minimum = computed(() => fee.value.free_delivery_fee || 0);

        const subtotal = computed(() => props.items.reduce((value,item) => value + parseFloat(item.subtotal), 0));
        const outstanding = computed(() => minimum.value - subtotal.value);

        /*
		|--------------------------------------------------------------------------
		| @Methods
		|--------------------------------------------------------------------------
		*/
        
        const openReplacementModal = () => { emit('choose-replacement'); };
        const refresh = () => { emit('refresh'); };

        watch(
            () => props.items,
            (items) => items.forEach(item => addToCheckout(item))
        );

        return {
            checkAll,
            subtotal,
			outstanding,
            minimum,
            forDelivery,
            openReplacementModal,
            refresh
        }
    },
});
</script>