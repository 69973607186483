<template>
  <div class="py-6 border-b">
    <div class="border-0 flex lg:items-start pb-4">
      <div class="subs-leftCon flex lg:flex-wrap flex-nowrap items-start">
        <img
          :src="productImage || '/assets/image/prd-img1.png'"
          alt="product-img"
          width="103"
          height="103"
          class="object-contain block border-radius-5 overflow-hidden"
        >
      </div>
      <div class="flex lg:flex-row flex-col items-start lg:justify-between w-full">
        <div class="subs-textCon pl-4 lg:mb-0 mb-2">
          <h5 class="font-bold mb-1 text--gray--800">
            {{ item.items[0].product_name }}
          </h5>
          <p class="font--h6 text--gray--300">
            {{ item.group_type == 'App\\Models\\Carts\\Cart' ? 'One-time Purchase' : 'Subscription' }}
          </p>
          <p class="font--h6 font-bold mb-4">
            {{ moneyFormat(item.items[0].subtotal, 2, '₱') }}
          </p>
          <p class="font--h6 text--gray--300">
            Order placed on: {{ item.formatted_created_at }}
          </p>
        </div>
        <div class="subs-rightCon lg:text-right text-left lg:pl-0 pl-4">
          <h5 class="mb-1">
            Status: <span class="font-bold" :class="item.formatted_order_status == 'Refunded' ? 'text--red--400' : `font-bold`">{{ item.formatted_order_status.toUpperCase() }}</span>
          </h5>
          <p class="font--h6 mb-4 text--gray--300">
            Order #: {{ item.invoice_no }}
          </p>
          <h5>
            Order Total: <span class="font-bold text--secondary">{{ moneyFormat(item.total, 2, '₱') }}</span>
          </h5>
        </div>
      </div>
    </div>
    <div class="flex items-center justify-between">
      <div class="flex-1 w-1/2">
        <p class="text-sm">Total Items: {{ item.items.length }}</p>
      </div>
      <div class="flex-1 w-1/2 flex lg:flex-nowrap flex-wrap items-end justify-end">
        <template v-if="item.delivery_label != 'Pick Up'">
          <ChatButton
            v-if="item.order_status == 4 || item.order_status == 5"
            :invoice-id="item.id"
            :rider-name="item.rider_name"
            class="lg:mr-4 mr-0"
          />
        </template>
        <template v-if="canOrderAgain">
          <div class="lg:mr-4 mr-0">
            <ion-button
              class="btn-primary"
              shape="round"
              size="large"
              fill="outline"
              @click="orderAgain"
            >
              Order Again
            </ion-button>
          </div>
        </template>
        <div>
          <ion-button
            class="btn-primary"
            shape="round"
            size="large"
            fill="outline"
            :href="`order-history/view?i=${item.id}`"
          >
            View Details
          </ion-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import { defineComponent, ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import useCart from '@/composables/useCart';
import useEnum from '@/composables/useEnum';
import useFormat from '@/composables/useFormat';

import { modalController } from '@ionic/vue';
import Modal from '@/components/Modal.vue';

import ChatButton from '@/components/chat/ChatButton.vue';

export default defineComponent({
  name: 'OrderHistoryCard',
  components: { 
    ChatButton
  },
  props: {
    item:{
      type:Object,
      default:() => ({})
    }
  },
  setup(props) {
    
    const store = useStore();
    
    const { fetchCart } = useCart();
    const { hasStatus } = useEnum();
    const { moneyFormat } = useFormat();
    const router = useRouter();

    const canOrderAgain = ref(hasStatus('orderStatuses', 'COMPLETED', props.item.order_status));

    const openModal = async () => {
      const modal = await modalController.create({
        component: Modal,
        cssClass: 'gen-modal',
        componentProps: {
          title: "Success!",
          description: 'Items from this order have been added to cart',
          buttonText: "Okay",
          imageLink: "/assets/icon/ic-status-check.svg",
          closeModalButton: true,
          imageWidth: '64',
          imageHeight: '64'
        }
      });
      return modal.present();
    };

    const orderAgain = () => {
      const id = props.item.id;
      window.axios.post(store.state.api.routes.buyAgain, { id })
        .then(() => {
          openModal();
          fetchCart();
          router.push({ name: 'my-cart' });
        });
    };

    const productImage = computed(() => {
      return props.item.items.length && props.item.items[0].product
        ? props.item.items[0].product.initial_image 
        : null;
    });

    return {
      canOrderAgain,
      moneyFormat,
      orderAgain,
      productImage
    }
  }
})
</script>