<template>
    <ion-page>
        <top-navigation />
        <ion-content
            :fullscreen="true"
            class="ion-padding" 
        >
            <account-side-navigation class="notif-con">
                <slot>
                    <div>
                        <div class="space-y-4">
                            <h1 class="font--h4 font-bold">Refer a Friend</h1>
                            <p class="text-base text-[#181D25]">Introduce a friend to Nature’s Spring! For each friend you refer, you’ll earn droplets and they’ll get a discount of {{ discount }}. Simply generate your code, copy it and send it to your friends.</p>
                        </div>
                        <div class="mt-4 mb-10">
                            <div class="relative rounded-md w-[386px]">
                                <input 
                                    type="text" 
                                    class="focus:ring-[#02adf0] focus:border-[#02adf0] block w-full border-gray-300 placeholder-gray-400 rounded-[10px] h-12" 
                                    placeholder="Referral Code" 
                                    v-model="code" 
                                    readonly
                                />
                                <div 
                                    @click="toClipboard" 
                                    title="Copy to Clipboard" 
                                    class="z-50 cursor-pointer absolute inset-y-0 right-0 pr-3 flex items-center"
                                >
                                    <DuplicateIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </div>
                            </div>
                        </div>
                        <!-- <div>
                            <ion-button
                                class="btn-primary"
                                shape="round"
                                size="large"
                                fill="outline"
                                @click="showCopySuccessModal = true"
                            >
                                Generate Code
                            </ion-button>
                        </div> -->
                    </div>
                </slot>
            </account-side-navigation>
            <bottom-navigation />
        </ion-content>
    </ion-page>

    <copy-success-modal
        :is-visible="showCopySuccessModal" 
        @close="showCopySuccessModal = false"
    ></copy-success-modal>

</template>

<script type="text/javascript">

import { computed, defineComponent, ref } from 'vue';
import { useStore } from 'vuex';

import { IonPage, IonContent } from '@ionic/vue';
import BottomNavigation from '@/partials/BottomNavigation.vue';
import TopNavigation from '@/partials/TopNavigation.vue';
import AccountSideNavigation from '@/partials/AccountSideNavigation.vue';
import { DuplicateIcon } from "@heroicons/vue/outline";
import CopySuccessModal from "@/components/CopySuccessModal.vue";

export default defineComponent({
    name: 'ReferAFriend',
    components: {
        IonPage,
        IonContent,
        BottomNavigation,
        TopNavigation,
        AccountSideNavigation,
        DuplicateIcon,
        CopySuccessModal
    },
    setup() {

        const store = useStore();

        const showCopySuccessModal = ref(false); 
        
        const code = computed(() => store.state.user.data.referral_code);
        const points = computed(() => store.state.settings.referralReward || 0);
        const discount = computed(() => {
            let result = null;
            const refs = store.state.settings.referrals || {};
            switch(refs.type) {
                case 1: result = `${refs.amount}%`; break;
                case 2: result = `₱${refs.amount}`; break;
            }
            return result;
        });

        const toClipboard = async () => {
            await navigator.clipboard.writeText(code.value);
            showCopySuccessModal.value = true;
        };

        return {
            showCopySuccessModal,
            code,
            points,
            discount,
            toClipboard
        }
    }
})
</script>
