import { ref, watch } from "vue";
import { useStore } from 'vuex';
import useLocalStorage from "./useLocalStorage.js";

export default function useCms() 
{
    const store = useStore();
    const { localStorage } = useLocalStorage();
    const imagePath = 'https://naturespring.sgp1.digitaloceanspaces.com/';

    const cms = ref({});
    const contentSlug = ref(null);

    const initCms = async () => {
        const content = await localStorage.value.get('cms') || [];
        store.commit('content/set', content);
    };

    const getCms = (slug) => {
        const content = store.state.content.items || [];
        cms.value = Object.keys(content).length > 0 ? content.find(x => x.slug == slug) : {};
    }

    const getCmsContent = (key) => {
        if(!Object.keys(cms.value).length) return;
        return Object.keys(cms.value).length > 0 ? cms.value.content[key] : null;
    }

    const getCmsImage = (key) => {
        if(!Object.keys(cms.value).length) return;
        return Object.keys(cms.value).length > 0 ? imagePath + cms.value.content[key] : null;
    } 

    const renderCmsImage = (key) => {
        if(!Object.keys(cms.value).length) return;
        return key ? imagePath + key : null;
    }

    watch(
        () => store.state.content.items,
        (items) => {
            if(items.length) getCms(contentSlug.value)
        }
    );

    return {
        contentSlug,
        initCms,
        getCms,
        getCmsContent,
        getCmsImage,
        renderCmsImage
    }
}