import { computed } from "vue";
import { useStore } from "vuex"
import useLocalStorage from '@/composables/useLocalStorage.js';
import useResponseHandler from "./useResponseHandler";

export default function useEnum() {
    
    const store = useStore();
    
    const { localStorage } = useLocalStorage();
    const { errorHandler } = useResponseHandler();
    
    const fetchLocationUrl = computed(() => store.state.api.routes.fetchLocations);
    
    const addressForm = computed(() => store.state.address.form);
    const provinceOptions = computed(() => store.state.address.provinces);
    const cityOptions = computed(() => store.state.address.cities);
    const barangayOptions = computed(() => store.state.address.barangays);

    const saveLocation = () => {
        const code = addressForm.value.barangay || addressForm.value.barangayCode;
        return window.axios
            .post(store.state.api.routes.fetchBranch, { code })
            .then(({ data }) => {
                store.commit('address/setBranch', data.branch_id);
                localStorage.value.set('branch', data.branch_id);
                localStorage.value.set('location', addressForm.value);
                setBranchMethods(data.branch_methods);
            })
            .catch(({ response }) => errorHandler(response));
    };

    const setBranchMethods = async (data) => {
        localStorage.value.set('branchMethods', data);
        store.commit('settings/setDeliveryMethods', await localStorage.value.get('branchMethods'));
    }

    const storeAddress = (data) => {
        store.commit('address/setForm', data);
        localStorage.value.set('location', data);
    };

    const storeBranch = (id) => {
        store.commit('address/setBranch', id);
        localStorage.value.set('branch', id);
    };

    const fetchLocations = async (url, payload) => {
        let items = [];
        url = url || fetchLocationUrl.value;
        if(url) {
            items = await window.axios
            .post(url, payload)
            .then(({ data }) => {
                return data.items;
            })
            .catch(error => {
                console.error(error);
            });
        }
        return items;
    };

    const fetchProvinces = async () => {
        const provinces = await fetchLocations(fetchLocationUrl.value, { level: 'REGION' });
        store.commit('address/setProvinces', provinces);
        // localStorage.value.set('provinces', provinces);
    };

    const fetchCities = async (id) => {
        if(!id) return;
        const cities = await fetchLocations(fetchLocationUrl.value, { level: 'CITY', parentId: id });
        store.commit('address/setCities', cities);
        // localStorage.value.set('cities', cities);
    };

    const fetchBarangays = async (id) => {
        if(!id) return;
        const barangays = await fetchLocations(fetchLocationUrl.value, { level: 'BARANGAY', parentId: id });
        store.commit('address/setBarangays', barangays);
        // localStorage.value.set('barangays', barangays);
    };

    const checkCityOptions = () => {
        if(cityOptions.value.findIndex(x => x.code == addressForm.value.city) === -1) {
            fetchCities(addressForm.value.province);
        }
    };

    const checkBarangayOptions = () => {
        if(barangayOptions.value.findIndex(x => x.code == addressForm.value.barangay) === -1) {
            fetchBarangays(addressForm.value.city);
        }
    };

    return {
        addressForm,
        provinceOptions,
        cityOptions,
        barangayOptions,
        saveLocation,
        storeAddress,
        storeBranch,
        fetchProvinces,
        fetchCities,
        fetchBarangays,
        checkCityOptions,
        checkBarangayOptions
    }
}