export const products = {
    namespaced: true,
    state: {
      items: [],
			categories: [],
    },
    mutations: {
      set(state, items) {
        state.items = items || [];
      },
			setCategories(state, items) {
        state.categories = items || [];
      },
    },
  };
  