<template>
  <div class="subs-con flex items-start justify-between py-8">
    <div class="subs-leftCon flex flex-wrap items-start">
      <img
        :src="productImage"
        alt="product-img"
        width="103"
        height="103"
        class="object-contain block border-radius-5 overflow-hidden md:w-[103px] w-[40px]"
      >
      <div class="subs-textCon md:pl-4 pl-3 flex-1">
        <h5 class="font-bold mb-1 text--gray--800">
          {{ productNames }}
        </h5>
        <p class="font--h6 mb-1 text--gray--300 md:block hidden">
          Order Placed On: {{ item.formatted_created_at }}
        </p>
        <p class="font--h6 text--gray--300 md:block hidden">
          Next Order On: {{ nextSchedule.formatted_billing_date }}
        </p>
      </div>
    </div>
    <div class="subs-rightCon md:text-right text-left pl-[52px]">
      <h5 class="font-bold mb-1">
        <span class="line-through text--gray--300">{{ moneyFormat(item.original_amount, 2, '₱') }}</span>
        <span class="ml-2 text--gray--900">{{ moneyFormat(item.total_amount, 2, '₱') }}</span>
      </h5>
      <p class="font--h6 mb-1">
        {{ item.processed_orders }} out of {{ item.total_orders }} order placed
      </p>
      <p class="font--h6 text--gray--300">
        {{ item.frequency_label }} for {{ item.duration_label }}
      </p>
    </div>
  </div>
</template>

<script type="text/javascript">
import { computed, defineComponent, ref } from 'vue';
import useFormat from '@/composables/useFormat';
export default defineComponent({
  name: 'SubscriptionList',
  props:{
    item:{
      type:Object,
      default:() => ({})
    },
  },
  setup(props) {

    const { moneyFormat } = useFormat();
    
    const nextSchedule = ref(props.item.schedules[0]);
    const items = ref(props.item.items || []);

    const productImage = computed(() => items.value.length ? items.value[0].product.initial_image : '/assets/image/prd-img1.png');
    const productNames = computed(() => {
      let name = '';
      if(items.value.length) {
        const names = items.value.map(x => `${x.product.name} x ${x.quantity}`);
        name = names.join(', ');
      }
      return name;
    });
    
    return {
      productNames,
      productImage,
      nextSchedule,
      moneyFormat
    }
  }
})
</script>