<template>
    <a
      :href="socialLink"
      target="_blank"
      rel="noopener noreferrer"
      class="mx-4 ftr-social"
    >
      <img
        :src="iconSrc"
        alt="facebook-icon"
        width="30"
        height="30"
        class="object-contain block"
      >
    </a>
</template>

<script type="text/javascript">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'ContactSocialMedia',
    props: {
      iconSrc: {
        type: String,
        default: null
      },
      socialLink: {
        type: String,
        default: null
      },
    }
});
</script>