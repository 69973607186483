<template>
  <div
    class="ion-padding text-center py-15 px-20"
    :class="customClass"
  >
    <img
      class="mx-auto"
      :width="imageWidth"
      :height="imageHeight"
      :src="imageLink"
      alt="Icon"
    >
    <p class="font-bold mt-4 text-2xl relative text-center text-[#272682]">
      {{ title }}
    </p>
    <p class="text-base mt-4 mb-4 modal-description">
      {{ description }}
    </p>
    <div class="flex items-start w-full justify-center">
      <div
        class="pr-1"
        v-if="cancelButton"
      >
        <ion-button
          :class="cancelButtonClass"
          :fill="cancelButtonFill"
          size="large"
          :expand="expand"
          @click="closeModal"
        >
          {{ cancelButtonText }}
        </ion-button>
      </div>
      <div
        v-show="!closeModalButton"
        :class="cancelButton ? 'pl-1' : 'w-full'"
      >
        <ion-button
          @click="redirectToPage"
          :class="buttonClass"
          size="large"
          :expand="expand"
        >
          {{ buttonText }}
        </ion-button>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import { IonButton, modalController } from '@ionic/vue';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
    name: 'Modal',
    props: {
        customClass: {
            type: String,
            default: null
        },
        title: {
            type: String,
            default: null
        },
        description: {
            type: String,
            default: null
        },
        buttonText: {
            type: String,
            default: null
        },
        buttonLink: {
            type: String,
            default: null
        },
        buttonClass: {
            type: String,
            default: null
        },
        expand: {
            type: String,
            default: null
        },
        imageLink: {
            type: String,
            default: null
        },
        imageWidth: {
            type: String,
            default: null
        },
        imageHeight: {
            type: String,
            default: null
        },
        cancelButton: {
            type: Boolean,
            default: false
        },
        cancelButtonClass: {
            type: String,
            default: null
        },
        cancelButtonFill: {
            type: String,
            default: null
        },
        cancelButtonText: {
            type: String,
            default: null
        },
        closeModalButton : {
            type: Boolean,
            default: false
        },
        emitButton: {
          type: Boolean,
          default: false
        },
        shouldAutoClose: {
          type: Boolean,
          default: true
        }
    },
    data() {
      return {
        content: 'Content',
      };
    },
    components: { IonButton },
    setup(props) {
        const router = useRouter();

        const closeModal = async(status = 'cancel') => {
            await modalController.dismiss(status);
        };

        const autoClose = () => {
          if(props.shouldAutoClose) {
            setTimeout(() => {
              modalController.dismiss();
            }, 5000);
          }
        }

        autoClose();

        const redirectToPage = () => {
            if(!props.emitButton) {
              router.push(props.buttonLink);
            }

            closeModal(props.emitButton ? 'emit' : 'redirect');
        };

        return {
            redirectToPage,
            closeModal,
            autoClose
        }
    }
});
</script>
