<template>
  <ion-page>
    <top-navigation />
    <ion-content
      :fullscreen="true"
      class="ion-padding"
    >
      <generic-frame-one
        class="animate-up"
        :title="getCmsContent('header_pageTitle') || 'Contact Us'"
      />
      <section class="cnct-fr1 lg:pt-9 pt-0 lg:pb-24 pb-[90px]">
        <div
          class="w-11/12 mx-auto flex flex-wrap items-start justify-between animate-up"
        >
          <div class="cnctfr1-leftCon lg:w-8/12 w-full lg:pr-16 lg:mb-0 mb-[66px]">
            <div class="flex flex-wrap">
              <div class="md:w-1/2 w-full md:pr-2 pb-3">
                <input
                  type="text"
                  name="firstname"
                  id="firstname"
                  class="focus:ring-[#02adf0] focus:border-[#02adf0] block w-full border-gray-300 placeholder-gray-400 rounded-[10px] h-12"
                  placeholder="First Name"
                  v-model="form.first_name"
                >
              </div>
              <div class="md:w-1/2 w-full md:pl-2 pb-3">
                <input
                  type="text"
                  name="lastname"
                  id="lastname"
                  class="focus:ring-[#02adf0] focus:border-[#02adf0] block w-full border-gray-300 placeholder-gray-400 rounded-[10px] h-12"
                  placeholder="Last Name"
                  v-model="form.last_name"
                >
              </div>
            </div>
            <div class="flex flex-wrap">
              <div class="md:w-1/2 w-full md:pr-2 pb-3">
                <input
                  type="text"
                  name="mobileNumber"
                  id="mobileNumber"
                  class="focus:ring-[#02adf0] focus:border-[#02adf0] block w-full border-gray-300 placeholder-gray-400 rounded-[10px] h-12"
                  placeholder="Phone Number"
                  maxlength="11"
                  v-model="form.mobile_number"
                  @keydown="numberOnlyInput"
                >
              </div>
              <div class="md:w-1/2 w-full md:pl-2 pb-3">
                <input
                  type="email"
                  name="email"
                  id="email"
                  class="focus:ring-[#02adf0] focus:border-[#02adf0] block w-full border-gray-300 placeholder-gray-400 rounded-[10px] h-12"
                  placeholder="Email"
                  v-model="form.email"
                >
              </div>
            </div>
            <div class="mb-4">
              <textarea
                class="focus:ring-[#02adf0] focus:border-[#02adf0] block w-full border-gray-300 placeholder-gray-400 rounded-[10px]"
                placeholder="Message"
                rows="5"
                v-model="form.message"
              />
            </div>
            <div class="mb-6">
              <vue-recaptcha
              :sitekey="siteKey"
              @verify="verifyRecaptcha"
              @expired="expiredRecaptcha"
              ></vue-recaptcha>
            </div>
            <div>
              <ion-button
                class="btn-primary"
                size="large"
                @click="submit"
                :disabled="loader"
              >
                Submit
              </ion-button>
            </div>
          </div>
          <div
            class="cnctfr1-rightCon lg:pt-6 lg:w-4/12 w-full animate-up"
          >
            <div class="ftr-rightCon">
              <h5 class="font-bold text--secondary uppercase mb-2">
                {{ getCmsContent('contact_title1') || 'Contact Details'}}
              </h5>
              <contact-detail 
                :address="getCmsContent('contact_officeAddress') || 'A.C. Cortes Ave. 6014 Mandaue City, Cebu'"
                :addressLink="getCmsContent('contact_address_link') || 'https://www.google.com/maps/place/Nature+Spring+Mineral+Water,+Mandaue+City,+Cebu/@10.32817,123.9503292,17z/data=!3m1!4b1!4m5!3m4!1s0x33a999b55f66e5af:0xddd5b85177acd66f!8m2!3d10.3279571!4d123.9525289'"
                :email="getCmsContent('contact_emails') || 'homedelivery@naturespring.com.ph'"
                :emailLink="getCmsContent('contact_email_link') || 'mailto:homedelivery@naturespring.com.ph'"
                :phoneNumber="getCmsContent('contact_phoneNumber') || '+63 917 327 0652 (SMS/Viber Only)'"
                :phoneNumberLink="getCmsContent('contact_phone_number_link') || 'tel:+639173270652'"
              />
              <div class="ftr-socialCon flex flex-wrap items-center">
                <contact-social-media 
                  v-for="item in getCmsContent('contact_social_accts')" :key="item.id"
                  :iconSrc="renderCmsImage(item.icon)"
                  :socialLink="item.link"
                  class="mt-8 lg:justify-start justify-center"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <bottom-navigation />
    </ion-content>
    <Loader v-show="loader" />
  </ion-page>
</template>

<script type="text/javascript">
import { defineComponent, onMounted, ref } from 'vue';
import { IonPage, IonContent, IonButton, modalController } from '@ionic/vue';
import BottomNavigation from '@/partials/BottomNavigation.vue';
import TopNavigation from '@/partials/TopNavigation.vue';
import GenericFrameOne from '@/components/GenericFrame1.vue';
import ContactDetail from '@/components/ContactDetail.vue';
import ContactSocialMedia from '@/components/ContactSocialMedia.vue';
import useCms from "@/composables/useCms";
import { useStore } from 'vuex';
import useFormat from '@/composables/useFormat';
import Modal from '@/components/Modal.vue';
import Loader from '@/components/PageLoader.vue';
import { VueRecaptcha } from 'vue-recaptcha';

export default defineComponent({
  name: 'ContactPage',
  components: { 
    IonContent, 
    IonButton, 
    IonPage, 
    BottomNavigation, 
    TopNavigation, 
    GenericFrameOne, 
    ContactDetail, 
    ContactSocialMedia,
    Loader,
    VueRecaptcha
  },
  setup() {
    const { contentSlug, getCmsContent, renderCmsImage } = useCms();
    
    onMounted(() => {
        contentSlug.value = 'contact_us';
    })
    
    const store = useStore();
    const { numberOnlyInput } = useFormat();

    const form = ref({});

    const loader = ref(false);
    const errors = ref(null);

    /* Modal */
    const title = ref('Oops!');
    const message = ref('Something went wrong.');
    const imageLink = ref('/assets/icon/ic-warning.svg');
    const openModal = async () => {
      const modal = await modalController.create({
        component: Modal,
        cssClass: 'gen-modal',
        componentProps: {
          title: title.value,
          description: message.value,
          imageLink: imageLink.value,
          closeModalButton: true,
          imageWidth: '64',
          imageHeight: '64',
        }
      });
      return modal.present();
    };
    
    const submit = () => {
      loader.value = true;
      window.axios
        .post(store.state.api.routes.inquirySubmit, form.value)
        .then(({ data }) => {
          form.value = {};
          title.value = 'Success!';
          message.value = data.message;
          imageLink.value = '/assets/icon/ic-status-check.svg';
          openModal();
          loader.value = false;
        })
        .catch(({ response }) => {
          loader.value = false;
          title.value = 'Oops!';
          message.value = response.data.message;
          imageLink.value = '/assets/icon/ic-status-warning.svg';
          openModal();
        });
    };

    const siteKey = process.env.VUE_APP_SITE_KEY

    const verifyRecaptcha = () => {
      form.value.recaptcha = true;
    }

    const expiredRecaptcha = () => {
      form.value.recaptcha = null;
    }

    return {
      form,
      submit,
      numberOnlyInput,
      getCmsContent,
      renderCmsImage,
      loader,
      errors,
      siteKey,
      verifyRecaptcha,
      expiredRecaptcha
    }
  }
})
</script>
