<template>
  <footer class="ftr-frame">
    <div class="w-11/12 mx-auto">
      <div class="ftr-topCon mb-3">
        <div class="ftr-logoCon text-center">
          <a
            href="/"
            class="inline-block mx-auto"
          >
            <img
              :src="getCmsImage('generic_brandLogo') || '/assets/image/Logo.png'"
              alt="natures-spring-logo"
              width="116"
              height="56"
              class="object-contain block"
            >
          </a>
        </div>
      </div>
      <div class="ftr-botCon flex lg:flex-row flex-col flex-wrap items-start justify-between gap-10">
        <div class="ftr-leftCon flex flex-wrap items-start justify-between">
          <div class="ftr-pageCon lg:order-none order-1 lg:w-auto w-1/2">
            <h5 class="font-bold text--secondary uppercase mb-2">
              {{ getCmsContent('footer_title1') || 'Pages'}}
            </h5>
            <p class="mb-1">
              <a href="/">Home</a>
            </p>
            <p class="mb-1">
              <a href="/shop">Shop</a>
            </p>
            <p class="mb-1">
              <a href="/faqs">FAQs</a>
            </p>
            <p class="mb-1">
              <a href="/contact-us">Contact Us</a>
            </p>
          </div>
          <div class="ftr-informationCon lg:order-none order-2 lg:w-auto w-1/2">
            <h5 class="font-bold text--secondary uppercase mb-2">
              {{ getCmsContent('footer_title2') || 'Information '}}
            </h5>
            <p class="mb-1">
              <a href="/wishlist">Wish List</a>
            </p>
            <p class="mb-1">
              <a href="/terms-and-conditions">Terms & Conditions</a>
            </p>
            <p class="mb-1">
              <a href="/privacy-policy">Privacy Policy</a>
            </p>
          </div>
        </div>
        <div class="ftr-midCon text-center lg:order-none order-4 lg:w-auto w-full">
          <div class="ftr-socialCon flex flex-wrap items-center">
            <contact-social-media 
              v-for="item in getCmsContent('footer_social_accts')" :key="item.id"
              :iconSrc="renderCmsImage(item.icon)"
              :socialLink="item.link"
              class="mb-4 justify-center" 
            />
          </div>
          <p class="text-sm text--gray--300">
            {{ getCmsContent('footer_copyrightText') || "Nature's Spring. All Rights Reserved 2022." }}
          </p>
        </div>
        <div class="ftr-rightCon lg:order-none order-3  lg:w-auto w-full">
          <h5 class="font-bold text--secondary uppercase mb-2">
            {{ getCmsContent('footer_title3') || 'Contact Us' }}
          </h5>
          <contact-detail 
            :address="getCmsContent('footer_officeAddress') || 'A.C. Cortes Ave. 6014 Mandaue City, Cebu'"
            :addressLink="getCmsContent('footer_address_link') || 'https://www.google.com/maps/place/Nature+Spring+Mineral+Water,+Mandaue+City,+Cebu/@10.32817,123.9503292,17z/data=!3m1!4b1!4m5!3m4!1s0x33a999b55f66e5af:0xddd5b85177acd66f!8m2!3d10.3279571!4d123.9525289'"
            :email="getCmsContent('footer_emails') || 'homedelivery@naturespring.com.ph'"
            :emailLink="getCmsContent('footer_email_link') || 'mailto:homedelivery@naturespring.com.ph'"
            :phoneNumber="getCmsContent('footer_phoneNumber') || '+63 917 327 0652 (SMS/Viber Only)'"
            :phoneNumberLink="getCmsContent('footer_phone_number_link') || 'tel:+639173270652'"
          />
        </div>
      </div>
    </div>
  </footer>
</template>

<script type="text/javascript">
import { defineComponent, onMounted } from 'vue';
import ContactDetail from '@/components/ContactDetail.vue';
import ContactSocialMedia from '@/components/ContactSocialMedia.vue';
import useCms from "@/composables/useCms";

export default defineComponent({
  name: 'BottomNavigation',
  components: { ContactDetail, ContactSocialMedia },
  setup () {
    
    const { contentSlug, getCms, getCmsContent, getCmsImage, renderCmsImage } = useCms();
    onMounted(() => {
      contentSlug.value = 'header_footer';
      getCms(contentSlug.value);
    });

    return {
      getCmsContent, getCmsImage, renderCmsImage
    }
  }
})
</script>
