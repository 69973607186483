<template>
  <ion-page>
    <top-navigation />

    <ion-content
      :fullscreen="true"
      class="ion-padding"
    >
      <section class="product-main__hldr w-11/12 mx-auto md:flex justify-between">
        <div class="product-images__hldr w-full md:w-[48%]">
          <ProductImagesGallery
            :images="productImages"
          />
        </div>
        <div class="product-options__hldr w-full md:w-[49%]">
          <div class="mb-8 md:hidden" />
          <h6 class="product-category text--gray--400">
            {{ category.name }}
          </h6>
          <h2 class="product-name text--secondary font-bold">
            {{ product.name }}
          </h2>
          <div class="flex items-center mt-4">
            <h4 class="product-price font-bold mr-4">
              {{ moneyFormat(product.price_per_case) }}
            </h4>
            <span class="product-points font-bold text--gray--400 font--21">{{ product.points }} Droplet/s</span>
          </div>
          <div class="flex justify-between items-center">
            <div class="product-quantity__option my-6">
              <button
                class="minus-qty__btn"
                @click="decrement"
              >
                -
              </button>
              <input
                v-model="addToCartVars.quantity"
                class="input-qty text-center font-bold font--21"
                type="number"
              >
              <button
                class="add-qty__btn"
                @click="increment"
              >
                + 
              </button>
            </div>
            <div v-if="product.stock == 0 || product.stock <= product.danger_stock_level" 
              class="py-2 px-3 inline-flex bg--red--400">
                <p class="text-xs font-bold text--white">
                  Stock count is low
                </p>
            </div>
          </div>
          <div class="product-purchase__option">
            <div>
              <!-- If checked add border-active class  -->
              <div
                class="form-check product-purchase__one-time bg--primary--50 border-radius-10 p-5 mb-5"
                :class="isOnetimePurchase ? 'border-active' : ''"
              >
                <input
                  v-model="addToCartVars.type"
                  class="mr-3"
                  type="radio"
                  name="flexRadioDefault"
                  id="oneTime"
                  checked
                  value="1"
                >
                <label
                  class="form-check-label inline-block"
                  for="oneTime"
                >
                  One-Time Purchase
                </label>
                <label
                  class="form-check-label font-bold float-right"
                  for="oneTime"
                >
                  {{ moneyFormat(product.price_per_case * addToCartVars.quantity, 2, '₱') }}
                </label>
              </div>
              <div
                v-if="product.for_subscription"
                class="form-check product-purchase__subscription bg--primary--50 border-radius-10 p-5 pb-2"
                :class="isSubscriptionPurchase ? 'border-active' : ''"
              >
                <input
                  v-model="addToCartVars.type"
                  class="mr-3"
                  type="radio"
                  name="flexRadioDefault"
                  id="subscription"
                  value="2"
                >
                <label
                  class="form-check-label inline-block"
                  for="subscription"
                >
                  Subscribe and Save <span class="font-bold text--secondary">{{ selectedSubscription.amount }}%</span>
                </label>
                <div class="flex md:float-right">
                  <div class="mr-7" />
                  <div>
                    <p
                      class="font-bold block md:float-right"
                    >
                      {{ moneyFormat(selectedSubscription.price * addToCartVars.quantity, 2, '₱') }}
                    </p>
                    <p
                      class="font--h6 text--gray--400 md:float-right mr-2 line-through"
                    >
                      {{ moneyFormat(product.price_per_case * addToCartVars.quantity, 2, '₱') }}
                    </p>
                  </div>
                </div>
                <div class="mt-4 flex">
                  <div class="mr-7" />
                  <div class="w-full flex flex-wrap md:justify-between">
                    <!-- interface="popover" -->
                    <ion-select
                      v-model="frequency"
                      class="frm-select bg--white w-full md:w-[49%] mb-3"
                      placeholder="Delivery Frequency: --"
                      :disabled="addToCartVars.type == 1"
                    >
                      <ion-select-option 
                        value="" 
                        disabled
                      >
                        Select Frequency
                      </ion-select-option>
                      <ion-select-option 
                        v-for="freq in subscriptions.frequencies" 
                        :key="`frequency-option-${freq.id}`" 
                        :value="freq.id"
                      >
                        {{ freq.name }}
                      </ion-select-option>
                    </ion-select>
                    <ion-select
                      v-model="duration"
                      class="frm-select bg--white w-full md:w-[49%] mb-3"
                      placeholder="Duration: --"
                      :disabled="addToCartVars.type == 1"
                    >
                      <ion-select-option 
                        value="" 
                        disabled
                      >
                        Select Duration
                      </ion-select-option>
                      <ion-select-option 
                        v-for="dur in subscriptions.durations" 
                        :key="`duration-option-${dur.id}`" 
                        :value="dur.id"
                      >
                        {{ dur.name }}
                      </ion-select-option>
                    </ion-select>
                  </div>
                </div>
              </div>
            </div>
            <p class="text--gray--300 text-sm mt-2"><span class="font-bold">NOTE:</span> Subscription items can only be checked out with a logged in account.</p>
          </div>
          <div class="product-btn__options flex items-center flex-wrap md:justify-between mt-9">
            <div class="flex justify-between w-full md:w-[45%]">
              <ion-button
                v-if="product.stock > 0"
                @click="addToCartHandler"
                :disabled="cannotContinue"
                shape="round"
                size="large"
                class="font--main font-size--medium m-0 capitalized w-[48%]"
              >
                Add to Cart
              </ion-button>
              <ion-button
                @click="buyNow"
                :disabled="cannotContinue"
                shape="round"
                size="large"
                fill="outline"
                class="font--main font-size--medium m-0 capitalized w-[48%]"
                v-if="product.stock > 0"
              >
                Checkout Now
                <!-- v-if="isAuthenticated && product.stock > 0" -->
              </ion-button>
            </div>
            <div class="flex flex-col md:flex-row items-center md:justify-between w-full md:w-[52%]">
              <div class="mt-6 block md:hidden" />
              <template v-if="!inWishlist">
              <ion-button
                @click="addToWishlistWrapper()"
                slot="end"
                shape="round"
                class="flex items-center font--main btn-link font-bold text--primary my-0 capitalized"
              >
                <img
                  class="mr-2"
                  src="/assets/icon/ic-star-blue.svg"
                >
                Add to Wishlist 
              </ion-button>
              </template>
              <template v-else>
                <ion-button
                @click="removeToWishlistWrapper()"
                slot="end"
                shape="round"
                class="flex items-center font--main btn-link font-bold text--primary my-0 capitalized"
              >
                <img
                  class="mr-2"
                  src="/assets/icon/ic-star-blue.svg"
                >
                Remove from Wishlist 
              </ion-button>
              </template>
              <div class="mt-3 block md:hidden" />
              <img
                src="/assets/image/product-security.png"
                width="146"
                height="37"
                alt="safe-checkout"
              >
            </div>
          </div>
        </div>
      </section>
          
      <section class="product-details__hldr w-11/12 mx-auto mt-16 flex flex-wrap md:justify-between">
        <div class="w-full md:w-[63%]">
          <h3 class="text--secondary font-bold mb-4">
            Description
          </h3>
          <!-- <div v-html="item.description"></div> -->
          <div class="product-description">
            <h5 class="text--secondary font-bold mb-1">
              Details
            </h5>
            <ul class="list-disc pl-5">
              <li>SKU: {{ product.sku }}</li>
              <li>Type: {{ category.name }}</li>
              <li>Case: {{ product.qty_per_case }} per case</li>
              <li>Weight: {{ parseInt(product.case_weight) }} kg</li>
              <li>Availability: {{ product.availability }}</li>
            </ul> 
          </div>
          <div class="product-description mt-4">
            <h5 class="text--secondary font-bold mb-1">
              Description
            </h5>
            <p v-html="product.description"></p>
          </div>
        </div>
        <div class="w-full md:w-[32%]">
          <div class="block md:hidden h-[60px]" />
          <img
            :src="product.nutrition_fact_image_path ? 
              product.nutrition_fact_image_path : 
              `/assets/image/nutrients-img.png`"
            alt="nutrients-img"
            class="nutrients-img"
          >
        </div>
      </section>

      <section class="product-ratings__hldr w-11/12 mx-auto mt-16 mb-12">
        <RatingsAndReview
          :product-ratings="ratings"
          :is-logged-in="user ? true : false"
          :rating-average="product.ratingAverage"
        />
      </section>

      <section class="product-related-items__hldr">
        <RelatedItems 
          :related-items="relatedItems"
        />
      </section>

      <bottom-navigation />
    </ion-content>
    <Loader v-show="loader" />
  </ion-page>
</template>

<script type="text/javascript">

import { ref, onMounted, defineComponent, computed, watch } from 'vue';
import { useStore } from "vuex";
import { useRoute, useRouter } from 'vue-router';
import useAuth from '@/composables/useAuth';
import useCart from '@/composables/useCart';
import useCheckout from '@/composables/useCheckout';
import useFormat from "@/composables/useFormat";
import useLocalStorage from "@/composables/useLocalStorage";
import useWishlist from "@/composables/useWishlist";
import Loader from '@/components/PageLoader.vue';

import {
  IonPage,
  IonContent,
  IonSelect,
  IonSelectOption,
  IonButton,
  modalController
} from '@ionic/vue';
import Modal from '@/components/Modal';
import TopNavigation from '@/partials/TopNavigation.vue';
import BottomNavigation from '@/partials/BottomNavigation.vue';
import ProductImagesGallery from './components/ProductImagesGallery.vue';
import RatingsAndReview from './components/RatingsAndReview.vue';
import RelatedItems from './components/RelatedItems.vue';

export default defineComponent({
  name: 'SelectedProductPage',
  components: {
    IonPage,
    IonContent,
    IonSelect,
    IonSelectOption,
    IonButton,
    TopNavigation,
    BottomNavigation,
    ProductImagesGallery,
    RatingsAndReview,
    RelatedItems,
    Loader
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const { isAuthenticated } = useAuth();
    const { addToCart, cartItemType } = useCart();
    const { addToCheckout } = useCheckout();
    const { moneyFormat } = useFormat();
    const { localStorage } = useLocalStorage();
    const { addToWishlist, removeToWishlist, fetchWishlist, loader } = useWishlist();

    const fetchProductUrl = computed(() => store.state.api.routes.product);

    const user = ref(null);
    const guestId = ref(null);

    const setUpGuestId = async() => {
      guestId.value = await localStorage.value.get('guestId');
    }

    const setUpUser = async() => {
      user.value = await localStorage.value.get('user');
    }

    const productId = route.params.id;

    const product = ref({});
    const category = ref({});
    const ratings = ref([]);
    const relatedItems = ref([]);

    /* Product Subscription */
    const subscriptions = ref([]);
    const frequency = ref(null);
    const duration = ref(null);
    const selectedSubscription = ref([]);
    const subscriptionPrices = ref({
      three: [],
      six: [],
      twelve: []
    });

    const addToCartVars = ref({
      productId: productId,
      quantity: 1,
      type: 1,
      originalPrice: 0,
      discountedPrice: 0,
      notAvailableOption: null,
      replacementItemId: null,
      frequency: null,
      duration: null,
      sessionId: null,
      branchId: null
    });

    const isOnetimePurchase = computed(() => addToCartVars.value.type == cartItemType.value.ONE_TIME_PURCHASE);
    const isSubscriptionPurchase = computed(() => addToCartVars.value.type == cartItemType.value.SUBSCRIPTION);
    const inWishlist = computed(() => store.state.wishlist.items.findIndex(x => x.product_id == product.value.id) != -1);
    const cannotContinue = computed(() => {
      return addToCartVars.value.type == 2
        && (!addToCartVars.value.frequency || !addToCartVars.value.duration)
    });

    watch (
      () => frequency.value,
      (value) => {
        if (value) {
          addToCartVars.value.frequency = value;
        }
      }
    )

    watch (
      () => duration.value,
      (value) => {
        if (frequency.value) {
          switch(value) {
            case 3:
              selectedSubscription.value = subscriptionPrices.value.three;
              break;
            case 6:
              selectedSubscription.value = subscriptionPrices.value.six;
              break;
            case 12:
              selectedSubscription.value = subscriptionPrices.value.twelve;
              break;
          }
          addToCartVars.value.duration = value;
          addToCartVars.value.discountedPrice = selectedSubscription.value.price;
        }
      }
    )

    const productImages = ref([
      // '/assets/image/prd-img1.png',
      // '/assets/image/prd-img2.png',
      // '/assets/image/prd-img3.png',
    ]);

    const errorMessage = ref(null);

    /*
    |--------------------------------------------------------------------------
    | @Modals
    |--------------------------------------------------------------------------
    */

    const openErrorModal = async () => {
      const modal = await modalController.create({
        component: Modal,
        cssClass: 'gen-modal',
        componentProps: {
        title: 'Oops!',
        description: errorMessage.value,
        buttonText: 'Close',
        imageLink: '/assets/icon/ic-status-warning.svg',
        closeModalButton: true,
        buttonClass: 'btn-primary',
        imageWidth: '28',
        imageHeight: '28',
        full: 'full'
        }
      });
      return modal.present();
    };

    function increment() {
      if(addToCartVars.value.quantity < product.value.stock - 1) {
        addToCartVars.value.quantity += 1;
      }
    }

    function decrement() {
      if (addToCartVars.value.quantity > 1) {
        addToCartVars.value.quantity -= 1;
      }
    }
    
    const fetch = (url = null) => {
      window.axios.post(url || fetchProductUrl.value, { productId, platform: 'web' })
        .then((result) => {
          product.value = result.data.product;
          addToCartVars.value.originalPrice = result.data.product.price_per_case;
          addToCartVars.value.discountedPrice = result.data.product.price_per_case;
          category.value = result.data.product.category;
          subscriptions.value = result.data.subscriptions;
          ratings.value = result.data.product.ratings;
          relatedItems.value = result.data.relatedProducts;
          productImages.value = result.data.product.images 
            ? result.data.product.images.map(x => x.file_path)
            : productImages.value;

          subscriptionPrices.value.three = result.data.subscriptions.prices[0];
          subscriptionPrices.value.six = result.data.subscriptions.prices[1];
          subscriptionPrices.value.twelve = result.data.subscriptions.prices[2];
          selectedSubscription.value = result.data.subscriptions.prices[0];

        }).catch(({response}) => {
          if(response) {
            errorMessage.value = response.data.message;
            openErrorModal();
          }
        })
    };

    const addToCartHandler = () => {
      return addToCart(
                Object.assign(addToCartVars.value, {
                  sessionId: guestId.value,
                  branchId: store.state.address.branchId,
                  originalPrice: product.value.price_per_case,
                })
              );
    };

    const buyNow = () => {
      addToCartHandler()
        .then(response => {
          if(!response) return;
          const item = store.state.cart.items.find(x => x.product_id == productId);
          if(item) {
            addToCheckout(item);
            if(store.state.user.token) router.push({ name:'checkout' })
             else window.location.href = '/login?user=guest';
            // router.push({ name:'checkout' });
          }
        });
    };

    const addToWishlistWrapper = () => {
      if(!inWishlist.value && isAuthenticated.value) {
        addToWishlist(product.value.id);
      } else {
        router.push({ name:'login' });
      }
    };

    const removeToWishlistWrapper = () => {
      if(inWishlist.value) removeToWishlist(product.value.id);
    };

    watch(
      () => fetchProductUrl.value,
      (url) => {
        if(typeof url === 'string') fetch(url);
      },
      { immediate: true }
    );

    onMounted(() => {
      setUpGuestId();
      setUpUser();
      setTimeout(() => {
        fetchWishlist();
      }, 500)
    });

    return {
      productImages,
      increment,
      decrement,
      fetch,
      product,
      category,
      subscriptions,
      subscriptionPrices,
      frequency,
      duration,
      selectedSubscription,
      user,
      ratings,
      relatedItems,
      addToCartHandler,
      addToCartVars,
      isOnetimePurchase,
      isSubscriptionPurchase,
      buyNow,
      setUpGuestId,
      guestId,
      inWishlist,
      addToWishlistWrapper,
      removeToWishlistWrapper,
      cannotContinue,
      moneyFormat,
      isAuthenticated,
      loader
    }
  }
})
</script>
<style lang="scss">
    .product-related-items__hldr {
      position: relative;
      // height: 100vh;
    }
</style>
