<template>
  <div class="payment-method__hldr">
    <h3 class="font-bold mb-4">
      {{ title }}
    </h3>
    <div class="payment-method-list__hldr">
      <template
        v-for="option in paymentOptions"
        :key="`payment-option-${option.id}`"
      >
        <!-- add border-active if selected -->
        <div class="bg--primary--50 border-radius-10 px-5 py-4 mb-4">
          <input
            class="mr-3"
            type="radio"
            name="payment-option-radio"
            :id="`payment-option-radio-${option.id}`"
            :value="option.code"
            v-model="selectedOption"
            :disabled="disablePaymaya && option.code == 'PAYMAYA_CREDIT_CARD'"
          >
          
          <label
            class="form-check-label inline-block font-bold"
            for="radio"
          >
            {{ option.name }} 
          </label>
          <div class="mt-1 ml-7">
            <h6 class="">
              {{ option.description }}
            </h6>
          </div>
          <p 
            v-if="disablePaymaya && option.code == 'PAYMAYA_CREDIT_CARD'" 
            class="pl-2 pt-2 block text-red-500 font-italic font-size--x-small">
            Please checkout your subscription order and one-time order separately to use Maya.
          </p>
          <div v-if="option.code == 'PAYMAYA_CREDIT_CARD'" class="flex pl-4 mt-2">
            <img src="/assets/icon/mastercard.svg" alt="">
            <img src="/assets/icon/visa.svg" alt="">
            <img src="/assets/icon/jcb.svg" alt="">
          </div>
          <div v-if="option.code == 'DRAGONPAY'" class="flex pl-4 mt-2">
            <img class="w-[90px] h-[40px]" src="/assets/icon/dragon.png" alt="">
          </div>
          <div v-if="option.code == 'PAY_WITH_MAYA'" class="flex pl-4 mt-2">
            <img class="w-[90px] h-[30px]" src="/assets/icon/gcash.png" alt="">
            <img class="w-[80px] h-[20px] mt-2 ml-2" src="/assets/icon/maya.png" alt="">
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { computed, defineComponent, ref, watch } from "@vue/runtime-core";
import { useStore } from "vuex";

export default defineComponent({
  name: 'PaymentMethod',
  props: {
    title: {
      type:String,
      default:null
    }
  },
  setup() {

    const store = useStore();
    
    const paymentOptions = ref([]);
    const selectedOption = ref(null);

    const hasSubscription = computed(() => store.state.checkout.items.findIndex(x => x.type == 2) !== -1);
    const hasOneTime = computed(() => store.state.checkout.items.findIndex(x => x.type == 1) !== -1);
    const subscriptionItems = computed(() => store.state.checkout.items.filter(x => x.type == 2));

    const disablePaymaya = computed(() => hasSubscription.value && subscriptionItems.value.length > 1 || hasSubscription.value && hasOneTime.value);


    watch(
      () => store.state.settings.paymentOptions,
      (options) => {
        if(options.length) {
          paymentOptions.value = hasSubscription.value 
            ? options.filter(x => ['CASH','PAYMAYA_CREDIT_CARD'].includes(x.code)) 
            : options;
          selectedOption.value = options[0].code;
        }
      },
      { immediate: true }
    );

    watch(
      () => selectedOption.value,
      (value) => store.commit('checkout/setPaymentMethod', value)
    );

    return {
      paymentOptions,
      selectedOption,
      disablePaymaya,
      hasSubscription
    }
  }
});
</script>

<style scoped>
  input[type="radio"]:disabled {
    pointer-events: none;
    background-color: #cccccc;
    border-color: #cccccc;
    cursor: not-allowed;
  }
</style>