export const api = {
    namespaced: true,
    state: {
      routes: [],  
    },
    mutations: {
      setRoutes(state, value) {
        state.routes = value || {};
      },
    },
  };
  