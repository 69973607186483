<template>
  <div class="promotional-tag bg--primary--50 border-radius-14 p-6 w-full flex items-center mt-4 mb-6">
    <img
      width="25"
      height="25"
      src="/assets/icon/ic-info.svg"
      alt="icon"
    >
    <h6 class="text--gray--800 ml-2">
      Congratulations! You’ve reached the minimum amount required to receive a FREE SHIPPING offer!
    </h6>
  </div>
</template>
<script>
import { defineComponent } from "@vue/runtime-core";
export default defineComponent({
    name: 'FreeShippingCongratulations',
});
</script>