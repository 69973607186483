export const settings = {
	namespaced: true,
	state: {
		fees: [],
		extraFees: [],
		subscriptionFees: [],
		subscriptionOptions: [],
		genderOptions: [],
		unavailableOptions: [],
		paymentOptions: [],
		paymentStatuses: {},
		orderStatuses: {},
		cartItemTypes: {},
		shortRatings: [],
		branchDeliveryMethods: [],
		referralReward: 0,
		referrals: {},
	},
	mutations: {
		set(state, value) {
			Object.keys(value).forEach(key => {
				state[key] = value[key] || state[key];
			});
		},
		setDeliveryMethods(state, value) {
			state.branchDeliveryMethods = value;
		}
	},
};
  