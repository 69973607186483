<template>
  <form>
    <div class="grid grid-cols-2 gap-4 mb-4">
      <div class="lg:col-span-1 col-span-full">
        <label
          for="firstName"
          class="sr-only"
        >First Name</label>
        <input
          type="text"
          name="firstName"
          id="firstName"
          class="focus:ring-[#02ADF0] focus:border-[#02ADF0] block w-full border-gray-300 placeholder-gray-400 rounded-[10px] h-12"
          placeholder="First Name"
          v-model="form.firstName"
          :readonly="!isEditProfile"
        >
      </div>
      <div class="lg:col-span-1 col-span-full">
        <label
          for="lastName"
          class="sr-only"
        >Last Name</label>
        <input
          type="text"
          name="lastName"
          id="lastName"
          class="focus:ring-[#02ADF0] focus:border-[#02ADF0] block w-full border-gray-300 placeholder-gray-400 rounded-[10px] h-12"
          placeholder="Last Name"
          v-model="form.lastName"
          :readonly="!isEditProfile"
        >
      </div>
      <div class="lg:col-span-1 col-span-full">
        <label
          for="email"
          class="sr-only"
        >Email</label>
        <input
          type="email"
          name="email"
          id="email"
          class="focus:ring-[#02ADF0] focus:border-[#02ADF0] block w-full border-gray-300 placeholder-gray-400 rounded-[10px] h-12"
          placeholder="Email"
          v-model="form.email"
          :readonly="!isEditProfile"
        >
      </div>
      <div class="lg:col-span-1 col-span-full">
        <label
          for="mobileNumber"
          class="sr-only"
        >Mobile Number</label>
        <div class="flex rounded-[10px]">
          <span class="inline-flex items-center px-3 rounded-l-[10px] border border-gray-300 border-r-0 text-gray-400">+ 63</span>
          <span class="inline-flex items-center border-t border-b border-gray-300 text-gray-400">|</span>
          <input 
            v-model="form.mobileNumber"
            type="text" 
            name="mobileNumber" 
            id="mobileNumber" 
            class="flex-1 min-w-0 focus:ring-[#02adf0] focus:border-[#02adf0] block w-full border-l-0 border-gray-300 placeholder-gray-400 rounded-r-[10px] h-12" 
            placeholder="Mobile Number"
            maxlength="10"
            @keydown="numberOnlyInput"
            :readonly="!isEditProfile"
          >
        </div>
      </div>
      <!-- <div id="date-input-box" class="lg:col-span-1 col-span-full relative">
        <label
          for="email"
          class="sr-only"
        >Birthdate</label>
        <div @click="calendarPopUp">
          <Datepicker
            v-model="form.birthdate"
            format="MM-dd-yyyy"
            :enable-time-picker="false"
            :max-date="maxDate"
            :start-date="maxDate"
          />
        </div>
      </div> -->
      <!-- <div class="lg:col-span-1 col-span-full">
        <label
          for="age"
          class="sr-only"
        >Age</label>
        <div class="relative rounded-md shadow-sm">
          <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <span class="text-gray-500 sm:text-sm"> Age | </span>
          </div>
          <input
            type="text"
            name="age"
            id="age"
            class="focus:ring-[#02ADF0] focus:border-[#02ADF0] block w-full sm:text-sm border-gray-300 rounded-md"
            :class="isMobile ? 'pl-14' : 'pl-12'"
            placeholder="Age"
            disabled
            v-model="age"
            @keydown="numberOnlyInput"
          >
        </div>
      </div> -->
      <!-- <div class="lg:col-span-1 col-span-full">
        <label
          for="gender"
          class="sr-only"
        >Gender</label>
        <select
          id="gender"
          name="gender"
          class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-[#02ADF0] focus:border-[#02ADF0] sm:text-sm rounded-md"
          v-model="form.gender"
        >
          <option
            selected
            disabled
            :value="null"
          >
            Gender
          </option>
          <option 
            v-for="(gender,index) in genders" 
            :key="`gender-option-${index}`"
            :value="index"
          >
            {{ gender }}
          </option>
        </select>
      </div> -->
    </div>
    <div class="flex justify-between">
      <ion-button 
        v-if="!isEditProfile"
        class="btn-primary"
        shape="round"
        size="large"
        fill="outline"
        @click="isEditProfile = true"
        :disabled="loader"
      >
        Edit
      </ion-button>
      <ion-button 
        v-else
        class="btn-primary"
        shape="round"
        size="large"
        fill="outline"
        @click="updateProfile"
        :disabled="loader"
      >
        Save
      </ion-button>
      <ion-button
        class="btn-danger"
        shape="round"
        size="large"
        fill="outline"
        href="/delete-account"
      >
        Delete Account
      </ion-button>
    </div>
    <Loader v-show="loader" />
  </form>
</template>
<script type="text/javascript">

import { ref } from '@vue/reactivity';
import { computed, onMounted, watch } from '@vue/runtime-core';
import { useStore } from 'vuex';
import { Duration, DateTime } from "luxon";
import useAuth from '@/composables/useAuth';
import useFormat from '@/composables/useFormat';
import useLocalStorage from '@/composables/useLocalStorage';

import { IonButton, modalController } from '@ionic/vue';
import Modal from '@/components/Modal';
import Loader from '@/components/PageLoader.vue';

export default {
  name: 'ProfileForm',
  components: { IonButton, Loader },
  data() {
    return {
      date: null,
    };
  },
  methods: {
    calendarPopUp() {
      let dateInputBox = document.querySelector("#date-input-box");
      let datePickerMenu = document.querySelector(".dp__menu");
      dateInputBox.appendChild(datePickerMenu);
    }
  },
  setup() {

    /*
    |--------------------------------------------------------------------------
    | @Data
    |--------------------------------------------------------------------------
    */

    const loader = ref(false);
    const isEditProfile = ref(false);

    const store = useStore();
    const { localStorage } = useLocalStorage();
    const { numberOnlyInput } = useFormat();
    const { logout } = useAuth();
    
    const isMobile = ref(false);
    const errorMessage = ref(null);
    const form = ref({
      gender: 'Gender'
    });

    /*
    |--------------------------------------------------------------------------
    | @Modals
    |--------------------------------------------------------------------------
    */

    const openModal = async () => {
      const modal = await modalController.create({
        component: Modal,
        cssClass: 'gen-modal email-verification',
        componentProps: {
          title: "Success!",
          description: "Your profile has been successfully updated.",
          buttonText: "Okay",
          imageLink: "/assets/icon/ic-status-check.svg",
          closeModalButton: true,
          imageWidth: '64',
          imageHeight: '64'
        }
      });
      return modal.present();
    };

    const openSuccessModal = async () => {
        const modal = await modalController.create({
          component: Modal,
          cssClass: 'gen-modal',
          componentProps: {
          title: 'Success!',
          description: 'Your account deletion will be processed shortly. You will be redirected into the home page.',
          buttonText : 'Close',
          imageLink: '/assets/icon/ic-status-check.svg',
          shouldAutoClose: true,
          closeModalButton: true,
          imageWidth: '64',
          imageHeight: '64',
          full: 'full'
          }
        });
        return modal.present();
    };

    const openErrorModal = async () => {
      const modal = await modalController.create({
        component: Modal,
        cssClass: 'gen-modal',
        componentProps: {
        title: 'Oops!',
        description: errorMessage.value,
        buttonText: 'Close',
        imageLink: '/assets/icon/ic-status-warning.svg',
        closeModalButton: true,
        buttonClass: 'btn-primary',
        imageWidth: '28',
        imageHeight: '28',
        full: 'full'
        }
      });
      return modal.present();
    };

    const openDeleteAccountModal = async () => {
      const modal = await modalController.create({
        component: Modal,
        cssClass: 'gen-modal order-confirmation',
        componentProps: {
        title: 'Are you sure?',
        description: `You will not be able to retrieve this account once it has been deleted and
          You will not be able to login/register using the same email/mobile number once it has been deleted.`,
        imageLink: '/assets/icon/ic-status-warning.svg',
        cancelButtonText: 'Go Back',
        cancelButtonClass: 'btn-primary',
        cancelButton: true,
        cancelButtonFill: 'outline',
        emitButton: true,

        buttonClass: 'btn-danger',
        buttonText: 'Delete Account',
        imageWidth: '36',
        imageHeight: '36',
        }
      });

      modal.onDidDismiss().then(({ data }) => {
        if(data === 'emit') deleteAccount();
      });

      return modal.present();
    };

    /*
    |--------------------------------------------------------------------------
    | @Computed
    |--------------------------------------------------------------------------
    */
    const deleteAccountUrl = computed(() => store.state.api.routes.deleteUserAccount);
    const genders = computed(() => store.state.gender.options);
    const updateProfileUrl = computed(() => store.state.api.routes.updateProfile);
    const fetchProfileUrl = computed(() => store.state.api.routes.fetchProfile);

    const age = computed(() => {
      if(form.value.birthdate) {
        const birthdate = DateTime.fromJSDate(form.value.birthdate);
        const duration = DateTime.now().diff(birthdate, 'years');
        return Math.floor(duration.years);
      }
      return '--';
    });

    const maxDate = computed(() => {
      const luxon = DateTime.now().minus(Duration.fromObject({ years:15 }));
      return new Date(luxon);
    });

    /*
    |--------------------------------------------------------------------------
    | @Methods
    |--------------------------------------------------------------------------
    */

   const deleteAccount = () => {
      if(loader.value) return false;
      loader.value = true;
      window.axios
        .post(deleteAccountUrl.value)
        .then(({ data }) => {
            console.log(data);
            loader.value = false;
            openSuccessModal()
            setTimeout(() => {
                modalController.dismiss();
                logout()
            }, 3000);
        }).catch(({response}) => {
            loader.value = false;
            errorMessage.value = response.data.message;
            openErrorModal();
        });
      }

    const fetchProfile = (url = null) => {
      window.axios.post(url || fetchProfileUrl.value, form.value)
        .then(({ data }) => {
          console.log(new Date())
          form.value.firstName = data.item.first_name;
          form.value.lastName = data.item.last_name;
          form.value.email = data.item.email;
          form.value.mobileNumber = data.item.mobile_number;
          form.value.gender = data.item.gender;
          form.value.birthdate = data.item.birthdate ? new Date(data.item.birthdate) : null;
          
          store.commit('user/setData', data.item);
          localStorage.value.set('user', data.item);
        })
        .catch(({ response }) => {
          if(response.status == 401) logout();
          else {
            errorMessage.value = response.data.message;
            openErrorModal();
          }
        });
    };

    const updateProfile = () => {
      loader.value = true;
      form.value.birthdate = new Date(DateTime.fromJSDate(form.value.birthdate).toFormat('yyyy-MM-dd'));
      window.axios.post(updateProfileUrl.value, form.value)
        .then(() => {
          openModal();
          fetchProfile();
          loader.value = false;
          isEditProfile.value = false;
        })
        .catch(({ response }) => {
          isEditProfile.value = false;
          loader.value = false;
          errorMessage.value = response.data.message;
          openErrorModal();
        });
    };

    watch(
      () => fetchProfileUrl.value,
      (url) => {
        if(typeof url === 'string') fetchProfile(url);
      },
      { immediate: true }
    );

    /*
    |--------------------------------------------------------------------------
    | @Return
    |--------------------------------------------------------------------------
    */

    onMounted(() => {
      const width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
      isMobile.value = width < 768 ? true : false;
    });
    
    return {
      isMobile,
      updateProfile,
      numberOnlyInput,
      maxDate,
      genders,
      form,
      age,
      loader,
      isEditProfile,
      openDeleteAccountModal
    };
  }
}
</script>
<style lang="scss" scoped>
.dp__input {
  font-size: 13px !important;
}
input:read-only {
 cursor: not-allowed;
}

#date-input-box {
  .dp__menu {
    top: 40px !important;
    left: 50% !important;
  }
}

</style>