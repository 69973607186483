<template>
  <ion-page>

    <template v-if="!isMobile">
      <transition name="down">
        <top-navigation
          v-if="isScrolled"
          name="Home"
          class="home-scrolled"
        />
      </transition>
    </template>

    <top-navigation
      v-if="isMobile"
      name="Home"
      :class="{ scrolled: isScrolled }"
    />
        
    <ion-content
      :fullscreen="true"
      class="ion-no-padding"
      :scroll-events="true"
      @ion-scroll="logScrolling($event)"
    >

      <template v-if="!isMobile">
        <transition name="fade">
          <top-navigation
            v-if="!isScrolled"
            name="Home"
            class="nav-start home-start"
          />
        </transition>
      </template>

      <template v-if="isMobile">
        <div class="hdr-spacing" />
      </template>

      <!-- Frame 1 -->
      <section class="hm-frm1 h-screen w-full">
        <swiper
          :pagination="{ dynamicBullets: true }"
          :autoplay="{
            delay: 2500,
            disableOnInteraction: false,
          }"
          :modules="modules"
          :loop="true"
          class="hm-frm1__swiper"
        >
          <template
            v-for="item in getCmsContent('section1_banner')" :key="item.id"
          >
            <swiper-slide>
              <div class="relative w-full h-full flex items-center justify-center">
                <img
                  :src="item.bg_image ? renderCmsImage(item.bg_image) : '/assets/image/hm-fr1-bg.gif'"
                  class="absolute w-full-h-full object-cover z-10"
                  alt="Nature Spring"
                >
                <div class="slide-content relative z-20 grid grid-cols-2 w-11/12">
                  <div class="lg:col-span-1 col-span-full flex flex-col lg:items-start items-center justify-center">
                    <h1 class="text--secondary font-bold leading-[55px] mb-4 lg:text-left text-center">
                      {{ item.title ? item.title : 'Stock up on water safely and conveniently' }}
                    </h1>
                    <p class="lg:text-left text-center">
                      {{ item.description ? item.description : 'Cash on Delivery available nationwide, free delivery for P500 minimum spend' }}
                    </p>
                    <ion-button
                      size="large"
                      class="btn-primary mt-8"
                      :href="item.url ? item.url : '#'"
                    >
                      {{ item.button1_text ? item.button1_text : 'Shop Now' }}
                    </ion-button>
                  </div>
                  <div class="lg:col-span-1 col-span-full lg:mt-0 mt-8">
                    <img
                      :src="item.image ? renderCmsImage(item.image) : '/assets/image/hm-fr1-img.png'"
                      alt="Nature Spring"
                    >
                  </div>
                </div>
              </div>
            </swiper-slide>
          </template>
        </swiper>
      </section>

      <!-- Frame 2 -->
      <section
        class="hm-frm2 min-h-screen w-full relative lg:mt-0 mt-32 animate-up"
      >
        <div class="grid grid-cols-2">
          <div class="lg:col-span-1 col-span-full relative">
            <div class="lg:h-screen h-full bg-fade">
              <swiper
                :navigation="{
                  nextEl: '.frm2-arrow__next',
                  prevEl: '.frm2-arrow__prev',
                }" 
                :slides-per-view="1"
                :space-between="50"
                :speed="1500"
                :breakpoints="{
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 150
                  }
                }"
                :centered-slides="true"
                :pagination="false"
                :modules="modules"
                :controller="{ control: controlledSwiper }"
                class="hm-frm2__swiper--left"
              >
                <template
                  v-for="item in getCmsContent('section2_banner')" :key="item.id"
                >
                  <swiper-slide>
                    <img
                      :src="item.image ? renderCmsImage(item.image) : '/assets/image/water-bottle.png'"
                      class="lg:h-[30rem] h-[24rem] w-auto"
                      alt="Nature Spring Water Bottle"
                    >
                  </swiper-slide>
                </template>
              </swiper>
            </div>
            <div class="hm-frm2__arrow-box">
              <button class="frm2-arrow__prev"></button>
              <button class="frm2-arrow__next"></button>
            </div>
          </div>
          <div class="lg:col-span-1 col-span-full lg:h-screen h-[50vh] overflow-hidden">
            <swiper
              :navigation="false"
              :direction="'vertical'"
              :pagination="false"
              :effect="'creative'"
              :creativeEffect="{
                prev: {
                  opacity: 0,
                  translate: [0, '-10%', 0],
                },
                next: {
                  opacity: 0,
                  translate: [0, '10%', 0],
                },
              }"
              :modules="modules"
              :allow-touch-move="false"
              @swiper="setControlledSwiper"
              class="hm-frm2__swiper--right"
            >
              <template
                v-for="item in getCmsContent('section2_banner')" :key="item.id"
              >
                <swiper-slide>
                  <div class="lg:w-[60%] w-[90%] mx-auto flex flex-col lg:items-start items-center">
                    <h2 class="font-bold text--secondary leading-[40px] lg:text-left text-center">
                      {{ item.title ? item.title : 'Sample Drinking Water' }}
                    </h2>
                    <p class="mt-6 lg:text-left text-center">
                      {{ item.description ? item.description 
                      : "Nature's Spring Purified Drinking Water is a quality yet affordable brand for daily consumption of clean and safe bottled water." }}
                    </p>
                    <ion-button
                      :href="item.url ? item.url : '#'"
                      size="large"
                      class="btn-primary mt-8"
                    >
                      {{ item.button1_text }}
                    </ion-button>
                  </div>
                </swiper-slide>
              </template>
            </swiper>
          </div>
        </div>
      </section>

      <!-- Frame 3 -->
      <section class="hm-frm3 min-h-screen w-full relative">
        <div class="lg:h-full min-h-screen relative bg-fade">
          <img
            :src="getCmsImage('section3_bg_image') || '/assets/image/hm-bg3.png'"
            class="w-full h-auto lg:object-[unset] object-cover lg:block hidden"
          >
          <div class="lg:absolute lg:inset-0 h-full w-full z-10">
            <div class="lg:w-11/12 w-full h-full mx-auto flex items-center justify-center">
              <div class="w-full">
                <div
                  class="lg:block hidden animate-up"
                >
                  <h2 class="text-white text-center font-bold leading-[40px] mb-8">
                    {{ getCmsContent('section3_title') || 'Choose a subscription!' }}
                  </h2>
                  <p class="w-[60%] mx-auto text-center text-white mb-10">
                    {{ getCmsContent('section3_description') 
                      || 'Have your water delivered to your doorstep every 2 weeks or every month by choosing a subscription, and enjoy discounts to your order!' }}
                  </p>
                </div>
                <div
                  class="flex lg:flex-row flex-col lg:items-stretch items-center lg:justify-between justify-center lg:gap-0 gap-16 mbl-bg animate-up"
                  :style="[ !isMobile ? '' : 'background-image: url(\'/assets/image/hm-bg3.png\')']"
                >
                  <template v-for="(item, index) in getCmsContent('section3_frame3list')" :key="item.id">
                    <div>
                      <subscription-card
                        :title="item.title"
                        :img-src="renderCmsImage(item.icon)"
                        :description="item.description"
                        :step="index + 1"
                      />
                    </div>
                    <div
                      class="flex items-center justify-center"
                      :class="[ 
                        index == 2 ? 'hidden' : '',
                        index == 1 && !isMobile ? 'flip' : '',
                        index == 1 && isMobile ? 'flip ml-24' : '',
                        index == 0 && isMobile ? 'flip-rotate' : '',
                        index == 0 && isMobile ? 'mr-24' : '',
                      ]"
                    >
                        <svg :class="{ 'h-16': isMobile }" xmlns="http://www.w3.org/2000/svg" width="184" height="104" viewBox="0 0 184 104" fill="none">
                          <path d="M24.0337 76.6175L22.8824 75.656L24.0337 76.6175ZM183.061 13.0607C183.646 12.4749 183.646 11.5251 183.061 10.9393L173.515 1.3934C172.929 0.807611 171.979 0.807611 171.393 1.3934C170.808 1.97919 170.808 2.92893 171.393 3.51472L179.879 12L171.393 20.4853C170.808 21.0711 170.808 22.0208 171.393 22.6066C171.979 23.1924 172.929 23.1924 173.515 22.6066L183.061 13.0607ZM3.15129 103.962L25.185 77.579L22.8824 75.656L0.848706 102.038L3.15129 103.962ZM162.189 13.5H182V10.5H162.189V13.5ZM25.185 77.579C59.0996 36.9708 109.281 13.5 162.189 13.5V10.5C108.392 10.5 57.367 34.3652 22.8824 75.656L25.185 77.579Z" fill="white"></path>
                        </svg>
                    </div>
                  </template>                  
                </div>
                <div class="mt-10 flex items-center justify-center absolute w-full bottom-20 md:bottom-0 md:relative">
                  <ion-button
                    href="/shop"
                    class="btn-white md button button-large button-solid ion-activatable ion-focusable" 
                    size="large"
                  > 
                    Shop Now!
                  </ion-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    
      <!-- Frame 4 -->
      <section class="hm-frm4 w-11/12 mx-auto lg:mb-[147px] lg:mt-[100px] mt-[100px] mb-[74px]">
        <div
          class="grid grid-cols-3 lg:gap-0 gap-20 animate-up"
        >
          <div 
            v-for="item in getCmsContent('section4_frame4list')"
            :key="item.id"
            class="lg:col-span-1 col-span-full"
          >
            <feature-card
              :title="item.title"
              :img-src="renderCmsImage(item.icon)"
              :description="item.description"
            />
          </div>
        </div>
      </section>

      <!-- Frame 5 -->
      <section
        class="hm-frm5 max-h-screen w-11/12 mx-auto animate-up"
      >
        <div class="mb-14">
          <h2 class="text--secondary font-bold">
            {{ getCmsContent('section5_title') || 'Reviews' }}
          </h2>
        </div>
        <div class="hm-frm5__swiper-box">
          <swiper 
            :navigation="{
              nextEl: '.swp-next',
              prevEl: '.swp-prev',
            }" 
            :slides-per-view="1"
            :space-between="30"
            :breakpoints="{
              1024: {
                slidesPerView: 3,
                spaceBetween: 30
              }
            }"
            :modules="modules" 
            class="hm-frm5__swiper"
          >
            <template v-for="item in getCmsContent('section5_reviews')"
              :key="item.id"
            >
              <swiper-slide >
                <review-card
                  :img="renderCmsImage(item.image)"
                  :name="item.customer_name"
                  :title="item.customer_title"
                  :short-rating="item.short_review"
                  :review="`“${item.review}”`"
                />
              </swiper-slide>
            </template>
          </swiper>
          <div class="hm-frm5__arrow-box">
            <button class="swp-prev"></button>
            <button class="swp-next"></button>
          </div>
        </div>
      </section>

      <!-- Frame 6 -->
      <section class="hm-frm6 lg:h-screen h-[75%] w-full relative lg:mt-28 mt-12">
        <div class="h-full relative">
          <img
            :src="getCmsImage('section6_bg_image') || '/assets/image/generic-footer-bg.png'"
            class="w-full h-full lg:object-fill object-cover"
          >
          <div
            class="absolute inset-0 h-full w-full z-10 animate-up"
          >
            <div class="w-11/12 h-full mx-auto flex items-center justify-center">
              <div class="lg:w-[40%] w-[95%] mx-auto lg:mt-16 mt-0">
                <div>
                  <h2 class="text-white text-center font-bold leading-[40px] mb-8">
                    {{ getCmsContent('section6_title') ||  'Sign up to our newsletter' }}
                  </h2>
                  <p class="w-full mx-auto text-center text-white mb-10">
                    {{ getCmsContent('section6_description') 
                      || 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.' }}
                  </p>
                </div>
                <newsletter-clients-form>
                </newsletter-clients-form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <referral-modal
        :is-visible="showReferralModal" 
        @close="showReferralModal = false"
      ></referral-modal>

      <bottom-navigation />
    </ion-content>
  </ion-page>
</template>

<script type="text/javascript">
import { onMounted, ref } from 'vue';
import { IonPage, IonContent, IonButton } from '@ionic/vue';
import BottomNavigation from '@/partials/BottomNavigation.vue';
import TopNavigation from '@/partials/TopNavigation.vue';
import SubscriptionCard from './components/SubscriptionCard.vue';
import ReviewCard from './components/ReviewCard.vue';
import FeatureCard from './components/FeatureCard.vue';
import NewsletterClientsForm from '@/components/NewsletterClientsForm.vue';
import ReferralModal from "@/components/ReferralModal.vue";

import { Swiper, SwiperSlide } from "swiper/vue";
import { EffectCreative, Controller, Navigation, Autoplay, Pagination } from "swiper";
import "swiper/scss";
import "swiper/scss/pagination";
import "swiper/scss/navigation";
import "swiper/scss/effect-creative";

import { gsap } from "gsap";

import useCms from "@/composables/useCms";

export default {
    name: 'HomePage',
    components: {
        IonContent,
        IonPage,
        IonButton,
        BottomNavigation,
        TopNavigation,
        Swiper,
        SwiperSlide,
        SubscriptionCard,
        ReviewCard,
        FeatureCard,
        NewsletterClientsForm,
        ReferralModal
    },
    data: () => ({
        navClass: 'home-start',
        isMobile: false,
        isScrolled: false,
    }),
    methods: {
        logScrolling(e) {
          if (e.detail.scrollTop > 150) {
              this.isScrolled = true;
          } else if (e.detail.scrollTop < 154) {
              this.isScrolled = false;
          }
        }
    },
    mounted() {
      if (window.innerWidth < 1024) {
        this.isMobile = true;
      }

      gsap.fromTo(".slide-content", {
        autoAlpha: 0,
        y: 50
      }, {
        autoAlpha: 1,
        y: 0,
        duration: 1.5,
        ease: "expo.out"
      });

    },
    setup() {
        const showReferralModal = ref(true);

        const controlledSwiper = ref(null);
        const setControlledSwiper = (swiper) => {
            controlledSwiper.value = swiper;
        };

        const { contentSlug, getCms, getCmsContent, getCmsImage, renderCmsImage } = useCms();

        onMounted(() => {
          contentSlug.value = 'home';
          getCms(contentSlug.value);
        })

        return {
            controlledSwiper,
            setControlledSwiper,
            modules: [ EffectCreative, Controller, Navigation, Autoplay, Pagination],
            getCmsContent, getCmsImage, renderCmsImage,
            showReferralModal
        };
    },
}
</script>