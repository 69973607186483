<template>
  <img
    :src="selectedImage"
    alt="product-img"
    class="product-main__img"
  >
  <ul class="product-images__list mt-4">
    <template
      v-for="(img, index) in images"
      :key="`gallery-product-image-${index}`"
    >
      <li
        class="product-image__item cursor-pointer mr-3"
        :class="selectedImageIndex == index ? 'active' : ''"
        @click="selectImage(index)"
      >
        <img
          :src="img"
          alt="product-img"
          class="img-item"
        >
      </li>
    </template>
  </ul>
</template>

<script type="text/javascript">
import { ref, onMounted, defineComponent, computed } from 'vue';
export default defineComponent({
  name: 'ProductImagesGallery',
  props: {
    images: {
      default() {
          return []
      },
      type: Array
    }
  },

  setup(props) {

    const selectedImageIndex = ref(0);

    const selectedImage = computed(() => props.images.length ? props.images[selectedImageIndex.value] : '');
    const selectImage = (index) => (selectedImageIndex.value = index);

    onMounted(() => {
      if(props.images.length) {
        selectedImageIndex.value = 0;
      }
    })

    return {
      selectedImage,
      selectedImageIndex,
      selectImage,
    }
  }
})
</script>
<style lang="scss">
  .product-images__hldr {

    .product-main__img {
      border: 1px solid #C8CFDA;
      border-radius: 5px;
      width: 100%;
      height: auto;
      object-fit: contain;
    }

    .product-images__list {
      display: flex;

      .product-image__item {

        .img-item {
          border: 1px solid #C8CFDA;
          border-radius: 5px;
          width: 72px;
          height: 72px;
          object-fit: contain;
        }

        &.active {
          .img-item {
            border: 1px solid #02ADF0;
          }
        }
      }
    }
  } 
</style>