<template>
  <ion-page>
    <top-navigation />
    <ion-content
      :fullscreen="true"
      class="ion-padding"
    >
      <account-side-navigation>
        <slot>
          <div class="flex flex-wrap align-center lg:justify-between justify-end">
            <h1 class="font--h4 font-bold md:block hidden">
              Subscriptions
            </h1>
            <div class="subs-progressCon pr-3 mr-12 self-center">
              <h6 class="font-bold">
                {{ subscription.processed_orders }} out of {{ subscription.total_orders }} invoices placed
              </h6>
              <div class="subs-progress">
                <svg
                  width="48"
                  height="48"
                  viewBox="0 0 48 48"
                >
                  <circle
                    cx="24"
                    cy="24"
                    r="21"
                    fill="none"
                    stroke="#C8CFDA"
                    stroke-width="5"
                  />
                  <!-- change `n` on stroke-dashoffset: calc(100 - n) -->
                  <circle
                    class="percent"
                    cx="24"
                    cy="24"
                    r="21"
                    fill="none"
                    stroke="#02ADF0"
                    stroke-width="5"
                    pathLength="100"
                    :style="`stroke-dashoffset: calc(100 - ${percentage});`"
                  />
                </svg>
                <h6 class="font-bold text-[#343A40] mx-auto absolute right-0 left-0 mx-auto top-[15px]">
                  {{ percentage.toFixed(2) }}<span>%</span>
                </h6>
              </div>
            </div>
          </div>
          <div class="flex flex-wrap items-start justify-between mt-10 md:mb-6 mb-12">
            <div class="subs-leftCon flex flex-wrap items-start">
              <img
                :src="productImage"
                alt="product-img"
                width="103"
                height="103"
                class="object-contain block border-radius-5 overflow-hidden md:w-[103px] w-[40px]"
              >
              <div class="subs-textCon md:pl-4 pl-3 flex-1">
                <h5 class="font-bold mb-2 text--gray--800">
                {{ productNames }}
                </h5>
                <p class="font--h6 mb-2 text--gray--300">
                  Order Placed On: {{ subscription.formatted_created_at }}
                </p>
              </div>
            </div>
            <div class="subs-rightCon md:text-right text-left pl-[52px] md:mt-0 mt-5">
              <h5 class="font-bold mb-2">
                <span class="line-through text--gray--300">{{ moneyFormat(subscription.original_amount, 2, '₱') }}</span>
                <span class="ml-2 text--gray--900">{{ moneyFormat(subscription.total_amount, 2, '₱') }}</span>
              </h5>
              <p 
                v-if="subscription.status == 2"
                class="font--h6 mb-2"
              >
                Next Order On: 
                <span class="font-bold">{{ schedule.formatted_billing_date }}</span>
              </p>
              <p 
                v-if="subscription.status == 2"
                class="font--h6 text--gray--300 cstm-link underline cursor-pointer"
                @click="editSubscription(subscription.id)"
              >
                Edit Subscription
              </p>
              <p
                v-if="subscription.status == 2"
                class="font--h6 text--gray--300 cstm-link underline cursor-pointer"
                @click="cancelConfirmationModal()"
              >
                Cancel Subscription
              </p>
            </div>
          </div>
          <div 
            v-if="subscription.status == 2"
            class="mb-12" 
          >
            <h5 class="font-bold mb-1">
              Update Delivery Schedule
            </h5>
            <p>You can request for your order to be sent in advance. Note that this wil also affect all your future deliveries under this subscription.</p>
            <div class="subs-calendar-wrapper mt-4">
              <Datepicker 
                v-model="date"
                placeholder="Set a New Date"
                :text-input="true"
                :enable-time-picker="false"
                :min-date="minDate"
                :start-date="minDate"
              />
              <ion-button
                shape="round"
                class="button tight my-0 mx-0 btn-primary"
                fill="outline"
                @click="submit"
                :disabled="!date"
              >
                Save
              </ion-button>
            </div>
          </div>
          <hr>
          <div class="mt-10" v-if="invoices.length">
            <h4 class="text-xl font-bold">
              History
            </h4>
            <subscription-history 
              v-for="(invoice,index) in invoices"
              :key="`subscription-invoice-${index}`"
              :name="productNames"
              :item="invoice"              
            />
          </div>
          <div class="mt-7">
            <a
              href="/subscription"
              class="font--h6 text--gray--300 cstm-link"
            >&lt; Return to Subscription</a>
          </div>
        </slot>
      </account-side-navigation>
      <bottom-navigation />
    </ion-content>
  </ion-page>
</template>

<script type="text/javascript">

// import Datepicker from 'vue3-date-time-picker';
// import 'vue3-date-time-picker/dist/main.css';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

import { computed, defineComponent, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { Duration, DateTime } from "luxon";
import useFormat from '@/composables/useFormat';

import { IonPage, IonContent, IonButton, modalController } from '@ionic/vue';
import Modal from '@/components/Modal.vue';
import BottomNavigation from '@/partials/BottomNavigation.vue';
import TopNavigation from '@/partials/TopNavigation.vue';
import AccountSideNavigation from '@/partials/AccountSideNavigation.vue';
import SubscriptionHistory from './components/SubscriptionHistory.vue';

export default defineComponent({
  name: 'SubscriptionViewPage',
  components: { 
    IonContent, IonButton, IonPage, 
    BottomNavigation, TopNavigation, AccountSideNavigation, 
    SubscriptionHistory, Datepicker 
  },
  setup() {

    const store = useStore();
    const router = useRouter();
    const { moneyFormat } = useFormat();

    const subscriptionId = router.currentRoute.value.query.i;
    const subscription = ref({ schedules: [] });
    const items = ref([]);
    const date = ref(null);
    const title = ref(null);
    const message = ref(null);
    const image = ref(null);

    const fetchSubscriptionUrl = computed(() => store.state.api.routes.subscriptionShow);

    const schedule = computed(() => subscription.value.schedules.length ? subscription.value.schedules[0] : {});
    const percentage = computed(() => (subscription.value.processed_orders / subscription.value.total_orders) * 100);
    const invoices = computed(() => subscription.value.invoices || []);
    
    const productImage = computed(() => items.value.length ? items.value[0].product.initial_image : '/assets/image/prd-img1.png');
    const productNames = computed(() => {
      let name = '';
      if(items.value.length) {
        const names = items.value.map(x => `${x.product.name} x ${x.quantity}`);
        name = names.join(', ');
      }
      return name;
    });

    const minDate = computed(() => {
      const luxon = DateTime.now().plus(Duration.fromObject({ days:3 }));
      return new Date(luxon);
    });

    const openModal = async () => {
        const modal = await modalController.create({
            component: Modal,
            cssClass: 'gen-modal',
            componentProps: {
                title: title.value,
                description: message.value,
                imageLink: image.value,
                closeModalButton: true,
                imageWidth: '64',
                imageHeight: '64',
            }
        });
        return modal.present();
    };

    const cancelConfirmationModal = async () => {
      const modal = await modalController.create({
        component: Modal,
        cssClass: 'gen-modal',
        componentProps: {
        title: 'Are you sure?',
        description: 'This will cancel your current subscription.',
        buttonText: 'Cancel Subscription',
        buttonClass: 'btn-danger',
        emitButton: true,
        cancelButton: true,
        cancelButtonClass: 'btn-primary',
        cancelButtonFill: 'outline',
        cancelButtonText: 'Go Back',
        imageLink: '/assets/icon/ic-status-warning.svg',
        imageWidth: '36',
        imageHeight: '36',},
      });
      modal.onDidDismiss().then(({ data }) => {
        if(data == 'emit') cancelSubscription();
      });
      return modal.present();
    }

    const editSubscription = (id) => {
        window.location.href = `/subscription/edit?id=${id}`
        // router.push({ path: '/subscription/edit' })
    }

    const fetch = (url) => {
      window.axios.post(url || fetchSubscriptionUrl.value, { subscriptionId })
        .then(({ data }) => {
          subscription.value = data.subscription;
          items.value = data.items;
        });
    };

    const submit = () => {
      window.axios.post(store.state.api.routes.subscriptionUpdate, {
          subscriptionId, 
          billingDate: date.value, 
          scheduleId: schedule.value.id
        })
        .then(({ data }) => {
          date.value = null;
          title.value = 'Success!';
          message.value = data.message;
          image.value = '/assets/icon/ic-status-check.svg';
          openModal();
          fetch();
        })
        .catch(({ response }) => {
          title.value = 'Oops!';
          message.value = response.data.message;
          image.value = '/assets/icon/ic-status-warning.svg';
          openModal();
        });
    };

    const cancelSubscription = () => {
      window.axios.post(store.state.api.routes.subscriptionCancel, { subscriptionId })
        .then(({ data }) => {
          title.value = 'Success!';
          message.value = data.message;
          image.value = '/assets/icon/ic-status-check.svg';
          openModal();
          fetch();
        })
        .catch(({ response }) => {
          title.value = 'Oops!';
          message.value = response.data.message;
          image.value = '/assets/icon/ic-status-warning.svg';
          openModal();
        });
    };

    watch(
      () => fetchSubscriptionUrl.value,
      (url) => {
        if(typeof url === 'string') fetch(url);
      },
      { immediate: true }
    );
    
    return {
      date,
      subscription,
      items,
      productNames,
      productImage,
      minDate,
      schedule,
      percentage,
      invoices,
      moneyFormat,
      submit,
      cancelConfirmationModal,
      editSubscription
    };
  }
})
</script>
